import requiredValidator from './requiredValidator';
import numericValidator from './numericValidator';
import alphaValidator from './alphaValidator';
import emailValidator from './emailValidator';

const validators = {
    required: requiredValidator,
    numeric: numericValidator,
    alpha: alphaValidator,
    email: emailValidator,
};

export default validators;
