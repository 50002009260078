import React from 'react';
import MenuItem from './MenuItem';

class MenuContent extends React.Component {
    render() {
        const vm = this;
        const children = React.Children.map(vm.props.children, (child) =>
            child.type === MenuItem ? React.cloneElement(child, {
                menu: vm,
            }) : child
        );

        return (
            <div className={`xs2-menu-content ${vm.props.className}`}>
                {children}
            </div>
        );
    }
}

MenuContent.defaultProps = {
    className: '',
};

export default MenuContent;
