import VendorsContainer from './containers/Vendors';
import VendorContainer from './containers/Vendor';
import MyVendorContainer from './containers/MyVendor';

export default [
    { name: 'vendors-list', component: VendorsContainer },
    { path: 'new', name: 'vendors-new', component: VendorsContainer },
    { path: 'my', name: 'my-vendor', component: MyVendorContainer },
    { path: 'my/:action', name: 'my-vendor', component: MyVendorContainer },
    { path: 'my/clients/new', name: 'vendors-client-edit', component: MyVendorContainer },
    { path: 'my/clients/:clientId/edit', name: 'vendors-client-edit', component: MyVendorContainer },
    { path: ':id', name: 'vendors-get', component: VendorContainer },
    { path: ':id/edit', name: 'vendors-edit', component: VendorsContainer },
    { path: ':id/options', name: 'vendors-options', component: VendorsContainer },
    { path: ':id/calctables', name: 'vendors-calc-tables', component: VendorsContainer },
    { path: ':id/equipment', name: 'vendors-equipment-type', component: VendorsContainer },
    { path: ':id/clients/new', name: 'vendors-client-new', component: VendorsContainer },
    { path: ':id/clients/:clientId', name: 'vendors-client', component: VendorsContainer },
    { path: ':id/clients/:clientId/edit', name: 'vendors-client-edit', component: VendorsContainer },
    { path: ':id/delete', name: 'vendors-delete', component: VendorsContainer },
];
