import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    Form,
    FormGroup,
    Autocomplete,
    Checkbox,
    Datepicker,
    Textarea,
    Input,
    FieldGroup,
    Button,
} from 'common/Form';
import appMessages from 'components/App/messages';
import vendorMessages from 'components/Vendor/messages';
import auth from 'components/Users/auth';
import {VENDORS_URL, MY_VENDOR_URL} from 'components/Vendor/constants';
import {setValue} from 'common/Form/actions';
import {DOSSIERS_URL} from '../constants';
import {newDossier, editDossier} from '../actions';
import messages from '../messages';
import {
    API_URL,
    DEFAULT_LOCALE,
    EQUIPMENT_TYPE_BOTH,
    EQUIPMENT_TYPE_HARDWARE,
    EQUIPMENT_TYPE_SOFTWARE,
} from 'components/App/constants';
import {PAYMENT_TYPES} from '../../../constants';

const EMPTY_PER_PAGE_DETAILS = {
    equipment: '',
    price_black: '',
    price_color_1: '',
    price_color_2: '',
    price_color_3: '',
    extra_price_black: '',
    extra_price_color_1: '',
    extra_price_color_2: '',
    extra_price_color_3: '',
    minimum_black: '',
    minimum_color_1: '',
    minimum_color_2: '',
    minimum_color_3: '',
};

const Operator = ({children}) => <span
    style={{display: 'grid', placeItems: 'center', fontWeight: 'bold'}}>{children}</span>;


class DossierForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        vendors: PropTypes.array,
        user: PropTypes.object,
        intl: PropTypes.object,
    }

    constructor(props) {
        super(props);
        const vm = this;
        const {intl} = props;
        vm.state = {
            dossier: {},
            vendors: props.vendors || [],
            vendor: props.user.vendor || {},
            clientOptions: [],
            vendorOptions: [],
            vendorUserOptions: [],
            durationOptions: [],
            contractOptions: [],
            pricePerPageDetails: [EMPTY_PER_PAGE_DETAILS],
            calcTableIndex: -1,
        };

        vm.formId = `${vm.props.action}-dossier`;
        vm.headers = {
            Authorization: `Token ${props.user.token}`,
            'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl'
        };

        vm.calculatedFieldKeys = [
            'low_limit_amount',
            'high_limit_amount',
            'residual_amount',
        ];
        vm.periodicityOptions = [
            {value: 'MONTH', disabled: true, label: intl.formatMessage(appMessages.month)},
            {value: 'QUARTER', disabled: true, label: intl.formatMessage(appMessages.quarter)},
            {value: 'SEMESTER', disabled: true, label: intl.formatMessage(appMessages.semester)},
            {value: 'YEAR', disabled: true, label: intl.formatMessage(appMessages.year)},
        ];

        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.getVendorData = vm.getVendorData.bind(vm);
        vm.getCalculatedValues = vm.getCalculatedValues.bind(vm);
        vm.addPricePerPageDetails = vm.addPricePerPageDetails.bind(vm);
        vm.removePricePerPageDetails = vm.removePricePerPageDetails.bind(vm);
        vm.onAmountChange = vm.onAmountChange.bind(vm);
    }

    componentWillMount() {
        const vm = this;
        const {router} = vm.context;
        const {user} = vm.props;
        if (vm.props.action === 'new') {
            vm.setState({dossier: {}});
        } else {
            const headers = {
                Authorization: `Token ${user.token}`,
                'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl'
            };
            axios.get(`${DOSSIERS_URL}${router.params.id}/`, {headers}).then((response) => {
                vm.setState({dossier: response.data.result});
            });
        }
    }

    componentDidMount() {
        const vm = this;
        const {vendor} = vm.state;
        vm.getVendors();
        if (!_.isEmpty(vendor)) {
            vm.getVendorData(vendor.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        const vm = this;
        const {router} = vm.context;
        const {[vm.formId]: {fields}} = nextProps.forms;

        if (nextProps.dossiers.length && !_.isEqual(JSON.stringify(nextProps.dossiers), JSON.stringify(vm.props.dossiers))) {
            vm.state.dossier = nextProps.dossiers.find((dossier) => +dossier.id === +router.params.id) || {};
            if (vm.state.dossier.price_per_page_details) {
                vm.state.pricePerPageDetails = vm.state.dossier.price_per_page_details.details;
            }
        }
        if (vm.props.forms[vm.formId] && fields.vendor) {
            const oldVendor = vm.props.forms[vm.formId].fields.vendor.value;
            if (!_.isEqual(fields.vendor.value, oldVendor)) {
                vm.state.vendor = _.find(vm.state.vendors, (vendor) => +vendor.id === +fields.vendor.value);
                vm.getVendorData(fields.vendor.value);
            }
        }

        vm.setState(vm.state);
    }

    async onSubmit(data) {
        const vm = this;
        const {headers} = vm;
        const {router} = vm.context;
        const {toast, user, dispatch} = vm.props;

        if (vm.props.action !== 'administrativeEdit') {
            const calcTable = vm.state.vendor.available_calculationtable_choices[data.calculation_table];
            delete data.calculation_table;
            data.type = calcTable.type;
            data.duration_type = calcTable.duration_type;
            data.ten_twelve_type = calcTable.ten_twelve_type;
            data.payment_type = calcTable.payment_type;
            data.tag = calcTable.tag;

            if (auth.userIsAtlance(user)) {
                data.has_zero_initial = calcTable.zero_amount_type === 'YES';
            } else {
                // Prevent has_zero_initial is in excluded fields error
                // When vendor manager added dossier
                // and Atlance admin tries to edit it.
                delete data.has_zero_initial;
            }

            data.residual_amount = data.residual_amount || 0;

            if (data.start_date) {
                data.start_date = moment(data.start_date).format('YYYY-MM-DD');
            }
            if (!data.vendor) {
                data.vendor = vm.state.vendor.id;
            }
            if (!vm.isTCO()) {
                delete data.price_per_page_details;
            }
            if (vm.quotation) {
                data.status = 'QUOTATION';
            } else if (vm.state.dossier.status === 'QUOTATION') {
                data.status = 'NEW';
            }
        }

        for (const prop in data) { // eslint-disable-line
            if (!data[prop] && data[prop] !== 0 && data[prop] !== false) {
                delete data[prop];
            }
        }
        if (!auth.userIsAtlance(user)) {
            delete data.low_limit_amount;
            delete data.high_limit_amount;
        }

        if (vm.props.action === 'new') {
            axios.post(`${DOSSIERS_URL}`, data, {headers}).then((response) => {
                dispatch(newDossier(response.data.result));
                if (vm.upload) {
                    router.push(`/dossiers/${response.data.result.id}/upload?referrer=${router.params.query}`);
                } else {
                    router.push(`/dossiers/${response.data.result.id}`);
                }
                if (vm.quotation) {
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.createdQuotation}
                                                                       tagName="p"/>);
                }
                return toast.success('Success!', <FormattedMessage {...messages.notifications.createdDossier}
                                                                   tagName="p"/>);
            });
        } else if (vm.props.action === 'edit') {
            const requestBody = {action_code: 'EDITDOSSIER', data};
            axios.post(`${DOSSIERS_URL}${router.params.id}/actions/`, requestBody, {headers}).then((response) => {
                dispatch(editDossier(response.data.result));
                if (vm.upload) {
                    router.push(`/dossiers/${response.data.result.id}/upload?referrer=${router.params.query}`);
                } else {
                    router.push(`/dossiers/${response.data.result.id}`);
                }
                if (vm.quotation) {
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.editedQuotation}
                                                                       tagName="p"/>);
                }
                return toast.success('Success!', <FormattedMessage {...messages.notifications.editedDossier}
                                                                   tagName="p"/>);
            });
        } else if (vm.props.action === 'financialEdit') {
            try {
                await vm.getCalculatedValues();
            }
            catch(exception) {
                console.error(exception);
            }
            const requestBody = {action_code: 'FINANCIALEDITDOSSIER', data};
            axios.post(`${DOSSIERS_URL}${router.params.id}/actions/`, requestBody, {headers}).then((response) => {
                dispatch(editDossier(response.data.result));
                if (vm.upload) {
                    router.push(`/dossiers/${response.data.result.id}/upload?referrer=${router.params.query}`);
                } else {
                    router.push(`/dossiers/${response.data.result.id}`);
                }
                if (vm.quotation) {
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.editedQuotation}
                                                                       tagName="p"/>);
                }
                return toast.success('Success!', <FormattedMessage {...messages.notifications.financialEditedDossier}
                                                                   tagName="p"/>);
            });
        } else if (vm.props.action === 'administrativeEdit') {
            const requestBody = {action_code: 'ADMINISTRATIVEEDITDOSSIER', data};
            axios.post(`${DOSSIERS_URL}${router.params.id}/actions/`, requestBody, {headers}).then((response) => {
                dispatch(editDossier(response.data.result));
                if (vm.upload) {
                    router.push(`/dossiers/${response.data.result.id}/upload?referrer=${router.params.query}`);
                } else {
                    router.push(`/dossiers/${response.data.result.id}`);
                }
                if (vm.quotation) {
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.editedQuotation}
                                                                       tagName="p"/>);
                }
                return toast.success('Success!',
                    <FormattedMessage {...messages.notifications.administrativeEditedDossier} tagName="p"/>);
            });
        }
    }

    getVendors() {
        const vm = this;
        const {headers} = vm;

        if (auth.userIsAtlance(vm.props.user)) {
            axios.get(`${VENDORS_URL}`, {headers}).then((response) => {
                const {forms: {[vm.formId]: {fields}}} = vm.props;
                vm.state.vendors = response.data.result;

                if (vm.state.vendors.length) {
                    vm.state.vendorOptions = _.map(
                        vm.state.vendors,
                        (vendor) => ({
                            value: vendor.id,
                            label: vendor.name,
                        })
                    );
                }
                if (fields.vendor && fields.vendor.value) {
                    vm.getVendorData(fields.vendor.value);
                } else if (!_.isEmpty(vm.state.vendor)) {
                    vm.getVendorData(vm.state.vendor.id);
                }

                vm.setState(vm.state);
            });
        } else {
            axios.get(MY_VENDOR_URL, {headers}).then((response) => {
                vm.state.vendor = response.data.result;
                vm.getVendorData(vm.state.vendor.id);
                vm.setState(vm.state);
            });
        }
    }

    getVendorData(vendorId) {
        const vm = this;
        const id = vendorId instanceof Object ? vendorId.currentTarget.value : vendorId;

        if (id) {
            if (_.isEmpty(vm.state.vendor)) {
                vm.state.vendor = vm.props.user.vendor || _.find(
                    vm.state.vendors,
                    (vendor) => +vendor.id === vendorId
                ) || {};
            }
            vm.setState(vm.state, () => {
                vm.getClients(id);
                vm.getUsers(id);
                vm.getCalculationTables(id);
            });
        }
    }

    getClients(vendorId) {
        const vm = this;
        const {headers} = vm;
        const {toast} = vm.props;

        axios.get(`${VENDORS_URL}${vendorId}/clients/`, {headers}).then((response) => {
            vm.state.clients = response.data.result;

            if (vm.state.clients.length) {
                vm.state.clientOptions = _.map(
                    vm.state.clients,
                    (client) => ({value: client.id, label: client.firmname})
                );
            } else {
                vm.state.clientOptions = [];
                toast.error('Error!', <FormattedMessage {...messages.notifications.missingClients}
                                                        values={{...vm.state.vendor}} tagName="p"/>);
            }

            vm.setState(vm.state);
        });
    }

    getUsers(vendorId) {
        const vm = this;
        const {headers} = vm;
        const {toast} = vm.props;

        axios.get(`${VENDORS_URL}${vendorId}/users/`, {headers}).then((response) => {
            if (response.data.result.length) {
                vm.state.vendorUserOptions = _.map(
                    response.data.result,
                    (user) => ({value: user.id, label: `${user.name || ''} ${user.lastname || ''}`})
                );
            } else {
                vm.state.vendorUserOptions = [];
                toast.error('Error!', <FormattedMessage {...messages.notifications.missingUsers}
                                                        values={{...vm.state.vendor}} tagName="p"/>);
            }

            vm.setState(vm.state);
        });
    }

    getCalculationTables() {
        const vm = this;
        const {dispatch, intl} = vm.props;
        const {dossier, vendor} = vm.state;
        vm.state.contractOptions = _.map(
            vendor.available_calculationtable_choices,
            (options, index) => ({
                value: index,
                label: options.name,
            })
        );
        vm.state.calcTableIndex = _.findIndex(
            vendor.available_calculationtable_choices,
            (choice) => (
                choice.payment_type === dossier.payment_type &&
                choice.type === dossier.type &&
                choice.ten_twelve_type === dossier.ten_twelve_type &&
                choice.duration_type === dossier.duration_type &&
                ((choice.zero_amount_type === 'YES' && dossier.has_zero_initial) ||
                    (choice.zero_amount_type === 'NO' && !dossier.has_zero_initial))
            )
        );

        vm.setState(vm.state, async () => {
            if (~vm.state.calcTableIndex) {
                dispatch(setValue(vm.formId, 'calculation_table', undefined, vm.state.calcTableIndex));
                vm.getCalculatedValues();
            }
        });
    }

    async onAmountChange(event) {
        try {
            await this.getCalculatedValues(event)
        } catch(exception) {
            console.error(exception);
        }
    }

    async getCalculatedValues(event = {currentTarget: {}}) {
        const vm = this;
        const {headers} = vm;
        const {dossier, vendor} = vm.state;
        const {intl, dispatch, forms: {[vm.formId]: {fields}}} = vm.props;
        const calcTableIndex = event.currentTarget.name === 'calculation_table'
            ? event.currentTarget.value
            : fields.calculation_table.value;
        const calcTable = vendor.available_calculationtable_choices[calcTableIndex];
        vm.state.calcTableIndex = calcTableIndex;

        if (!calcTable) {
            return new Promise((resolve) => resolve());
        }

        vm.setState({ loading: true });

        return new Promise((resolve, reject) => {
            const data = {
                vendor_id: vendor.id,
                type: calcTable.type,
                tag: calcTable.tag,
                ten_twelve_type: calcTable.ten_twelve_type,
                duration_type: fields.override_rates.value ? fields.duration_type.value : calcTable.duration_type,
                hardware_amount: Number(fields.hardware_amount?.value) || 0,
                software_amount: Number(fields.software_amount?.value) || 0,
                rental_amount: fields.rental_amount.value ? Number(fields.rental_amount.value) : '',
                duration: fields.duration.value ? Number(fields.duration.value) : 0,
                override_rates: fields.override_rates.value,
                software_rate: fields.override_rates.value ? Number(fields.software_rate.value) : '',
                hardware_rate: fields.override_rates.value ? Number(fields.hardware_rate.value) : '',
                fix_rental_amount: false,
            };

            if (event.currentTarget.name === 'rental_amount') {
                data.hardware_amount = '';
                data.software_amount = '';
            } else {
                data.rental_amount = '';
            }

            axios.post(`${API_URL}calculationtables/calculate/`, data, {headers}).then(response => {
                const { data: { result } } = response;
                dossier.equipment_type = result.equipment_type;
                dossier.hardware_amount = Math.round(result.hardware_amount * 100) / 100;
                dossier.software_amount = Math.round(result.software_amount * 100) / 100;
                dossier.hardware_rate = result.hardware_rate;
                dossier.software_rate = result.software_rate;
                dossier.low_limit_amount = result.low_limit_amount;
                dossier.high_limit_amount = result.high_limit_amount;
                dossier.rental_amount = Math.round(result.rental_amount * 100) / 100;
                dossier.residual_amount = Math.round(result.residual_amount * 100) / 100;
                dossier.maintenance_cost = Math.round(parseFloat(fields.maintenance_cost?.value || 0) * 100) / 100;
                const totalRentPlusMaintenance = parseFloat(dossier.rental_amount) + parseFloat(fields.maintenance_cost?.value || 0);
                dossier.total_rent_plus_maintenance = Math.round(totalRentPlusMaintenance * 100) / 100;
                vm.state.durationOptions = _.map(
                    result.duration_choices,
                    (duration) => ({
                        value: String(duration),
                        label: intl.formatMessage({id: `app.${duration}`, defaultMessage: String(duration)}),
                    })
                );

                _.forEach(vm.calculatedFieldKeys, (key) => {
                    if (_.has(fields, key)) {
                        dispatch(setValue(vm.formId, key, undefined, result[key]));
                    }
                });

                vm.setState(vm.state);
                vm.setState({ loading: false });
                
                resolve();
            }).catch((err) => {
                vm.setState({ loading: true });
                reject(err);
            });
        });
    }

    addPricePerPageDetails() {
        const vm = this;
        vm.state.pricePerPageDetails.push(EMPTY_PER_PAGE_DETAILS);
        vm.setState(vm.state);
    }

    removePricePerPageDetails(index) {
        const vm = this;
        vm.state.pricePerPageDetails.splice(index, 1);
        vm.setState(vm.state);
    }

    isTCO() {
        const vm = this;
        const {dossier, contractOptions} = vm.state;
        const form = vm.props.forms[vm.formId];
        let isTCO = false;
        if (dossier) {
            isTCO = dossier.payment_type === PAYMENT_TYPES.ALLINTCO;
        }
        if (form && contractOptions[form.fields.calculation_table.value]) {
            isTCO = ~contractOptions[form.fields.calculation_table.value].label.indexOf('TCO');
        }

        return isTCO;
    }

    isOverridden() {
        const vm = this;
        const {dossier} = vm.state;
        const form = vm.props.forms[vm.formId];
        let isOverridden = false;

        if (dossier) {
            isOverridden = dossier.override_rates;
        }
        if (form && form.fields.override_rates) {
            isOverridden = form.fields.override_rates.value;
        }

        return isOverridden;
    }

    getCalcTable() {
        const vm = this;
        const {
            vendor,
            calcTableIndex,
        } = vm.state;
        if (~calcTableIndex && vendor?.available_calculationtable_choices) {
            return vendor.available_calculationtable_choices[calcTableIndex]
        }
        return {}
    }

    render() {
        const vm = this;
        const {user} = vm.props;
        const {
            dossier,
            vendor,
            calcTableIndex,
            vendorOptions,
            vendorUserOptions,
            clientOptions,
            durationOptions,
            contractOptions,
            pricePerPageDetails,
        } = vm.state;
        const {
            periodicityOptions,
        } = vm;
        const userIsAtlance = auth.userIsAtlance(user);
        const userIsSales = auth.userIsSales(user);
        const isOverridden = vm.isOverridden();
        const form = vm.props.forms[vm.formId] || {};
        const calcTable = vm.getCalcTable();
        const durationType = calcTable.duration_type || dossier.duration_type;
        const equipmentType = calcTable.equipment_type || dossier.equipment_type;
        const showEquipmentBoth = equipmentType === EQUIPMENT_TYPE_BOTH;
        const showHardwareInput = equipmentType === EQUIPMENT_TYPE_HARDWARE || showEquipmentBoth;
        const showSoftwareInput = equipmentType === EQUIPMENT_TYPE_SOFTWARE || showEquipmentBoth;
        const newOrEdit = vm.props.action === 'edit' || vm.props.action === 'new';
        const financialEdit = vm.props.action === 'financialEdit';
        const administrativeEdit = vm.props.action === 'administrativeEdit';
        const isTCO = newOrEdit || financialEdit ? vm.isTCO() : false;

        return (
            <div id="DossierForm" className="dossier-form">
                <h1 className="center-align"><FormattedMessage {...appMessages[`${vm.props.action}Dossier`]} /></h1>
                <Form id={vm.formId} submitHandler={vm.onSubmit} theme="material">
                    {newOrEdit || financialEdit ?
                        <FormGroup className="dossier-details">
                            <FormGroup className="input-group">
                                {userIsAtlance ?
                                    <Autocomplete
                                        className="input-medium"
                                        name="vendor"
                                        label={appMessages.vendor}
                                        options={vendorOptions}
                                        onChange={vm.getVendorData}
                                        defaultValue={dossier.vendor}
                                        hidden={financialEdit} // In financialEdit the vendor field needs but be visually hidden so that getVendorData is triggered
                                        autoBlur
                                        required
                                    />
                                    : null}
                                {!userIsSales ?
                                    <Autocomplete
                                        className="input-medium"
                                        name="vendor_owner"
                                        label={appMessages.vendorOwner}
                                        options={vendorUserOptions}
                                        defaultValue={dossier.vendor_owner}
                                        autoBlur
                                    />
                                    : null}
                            </FormGroup>
                            <FormGroup className="input-group">
                                <Autocomplete
                                    className="input-medium"
                                    name="client"
                                    label={appMessages.client}
                                    options={clientOptions}
                                    defaultValue={dossier.client}
                                    autoBlur
                                    required
                                />
                                <Autocomplete
                                    className="input-medium"
                                    name="calculation_table"
                                    label={appMessages.calculationTable}
                                    options={contractOptions}
                                    defaultValue={calcTableIndex}
                                    onInput={vm.onAmountChange}
                                    autoBlur
                                    required
                                />
                            </FormGroup>
                        </FormGroup>
                        : null}
                    {newOrEdit || financialEdit ?
                        <FormGroup className="atlance-options">
                            {userIsAtlance && !financialEdit ?
                                <FormGroup className="input-group">
                                    <Checkbox
                                        name="has_start_date"
                                        className="input-small"
                                        label={appMessages.hasStartDate}
                                        defaultValue={!!dossier.has_start_date}
                                    />
                                    {form.fields && form.fields?.has_start_date?.value ?
                                        <Datepicker
                                            name="start_date"
                                            className="input-large"
                                            label={appMessages.startDate}
                                            defaultValue={dossier.start_date}
                                        /> : null}
                                </FormGroup>
                                : null}
                            {userIsAtlance ?
                                <FormGroup className="input-group">
                                    {!financialEdit ?
                                        <Checkbox
                                            name="has_co_debition"
                                            className="input-small"
                                            label={appMessages.hasCoDebition}
                                            defaultValue={!!dossier.has_co_debition}
                                        /> : null}
                                    {form.fields && form.fields?.has_co_debition?.value ?
                                        <Autocomplete
                                            name="co_client"
                                            className="input-large"
                                            label={appMessages.coClient}
                                            options={clientOptions}
                                            defaultValue={dossier.co_client}
                                            autoBlur
                                        /> : null}
                                </FormGroup>
                                : null}
                            <Textarea name="equipment_description" label={appMessages.equipmentDescription}
                                      defaultValue={dossier.equipment_description}/>
                            {userIsAtlance ?
                                <FormGroup className="input-group">
                                    {!financialEdit ?
                                        <Checkbox
                                            name="has_increased_first_rental"
                                            className="input-medium"
                                            label={appMessages.hasIncreasedFirstRental}
                                            defaultValue={!!dossier.has_increased_first_rental}
                                        />
                                        : null}
                                    {form.fields && form.fields?.has_increased_first_rental?.value ?
                                        <Input
                                            type="text"
                                            name="first_amount"
                                            className="input-large"
                                            label={appMessages.firstAmount}
                                            defaultValue={dossier.first_amount}
                                        /> : null}
                                </FormGroup>
                                : null}
                            {userIsAtlance && !financialEdit ?
                                <Checkbox name="process_verbal" label={appMessages.processVerbal}
                                          defaultValue={!!dossier.processVerbal}/>
                                : null}
                            <FormGroup className="input-group">
                                {showHardwareInput && (
                                    <Input
                                        type="text"
                                        name="hardware_amount"
                                        className="input-medium"
                                        label={appMessages.hardwareAmount}
                                        defaultValue={dossier.hardware_amount}
                                        onBlur={(event) => {
                                            vm.onAmountChange(event);
                                            this.setState({ submitButtonDisabled: false});
                                        }}
                                        onFocus={() => this.setState({ submitButtonDisabled: true})}
                                    />
                                )}
                                {showSoftwareInput && (
                                    <Input
                                        type="text"
                                        name="software_amount"
                                        className="input-medium"
                                        label={appMessages.softwareAmount}
                                        defaultValue={dossier.software_amount}
                                        onBlur={(event) => {
                                            vm.onAmountChange(event);
                                            this.setState({ submitButtonDisabled: false });
                                        }}
                                        onFocus={() => this.setState({ submitButtonDisabled: true})}
                                    />
                                )}
                            </FormGroup>
                            <Checkbox
                                name="override_rates"
                                label={appMessages.overrideRates}
                                defaultValue={dossier.override_rates}
                                disabled={!userIsAtlance}
                            />
                            {isOverridden ?
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="hardware_rate"
                                        className="input-medium"
                                        label={appMessages.hardwareRate}
                                        defaultValue={dossier.hardware_rate}
                                        disabled={!userIsAtlance}
                                    />
                                    <Input
                                        type="text"
                                        name="software_rate"
                                        className="input-medium"
                                        label={appMessages.softwareRate}
                                        defaultValue={dossier.software_rate}
                                        disabled={!userIsAtlance}
                                    />
                                </FormGroup>
                                : null}
                            <FormGroup className="input-group">
                                <Autocomplete
                                    name="duration_type"
                                    className="input-medium"
                                    label={appMessages.periodicity}
                                    options={periodicityOptions}
                                    defaultValue={durationType}
                                    onInput={vm.onAmountChange}
                                    autoBlur
                                />
                                <Autocomplete
                                    name="duration"
                                    className="input-medium"
                                    label={appMessages.duration}
                                    options={durationOptions}
                                    defaultValue={dossier.duration}
                                    onInput={vm.onAmountChange}
                                    autoBlur
                                />
                            </FormGroup>
                            <FormGroup className="input-group">
                                <Input
                                    type="text"
                                    name="low_limit_amount"
                                    className="input-medium"
                                    label={appMessages.lowLimitAmount}
                                    defaultValue={dossier.low_limit_amount}
                                    disabled={!userIsAtlance}
                                />
                                <Input
                                    type="text"
                                    name="high_limit_amount"
                                    className="input-medium"
                                    label={appMessages.highLimitAmount}
                                    defaultValue={dossier.high_limit_amount}
                                    disabled={!userIsAtlance}
                                />
                            </FormGroup>

                            {[PAYMENT_TYPES.RENT_PLUS_MAINTENANCE, PAYMENT_TYPES.ALLIN].includes(calcTable.payment_type) ||
                            [PAYMENT_TYPES.RENT_PLUS_MAINTENANCE, PAYMENT_TYPES.ALLIN].includes(calcTable.type) ?
                                <FormGroup className="input-group input-group__rent-plus-maintenance">
                                    <Input
                                        type="text"
                                        name="rental_amount"

                                        label={appMessages.rentalAmount}
                                        defaultValue={dossier.rental_amount}
                                        onFocus={() => this.setState({ submitButtonDisabled: true})}
                                        onBlur={(event) => {
                                            vm.onAmountChange(event);
                                            this.setState({ submitButtonDisabled: false});
                                        }}
                                    />
                                    <Operator>+</Operator>
                                    <Input
                                        type="text"
                                        name="maintenance_cost"
                                        label={appMessages.maintenanceAmount}
                                        defaultValue={dossier.maintenance_cost}
                                        onBlur={vm.onAmountChange}
                                    />
                                    <Operator>=</Operator>
                                    <Input
                                        type="text"
                                        name="total_rent_plus_maintenance"
                                        label={appMessages.totalRentPlusMaintenance}
                                        defaultValue={dossier.total_rent_plus_maintenance}
                                        readOnly
                                        className="input-opaque input-focused"
                                    />
                                </FormGroup>
                                : <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="rental_amount"
                                        className={userIsAtlance ? 'input-normal' : 'input-medium'}
                                        label={appMessages.rentalAmount}
                                        defaultValue={dossier.rental_amount}
                                        onFocus={() => this.setState({ submitButtonDisabled: true })}
                                        onBlur={(event) => {
                                            vm.onAmountChange(event);
                                            this.setState({ submitButtonDisabled: false });
                                        }}
                                    />
                                    {calcTable.zero_amount_type === 'YES' ?
                                        <Input
                                            type="text"
                                            name="zero_amount_intervals"
                                            className="input-normal"
                                            label={appMessages.zeroAmountIntervals}
                                            defaultValue={dossier.zero_amount_intervals}
                                        />
                                        : null}
                                    {calcTable.type === 'LEASE' ?
                                        <Input
                                            type="text"
                                            name="residual_amount"
                                            className={userIsAtlance ? 'input-normal' : 'input-medium'}
                                            label={appMessages.residualAmount}
                                            defaultValue={dossier.residual_amount || '0'}
                                        />
                                        : null}
                                </FormGroup>
                            }

                            {userIsAtlance && !financialEdit ?
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="ggirs"
                                        className="input-normal"
                                        label={appMessages.ggirs}
                                        defaultValue={dossier.ggirs || 0.1}
                                    />
                                </FormGroup>
                                : null}
                        </FormGroup>
                        : null}
                    {isTCO && (newOrEdit || financialEdit) ?
                        <FormGroup className="maintenance-details">
                            <h2><FormattedMessage {...appMessages.tcoOptions} /></h2>
                            <FieldGroup
                                name="price_per_page_details"
                                add={vm.addPricePerPageDetails}
                                remove={vm.removePricePerPageDetails}
                                data={pricePerPageDetails}
                            >
                                <Input type="text" name="flat_amount" label={appMessages.tcoFlatAmount}/>
                                <Input type="text" name="equipment" label={appMessages.equipment} required/>
                                <Input type="text" name="price_black" label={appMessages.priceBlack} required/>
                                <FormGroup className="input-group">
                                    <Input type="text" name="price_color_1" className="input-normal"
                                           label={appMessages.priceColor1} required/>
                                    <Input type="text" name="price_color_2" className="input-normal"
                                           label={appMessages.priceColor2} required/>
                                    <Input type="text" name="price_color_3" className="input-normal"
                                           label={appMessages.priceColor3} required/>
                                </FormGroup>
                                <Input type="text" name="extra_price_black" label={appMessages.extraPriceBlack}
                                       required/>
                                <FormGroup className="input-group">
                                    <Input type="text" name="extra_price_color_1" className="input-normal"
                                           label={appMessages.extraPriceColor1} required/>
                                    <Input type="text" name="extra_price_color_2" className="input-normal"
                                           label={appMessages.extraPriceColor2} required/>
                                    <Input type="text" name="extra_price_color_3" className="input-normal"
                                           label={appMessages.extraPriceColor3} required/>
                                </FormGroup>
                                <Input type="text" name="minimum_black" label={appMessages.minimumPriceBlack} required/>
                                <FormGroup className="input-group">
                                    <Input type="text" name="minimum_color_1" className="input-normal"
                                           label={appMessages.minimumPriceColor1} required/>
                                    <Input type="text" name="minimum_color_2" className="input-normal"
                                           label={appMessages.minimumPriceColor2} required/>
                                    <Input type="text" name="minimum_color_3" className="input-normal"
                                           label={appMessages.minimumPriceColor3} required/>
                                </FormGroup>
                            </FieldGroup>
                        </FormGroup>
                        : null}
                    {!isTCO && calcTable.tag === 'MAINTENANCE' ?
                        <FormGroup className="maintenance-details">
                            <h2><FormattedMessage {...appMessages.maintenance} /></h2>
                            <FormGroup className="input-group">
                                <Input
                                    type="text"
                                    name="maintenance_cost"
                                    className="input-normal"
                                    label={appMessages.maintenanceCost}
                                    defaultValue={dossier.maintenance_cost}
                                />
                                <Input
                                    type="text"
                                    name="maintenance_contract_owner"
                                    className="input-normal"
                                    label={appMessages.maintenanceContractOwner}
                                    defaultValue={dossier.maintenance_contract_owner}
                                />
                            </FormGroup>
                            {vm.state.dossier.payment_type !== PAYMENT_TYPES.ALLIN &&
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="number_of_pages"
                                        className="input-normal"
                                        label={appMessages.numberOfPages}
                                        defaultValue={dossier.number_of_pages}
                                    />
                                    <Input
                                        type="text"
                                        name="page_price_black"
                                        className="input-normal"
                                        label={appMessages.pagePriceBlack}
                                        defaultValue={dossier.page_price_black}
                                    />
                                    <Input
                                        type="text"
                                        name="page_price_color"
                                        className="input-normal"
                                        label={appMessages.pagePriceColor}
                                        defaultValue={dossier.page_price_color}
                                    />
                                </FormGroup>
                            }
                        </FormGroup>
                        : null}
                    {newOrEdit || administrativeEdit ?
                        <FormGroup className="layout-row">
                            <FormGroup className="building-details flex">
                                <h2><FormattedMessage {...appMessages.building} /></h2>
                                <Checkbox name="is_client_owner_of_building" label={appMessages.isClientOwnerOfBuilding}
                                          defaultValue={!!dossier.is_client_owner_of_building}/>
                                <Input type="text" name="owner_name" label={appMessages.name}
                                       defaultValue={dossier.owner_name}/>
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="owner_street"
                                        className="input-large"
                                        label={appMessages.street}
                                        defaultValue={dossier.owner_street}
                                    />
                                    <Input
                                        type="text"
                                        name="owner_number"
                                        className="input-small"
                                        label={appMessages.number}
                                        defaultValue={dossier.owner_number}
                                    />
                                </FormGroup>
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="owner_postalcode"
                                        className="input-small"
                                        label={appMessages.postalcode}
                                        defaultValue={dossier.owner_postalcode}
                                    />
                                    <Input
                                        type="text"
                                        name="owner_city"
                                        className="input-large"
                                        label={appMessages.city}
                                        defaultValue={dossier.owner_city}
                                    />
                                </FormGroup>
                            </FormGroup>
                            <FormGroup className="creditor-details flex">
                                <h2><FormattedMessage {...appMessages.creditor} /></h2>
                                <Checkbox name="has_creditor" label={appMessages.hasCreditor}
                                          defaultValue={!!dossier.has_creditor}/>
                                <Input type="text" name="creditor_name" label={appMessages.name}
                                       defaultValue={dossier.creditor_name}/>
                                <FormGroup className="input-group">
                                    <Input type="text" name="creditor_street" className="input-large"
                                           label={appMessages.street} defaultValue={dossier.creditor_street}/>
                                    <Input type="text" name="creditor_number" className="input-small"
                                           label={appMessages.number} defaultValue={dossier.creditor_number}/>
                                </FormGroup>
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="creditor_postalcode"
                                        className="input-small"
                                        label={appMessages.postalcode}
                                        defaultValue={dossier.creditor_postalcode}
                                    />
                                    <Input
                                        type="text"
                                        name="creditor_city"
                                        className="input-large"
                                        label={appMessages.city}
                                        defaultValue={dossier.creditor_city}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </FormGroup>
                        : null}
                    {newOrEdit || administrativeEdit ?
                        <FormGroup className="layout-row">
                            <FormGroup className="guarantee-details flex">
                                <h2><FormattedMessage {...appMessages.guarantee} /></h2>
                                <Checkbox name="has_guarantee" label={appMessages.hasGuarantee}
                                          defaultValue={!!dossier.has_guarantee}/>
                                <Input type="text" name="guarantee_period" label={appMessages.guaranteePeriod}
                                       defaultValue={dossier.guarantee_period}/>
                            </FormGroup>
                            <FormGroup className="constructor-details flex">
                                <h2><FormattedMessage {...appMessages.maintenance} /></h2>
                                <Checkbox name="has_constructor" label={appMessages.hasConstructor}
                                          defaultValue={!!dossier.has_constructor}/>
                                <Checkbox name="constructor_is_other_company"
                                          label={appMessages.constructorIsOtherCompany}
                                          defaultValue={!!dossier.constructor_is_other_company}/>
                                <Input type="text" name="constructor_name" label={appMessages.name}
                                       defaultValue={dossier.constructor_name}/>
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="constructor_street"
                                        className="input-large"
                                        label={appMessages.street}
                                        defaultValue={dossier.constructor_street}
                                    />
                                    <Input
                                        type="text"
                                        name="constructor_number"
                                        className="input-small"
                                        label={appMessages.number}
                                        defaultValue={dossier.constructor_number}
                                    />
                                </FormGroup>
                                <FormGroup className="input-group">
                                    <Input
                                        type="text"
                                        name="constructor_postalcode"
                                        className="input-small"
                                        label={appMessages.postalcode}
                                        defaultValue={dossier.constructor_postalcode}
                                    />
                                    <Input
                                        type="text"
                                        name="constructor_city"
                                        className="input-large"
                                        label={appMessages.city}
                                        defaultValue={dossier.constructor_city}
                                    />
                                </FormGroup>
                                <Input
                                    type="text"
                                    name="maintenance_contract_number"
                                    label={appMessages.maintenanceContractNumber}
                                    defaultValue={dossier.maintenance_contract_number}
                                />
                            </FormGroup>
                        </FormGroup>
                        : null}
                    {vm.props.action === 'new' || dossier.status === 'QUOTATION' && !administrativeEdit ?
                        <FormGroup>
                            <Button onClick={() => {
                                vm.quotation = true;
                            }}><FormattedMessage {...appMessages.saveQuotation} /></Button>
                        </FormGroup>
                        : null}
                    {administrativeEdit ?
                         <FormGroup>
                            <Button><FormattedMessage {...appMessages.saveAdministrative} /></Button>
                        </FormGroup>
                    :
                        <FormGroup>
                            <Button disabled={vm.state.submitButtonDisabled} loading={vm.state.loading}><FormattedMessage {...appMessages.saveRequest} /></Button>
                        </FormGroup> }
                    {!administrativeEdit ?
                        <FormGroup>
                            <Button onClick={() => {
                                vm.upload = true;
                            }}>
                                <FormattedMessage {...appMessages.saveRequestWithDoc} />
                                {/* <FormattedMessage {...appMessages.saveRequest} /> & <FormattedMessage {...appMessages.withDoc} /> */}
                            </Button>
                        </FormGroup> : null}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('forms').toJS(),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (user, forms, toast, dossiers) => ({user, forms, toast, dossiers})
);

export default connect(mapStateToProps, null)(injectIntl(DossierForm));
