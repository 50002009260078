import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Button, Datepicker } from 'common/Form';

import appMessages from 'components/App/messages';
import { editDossier } from '../actions';
import { DOSSIERS_URL } from '../constants';
import messages from '../messages';
import { API_URL, DEFAULT_LOCALE } from 'components/App/constants';

class SignForm extends React.Component {
    constructor(props, context) {
        super(props);

        const vm = this;

        vm.dossierId = +context.router.params.id;
        vm.state = {
            dateNow: Date.now(),
            dossier: {},
            actions: [],
            requests: [],
            loading: true,
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getData();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const nextDossier = _.find(nextProps.dossiers, { id: vm.dossierId });

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.dossierId = +vm.context.router.params.id;
            vm.getData();
        }

        if (!_.isEmpty(nextDossier) && !_.isEqual(nextDossier, vm.state.dossier)) {
            vm.state.dossier = nextDossier;
        }

        vm.setState(vm.state);
    }
    getData() {
        const vm = this;
        const { dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.state.loading = true;
        vm.setState(vm.state);

        axios.get(`${DOSSIERS_URL}${vm.dossierId}/`, { headers }).then((response) => {
            const dossier = response.data.result;
            vm.setState(vm.state);

            const data = {
                vendor_id: dossier.vendor,
                type: dossier.type,
                tag: dossier.tag,
                ten_twelve_type: dossier.ten_twelve_type,
                hardware_amount: dossier.hardware_amount,
                software_amount: dossier.software_amount,
                duration: dossier.duration,
                duration_type: dossier.duration_type,
                software_rate: dossier.software_rate,
                hardware_rate: dossier.hardware_rate,
                override_rates: dossier.override_rates,
                rental_amount: dossier.rental_amount,
                fix_rental_amount: dossier.fix_rental_amount,
            };

            vm.state.loading = false;
            vm.setState(vm.state);
            dispatch(editDossier(dossier));
        });
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const id = vm.context.router.params.id;

        data.client_sign_date = moment(data.client_sign_date).format('YYYY-MM-DD');

        axios.post(`${DOSSIERS_URL}${id}/actions/`, { action_code: 'SIGN', 'data': data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${id}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.signedDossier} tagName="p" />);
        }).catch((response) => {
            toast.error('Error!', <p>{response.message}</p>);
        });
    }
    render() {
        const vm = this;
        const { dossier } = vm.state;

        return (
            <div id="SignForm">
                <h1 className="center-align"><FormattedMessage {...appMessages.actions.SIGN} /></h1>
                <Form submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Datepicker name="client_sign_date" className="input-medium" label={appMessages.clientSignDate} defaultValue={vm.state.dateNow} />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

SignForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (user, toast, dossiers) => ({ user, toast, dossiers })
);

export default connect(mapStateToProps, null)(SignForm);
