import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FieldBase } from './FieldBase';

class Autocomplete extends FieldBase {
    constructor(props) {
        super(props);

        const vm = this;
        vm.onFocus = vm.onFocus.bind(vm);
        vm.onChange = vm.onChange.bind(vm);
        vm.onBlur = vm.onBlur.bind(vm);
    }
    onChange(newValue) {
        const vm = this;
        const value = (newValue instanceof Array) ? (_.map(newValue, (i) => i.value)) : (newValue.value);
        const fakeEvent = { persist: _.noop, preventDefault: _.noop, currentTarget: { ...vm.props, value } };
        super.onInput(fakeEvent);
        super.onBlur(fakeEvent);
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId];
        const field = form.fields[vm.props.name] || {};
        const containerClasses = `input-container input-container-autocomplete input-container-${vm.props.name} ${vm.props.className} ${vm.state.focus ? ' input-focused' : ''}`;
        const theme = vm.props.theme || form.theme;
        let error = vm.state.errors[0];

        if (error) {
            error = <div className="input-validation-error"><FormattedMessage id={error.id} defaultMessage={error.error} /></div>;
        }

        return (
            <div className={containerClasses} hidden={vm.props.hidden}>
                {vm.props.label ? <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} /></label> : null}
                <Select
                    ref={(ref) => { this.select = ref; }}
                    name={vm.props.name}
                    value={field.value != null ? field.value : vm.props.defaultValue}
                    options={vm.props.options}
                    onFocus={vm.onFocus}
                    onChange={vm.onChange}
                    autoBlur={vm.props.autoBlur}
                    multi={vm.props.multi}
                    resetValue={vm.props.resetValue || ''}
                />
                <ReactCSSTransitionGroup
                    transitionName={theme}
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                >
                    {error}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null, null, { withRef: true })(Autocomplete);
