import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Textarea, Autocomplete, FileField, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { VENDORS_URL } from 'components/Vendor/constants';
import { DOWNLOADS_URL } from '../constants';
import { newDownload } from '../actions';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class UploadFileForm extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            vendorOptions: [],
        };

        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };


        axios.get(`${VENDORS_URL}`, { headers }).then((response) => {
            _.forEach(response.data.result, (vendor) => {
                vm.state.vendorOptions.push({ value: vendor.id, label: vendor.name });
            });

            vm.setState(vm.state);
        });
    }
    onSubmit(data) {
        const vm = this;
        const { router } = vm.context;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const referrer = router.getCurrentLocation().query.referrer;
        const formData = new FormData();

        formData.append('file', data.file[0]);
        delete data.file;
        formData.append('data', JSON.stringify(data));

        axios.post(DOWNLOADS_URL, formData, { headers }).then((response) => {
            dispatch(newDownload(response.data.result));
            router.push(referrer || '/downloads');
            return toast.success('Success!', <FormattedMessage {...messages.notifications.fileUploaded} tagName="p" />);
        });
    }
    render() {
        const vm = this;
        const { vendorOptions } = vm.state;

        return (
            <div className="FileUpload">
                <h1 className="center-align"><FormattedMessage {...appMessages.uploadFile} /></h1>
                <Form id="file-upload" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup className="layout-row layout-xs-column layout-wrap">
                        <FormGroup className="flex-50 flex-xs-100">
                            <FileField name="file" emptyLabel={<FormattedMessage {...appMessages.nofile} />} preview required />
                        </FormGroup>
                        <FormGroup className="flex-50 flex-xs-100">
                            <Input type="text" name="name" label={appMessages.name} required />
                            <Textarea name="description" label={appMessages.details} required />
                            <Autocomplete
                                name="vendors"
                                label={appMessages.vendors}
                                options={vendorOptions}
                                autoBlur
                                multi
                                required
                            />
                        </FormGroup>
                    </FormGroup>
                    <FormGroup className="flex-100">
                        <Button><FormattedMessage {...appMessages.upload} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

UploadFileForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('downloads'),
    (user, toast, downloads) => ({ user, toast, downloads })
);

export default connect(mapStateToProps, null)(UploadFileForm);
