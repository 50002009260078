import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FieldBase } from './FieldBase';

class Textarea extends FieldBase {
    getContainerClassList() {
        const vm = this;
        return [
            'input-container',
            `input-container-${vm.props.name}${vm.state.focus ? ' input-focused' : ''}`,
            vm.props.className,
        ].join(' ');
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId] || {};
        const field = (form.fields || {})[vm.props.name] || {};
        const theme = vm.props.theme || form.theme;
        let error = vm.state.errors[0];

        if (error) {
            error = <div className="input-validation-error"><FormattedMessage id={error.id} defaultMessage={error.error} /></div>;
        }

        return (
            <div className={vm.getContainerClassList()}>
                {vm.props.label ? <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} />&nbsp;{vm.props.labelSuffix}</label> : null}
                <textarea
                    id={vm.props.id}
                    name={vm.props.name}
                    className={vm.props.className}
                    value={field.value}
                    onFocus={vm.onFocus}
                    onBlur={vm.onBlur}
                    onInput={vm.onInput}
                ></textarea>
                <ReactCSSTransitionGroup transitionName={theme} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {error}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null)(Textarea);
