import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Table, Tr, Td } from 'reactable';

import { Form, FormGroup, Input, Textarea, Autocomplete, Datepicker, Button, Checkbox } from 'common/Form';
import appMessages from 'components/App/messages';
import languageMessages from 'components/LanguageSwitcher/messages';
import { ibanFormatter, vatValidator, vatRegex } from 'components/App/utils';
import { API_URL } from 'components/App/constants';
import { VENDORS_URL } from 'components/Vendor/constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';
import Loader from 'components/App/partials/Loader';
import auth from 'components/Users/auth';

class VendorClientsForm extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;
        vm.state = {
            edit: false,
            client: {},
            retrievingCompanyInfo: false,
            userIsVendor: auth.userIsVendor(props.user),
            RRECodeResults: {
                loading: false,
                empty: false,
                data: [],
            },
        };
        vm.fields = {};
        vm.countryOptions = [
            { value: 'BE', label: intl.formatMessage(languageMessages.countries.be) },
            { value: 'FR', label: intl.formatMessage(languageMessages.countries.fr) },
            { value: 'LU', label: intl.formatMessage(languageMessages.countries.lu) },
            { value: 'NL', label: intl.formatMessage(languageMessages.countries.nl) },
        ];
        vm.languageOptions = [
            { value: 'FR', label: intl.formatMessage(languageMessages.languages.fr) },
            { value: 'EN', label: intl.formatMessage(languageMessages.languages.en) },
            { value: 'NL', label: intl.formatMessage(languageMessages.languages.nl) },
        ];
        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.getClientBI = _.debounce(vm.getClientBI.bind(vm), 500);
        vm.nationalRegistryNumberToBirthdate = _.debounce(vm.nationalRegistryNumberToBirthdate.bind(vm), 500);
        vm.formId = 'vendor-client-options';
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
    }
    componentDidMount() {
        const vm = this;
        const { headers } = vm;
        const clientId = vm.context.router.params.clientId;

        if (clientId) {
            axios.get(`${API_URL}clients/${clientId}/`, { headers }).then((response) => {
                vm.state.edit = true;
                vm.state.client = response.data.result;
                vm.setState(vm.state);
            });
        }
    }
    onSubmit(data) {
        const vm = this;
        const { headers } = vm;
        const { toast, vendorId } = vm.props;
        const { router } = vm.context;
        const clientId = router.params.clientId;
        const vendorName = vm.props.vendors.find((i) => i.id === +vendorId).name;

        if (data.date_of_birth) {
            if (moment.isMoment(data.date_of_birth)) {
                data.date_of_birth = data.date_of_birth.toISOString();
            } else {
                if (data.date_of_birth.length > 0) {
                    data.date_of_birth = moment(data.date_of_birth).toISOString();
                } else {
                    delete data.date_of_birth;
                }
            }
        }

        if (data.activity_start) {
            if (moment.isMoment(data.activity_start)) {
                data.activity_start = data.activity_start.toISOString();
            } else {
                if (data.activity_start.length > 0) {
                    data.activity_start = moment(data.activity_start).toISOString();
                } else {
                    delete data.activity_start;
                }
            }
        }

        if (!data.nace) {
            delete data.nace;
        }

        if (clientId && vm.state.client) {
            axios.patch(`${API_URL}clients/${clientId}/`, data, { headers }).then((response) => {
                vm.context.router.push(router.location.query.referrer || `/vendors/${vendorId}`);
                return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedClient} values={{ ...response.data.result }} tagName="p" />);
            });
        } else {
            axios.post(`${VENDORS_URL}${vendorId}/clients/`, data, { headers }).then((response) => {
                vm.context.router.push(router.location.query.referrer || `/vendors/${vendorId}`);
                return toast.success('Success!', <FormattedMessage {...messages.notifications.createdClient} values={{ ...response.data.result, vendorName }} tagName="p" />);
            });
        }
    }
    nationalRegistryNumberToBirthdate(event, value) {
        const vm = this;

        // Only convert national registry number if we are from Belgium
        if (vm.fields.country_code.state.value !== 'BE') {
            return false;
        }

        if (value.length != 11) {
            return false;
        }

        if (!parseInt(value)) {
            return false;
        }

        const checkDigit = value.substr(value.length - 2, 2);
        const modFunction = function(nr) { return 97 - (nr % 97); };

        let NRN = parseInt(value.substr(0, 9));
        let birthdate = null;

        // 1900 dates
        if (modFunction(NRN) == checkDigit) {
            birthdate =  '19' + value[0] + value[1] + '-' + value[2] + value[3] + '-' +  value[4] + value[5];
        }

        // Y2K+ dates
        NRN = parseInt('2' + value.substr(0, 9));
        if (modFunction(NRN) == checkDigit) {
            birthdate =  '20' + value[0] + value[1] + '-' + value[2] + value[3] + '-' +  value[4] + value[5];
        }

        const input = vm.fields.date_of_birth.getWrappedInstance();
        // Auto fill birth date
        if (input && birthdate) {
            input.setValue(birthdate || '');
        }
    }
    getClientBI(event, value) {
        const vm = this;
        const { headers } = vm;
        const vat = value;
        const isValidVAT = vatRegex.test(vat);

        if (isValidVAT) {
            vm.setState({ retrievingCompanyInfo: true });
            axios.get(`${API_URL}vat_check/`, { params: { vat }, headers }).then((response) => {
                const company = response.data.result;
                _.forEach(vm.fields, (field, key) => {
                    if (typeof field.getWrappedInstance === 'function') {
                        const input = field.getWrappedInstance();
                        if (input) {
                            input.setValue(company[key] || '');
                        }
                    }
                });
            }).finally(() => {
                vm.setState({ retrievingCompanyInfo: false });
            });
        }
    }
    searchRRECode(event, value) {
        const vm = this;
        const { headers } = vm;
        const { router } = vm.context;
        const clientId = router.params.clientId;

        vm.setState({ RRECodeResults: { ...vm.state.RRECodeResults, loading: true } });
        axios.get(`${API_URL}clients/${clientId}/rrecode/`, { headers }).then((response) => {
            const results = response.data.result;
            if (!results.length) {
                vm.setState({ RRECodeResults: { ...vm.state.RRECodeResults, data: [], empty: true} });
            } else {
                vm.setState({ RRECodeResults: { ...vm.state.RRECodeResults, data: results, empty: false } });
            }
        }).finally(() => {
            vm.setState({ RRECodeResults: { ...vm.state.RRECodeResults, loading: false } });
        });
    }
    addRRECode(event, value) {
        const vm = this;
        const { headers } = vm;
        const { toast } = vm.props;
        const { router } = vm.context;
        const clientId = router.params.clientId;
        const data = { econocom_rrecode: value };

        axios.patch(`${API_URL}clients/${clientId}/`, data, { headers }).then((response) => {
            vm.setState({ client: { ...vm.state.client, ...data} });
            return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedClient} values={{ ...response.data.result }} tagName="p" />);
        });
      }
    render() {
        const vm = this;
        const { client, userIsVendor } = vm.state;
        const vendor = vm.props.vendors.find((i) => i.id === +vm.props.vendorId) || {};
        const form = vm.props.forms[vm.formId] || {};
        const { intl } = vm.props;


        return (
            <div id="VendorClientsForm" className="vendor-clients-form">
                <h1 className="center-align">{vendor.name}</h1>
                <h2 className="center-align">{vm.state.edit ? <FormattedMessage {...appMessages.edit} /> : <FormattedMessage {...appMessages.add} />} client</h2>
                <Form id={vm.formId} submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <h2 className="form-group-label"><FormattedMessage {...messages.vat} /></h2>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="vat"
                                className="input-small"
                                label={appMessages.vat}
                                defaultValue={client.vat}
                                onInput={vm.getClientBI}
                                validator={vatValidator}
                                loading={this.state.retrievingCompanyInfo}
                                // required
                            />
                        </FormGroup>
                    </FormGroup>
                    {!userIsVendor ? (
                        <FormGroup>
                            <h2 className="form-group-label"><FormattedMessage {...appMessages.rrecode} /></h2>
                            <FormGroup className="input-group">
                                <Input
                                    type="text"
                                    name="rre"
                                    className="input-small"
                                    label={appMessages.rrecode}
                                    defaultValue={client.econocom_rrecode ? client.econocom_rrecode : intl.formatMessage(appMessages.na)}
                                    readOnly
                                />
                                {!client.econocom_rrecode && (
                                    <Button type="button" onClick={this.searchRRECode.bind(this)}>
                                        <FormattedMessage {...appMessages.searchRRECode} />
                                    </Button>
                                )}
                            </FormGroup>
                            {!client.econocom_rrecode && (
                                <Loader active={vm.state.RRECodeResults.loading}>
                                    {vm.state.RRECodeResults.empty && (
                                        <p className="center-align"><FormattedMessage {...appMessages.noResultsFound} /></p>
                                    )}
                                    {vm.state.RRECodeResults.data.length > 0 && (
                                        <div style={{marginTop: '25px'}}>
                                            <h3><FormattedMessage {...appMessages.searchResults} /></h3>
                                            <Table className="table">
                                                {vm.state.RRECodeResults.data.map((result, index) => (
                                                    <Tr key={index}>
                                                        <Td column={intl.formatMessage(appMessages.rrecode)}>{result.rrecode}</Td>
                                                        <Td column={intl.formatMessage(appMessages.firmname)}>{result.firmname}</Td>
                                                        <Td column={intl.formatMessage(appMessages.address)}>{result.address}</Td>
                                                        <Td column={intl.formatMessage(appMessages.postalcode)}>{result.postalcode}</Td>
                                                        <Td column={intl.formatMessage(appMessages.city)}>{result.city}</Td>
                                                        <Td column={intl.formatMessage(appMessages.country)}>{result.country}</Td>
                                                        <Td column={intl.formatMessage(appMessages.structureType)}>{result.structure_type}</Td>
                                                        <Td column="">
                                                            <Button type="button" onClick={event => this.addRRECode(event, result.rrecode)}>
                                                                <FormattedMessage {...appMessages.addThisRRECode} />
                                                            </Button>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Table>
                                        </div>
                                    )}
                                    {(vm.state.RRECodeResults.empty || vm.state.RRECodeResults.data.length > 0) && (
                                        // eslint-disable-next-line jsx-a11y/href-no-hash
                                        <a
                                            className="button button-default"
                                            href="#"
                                            onClick={() => window.open('https://rre.econocom.com/request/create/atlancebelux', '_blank', 'toolbar=0,location=0,menubar=0')}
                                        >
                                            <FormattedMessage {...appMessages.createRRECode} />
                                        </a>
                                    )}
                                </Loader>
                            )}
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <h2 className="form-group-label"><FormattedMessage {...messages.address} /></h2>
                        <FormGroup className="input-group">
                        <Input
                            type="text"
                            name="firmname"
                            className="input-large"
                            label={appMessages.firmname}
                            defaultValue={client.firmname}
                            // required
                            ref={(ref) => { vm.fields.name = ref && ref.getWrappedInstance(); }}
                        />
                        <Autocomplete
                            type="text"
                            name="language"
                            className="input-small"
                            label={appMessages.language}
                            options={vm.languageOptions}
                            defaultValue={client.language || 'NL'}
                            // required
                            ref={(ref) => { vm.fields.language_code = ref && ref.getWrappedInstance(); }}
                        />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="street"
                                className="input-large"
                                label={appMessages.street}
                                defaultValue={client.street}
                                // required
                                ref={(ref) => { vm.fields.street = ref && ref.getWrappedInstance(); }}
                            />
                            <Input
                                type="text"
                                name="number"
                                className="input-small"
                                label={appMessages.number}
                                defaultValue={client.number}
                                // required
                                ref={(ref) => { vm.fields.number = ref && ref.getWrappedInstance(); }}
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="postalcode"
                                className="input-small"
                                label={appMessages.postalcode}
                                defaultValue={client.postalcode}
                                // required
                                ref={(ref) => { vm.fields.postalcode = ref && ref.getWrappedInstance(); }}
                            />
                            <Input
                                type="text"
                                name="bus"
                                className="input-small"
                                label={appMessages.bus}
                                defaultValue={client.bus}
                                // required
                            />
                            <Autocomplete
                                type="text"
                                name="country"
                                className="input-small"
                                label={appMessages.country}
                                options={vm.countryOptions}
                                defaultValue={client.country || 'BE'}
                                // required
                                ref={(ref) => { vm.fields.country_code = ref && ref.getWrappedInstance(); }}
                            />
                            <Input
                                type="text"
                                name="city"
                                className="input-small"
                                label={appMessages.city}
                                defaultValue={client.city}
                                // required
                                ref={(ref) => { vm.fields.city = ref && ref.getWrappedInstance(); }}
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup>
                        <h2 className="form-group-label"><FormattedMessage {...messages.financial} /></h2>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="last_name"
                                className="input-medium"
                                label={appMessages.lastName}
                                defaultValue={client.last_name}
                                // required
                            />
                            <Input
                                type="text"
                                name="name"
                                className="input-medium"
                                label={appMessages.firstName}
                                defaultValue={client.name}
                                // required
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="phone"
                                className="input-normal"
                                label={appMessages.phone}
                                defaultValue={client.phone}
                                // required
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="function"
                                className="input-medium"
                                label={appMessages.function}
                                defaultValue={client.function}
                                // required
                            />
                            <Input
                                type="email"
                                name="mail_accounting"
                                className="input-medium"
                                label={appMessages.mailAccounting}
                                defaultValue={client.mail_accounting}
                                noValidate
                                // required
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="iban"
                                className="input-small"
                                label={appMessages.iban}
                                defaultValue={client.iban}
                                formatter={ibanFormatter}
                                // required
                            />
                            <Input
                                type="text"
                                name="swift_bic"
                                className="input-small"
                                label={appMessages.swiftBic}
                                defaultValue={client.swift_bic}
                                // required
                            />
                            <Input
                                type="text"
                                name="nace"
                                className="input-small"
                                label={appMessages.nace}
                                defaultValue={client.nace}
                                // required
                            />
                        </FormGroup>
                        <Checkbox
                            name="is_school"
                            className="input-small"
                            label={appMessages.isSchool}
                            defaultValue={!!client.is_school}
                        />
                        <Checkbox
                            name="is_physical_person"
                            className="input-small"
                            label={appMessages.isPhysicalPerson}
                            defaultValue={!!client.is_physical_person}
                        />
                    </FormGroup>
                    {form.fields && form.fields.is_physical_person.value ?
                        [
                            <FormGroup>
                                <h2 className="form-group-label">Person info</h2>
                                <FormGroup className="input-group">
                                    <Autocomplete
                                        type="text"
                                        name="nationality"
                                        className="input-normal"
                                        label={appMessages.country}
                                        options={vm.countryOptions}
                                        defaultValue={client.nationality || 'BE'}
                                        // required
                                        ref={(ref) => { vm.fields.country_code = ref && ref.getWrappedInstance(); }}
                                    />

                                    <Input
                                        type="text"
                                        className="input-medium"
                                        name="national_registry_no"
                                        label={appMessages.nationalRegistryNo}
                                        onInput={vm.nationalRegistryNumberToBirthdate}
                                        defaultValue={client.national_registry_no}
                                    />
                                </FormGroup>
                                <FormGroup className="input-group">
                                    <Input
                                        type="email"
                                        name="email"
                                        className="input-normal"
                                        label={appMessages.email}
                                        defaultValue={client.email}
                                        noValidate
                                    />

                                    <Datepicker
                                        name="date_of_birth"
                                        className="input-normal"
                                        label={appMessages.dateOfBirth}
                                        defaultValue={client.date_of_birth}
                                        ref={(ref) => { vm.fields.date_of_birth = ref && ref.getWrappedInstance(); }}
                                    />
                                    <Datepicker
                                        name="activity_start"
                                        className="input-normal"
                                        label={appMessages.activityStart}
                                        defaultValue={client.activity_start}
                                    />
                                </FormGroup>
                                <Textarea
                                    key="1"
                                    name="activity_description"
                                    className="input-xlarge"
                                    label={appMessages.activityDescription}
                                    defaultValue={client.activity_description || intl.formatMessage(appMessages.commercant)}
                                />
                            </FormGroup>
                        ]
                    : null}
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

VendorClientsForm.contextTypes = {
    router: PropTypes.object,
};
VendorClientsForm.propTypes = {
    intl: PropTypes.object,
    user: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('forms').toJS(),
    (state) => state.get('vendors'),
    (state) => state.get('dashboard').get('toast'),
    (user, forms, vendors, toast) => ({ user, forms, vendors, toast })
);

export default connect(mapStateToProps, null)(injectIntl(VendorClientsForm));
