import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Table, Tr, Td } from 'reactable';
import { Link } from 'react-router';

import appMessages from 'components/App/messages';

class Users extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {};
    }

    componentDidMount() {
        const vm = this;

        vm.setState({
            columns: [
                { label: 'Name', key: 'name' },
                { label: 'Email', key: 'email' },
                { label: 'Role', key: 'role' },
                { label: 'Status', key: 'is_active' },
                { label: 'Actions', key: 'actions' },
            ],
        });
    }

    render() {
        const vm = this;
        const rows = [];

        vm.props.users.forEach((user) => {
            const status = user.is_active ? <FormattedMessage {...appMessages.active} /> : <FormattedMessage {...appMessages.inactive} />;
            rows.push(
                <Tr key={user.id} data-id={user.id} onClick={vm.onClick}>
                    <Td label="Name" column="name">{user.name}</Td>
                    <Td label="Email" column="email">{user.email}</Td>
                    <Td label="Role" column="role">{user.role}</Td>
                    <Td label="Status" column="is_active">{status}</Td>
                    <Td label="Actions" column="actions">
                        <div className="user-actions">
                            <Link to={`/users/${user.id}/edit`}><i className="icon-edit"></i></Link>
                            <Link to={`/users/${user.id}/delete`}><i className="icon-trash"></i></Link>
                        </div>
                    </Td>
                </Tr>
            );
        });

        return (
            <div className="user-list-container">
                <Table
                    className="user-list table-highlight"
                    columns={vm.state.columns}
                    defaultSort={{ column: 'name', direction: 'asc' }}
                    itemsPerPage={10}
                    pageButtonLimit={5}
                >
                    {rows}
                </Table>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('users'),
    (user, users) => ({ user, users })
);

export default connect(mapStateToProps, null)(Users);
