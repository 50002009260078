/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';
import moment from 'moment';

import {
    CHANGE_LOCALE,
} from './constants';
import { DEFAULT_LOCALE } from '../App/constants';

const locale = window.localStorage.getItem('locale') || DEFAULT_LOCALE;
const initialState = fromJS({ locale });
moment.locale(locale);

function languageProviderReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LOCALE:
            moment.locale(action.locale);
            localStorage.setItem('locale', action.locale);
            return state.set('locale', action.locale);
        default:
            return state;
    }
}

export default languageProviderReducer;
