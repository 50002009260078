import PropTypes from 'prop-types';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuTrigger, MenuContent, MenuItem } from 'common/Util';
import Loader from 'components/App/partials/Loader';
import auth from 'components/Users/auth';

import appMessages from '../../App/messages';
import { DOSSIERS_URL, DOCUMENT_TYPES } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class DocumentUploader extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            show: props.show || false,
            type: null,
            file: null,
        };
        vm.onDrop = vm.onDrop.bind(vm);
        vm.toggle = vm.toggle.bind(vm);
    }
    onDrop(files) {
        const vm = this;
        const { toast } = vm.props;

        if (vm.state.type) {
            const confirmed = window.confirm('Are you sure you wish to upload this file?');

            if (confirmed) {
                vm.state.file = files[0];
                vm.setState(vm.state);
                vm.uploadDocument();
            }
        } else {
            toast.error('Failed', <FormattedMessage {...messages.notifications.selectFileType} tagName="p" />);
        }
    }
    setDocumentType(type) {
        const vm = this;
        vm.state.type = type;
        vm.setState(vm.state);
        vm.typeMenu.close();
    }
    toggle() {
        const vm = this;
        vm.state.show = !vm.state.show;
        vm.setState(vm.state);
    }
    uploadDocument() {
        const vm = this;
        const { dossier } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const data = new FormData();
        const referrer = vm.context.router.getCurrentLocation().query.referrer;
        data.append('action_code', 'UPLOADDOCUMENT');
        data.append('file', vm.state.file);
        data.append('data', JSON.stringify({ document_type: vm.state.type }));
        vm.setState({ uploading: true });

        axios.post(`${DOSSIERS_URL}${dossier.id}/actions/`, data, { headers }).then(() => {
            vm.context.router.push(referrer || `/dossiers/${dossier.id}`);
            window.location.reload();
            // return toast.success('Success!', <p>Document {vm.state.file.name} succesfully uploaded.</p>);
        });
    }
    listDocumentTypes() {
        const vm = this;
        if (auth.userIsAtlance(vm.props.user)) {
            return DOCUMENT_TYPES;
        }
        if (auth.userIsVendor(vm.props.user)) {
            return DOCUMENT_TYPES.filter((type) => type !== 'INTERNALCOMMUNICATION');
        }
        return DOCUMENT_TYPES.filter((type) => type !== 'CALCULATIONEXCEL' && type !== 'SIGNEDCONTRACT');
    }
    render() {
        const vm = this;
        const docMenuLabel = vm.state.type ? vm.state.type : 'selectDocType';
        const documentTypes = this.listDocumentTypes();

        return (
            <ReactModal
                isOpen={vm.state.show}
                contentLabel="Upload document"
                className="modal-dashboard file-upload-modal"
                overlayClassName="modal-overlay-dashboard"
                onRequestClose={vm.toggle}
                shouldCloseOnOverlayClick
            >
              <Loader className="flex" active={vm.state.uploading}>
                <Link to="" className="modal-close" onClick={vm.toggle}><i className="fa fa-arrow-left"></i></Link>
                <div className="input-container input-container-select">
                    <Menu ref={(ref) => { vm.typeMenu = ref; }} scrollMask={false}>
                        <MenuTrigger>
                            <FormattedMessage {...appMessages.docTypes[docMenuLabel]} />
                        </MenuTrigger>
                        <MenuContent>
                            {_.map(documentTypes, (key) => (
                                <MenuItem key={key} onClick={() => { vm.setDocumentType(key); }}>
                                    <FormattedMessage {...appMessages.docTypes[key]} />
                                </MenuItem>
                            ))}
                        </MenuContent>
                    </Menu>
                </div>
                <Dropzone
                    onDrop={vm.onDrop}
                    className="file-dropzone layout-row layout-align-center-center"
                    activeClassName="file-dropzone-active"
                    rejectClassName="file-dropzone-rejected"
                    multiple={false}
                >
                    <div className="file-dropzone-label">
                        <FormattedMessage id="forms.dropzone.instructions" />
                    </div>
                </Dropzone>
                </Loader>
            </ReactModal>
        );
    }
}

DocumentUploader.contextTypes = {
    router: PropTypes.object,
};
DocumentUploader.propTypes = {
    show: PropTypes.bool,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null, null, { withRef: true })(DocumentUploader);
