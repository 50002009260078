import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import List from 'components/App/partials/List';
import Loader from 'components/App/partials/Loader';
import appMessages from 'components/App/messages';
import messages from '../messages';

class NewsList extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        news: PropTypes.array,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            loading: !props.news.length,
        };
        vm.onClick = vm.onClick.bind(vm);
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (nextProps.news.length && nextProps.forms === vm.props.forms) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    onClick(event) {
        const vm = this;
        const id = event.currentTarget.getAttribute('data-id');

        if (event.target.parentNode.tagName !== 'A') {
            vm.context.router.push(`/news/${id}/edit`);
        }
    }
    render() {
        const vm = this;
        const { intl } = vm.props;
        const columns = [
            { label: intl.formatMessage(messages.title), key: 'title' },
            { label: intl.formatMessage(messages.content), key: 'content' },
            { label: intl.formatMessage(messages.active), key: 'active' },
            { label: '', key: 'actions' },
        ];
        const news = _.map(vm.props.news, (item) => {
            item.actions = [
                { label: appMessages.delete, url: `/news/${item.id}/delete`, icon: 'trash' },
            ];
            return item;
        });

        return (
            <div id="NewsList">
                <Loader active={vm.state.loading}>
                    <List
                        name="news"
                        columns={columns}
                        data={news}
                        onRowClick={vm.onClick}
                    />
                </Loader>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('news'),
    (news) => ({ news })
);

export default connect(mapStateToProps)(injectIntl(NewsList));
