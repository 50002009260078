import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class Filter extends PureComponent {
    static defaultProps = {
        component: 'span',
    }
    static propTypes = {
        filter: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
    }
    static filters = {
        currency: currencyFilter,
    };
    constructor(props) {
        super(props);
        const vm = this;

        if (typeof props.filter === 'string') {
            vm.filter = Filter.filters[props.filter];
        } else {
            vm.filter = vm.props.filter;
        }
    }
    render() {
        const vm = this;
        const WrapperComponent = vm.props.component;
        return (
            <WrapperComponent>
                {vm.filter(vm.props.children, vm.props.separator, vm.props.prefix, vm.props.suffix)}
            </WrapperComponent>
        );
    }
}

export function currencyFilter(value, separator = ',', prefix = '', suffix = '', float = true) {
    let parsed = String(float ? parseFloat(value).toFixed(2) : value);
    parsed = separator === '.' ? parsed.replace('.', ',') : parsed;
    parsed = parsed.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    return `${prefix}${parsed}${suffix}`;
}

export default Filter;
