import PropTypes from 'prop-types';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone';
import { VENDORS_URL } from 'components/Vendor/constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, MenuTrigger, MenuContent, MenuItem } from 'common/Util';
import Loader from 'components/App/partials/Loader';
import auth from 'components/Users/auth';
import { newVendor, editVendor } from '../actions';

import appMessages from '../../App/messages';
import { DOSSIERS_URL, DOCUMENT_TYPES } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class LogoUploader extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            show: props.show || false,
            type: null,
            file: null,
        };
        vm.onDrop = vm.onDrop.bind(vm);
        vm.onDropRejected = vm.onDropRejected.bind(vm);
        vm.uploadLogo = vm.uploadLogo.bind(vm);
        vm.clearLogo = vm.clearLogo.bind(vm);
        vm.MAX_FILE_SIZE = (1024 ** 2) * 4;
    }
    onDrop(files) {
        const vm = this;
        const file = files[0];
        if (file) {
            vm.uploadLogo(file);
        }
    }
    onDropRejected(files) {
        const { intl } = this.props;
        const file = files[0];
        const { size } = file;
        const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const getBytes = (bytes) => {
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            return !(bytes || !'0 Bytes') || `${(bytes / (1024 ** i)).toFixed(2)} ${suffixes[i]}`;
        };
        if (size > this.MAX_FILE_SIZE) {
            const errorMessage = intl.formatMessage(
                {
                    id: 'forms.vendor.fileTooLarge',
                    defaultMessage: '{fileSize} is too large, please upload a file less than {allowedSize}',
                },
                {
                    fileSize: getBytes(size),
                    allowedSize: getBytes(this.MAX_FILE_SIZE),
                }
            );
            this.setState({ uploadError: errorMessage });
        }
    }

    uploadLogo(file) {
        const vm = this;
        const { vendor, dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const data = new FormData();
        data.append('file', file);
        vm.setState({ uploading: true });

        axios.post(`${VENDORS_URL}${vendor.id}/upload_logo/`, data, { headers }).then((response) => {
            const newLogoUrl = response?.data?.result?.logo_url;
            dispatch(editVendor({
                ...vendor,
                logo: newLogoUrl,
            }));
        }).catch((response) => {
            vm.setState({ uploadError: response.error });
        }).finally(() => {
            vm.setState({ uploading: false });
        });
    }

    clearLogo() {
        const vm = this;
        const { vendor, dispatch } = vm.props;
        vm.setState({ clearing: true });
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        axios.post(`${VENDORS_URL}${vendor.id}/clear_logo/`, { clear: true }, { headers }).then(() => {
            dispatch(editVendor({
                ...vendor,
                logo: false,
            }));
        }).finally(() => {
            vm.setState({ clearing: false });
        });
    }

    render() {
        const vm = this;
        const { vendor } = vm.props;

        return (
            <div>
                <p>Logo</p>
                <div style={{ display: 'flex' }}>
                    {vendor.logo &&
                    <div>
                        <img src={vendor.logo} alt="" style={{ objectFit: 'contain', objectPosition: 'center', width: '200px', maxHeight: '200px', marginRight: '16px' }} />
                        <button type="button" disabled={this.state.clearing} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.clearLogo}><FormattedMessage id="forms.vendor.clearLogo" defaultMessage="Clear Logo" /></button>
                    </div>
                    }
                    <div style={{ flex: 1 }}>
                        <Loader className="flex" active={vm.state.uploading}>
                            <Dropzone
                                onDrop={vm.onDrop}
                                className="file-dropzone layout-row layout-align-center-center"
                                style={{
                                    borderWidth: '2px',
                                    width: '100%',
                                    margin: '0',
                                    maxWidth: '300px',
                                    maxHeight: 'unset',
                                    height: '100%',
                                    minHeight: '100px',
                                }}
                                activeClassName="file-dropzone-active"
                                rejectClassName="file-dropzone-rejected"
                                multiple={false}
                                maxSize={this.MAX_FILE_SIZE} /* 4MB */
                                accept="image/*"
                                onDropRejected={this.onDropRejected}
                                onDropAccepted={() => this.setState({ uploadError: null })}
                            >
                                <div className="file-dropzone-label" style={{ textAlign: 'center', width: 'unset' }}>
                                    {vendor.logo ?
                                        <div>
                                            <FormattedMessage id="forms.vendor.existingLogoInstructions" defaultMessage="Click here to upload a new logo" />
                                        </div> :
                                        <div>
                                            <FormattedMessage id="forms.vendor.uploadLogoInstructions" defaultMessage="Click here to upload a logo" />
                                        </div>
                                    }
                                    <span style={{ color: '#f00', fontSize: '12px' }}>{this.state.uploadError}</span>
                                </div>
                            </Dropzone>
                        </Loader>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(LogoUploader, { withRef: true }));
