import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { editDossier } from '../actions';
import { DOSSIERS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class DocuSignForm extends React.Component {
    constructor(props, context) {
        super(props);

        const vm = this;

        vm.dossierId = +context.router.params.id;
        vm.state = {
            dossier: {},
            actions: [],
            requests: [],
            loading: true,
            submitting: false,
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
    }

    componentDidMount() {
        const vm = this;
        vm.getData();
    }

    componentWillReceiveProps(nextProps) {
        const vm = this;
        const nextDossier = _.find(nextProps.dossiers, { id: vm.dossierId });

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.dossierId = +vm.context.router.params.id;
            vm.getData();
        }

        if (!_.isEmpty(nextDossier) && !_.isEqual(nextDossier, vm.state.dossier)) {
            vm.state.dossier = nextDossier;
        }

        vm.setState(vm.state);
    }
    getData() {
        const vm = this;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const { dispatch } = vm.props;

        vm.state.loading = true;
        vm.setState(vm.state);
        axios.get(`${DOSSIERS_URL}${vm.dossierId}/`, { headers }).then((response) => {
            const dossier = response.data.result;
            dispatch(editDossier(dossier));
            vm.state.loading = false;
            vm.setState(vm.state);
        });
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.state.submitting = true;
        vm.setState(vm.state);

        const id = vm.context.router.params.id;

        axios.post(`${DOSSIERS_URL}${id}/actions/`, { action_code: 'SIGNWITHDOCUSIGN', 'data': data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${id}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.StartedDocusignSession} tagName="p" />);
        }).catch((response) => {
            toast.error('Error!', <p>{response.message}</p>);
        }).finally(() => {
            vm.state.submitting = false;
            vm.setState(vm.state);
        });
    }
    render() {
        const vm = this;
        const { dossier } = vm.state;
        return (
            <div id="DocuSignForm">
                <h1 className="center-align"><FormattedMessage {...appMessages.signWithDocuSign} /></h1>
                <Form submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <h2><FormattedMessage {...appMessages.client} /></h2>
                        <Input type="text" name="first_name" className="input-medium" label={appMessages.firstName} defaultValue={dossier.client_first_name} />
                        <Input type="text" name="last_name" className="input-medium" label={appMessages.lastName}  defaultValue={dossier.client_last_name} />
                        <Input type="email" name="email" className="input-medium" label={appMessages.email} />
                        <Input type="text" name="phone_number" className="input-medium" label={appMessages.phone} />
                        <p><FormattedMessage {...appMessages.phoneNumberFormatting}/></p>
                    </FormGroup>
                    {dossier.has_co_debition &&
                        <FormGroup>
                            <h2><FormattedMessage {...appMessages.coClient} /></h2>
                            <Input type="text" name="co_client_first_name" className="input-medium" label={appMessages.firstName} defaultValue={dossier.co_client_first_name} />
                            <Input type="text" name="co_client_last_name" className="input-medium" label={appMessages.lastName}  defaultValue={dossier.co_client_last_name} />
                            <Input type="email" name="co_client_email" className="input-medium" label={appMessages.email} />
                            <Input type="text" name="co_client_phone_number" className="input-medium" label={appMessages.phone} />
                            <p><FormattedMessage {...appMessages.phoneNumberFormatting}/></p>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Button loading={vm.state.submitting}><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

DocuSignForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (user, toast, dossiers) => ({ user, toast, dossiers })
);

export default connect(mapStateToProps, null)(DocuSignForm);
