import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import appMessages from 'components/App/messages';
import { Form, FormGroup, Input, Textarea, Button } from 'common/Form';
import { initDossierDocuments, editDossier } from '../actions';
import { DOSSIERS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class DocumentVerdictForm extends PureComponent {
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.onVerdictSubmit = vm.onVerdictSubmit.bind(vm);
    }
    onVerdictSubmit(data) {
        const vm = this;
        const { headers } = vm;
        const { toast, dispatch, dossier } = vm.props;

        axios.post(`${DOSSIERS_URL}${dossier.id}/actions/`, {
            action_code: 'REVIEWDOCUMENT',
            data: { ...data, document_id: vm.props.document.id, verdict: vm.props.verdict },
        }, { headers }).then((response) => {
            dispatch(initDossierDocuments(dossier.id, response.data.extra.documents));
            dispatch(editDossier(response.data.result));
            vm.props.onSubmit();
            toast.success('Success!', <FormattedMessage {...messages.notifications.reviewedDocument} tagName="p" />);
        });
    }
    render() {
        const vm = this;
        const { dossier, document, verdict } = vm.props;

        return (
            <Form id="document-verdict" submitHandler={vm.onVerdictSubmit}>
                <FormGroup className="layout-margin">
                    {dossier.document_summary && dossier.document_summary[document.type] === verdict
                        ? <h3>You are about to {verdict.toLowerCase()} {document.name}. Are you sure?</h3>
                        : <h3>There already is a document with the status {verdict.toLowerCase()}.<br />Are you sure you wish to replace it?</h3>
                    }
                    {verdict === 'REJECT' &&
                        <Textarea name="reason" label={appMessages.reason} />
                    }
                    <Input type="hidden" name="fix?" value="FIX!" />
                </FormGroup>
                <FormGroup className="layout-row layout-margin layout-align-end-end">
                    <div>
                        <Button type="submit" form="document-verdict">
                            <FormattedMessage {...appMessages.confirm} />
                        </Button>
                    </div>
                    <div>
                        <Button type="button" onClick={vm.props.onCancel}>
                            <FormattedMessage {...appMessages.cancel} />
                        </Button>
                    </div>
                </FormGroup>
            </Form>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast }),
);

export default connect(mapStateToProps)(DocumentVerdictForm);
