import {
    LOGIN,
    LOGOUT,
    EDIT_PROFILE,
    INIT_USERS,
    NEW_USER,
    EDIT_USER,
    REMOVE_USER,
} from './constants';

export function login(user) {
    return {
        type: LOGIN,
        user,
    };
}
export function logout() {
    return {
        type: LOGOUT,
    };
}

export function editProfile(user) {
    return {
        type: EDIT_PROFILE,
        user,
    };
}

export function initUsers(users) {
    return {
        type: INIT_USERS,
        users,
    };
}

export function newUser(user) {
    return {
        type: NEW_USER,
        user,
    };
}

export function editUser(user) {
    return {
        type: EDIT_USER,
        user,
    };
}

export function deleteUser(userId) {
    return {
        type: REMOVE_USER,
        userId,
    };
}
