import Validator from '../Validator';

const options = {
    rules: [],
};

options.rules.push({
    name: 'alpha',
    pattern: new RegExp(/^[a-z|A-Z]*$/),
    error: 'alpha',
});

export default new Validator(options);
