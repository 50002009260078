/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
const browserLocale = typeof navigator !== 'undefined' ? navigator.language
                    || navigator.browserLanguage
                    || navigator.systemLanguage
                    || navigator.userLanguage : '';

export const DEFAULT_LOCALE = browserLocale.split('-')[0] || 'nl';
export const SCREEN_XS = 600;
export const SCREEN_SM = 960;
export const SCREEN_MD = 1280;
export const SCREEN_LG = 1920;
export const BREAKPOINTS = {
    xs: SCREEN_XS,
    sm: SCREEN_SM,
    md: SCREEN_MD,
    lg: SCREEN_LG,
};
const getApiUrl = (url) => {
    if ((url || '').endsWith('/')) {
        return `${url}api/`;
    }
    return `${url}/api/`;
};
export const API_URL = getApiUrl(process.env.API_URL);
export const TOKEN_DURATION = 12; // in hours
export const EQUIPMENT_TYPE_HARDWARE = 'HARDWARE';
export const EQUIPMENT_TYPE_SOFTWARE = 'SOFTWARE';
export const EQUIPMENT_TYPE_BOTH = 'BOTH';
