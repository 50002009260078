import DossierContainer from './containers/Dossier';
import DossiersContainer from './containers/Dossiers';
import ArchiveContainer from './containers/Archive';

export default [
    { name: 'dossiers-list', component: DossiersContainer },
    { path: 'archive', name: 'archive', component: ArchiveContainer },
    { path: 'new', name: 'dossiers-new', component: DossiersContainer },
    { path: ':id', name: 'dossiers-get', component: DossierContainer },
    // { path: ':id/edit', name: 'dossiers-edit', component: DossiersContainer },
    // { path: ':id/request', name: 'dossiers-request', component: DossiersContainer },
    { path: ':id/confirm', name: 'dossiers-confirm', component: DossiersContainer },
    // { path: ':id/send', name: 'dossiers-send', component: DossiersContainer },
    // { path: ':id/success', name: 'dossiers-bank-failure', component: DossiersContainer },
    // { path: ':id/failure', name: 'dossiers-bank-success', component: DossiersContainer },
    { path: ':id/quotation', name: 'dossiers-quotationdoc', component: DossiersContainer },
    { path: ':id/calcdoc', name: 'dossiers-calcdoc', component: DossiersContainer },
    { path: ':id/worddoc', name: 'dossiers-worddoc', component: DossiersContainer },
    { path: ':id/sign', name: 'dossiers-sign', component: DossiersContainer },
    { path: ':id/complete', name: 'dossiers-complete', component: DossiersContainer },
    // { path: ':id/cancel', name: 'dossiers-cancel', component: DossiersContainer },
    // { path: ':id/archive', name: 'dossiers-archive', component: DossiersContainer },
    { path: ':id/:action', name: 'dossiers-action', component: DossiersContainer },
];
