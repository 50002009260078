import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import List from 'components/App/partials/List';
import ActionsMenu from 'components/ActionsMenu';
import auth from 'components/Users/auth';
import appMessages from 'components/App/messages';
import langMessages from 'components/LanguageSwitcher/messages';

import { getVendorActions } from '../utils';
import { VENDORS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';
import '../styles/vendor.scss';
import Fuse from 'fuse.js';


class Vendor extends React.PureComponent {
    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;
        vm.state = {
            vendor: { clients: [], equipment_types: [], contract_options: [], allowed_funders: [] },
            contractColumns: [
                { label: intl.formatMessage(messages.type), key: 'type' },
                { label: intl.formatMessage(messages.ten_twelve_type), key: 'ten_twelve_type' },
                { label: intl.formatMessage(messages.payment_type), key: 'payment_type' },
            ],
            clientColumns: [
                { label: intl.formatMessage(messages.firmname), key: 'firmname' },
                { label: intl.formatMessage(messages.street), key: 'street' },
                { label: intl.formatMessage(messages.number), key: 'number' },
                { label: intl.formatMessage(messages.postalcode), key: 'postalcode' },
                { label: intl.formatMessage(messages.bus), key: 'bus' },
                { label: intl.formatMessage(messages.city), key: 'city' },
                { label: intl.formatMessage(messages.vat), key: 'vat' },
                { label: intl.formatMessage(messages.iban), key: 'iban' },
                { label: intl.formatMessage(messages.swiftBIC), key: 'swift_bic' },
                { label: intl.formatMessage(messages.contactName), key: 'name' },
                { label: intl.formatMessage(messages.contactFunction), key: 'function' },
                { label: intl.formatMessage(messages.contactEmail), key: 'mail_accounting' },
                { label: '', key: 'actions' },
            ],
        };

        vm.onClick = vm.onClick.bind(vm);
    }

    componentDidMount() {
        const vm = this;
        const pathArray = vm.context.router.getCurrentLocation().pathname.split('/').filter((arg) => !!arg);
        const id = pathArray[pathArray.length - 1];
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.all([
            axios.get(`${VENDORS_URL}${id}/`, { headers }),
            axios.get(`${VENDORS_URL}${id}/clients/`, { headers }),
        ]).then((response) => {
            vm.setState({
                id,
                vendor: {
                    ...response[0].data.result,
                    clients: response[1].data.result,
                },
            });
        });
    }

    onClick(event) {
        const vm = this;
        const pathArray = vm.context.router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const vendorId = pathArray[pathArray.length - 1];
        const clientId = event.currentTarget.getAttribute('data-id');

        if (event.target.parentNode.tagName !== 'A') {
            vm.context.router.push(`/vendors/${vendorId}/clients/${clientId}/edit?referrer=/vendors/${vendorId}`);
        }
    }

    onClientSearch = (evt) => {
        this.setState({clientSearchValue: evt.target.value});
    }

    getClients() {
        const { vendor } = this.state;
        const clients = _.map(vendor.clients, (client) => {
            const mappedClient = { ...client };
            mappedClient.name = `${mappedClient.name} ${mappedClient.last_name || ''}`;
            mappedClient.actions = [{
                label: appMessages.edit,
                url: `/vendors/${vendor.id}/clients/${client.id}/edit`,
                icon: 'icon-note',
            }];
            return mappedClient;
        });
        if (!!this.state.clientSearchValue) {
            const fuse = new Fuse(clients, {
                keys: ['firmname'],
                threshold: 0.38, // the lower, the more strict the match must be.
            });
            const result = fuse.search(this.state.clientSearchValue);
            return result;
        }
        return clients;
    }

    render() {
        const vm = this;
        const { intl, user } = vm.props;
        const { vendor, clientSearchValue } = vm.state;
        const actions = getVendorActions(vendor.id, user);
        const contractOptions = _.map(vendor.contract_options, (option) => {
            const translatedOption = { ...option };
            translatedOption.type = intl.formatMessage(messages.contractTypes[translatedOption.type]);
            translatedOption.ten_twelve_type = intl.formatMessage(messages.tenTwelveTypes[translatedOption.ten_twelve_type]);
            translatedOption.payment_type = intl.formatMessage(messages.paymentTypes[translatedOption.payment_type]);
            return translatedOption;
        });
        const clients = this.getClients();

        const referrer = vm.context.router.getCurrentLocation().pathname;

        vendor.logo = vendor.logo ? <img src={vendor.logo} alt="logo" title="logo" /> : null;

        const allowedFunders = vendor.allowed_funders.length > 0
            ? vendor.allowed_funders.map((funder, index) =>
                <li key={index}>{funder.name}</li>)
            : <p>No allowed funders yet</p>;

        const equipmentTypes = vendor.equipment_types.length > 0
                               ? vendor.equipment_types.map((equipmentType, index) =>
                                   <li key={index}>{equipmentType.description}</li>)
                               : <p>No equipment types yet</p>;

        if (!vendor.clients.length) {
            clients.push({ id: -1, firmname: 'No clients yet' });
        }

        return (
            <div id="Vendor" className="vendor">
                <div className="vendor-header layout-row">
                    <h1 className="flex">{vendor.name}</h1>
                    <div className="vendor-detail-controls detail-controls layout-row layout-align-center-end">
                        <ActionsMenu actions={actions} />
                    </div>
                </div>
                <div className={`vendor-content vendor-${vendor.id} layout-column layout-gt-sm-row`}>
                    <div className="vendor-contact-info flex">
                        <div className="layout-row">
                            <h2 className="flex"><FormattedMessage {...appMessages.contactInfo} /></h2>
                            <div className="vendor-contact-info-controls flex-nogrow">
                                <Link to={{ pathname: `/vendors/${vendor.id}/edit`, query: { referrer } }} className="vendor-edit button button-default"><FormattedMessage {...appMessages.actions.EDITVENDOR} /></Link>
                            </div>
                        </div>
                        {vendor.logo}
                        <p><FormattedMessage {...appMessages.name} />: {vendor.name}</p>
                        <p><FormattedMessage {...appMessages.address} />: {vendor.street} {vendor.number}</p>
                        <p><FormattedMessage {...appMessages.postalcode} />: {vendor.postalcode}</p>
                        <p><FormattedMessage {...appMessages.country} />: {vendor.country}</p>
                        <p><FormattedMessage {...appMessages.phone} />: {vendor.phone}</p>
                        <p><FormattedMessage {...appMessages.vat} />: {vendor.vat}</p>
                        <p><FormattedMessage {...appMessages.email} />: {vendor.accounting_email}</p>
                        <p><FormattedMessage {...appMessages.language} />: <FormattedMessage {...langMessages.languages[(vendor.language || 'nl').toLowerCase()]} /></p>
                    </div>
                    <div className="vendor-options flex">
                        <div className="layout-row">
                            <h2 className="flex"><FormattedMessage {...appMessages.contractOptions} /></h2>
                            <div className="vendor-contract-options-controls flex-nogrow">
                                <Link to={{ pathname: `/vendors/${vendor.id}/options`, query: { referrer } }} className="vendor-edit-contract-options button button-default"><FormattedMessage {...appMessages.actions.EDITCONTRACTOPTIONS} /></Link>
                            </div>
                        </div>
                        <p><FormattedMessage {...appMessages.terms_type} />: {vendor.terms_type}</p>
                        <p><FormattedMessage {...appMessages.commissionPercentage} />: {vendor.commission_percentage}</p>
                        <p><FormattedMessage {...appMessages.commercialActionPercentage} />: {vendor.commercial_action_percentage}</p>
                        <List
                            name="contract-options"
                            columns={vm.state.contractColumns}
                            data={contractOptions}
                        />
                    </div>
                </div>
                <div className={`vendor-content vendor-${vendor.id}`}>
                    <div className="vendor-clients">
                        <div className="layout-row">
                            <h2 className="flex"><FormattedMessage {...appMessages.clients} /></h2>
                            {auth.userIsAdmin(user) || auth.userIsVendor(user) ?
                                <div className="vendor-clients-controls flex-nogrow">
                                    <Link to={{ pathname: `/vendors/${vendor.id}/clients/new`, query: { referrer } }} className="vendor-add-client button button-default"><FormattedMessage {...appMessages.actions.ADDCLIENT} /></Link>
                                </div>
                                : null}
                        </div>
                        <div className="layout-row">
                            <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}><label><FormattedMessage {...messages.filterByCompanyName} /></label></div>
                            <div style={{width: '250px'}}>
                                <input
                                    type="text"
                                    value={clientSearchValue || ''}
                                    onChange={this.onClientSearch}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <List
                                name="vendors-client"
                                columns={vm.state.clientColumns}
                                data={clients}
                                defaultSort={{ column: 'name', direction: 'asc' }}
                                onRowClick={vm.onClick}
                                responsive
                            />
                        </div>
                    </div>
                </div>
                <div className={`vendor-content vendor-${vendor.id}`}>
                    <div className="vendor-equipment-types">
                        <div className="layout-row">
                            <h2 className="flex"><FormattedMessage {...appMessages.equipmentTypes} /></h2>
                            <div className="vendor-equipment-types-controls flex-nogrow">
                                <Link to={{ pathname: `/vendors/${vendor.id}/equipment`, query: { referrer } }} className="vendor-add-client button button-default"><FormattedMessage {...appMessages.actions.EDITEQUIPMENTTYPES} /></Link>
                            </div>
                        </div>
                        <div className="flex">
                            <ul>
                                {equipmentTypes}
                            </ul>
                        </div>
                    </div>
                </div>
                {auth.userIsAtlance(user) ?
                    <div className={`vendor-content vendor-${vendor.id}`}>
                        <div className="vendor-allowed-funders">
                            <div className="layout-row">
                                <h2 className="flex"><FormattedMessage {...appMessages.allowedFunders} /></h2>
                            </div>
                            <div className="flex">
                                <ul>
                                    {allowedFunders}
                                </ul>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}

Vendor.contextTypes = {
    router: PropTypes.object,
};
Vendor.propTypes = {
    intl: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (user) => ({ user }),
);

export default connect(mapStateToProps, null)(injectIntl(Vendor));
