import PropTypes from 'prop-types';
import React from 'react';
// import ReactDOM from 'react-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class Tooltip extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = { hover: vm.props.show };
        vm.onMouseOver = vm.onMouseOver.bind(vm);
        vm.onMouseOut = vm.onMouseOut.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const triggerElement = vm.tooltip.parentNode;
        const triggerHeight = triggerElement.offsetHeight;

        vm.tooltip.style.transform = `translate3d(0,${triggerHeight}px,0)`;

        triggerElement.classList.add('xs2-tooltip-trigger');
        triggerElement.addEventListener('mouseover', vm.onMouseOver);
        triggerElement.addEventListener('mouseout', vm.onMouseOut);
    }
    onMouseOver() {
        const vm = this;

        if (!vm.props.show) {
            vm.state.hover = true;
            vm.setState(vm.state);
        }
    }
    onMouseOut() {
        const vm = this;

        if (!vm.props.show) {
            vm.state.hover = false;
            vm.setState(vm.state);
        }
    }
    render() {
        const vm = this;
        const tooltip = (
            <div className="xs2-tooltip">
                <span>{vm.props.label}</span>
            </div>
        );

        return (
            <div className="xs2-tooltip-container" ref={(ref) => { vm.tooltip = ref; }}>
                <ReactCSSTransitionGroup
                    transitionName="xs2-tooltip"
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                    component="div"
                    className="xs2-tooltip-transition-container"

                >
                    {vm.state.hover ? tooltip : null}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

Tooltip.propTypes = {
    label: PropTypes.string,
    parent: PropTypes.object,
    show: PropTypes.bool,
};

export default Tooltip;
