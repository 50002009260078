import React from 'react';

class ExtraInfo extends React.Component {
    render() {
        const vm = this;
        const { children, className, title } = vm.props;
        return (
            <div className="extra-info">
                <h2>{title}</h2>
                <details className={`select-options-container ${className}`}>
                    <summary>i</summary>
                    <p className="select-options">{children}</p>
                </details>
            </div>
        );
    }
}


export default ExtraInfo;
