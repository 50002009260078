import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import List from 'components/App/partials/List';
import Tabs, { Pane } from 'common/Tabs';
import appMessages from 'components/App/messages';
import messages from '../messages';

function CalculationTable(props) {
    const { intl } = props;
    const { hardwareColumns, softwareColumns } = getColumnsFromData(props.data);
    const { hardwareRows, softwareRows } = getRowsFromData(props.data, hardwareColumns, softwareColumns);
    return (
        <div className="calculation-table-container">
            <Tabs selected={'hardware'}>
                <Pane key="hardware" label={<FormattedMessage {...appMessages.hardware} />}>
                    <List
                        name="calculation-table"
                        columns={hardwareColumns}
                        data={hardwareRows}
                        responsive
                    />
                </Pane>
                <Pane key="software" label={<FormattedMessage {...appMessages.software} />}>
                    <List
                        name="calculation-table"
                        columns={softwareColumns}
                        data={softwareRows}
                        responsive
                    />
                </Pane>
            </Tabs>
        </div>
    );
    function getColumnsFromData(data) {
        const defaultColumns = [
            { label: intl.formatMessage(messages.limit), key: 'limit' },
        ];
        const columns = {};
        columns.softwareColumns = _.map(data.software_data.durations, (duration) => ({ label: String(duration), key: String(duration) }));
        columns.softwareColumns.unshift(...defaultColumns);
        columns.hardwareColumns = _.map(data.hardware_data.durations, (duration) => ({ label: String(duration), key: String(duration) }));
        columns.hardwareColumns.unshift(...defaultColumns);
        return columns;
    }

    function getRowsFromData(data, hardwareColumns, softwareColumns) { // eslint-disable-line
        const rows = {};
        rows.hardwareRows = mapDataToRow(data.hardware_data, hardwareColumns);
        rows.hardwareRows.push({ limit: data.hardware_data.limits[data.hardware_data.limits.length - 1] });
        rows.softwareRows = mapDataToRow(data.software_data, softwareColumns);
        rows.softwareRows.push({ limit: data.software_data.limits[data.software_data.limits.length - 1] });
        return rows;
    }

    function mapDataToRow(data, columns) {
        return _.map(data.values, (values, index) => {
            const row = {};
            _.forEach(columns, (column, columnIndex) => {
                row.limit = data.limits[index];
                row[column.key] = values[columnIndex - 1];
            });
            return row;
        });
    }
}

CalculationTable.propTypes = {
    intl: PropTypes.object,
    data: PropTypes.object.isRequired,
};

export default injectIntl(CalculationTable);
