import {
    CONTACTS_INIT,
    CONTACTS_NEW,
    CONTACTS_EDIT,
    CONTACTS_DELETE,
    SUPPORT_INIT,
} from './constants';

export function initContacts(contacts) {
    return {
        type: CONTACTS_INIT,
        contacts,
    };
}

export function newContact(contact) {
    return {
        type: CONTACTS_NEW,
        contact,
    };
}

export function editContact(contact) {
    return {
        type: CONTACTS_EDIT,
        contact,
    };
}

export function deleteContact(contact) {
    return {
        type: CONTACTS_DELETE,
        contact,
    };
}

export function initSupport(contacts) {
    return {
        type: SUPPORT_INIT,
        contacts,
    };
}
