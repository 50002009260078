import { fromJS } from 'immutable';
import { BREAKPOINTS } from 'components/App/constants';
import { SIDENAV_TOGGLE, NOTIFY_INIT, TOAST_INIT } from './constants';

const initialState = fromJS({
    toast: null,
    sidenav: { show: (window.innerWidth > BREAKPOINTS.xs) },
});

function dashboardReducer(state = initialState, action) {
    let newState = state;

    newState = sidenavReducer(newState, action);
    newState = notificationsReducer(newState, action);
    newState = toastReducer(newState, action);

    return newState;
}

function sidenavReducer(state, action) {
    let newState = state;
    switch (action.type) {
        case SIDENAV_TOGGLE: {
            newState = newState.updateIn(['sidenav', 'show'], (value) => !value);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

function notificationsReducer(state, action) {
    let newState = state;

    switch (action.type) {
        case NOTIFY_INIT: {
            newState = newState.set('notify', action.provider);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

function toastReducer(state, action) {
    let newState = state;

    switch (action.type) {
        case TOAST_INIT: {
            newState = newState.set('toast', action.provider);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default dashboardReducer;
