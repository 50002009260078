import { defineMessages } from 'react-intl';

export default defineMessages({
    news: {
        id: 'app.News.news',
        defaultMessage: 'News',
    },
    addNews: {
        id: 'app.News.addNews',
        defaultMessage: 'Add news',
    },
    title: {
        id: 'app.News.title',
        defaultMessage: 'Title',
    },
    content: {
        id: 'app.News.content',
        defaultMessage: 'Content',
    },
    active: {
        id: 'app.News.active',
        defaultMessage: 'Active',
    },
    notifications: {
        deletedNews: {
            id: 'app.News.notifications.deletedNews',
            defaultMessage: '{title} has been removed',
        },
        createdNews: {
            id: 'app.News.notifications.createdNews',
            defaultMessage: 'A new news message has been added',
        },
        editedNews: {
            id: 'app.News.notifications.editedNews',
            defaultMessage: 'Updated the news message',
        },
    },
    actions: {
        new: {
            id: 'app.Vendors.actions.new',
            defaultMessage: 'New',
        },
        edit: {
            id: 'app.Vendors.actions.edit',
            defaultMessage: 'Edit',
        },
    },
});
