import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { API_URL, DEFAULT_LOCALE } from 'components/App/constants';

import UserList from '../partials/Users';
import { initUsers } from '../actions';


class Users extends React.Component {

    componentDidMount() {
        const vm = this;

        vm.getUsers();
    }

    getUsers() {
        const vm = this;
        const { dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.get(`${API_URL}users/`, { headers }).then((response) => {
            dispatch(initUsers(response.data.result));
        });
    }

    render() {
        return (
            <div id="Users">
                <h1>Users</h1>
                <div className="users">
                    <UserList />
                </div>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('users'),
    (user, users) => ({ user, users })
);

export default connect(mapStateToProps, null)(Users);
