import {
    LOGIN,
    LOGOUT,
    EDIT_PROFILE,
    INIT_USERS,
    NEW_USER,
    EDIT_USER,
    REMOVE_USER,
} from './constants';
import auth from './auth';

const initialState = {};

export function userReducer(state = initialState, action) {
    let newState = { ...state };

    switch (action.type) {
        case LOGIN:
            auth.setToken(action.user.token);
            auth.setCurrentUser(action.user);
            newState = action.user;
            break;
        case LOGOUT:
            auth.logout();
            newState = false;
            break;
        case EDIT_PROFILE:
            action.user.token = state.token;
            auth.setToken(state.token);
            auth.setCurrentUser(action.user);
            newState = Object.assign(newState, action.user);
            break;
        default:
            return state;
    }

    return newState;
}

export function usersReducer(state = initialState, action) {
    let newState = { ...state };

    switch (action.type) {
        case INIT_USERS: {
            newState = action.users;
            break;
        }
        case NEW_USER: {
            newState.push(action.user);
            break;
        }
        case EDIT_USER: {
            const user = newState.filter((i) => i.id === action.user.id)[0];
            Object.assign(user, action.vendor);
            break;
        }
        case REMOVE_USER: {
            newState = newState.filter((i) => i.id !== action.userId);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}
