import PropTypes from 'prop-types';
import React, { Component } from 'react';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import { Autocomplete, Form, FormGroup, Checkbox, Input, Button } from 'common/Form';
import Loader from 'components/App/partials/Loader';
import appMessages from 'components/App/messages';
import { VENDORS_URL } from 'components/Vendor/constants';
import messages from '../messages';
import { DOSSIERS_URL, FUNDERS_URL, CREDITREQUEST_OPTION_URL } from '../constants';
import { editDossier } from '../actions';
import { DEFAULT_LOCALE } from 'components/App/constants';


class SendToBanksForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static defaultProps = {
        dossier: {},
        client: {},
        vendor: {},
        funders: [],
    }
    static propTypes = {
        dossier: PropTypes.object,
        client: PropTypes.object,
        vendor: PropTypes.object,
        user: PropTypes.object,
        funders: PropTypes.array,
    }
    constructor(props, context) {
        super(props);

        const vm = this;

        vm.dossierId = +context.router.params.id;
        vm.dialogField = '';
        vm.dialogFunder = {};
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.state = {
            dossier: props.dossier,
            client: props.client,
            vendor: props.vendor,
            funders: props.funders,
            edit: {},
            loading: !!props.funders.length,
            showDialog: false,
            residualValueOptions: [],
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.onSubmitEdit = vm.onSubmitEdit.bind(vm);
        vm.editData = vm.editData.bind(vm);
        vm.cancelDialog = vm.cancelDialog.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getData();
    }
    onSubmit(data) {
        const vm = this;
        const { headers } = vm;
        const { dispatch, toast } = vm.props;
        const { router } = vm.context;
        const { edit } = vm.state;
        const credit_requests = _.map( // eslint-disable-line camelcase
            vm.state.funders.filter((funder) => !!data[funder.name]),
            (funder) => {
                return ({ funder: funder.id, ...edit[funder.name] });
            }
        );
        axios.post(`${DOSSIERS_URL}${vm.dossierId}/actions/`, {
            action_code: 'SENDTOBANKS',
            data: {
                credit_requests,
            },
        }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            router.push(`/dossiers/${vm.dossierId}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.sentRequest} tagName="p" />);
        });
    }
    onSubmitEdit(data) {
        const vm = this;
        vm.state.edit[vm.dialogFunder.name][vm.dialogField] = data[vm.dialogField];
        vm.setState(update(vm.state, {
            edit: { [vm.dialogFunder.name]: { [vm.dialogField]: { $set: data[vm.dialogField] } } },
        }), () => {
            vm.toggleDialog();
        });
    }
    getData() {
        const vm = this;
        const { headers } = vm;

        axios.all([
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/`, { headers }),
            axios.get(FUNDERS_URL, { headers }),
            axios.get(CREDITREQUEST_OPTION_URL, { headers }),
        ]).then((response) => {
            const dossier = response[0].data.result;
            const funders = response[1].data.result;
            const creditRequestOptions = response[2].data;

            vm.state.dossier = dossier;
            vm.state.funders = funders;
            vm.state.residualValueOptions = creditRequestOptions.residual_value.map((option) => {
                const [value, label] = option;
                return { value, label };
            });

            axios.all([
                axios.get(`${VENDORS_URL}${dossier.vendor}/`, { headers }),
                axios.get(`${VENDORS_URL}${dossier.vendor}/clients/`, { headers }),
                axios.get(`${VENDORS_URL}${dossier.vendor}/funders/`, { headers }),
            ]).then((vendorResponse) => {
                const vendor = vendorResponse[0].data.result;
                const client = vendorResponse[1].data.result.find((i) => +i.id === +dossier.client);
                const funders = vendorResponse[2].data;

                vm.state.vendor = vendor;
                vm.state.client = client;
                vm.state.funders = funders;

                _.forEach(vm.state.funders, (funder) => {
                    vm.state.edit[funder.name] = {};
                });
                vm.setState(vm.state);
            });
        });
    }
    getFunderFieldDisplayValue(funder, field) {
        const vm = this;
        const { client, dossier, edit } = vm.state;
        const funderField = edit[funder.name] ? edit[funder.name][field] : null;
        const displayValue = funderField || dossier[field] || client[field] || 'N/A';

        if (funderField && field === 'residual_value') {
            const residualValueLabel = vm.state.residualValueOptions.find((option) => option.value === funderField).label;
            return residualValueLabel || displayValue;
        }

        return displayValue;
    }
    editData(field, funder, value) {
        const vm = this;
        return () => {
            vm.dialogField = field;
            vm.dialogFunder = funder;
            vm.dialogValue = value;
            vm.toggleDialog();
        };
    }
    toggleDialog() {
        const vm = this;
        vm.state.showDialog = !vm.state.showDialog;
        vm.setState(vm.state);
    }
    cancelDialog() {
        const vm = this;
        vm.dialogField = '';
        vm.dialogFunder = {};
        vm.dialogValue = '';
        vm.toggleDialog();
    }
    renderDialogInput() {
        const vm = this;
        switch (vm.dialogField) {
            case 'residual_value':
                return (
                    <Autocomplete
                        name={vm.dialogField}
                        defaultValue={vm.dialogValue}
                        label={messages.fields.residual_value}
                        options={vm.state.residualValueOptions}
                    />
                );
            default:
                return (
                    <Input type="text" name={vm.dialogField} defaultValue={vm.dialogValue} />
                );
        }
    }

    render() {
        const vm = this;
        const { intl } = vm.props;
        const { funders, dossier, client, edit } = vm.state;
        const fields = {
            comment_to_funder: intl.formatMessage(messages.fields.comment_to_funder),
            residual_value: intl.formatMessage(messages.fields.residual_value),
        };
        const dependentFunderFields = {
            residual_value: 'process_residual_value',
        };
        const modalOptions = {
            isOpen: vm.state.showDialog,
            contentLabel: 'Edit dialog',
            className: 'modal-dialog',
            overlayClassName: 'modal-dialog-overlay layout-row layout-align-center-center',
            onRequestClose: vm.toggleDialog,
            shouldCloseOnOverlayClick: true,
            parentSelector: () => document.querySelector('.dashboard-main > .container'),
        };
        return (
            <div id="SendToBanksForm">
                <Form id="send-to-banks-form" submitHandler={vm.onSubmit}>
                    <FormGroup>
                        <div className="layout-row">
                            <div className="flex layout-row">
                                <div key={0} className="fields flex">
                                    {_.map(fields, (field, key) => (
                                        <div key={key} className="field">
                                            {field}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Loader active={vm.state.loading}>
                                <div className="funders layout-row">
                                    {_.map(funders, (funder, key) => (
                                        <div key={key + 2} className="funder center-align">
                                            <div className="funder-fields">
                                                <h3 className="bold">
                                                    <a href={funder.portal_url} target="_blank">
                                                        {funder.name}
                                                    </a>
                                                </h3>
                                                {_.map(fields, (field, key) => { // eslint-disable-line no-shadow
                                                    // if field in keys of dependentFunderFields, then check if the value equals true as funder attribute
                                                    if (_.has(dependentFunderFields, key) && !funder[dependentFunderFields[key]]) {
                                                        return '-';
                                                    }
                                                    const funderField = edit[funder.name] ? edit[funder.name][field] : null;
                                                    return (<div key={key}>
                                                        <span className="value">{this.getFunderFieldDisplayValue(funder, key)}</span>
                                                        <button type="button" className="xs2-icon-button" onClick={vm.editData(key, funder, funderField || dossier[key] || client[key] || '')}>
                                                            <i className="icon-pencil" />
                                                        </button>
                                                    </div>);
                                                })}
                                            </div>
                                            <Checkbox
                                                formId="send-to-banks-form"
                                                className="inline-block"
                                                name={funder.name}
                                                label={appMessages.noop}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Loader>
                        </div>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
                <ReactModal {...modalOptions}>
                    <Form id="edit-before-send" submitHandler={vm.onSubmitEdit}>
                        <FormGroup className="layout-margin">
                            <h3>Edit {vm.dialogField.replace('_', ' ')} for {vm.dialogFunder.name}:</h3>
                            {this.renderDialogInput()}
                        </FormGroup>
                        <FormGroup className="layout-row layout-margin layout-align-end-end">
                            <div>
                                <Button type="submit">
                                    <FormattedMessage {...appMessages.confirm} />
                                </Button>
                            </div>
                            <div>
                                <Button type="button" onClick={vm.cancelDialog}>
                                    <FormattedMessage {...appMessages.cancel} />
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (user, toast, dossiers) => ({ user, toast, dossiers })
);

export default connect(mapStateToProps, null)(injectIntl(SendToBanksForm));
