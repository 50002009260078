import { fromJS } from 'immutable';
import {
    DOSSIERS_INIT,
    DOSSIERS_NEW,
    DOSSIERS_EDIT,
    DOSSIERS_DOCUMENTS_INIT,
    DOSSIERS_DOCUMENTS_NEW,
    DOSSIERS_DOCUMENTS_EDIT,
    DOSSIERS_ACTIONS_INIT,
    ARCHIVE_INIT,
    ARCHIVE_ADD,
    FUNDERS_INIT,
} from './constants';

const initialState = fromJS({
    dossiers: fromJS([]),
    counters: fromJS({}),
    requirements: fromJS([]),
    archive: fromJS([]),
    documents: fromJS({}),
    actions: fromJS([]),
    funders: fromJS([]),
    count: fromJS(0),
    filters: fromJS({}),
});

function dossiersReducer(state = initialState, action) {
    let newState = state;

    switch (action.type) {
        case DOSSIERS_INIT: {
            newState = newState.set('dossiers', fromJS(action.dossiers));
            newState = newState.set('count', fromJS(action.count));
            newState = newState.set('filters', fromJS(action.filters));
            if (action.counters) {
                newState = newState.set('counters', fromJS(action.counters));
            }
            if (action.requirements) {
                newState = newState.set('requirements', fromJS(action.requirements));
            }
            break;
        }
        case DOSSIERS_NEW: {
            newState = newState.updateIn(['dossiers'],
                (dossiers) => dossiers.push(fromJS(action.dossier))
            );
            break;
        }
        case DOSSIERS_EDIT: {
            newState = newState.updateIn([
                'dossiers',
                newState.get('dossiers').findIndex((item) => item.get('id') === action.dossier.id),
            ],
                () => fromJS(action.dossier)
            );
            break;
        }
        case DOSSIERS_DOCUMENTS_INIT: {
            newState = newState.setIn(['documents', action.dossierId], fromJS(action.documents));
            break;
        }
        case DOSSIERS_DOCUMENTS_NEW: {
            newState = newState.updateIn([
                'documents',
                +action.dossierId,
            ], (documents) => (documents || fromJS([])).push(fromJS(action.document)));
            break;
        }
        case DOSSIERS_DOCUMENTS_EDIT: {
            newState = newState.updateIn([
                'documents',
                +action.dossierId,
                newState.get('documents').get(action.dossierId).findIndex((item) => item.get('id') === action.document.id),
            ],
                () => fromJS(action.document)
            );
            break;
        }
        case DOSSIERS_ACTIONS_INIT: {
            newState = newState.updateIn([
                'actions',
                +action.dossierId,
            ],
                () => fromJS(action.actions)
            );
            break;
        }
        case ARCHIVE_INIT: {
            newState = newState.set('archive', fromJS(action.dossiers));
            break;
        }
        case ARCHIVE_ADD: {
            newState = newState.updateIn(['archive'],
                (dossiers) => dossiers.push(fromJS(action.dossier))
            );
            break;
        }
        case FUNDERS_INIT: {
            newState = newState.set('funders', fromJS(action.funders));
            break;
        }
        default: {
            return state;
        }
    }
    return newState;
}

export default dossiersReducer;
