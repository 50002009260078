import PropTypes from 'prop-types';
import React from 'react';
import throbber from 'svg-inline-loader!assets/img/throbber.svg';

function Loader(props) {
    return ( // eslint-disable-line react/no-danger
        <div className={`xs2-loader-container ${props.className}`}>
            {props.children}
            <div className={`xs2-loader ${props.active ? 'xs2-active' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: throbber }} />
            </div>
        </div>
    );
}

Loader.defaultProps = {
    loader: <i></i>,
    className: '',
};
Loader.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    className: PropTypes.string,
};

export default Loader;
