import {
    VENDORS_INIT_VENDORS,
    VENDORS_NEW_VENDOR,
    VENDORS_EDIT_VENDOR,
    VENDORS_DELETE_VENDOR,
    VENDORS_ADD_CONTRACT_OPTIONS,
    VENDORS_REMOVE_CONTRACT_OPTIONS,
    VENDORS_ADD_EQUIPMENT_TYPE,
    VENDORS_REMOVE_EQUIPMENT_TYPE,
    VENDORS_ADD_CLIENTS,
    VENDORS_REMOVE_CLIENTS,
} from './constants';

export function initVendors(vendors) {
    return {
        type: VENDORS_INIT_VENDORS,
        vendors,
    };
}

export function newVendor(vendor) {
    return {
        type: VENDORS_NEW_VENDOR,
        vendor,
    };
}

export function editVendor(vendor) {
    return {
        type: VENDORS_EDIT_VENDOR,
        vendor,
    };
}

export function addContractOptions(vendorId, contractOptions) {
    return {
        type: VENDORS_ADD_CONTRACT_OPTIONS,
        vendorId,
        contractOptions,
    };
}

export function removeContractOptions(vendorId, index) {
    return {
        type: VENDORS_REMOVE_CONTRACT_OPTIONS,
        vendorId,
        index,
    };
}

export function addEquipmentType(vendorId, equipmentType) {
    return {
        type: VENDORS_ADD_EQUIPMENT_TYPE,
        vendorId,
        equipmentType,
    };
}

export function removeEquipmentType(vendorId, index) {
    return {
        type: VENDORS_REMOVE_EQUIPMENT_TYPE,
        vendorId,
        index,
    };
}

export function addClients(vendorId, clients) {
    return {
        type: VENDORS_ADD_CLIENTS,
        vendorId,
        clients,
    };
}

export function removeClients(vendorId, index) {
    return {
        type: VENDORS_REMOVE_CLIENTS,
        vendorId,
        index,
    };
}

export function deleteVendor(vendorId) {
    return {
        type: VENDORS_DELETE_VENDOR,
        vendorId,
    };
}
