import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import appMessages from 'components/App/messages';
import List from 'components/App/partials/List';
import Loader from 'components/App/partials/Loader';
import { getVendorActions } from '../utils';

class Vendors extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        intl: PropTypes.object,
        vendors: PropTypes.array,
    }
    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;
        vm.state = {
            columns: [
                { label: intl.formatMessage(appMessages.name), key: 'name' },
                { label: intl.formatMessage(appMessages.phone), key: 'phone' },
                { label: intl.formatMessage(appMessages.email), key: 'accounting_email' },
                {
                    label: intl.formatMessage(appMessages.language),
                    key: 'language',
                    formatter: (value) => props.intl.formatMessage({ id: `app.languages.${value.toLowerCase()}` }),
                },
                { label: '', key: 'actions' },
            ],
            loading: !props.vendors.length,
        };

        vm.onClick = vm.onClick.bind(vm);
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (nextProps.vendors.length && nextProps.forms === vm.props.forms) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    onClick(event) {
        const vm = this;
        const vendorId = event.currentTarget.getAttribute('data-id');

        if (event.target.parentNode.tagName !== 'A') {
            vm.context.router.push(`/vendors/${vendorId}`);
        }
    }
    render() {
        const vm = this;
        const { columns } = vm.state;
        const vendors = _.map(vm.props.vendors, (vendor) => {
            const mappedVendor = { ...vendor };
            mappedVendor.actions = getVendorActions(vendor.id, vm.props.user);
            return mappedVendor;
        });

        return (
            <div className="vendor-list-container">
                <Loader active={vm.state.loading}>
                    <List
                        name="vendors"
                        columns={columns}
                        data={vendors}
                        defaultSort={{ column: 'name', direction: 'asc' }}
                        onRowClick={vm.onClick}
                    />
                </Loader>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('vendors'),
    (user, vendors) => ({ user, vendors })
);

export default connect(mapStateToProps, null)(injectIntl(Vendors));
