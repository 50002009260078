import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Validator from '../../validator';
import { registerField, setValue, setErrors } from '../actions';

export class FieldBase extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;

        vm.state = {
            value: props.defaultValue,
            focus: !!props.defaultValue || props.defaultValue === 0,
            active: false,
            pristine: true,
            disabled: props.disabled,
            hasValue: !!props.defaultValue || props.defaultValue === 0,
            errors: [],
        };

        vm.onFocus = vm.onFocus.bind(vm);
        vm.onBlur = vm.onBlur.bind(vm);
        vm.onInput = vm.onInput.bind(vm);
        vm.validate = vm.validate.bind(vm);
        vm.setValue = _.debounce(vm.setValue);
    }
    componentWillMount() {
        const vm = this;
        const { dispatch } = vm.props;
        const field = {
            name: vm.props.name,
            value: vm.props.defaultValue === false
                   ? vm.props.defaultValue
                   : vm.props.defaultValue || '',
            errors: vm.props.errors || [],
            validate: vm.validate || vm.props.type,
        };

        if (vm.props.fieldGroup) {
            field.fieldGroup = vm.props.fieldGroup;
        }

        field.value = vm.format(field.value);

        if (vm.props.forms[vm.props.formId]) {
            dispatch(registerField(vm.props.formId, field));
        }
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { dispatch } = nextProps;

        const fieldId = vm.props.fieldGroup ? `${vm.props.fieldGroup}.${vm.props.name}` : vm.props.name;
        const nextFieldId = nextProps.fieldGroup ? `${nextProps.fieldGroup}.${nextProps.name}` : nextProps.name;
        const field = vm.props.forms[vm.props.formId].fields[fieldId] || {};
        const nextField = nextProps.forms[nextProps.formId].fields[nextFieldId];
        const pristine = vm.props.forms[vm.props.formId].states.pristine;

        if (nextField) {
            vm.setState({
                errors: nextField.errors || [],
            });
        }

        if (nextField && nextField.value === '') {
            if (nextField.value !== field.value) {
                vm.setState(() => ({
                    value: nextField.value,
                    focus: (!!nextField.value || nextField.value === 0) && !pristine,
                }));
            }
        }

        if ((_.has(nextProps, 'defaultValue') && !_.isEqual(vm.props.defaultValue, nextProps.defaultValue)) ||
            (_.has(vm.props, 'defaultChecked') && !_.isEqual(vm.props.defaultChecked, nextProps.defaultChecked))) {
            const nextValue = vm.format(nextProps.defaultValue);
            vm.setState(() => ({
                value: nextValue,
                focus: !!nextValue || nextValue === 0,
                hasValue: !!nextValue || nextValue === 0,
            }));
            dispatch(setValue(nextProps.formId, nextProps.name, nextProps.fieldGroup, nextValue));
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        const vm = this;
        let shouldUpdate = false;
        const fieldId = vm.props.fieldGroup ? `${vm.props.fieldGroup}.${vm.props.name}` : vm.props.name;
        const nextFieldId = nextProps.fieldGroup ? `${nextProps.fieldGroup}.${nextProps.name}` : nextProps.name;
        const field = vm.props.forms[vm.props.formId].fields[fieldId] || {};
        const nextField = nextProps.forms[nextProps.formId].fields[nextFieldId];
        if (nextField) {
            if (nextField.value !== field.value) {
                shouldUpdate = true;
            }
            if (JSON.stringify(nextField.errors) !== JSON.stringify(field.errors)) {
                shouldUpdate = true;
            }
        }
        if (JSON.stringify(nextProps.options) !== JSON.stringify(vm.props.options)) {
            shouldUpdate = true;
        }
        if (JSON.stringify(nextState) !== JSON.stringify(vm.state)) {
            shouldUpdate = true;
        }
        if (vm.props.loading !== nextProps.loading) {
          shouldUpdate = true;
        }

        return shouldUpdate;
    }
    onFocus(event) {
        const vm = this;
        const newState = { ...vm.state };
        const value = event.currentTarget.type === 'checkbox'
                      ? vm.format(event.currentTarget.checked)
                      : vm.format(event.currentTarget.value);
        event.preventDefault();

        if (!vm.state.disabled) {
            newState.value = value;
            newState.hasValue = !!value || value === 0;
            newState.focus = true;
            newState.active = true;
            vm.setState(newState);
        }
        vm.props.onFocus(event);
    }
    onBlur(event) {
        const vm = this;
        const newState = { ...vm.state };
        const value = event.currentTarget.type === 'checkbox'
                      ? vm.format(event.currentTarget.checked)
                      : vm.format(event.currentTarget.value);
        event.preventDefault();

        if (!vm.state.disabled) {
            newState.hasValue = !!value || value === 0;
            newState.focus = !!value || value === 0;
            newState.active = false;
            vm.setState(newState, () => {
                this.validate(value);
            });
        }
        vm.props.onBlur(event);
    }
    onInput(event) {
        const vm = this;
        const value = event.currentTarget.type === 'checkbox'
                      ? vm.format(event.currentTarget.checked)
                      : vm.format(event.currentTarget.value);
        event.persist();

        if (!vm.state.disabled) {
            vm.setValue(value);
        }
        vm.props.onInput(event, value);
    }
    setValue(value) {
        const vm = this;
        const { dispatch } = vm.props;
        const newState = { ...vm.state };
        newState.value = value;
        newState.focus = newState.hasValue = !!value || value === 0;
        newState.pristine = false;
        vm.setState(newState, () => {
            vm.validate(value);
            dispatch(setValue(vm.props.formId, vm.props.name, vm.props.fieldGroup, value));
        });
    }
    validate(value) {
        const vm = this;
        const { dispatch } = vm.props;
        const newState = { ...vm.state };
        newState.errors = [];

        if (vm.props.noValidate) {
            return;
        }

        Validator.validate(vm.props.validator || vm.props.type, value, (isValid, errors) => {
            newState.errors.push(...errors);
        });

        if (vm.props.required) {
            Validator.validate('required', value, (isValid) => {
                if (isValid) { return; }
                newState.errors.push({
                    id: 'Form.errors.required',
                    error: 'This field is required',
                });
            });
        }
        if (vm.props.minlength) {
          const str = value.toString();
          if (str.length < vm.props.minlength) {
            newState.errors.push({
              id: 'Form.errors.minlength',
              error: `This field has a minimum length of ${vm.props.minlength}`,
            });
          }
        }
        if (vm.props.maxlength) {
          const str = value.toString();
          if (str.length > vm.props.maxlength) {
            newState.errors.push({
              id: 'Form.errors.maxlength',
              error: `This field has a maximum length of ${vm.props.maxlength}`,
            });
          }
        }
        if (vm.props.exactlength) {
          const str = value.toString();
          if (str.length !== vm.props.exactlength) {
            newState.errors.push({
              id: 'Form.errors.maxlength',
              error: `This field must be exactly ${vm.props.exactlength} characters of length`,
            });
          }
        }

        if (vm.props.type === 'number' || vm.props.numeric) {
            Validator.validate('numeric', value, (isValid) => {
                if (isValid) { return; }
                newState.errors.push({
                    id: 'Form.errors.numeric',
                    error: 'This field allows numbers only',
                });
            });
        }

        vm.setState(newState);
        dispatch(setErrors(vm.props.formId, vm.props.name, vm.props.fieldGroup, newState.errors));
    }
    format(value) {
        const vm = this;
        return vm.props.formatter(value);
    }
    render() {
        return null;
    }
}

FieldBase.defaultProps = {
    className: '',
    defaultValue: '',
    disabled: false,
    onFocus: () => {},
    onInput: () => {},
    onBlur: () => {},
    formatter: (value) => value,
    noValidate: false,
};

FieldBase.propTypes = {
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    disabled: PropTypes.bool,
    // noValidate: React.PropTypes.bool,
    // onFocus: React.PropTypes.func,
    // onInput: React.PropTypes.func,
    // onBlur: React.PropTypes.func,
    // formatter: React.PropTypes.func,
};

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps)(FieldBase);
