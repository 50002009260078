import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import '../styles/callout.scss';

class Callout extends PureComponent {
    static defaultProps = {
        onClick: _.noop,
    }
    static propTypes = {
        count: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }
    getClassName() {
        const classList = [
            'xs2-callout',
        ];

        if (this.props.className) {
            classList.push(this.props.className);
        }

        return classList.join(' ');
    }
    render() {
        const { count, label } = this.props;

        return (
            // eslint-disable-next-line
            <div className={this.getClassName()} onClick={this.props.onClick}>
                <small className="muted">{label}</small><br />
                <strong className="h4">{count}</strong>
            </div>
        );
    }
}

export default Callout;
