import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Autocomplete, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { VENDORS_URL } from 'components/Vendor/constants';
import auth from 'components/Users/auth';
import { CONTACTS_URL } from '../constants';
import { newContact, editContact } from '../actions';
import messages from '../messages';

class ContactForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            contact: {},
            vendorId: props.user.vendor || null,
            vendorOptions: [],
            headers: { Authorization: `Token ${props.user.token}` },
        };

        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const { headers, vendorId } = vm.state;

        if (!vendorId) {
            axios.get(`${VENDORS_URL}`, { headers }).then((response) => {
                vm.state.vendorOptions = response.data.result.map((vendor) =>
                    ({
                        value: vendor.id,
                        label: vendor.name,
                    })
                );

                vm.setState(vm.state);
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { router } = vm.context;

        if (nextProps.contacts.length) {
            vm.state.contact = nextProps.contacts.find((i) => +i.id === +router.params.id) || {};
        }

        vm.setState(vm.state);
    }
    onSubmit(data) {
        const vm = this;
        const { router } = vm.context;
        const { toast, dispatch } = vm.props;
        const { headers } = vm.state;

        if (vm.props.action === 'new') {
            axios.post(`${CONTACTS_URL}`, data, { headers }).then((response) => {
                dispatch(newContact(response.data.result));
                router.push(router.getCurrentLocation().query.referrer || '/contacts');
                return toast.success('Success!', <FormattedMessage {...messages.notifications.createdContact} values={{ ...data }} tagName="p" />);
            });
        } else if (vm.props.action === 'edit') {
            axios.patch(`${CONTACTS_URL}${router.params.id}/`, data, { headers }).then((response) => {
                dispatch(editContact(response.data.result));
                router.push(router.getCurrentLocation().query.referrer || '/contacts');
                return toast.success('Success!', <FormattedMessage {...messages.notifications.editedContact} values={{ ...data }} tagName="p" />);
            });
        }
    }
    render() {
        const vm = this;
        const { user, action } = vm.props;
        const { contact, vendorOptions } = vm.state;

        return (
            <div id="UserForm" className="user-form">
                <div className="contacts-header">
                    <h1 className="center-align">
                        <FormattedMessage {...appMessages[action]} /> <FormattedMessage {...appMessages.contact} />
                    </h1>
                </div>
                <Form id="contact-new" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <FormGroup className="input-group">
                            <Input type="text" name="name" className="input-medium" label={appMessages.firstName} defaultValue={contact.name} />
                            <Input type="text" name="last_name" className="input-medium" label={appMessages.lastName} defaultValue={contact.last_name} />
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Input type="email" name="email" label={appMessages.email} defaultValue={contact.email} />
                        <Input type="text" name="phone_number" label={appMessages.phoneNumber} defaultValue={contact.phone_number} />
                        <Input type="text" name="mobile_number" label={appMessages.mobileNumber} defaultValue={contact.mobile_number} />
                    </FormGroup>
                    {auth.userIsAtlance(user) ?
                        <FormGroup>
                            <Autocomplete
                                name="vendor"
                                label={appMessages.vendor}
                                options={vendorOptions}
                                defaultValue={contact.vendor}
                            />
                        </FormGroup>
                    : null}
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('contacts').get('contacts').toJS(),
    (user, toast, contacts) => ({ user, toast, contacts })
);

export default connect(mapStateToProps, null)(ContactForm);
