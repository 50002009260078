import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import appMessages from 'components/App/messages';
import ArchiveList from '../partials/ArchiveList';
import { initArchive } from '../actions';
import { DOSSIERS_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';

export class Archive extends Component {
    static propTypes = {
        archive: PropTypes.array,
    }
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            loading: props.archive.length,
        };
    }
    componentDidMount() {
        const vm = this;
        vm.fetchData();
    }
    fetchData() {
        const vm = this;
        const { dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.get(`${DOSSIERS_URL}archive/`, { headers }).then((response) => {
            dispatch(initArchive(response.data.result));
        });
    }
    render() {
        const vm = this;
        const { archive } = vm.props;

        return (
            <div id="ArchiveList">
                <div className="archive-header layout-row">
                    <h1 className="flex">
                        <FormattedMessage {...appMessages.archive} />
                    </h1>
                </div>
                <div className="archive">
                    <ArchiveList dossiers={archive} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dossiers').get('archive').toJS(),
    (user, archive) => ({ user, archive }),
);

export default connect(mapStateToProps)(Archive);
