import PropTypes from 'prop-types';
import React from 'react';
import Pane from './Pane';

import './tabs.scss';

class Tabs extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        selected: PropTypes.number,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            selected: props.selected ?? 0,
        };
    }
    componentDidUpdate(prevProps) {
        if(prevProps.selected !== this.props.selected) {
            this.setState({selected: this.props.selected});
        }
    }
    handleClick(index, event) {
        event.preventDefault();
        this.setState({ selected: index });
    }
    renderTitles() {
        return (
            <ul className="tabs-labels">
                {this.props.children.map(labels.bind(this))}
            </ul>
        );

        function labels(child, index) {
            if (!child) return;
            const activeClass = (this.state.selected === index ? 'active' : '');
            return (
                <li key={index}>
                    <a href="" className={activeClass} onClick={this.handleClick.bind(this, index)}>
                        {child.props.label}
                    </a>
                </li>
            );
        }
    }
    renderContent() {
        return (
            <div className="tabs-content">
                {this.props.children[this.state.selected]}
            </div>
        );
    }

    render() {
        return (
            <div className="tabs">
                {this.renderTitles()}
                {this.renderContent()}
            </div>
        );
    }
}

export { Pane };
export default Tabs;
