import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactTable from 'react-table-v6';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


const PAGE_SIZE = 10;

const pageHref = (num) => `#page-${num + 1}`;

class Paginator extends React.Component {
    static propTypes = {
        onPageChange: PropTypes.func,
        currentPage: PropTypes.number,
        previousPageLabel: PropTypes.string,
        nextPageLabel: PropTypes.string,
        pageButtonLimit: PropTypes.number,
        numPages: PropTypes.number,
    }

    handlePrevious(e) {
        e.preventDefault();
        this.props.onPageChange(this.props.currentPage - 1);
    }

    handleNext(e) {
        e.preventDefault();
        this.props.onPageChange(this.props.currentPage + 1);
    }

    handlePageButton(page, e) {
        e.preventDefault();
        this.props.onPageChange(page);
    }

    renderPrevious() {
        if (this.props.currentPage > 0) {
            return (
                <a
                    className="reactable-previous-page"
                    href={pageHref(this.props.currentPage - 1)}
                    onClick={this.handlePrevious.bind(this)}
                >
                    {this.props.previousPageLabel || 'Previous'}
                </a>
            );
        }
    }

    renderNext() {
        if (this.props.currentPage < this.props.numPages - 1) {
            return (
                <a
                    className="reactable-next-page"
                    href={pageHref(this.props.currentPage + 1)}
                    onClick={this.handleNext.bind(this)}
                >
                    {this.props.nextPageLabel || 'Next'}
                </a>
            );
        }
    }

    renderPageButton(className, pageNum) {
        return (
            <a
                className={className}
                key={pageNum}
                href={pageHref(pageNum)}
                onClick={this.handlePageButton.bind(this, pageNum)}
            >
                {pageNum + 1}
            </a>
        );
    }

    render() {
        const pageButtons = [];
        const pageButtonLimit = this.props.pageButtonLimit;
        const currentPage = this.props.currentPage;
        const numPages = this.props.numPages;
        const lowerHalf = Math.round(pageButtonLimit / 2);
        const upperHalf = (pageButtonLimit - lowerHalf);

        for (let pageNum = 0; pageNum < this.props.numPages; pageNum++) {
            let className = 'reactable-page-button';
            if (currentPage === pageNum) {
                className += ' reactable-current-page';
            }
            pageButtons.push(this.renderPageButton(className, pageNum));
        }

        if (currentPage - pageButtonLimit + lowerHalf > 0) {
            if (currentPage > numPages - lowerHalf) {
                pageButtons.splice(0, numPages - pageButtonLimit);
            } else {
                pageButtons.splice(0, currentPage - pageButtonLimit + lowerHalf);
            }
        }

        if ((numPages - currentPage) > upperHalf) {
            pageButtons.splice(pageButtonLimit, pageButtons.length - pageButtonLimit);
        }

        return (
            <div className="reactable-pagination">
                {this.renderPrevious()}
                {pageButtons}
                {this.renderNext()}
            </div>
        );
    }
}


class ListTable extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
        noDataText: PropTypes.string,
        responsive: PropTypes.bool,
        intl: PropTypes.object,
        data: PropTypes.array,
        page: PropTypes.number,
        count: PropTypes.number,
        urlParams: PropTypes.object,
        user: PropTypes.object,
        processor: PropTypes.func,
        getURL: PropTypes.string,
        rowHighlight: PropTypes.bool,
        onRowClick: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            page: props.page || 0,
            pages: Math.ceil(props.count / PAGE_SIZE),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.state = {
            data: nextProps.data,
            page: nextProps.page || 0,
            pages: Math.ceil(nextProps.count / PAGE_SIZE),
        };
    }

    sortData = (sorting) => {
        const ordering = sorting.map((entry) => `${entry.desc ? '-' : ''}${entry.id}`).join(',');
        this.fetchData(this.state.page, { ordering });
    }

    fetchData = (pageIndex, extraParams = {}) => {
        this.props.onPageChange(pageIndex);
        this.props.loadData({
            ...extraParams,
            offset: pageIndex * PAGE_SIZE,
        });
    }

    render() {
        const {
            id,
            name,
            columns,
            intl,
            responsive,
            noDataText,
            onRowClick,
        } = this.props;
        const { data, page, pages } = this.state;
        const tableProps = {
            className: `${name}-list`,
            columns,
            showPageSizeOptions: false,
            data,
            manual: true,
            page,
            pages,
            pageSize: PAGE_SIZE,
            onPageChange: this.fetchData,
            showPaginationBottom: false,
            minRows: 0,
            resizable: false,
            onSortedChange: this.sortData,
            getTrProps: (state, rowInfo) => ({
                onClick: (evt) => onRowClick(evt, rowInfo.original.id),
                style: { cursor: 'pointer' },
            }),
        };
        const className = `${name}-list-container ${responsive ? 'responsive-list-container' : ''} ${this.props.className}`;

        return pages.length === 0 ?
            <div>{noDataText}</div>
            :
            <div id={id} className={className}>
                <ReactTable {...tableProps}></ReactTable>
                <Paginator
                    currentPage={page}
                    onPageChange={this.fetchData}
                    colSpan={1}
                    numPages={pages}
                    pageButtonLimit={5}
                    previousPageLabel={intl.formatMessage(messages.previous)}
                    nextPageLabel={intl.formatMessage(messages.next)}
                />
            </div>;
    }
}

ListTable.defaultProps = {
    id: '',
    className: '',
    itemsPerPage: 10,
    pageButtonLimit: 5,
    showActions: true,
    onRowClick: () => {},
    noDataText: 'No data found',
    rowHighlight: true,
    sortable: true,
    data: [],
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (user) => ({ user })
);

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(ListTable, { withRef: true }));
