import SupportContainer from './containers/Support';
import AddressbookContainer from './containers/Addressbook';

export const supportRoutes = [
    { name: 'support-list', component: SupportContainer },
];

export const contactRoutes = [
    { name: 'contacts', component: AddressbookContainer },
    { path: 'new', name: 'contacts-new', component: AddressbookContainer },
    { path: ':id/edit', name: 'contacts-edit', component: AddressbookContainer },
    { path: ':id/delete', name: 'contacts-delete', component: AddressbookContainer },
];
