import {
    DOWNLOADS_INIT,
    DOWNLOADS_NEW,
    DOWNLOADS_DELETE,
} from './constants';

export function initDownloads(downloads) {
    return {
        type: DOWNLOADS_INIT,
        downloads,
    };
}

export function newDownload(download) {
    return {
        type: DOWNLOADS_NEW,
        download,
    };
}

export function deleteDownload(id) {
    return {
        type: DOWNLOADS_DELETE,
        id,
    };
}

