import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import placeholderImg from 'assets/img/placeholder.png';
import appMessages from 'components/App/messages';
import messages from '../messages';


function UserCard(props) {
    const { user } = props;

    return (
        <div className={`card user-card ${props.className}`}>
            <div className="card-header user-card-header">
                <img src={user.picture.thumb || placeholderImg} alt={user.name} />
            </div>
            <div className="user-card-content">
                <h3 className="truncate">{user.name}</h3>
                <p className="truncate">{user.function}</p>
                <p className="truncate"><a href={`tel:${user.phone_number}`}>{user.phone_number}</a></p>
                <p className="truncate"><a href={`tel:${user.mobile_number}`}>{user.mobile_number}</a></p>
            </div>
            <div className="card-footer user-card-footer">
                <p><span className="bold"><FormattedMessage {...appMessages.status} />: </span>{user.status || 'N/A'}</p>
                {user.contact_for_vendor && <p className="truncate"><FormattedMessage{...messages.contactForVendor} /></p>}
                {user.contact_for_client && <p className="truncate"><FormattedMessage{...messages.contactForClient} /></p>}
            </div>
      </div>
    );
}

UserCard.defaultProps = {
    className: '',
};
UserCard.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
};

export default UserCard;
