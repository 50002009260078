import PropTypes from 'prop-types';
import React from 'react';

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.onClick = vm.onClick.bind(vm);
    }

    onClick(event) {
        const vm = this;
        const { menu } = vm.context;

        if (menu.props.closeOnSelect) {
            menu.close();
        }

        vm.props.onClick(event);
    }

    render() {
        const vm = this;
        const classes = `xs2-menu-item xs2-button ${vm.props.className}`;

        if (vm.props.styles) {
            return (
                <button className={classes} style={vm.props.styles} onClick={vm.onClick}>
                    {vm.props.children}
                </button>
            );
        }
        return (
            <button className={classes} onClick={vm.onClick}>
                {vm.props.children}
            </button>
        );
    }
}

MenuItem.contextTypes = {
    menu: PropTypes.object,
};

MenuItem.defaultProps = {
    className: '',
    styles: '',
    onClick: () => {},
};

MenuItem.propTypes = {
    className: PropTypes.string,
    styles: PropTypes.any,
    onClick: PropTypes.func,
};

export default MenuItem;
