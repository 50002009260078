import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router';
import ReactModal from 'react-modal';

import { FormattedMessage } from 'react-intl';
import { DEFAULT_LOCALE } from 'components/App/constants';
import appMessages from 'components/App/messages';
import Loader from 'components/App/partials/Loader';

import { DOSSIERS_URL } from '../constants';

import DossierStatusFilters from '../partials/DossierStatusFilters';
import DossierList from '../partials/Dossiers';
import DossierForm from '../partials/DossierForm';
import RequestInfoForm from '../partials/RequestInfoForm';
import SendToBankForm from '../partials/SendToBanksForm';
import SubmitToSNIForm from '../partials/SubmitToSNIForm';
import ConfirmBankResponseForm from '../partials/ConfirmBankResponseForm';
import ConfirmCreditRequestForm from '../partials/ConfirmCreditRequestForm';
import ExpiryDateForm from '../partials/ExpiryDateForm';
import DocumentUploader from '../partials/DocumentUploader';
import LogClientCommunicationForm from '../partials/LogClientCommunicationForm';
import DocuSignForm from '../partials/DocuSignForm';
import SignForm from '../partials/SignForm';
import FunderForm from '../partials/FunderForm';
import { initDossiers, editDossier, addToArchive, newDossierDocument } from '../actions';
import messages from '../messages';

import '../styles/dossiers.scss';

const FORM_ACTIONS = [
    'new',
    'edit',
    'financialEdit',
    'administrativeEdit',
    'finishinfo',
    'confirminfo',
    'request',
    'confirm',
    'send',
    'success',
    'successconditions',
    'failure',
    'quotation',
    'worddoc',
    'calcdoc',
    'expiry',
    'review',
    'upload',
    'funder',
    'submittosni',
    'communication',
    'cancel',
    'archive',
    'expire',
    'sign',
    'docusign',
    'complete',
];

class Dossiers extends React.PureComponent {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            loading: !props.dossiers.length,
        };

        vm.toggleForm = vm.toggleForm.bind(vm);
        vm.onCounterClick = vm.onCounterClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getDossiers();
        vm.handleForm();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.handleForm();
        }

        if (nextProps.dossiers || !_.isEqual(nextProps.dossiers, vm.props.dossiers)) {
            vm.state.loading = false;
        }
        vm.setState(vm.state);
    }
    onCounterClick(event, key) {
        const vm = this;
        const dossierList = vm.dossierList.getWrappedInstance().getWrappedInstance();
        dossierList.applyFilter('status', key === 'TOTAL' ? '' : key);
    }
    getDossiers(offset = 0) {
        const vm = this;
        const { dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const dossierList = vm.dossierList.getWrappedInstance().getWrappedInstance();
        const pathArray = vm.context.router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const action = vm.context.router.params.action || pathArray[pathArray.length - 1];

        if (action === 'dossiers') {
            axios.get(`${DOSSIERS_URL}?offset=${offset}`, { headers }).then((response) => {
                dispatch(initDossiers(
                    response.data.result,
                    response.data.extra.summaries,
                    response.data.extra.notable_fields,
                    response.data.count,
                    response.data.extra.filters,
                ));
                if (!_.isEmpty(vm.context.router.getCurrentLocation().query) || localStorage.getItem('restoreFiltersOnDossier')) {
                    dossierList.getFilteredDossiers();
                }
            });
        }
    }
    handleForm() {
        const vm = this;
        const pathArray = vm.context.router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const action = vm.context.router.params.action || pathArray[pathArray.length - 1];
        const { dispatch, toast, user } = vm.props;

        if (FORM_ACTIONS.includes(action)) {
            const dossierId = vm.context.router.params.id || pathArray[pathArray.length - 2];
            const dossier = vm.props.dossiers.find((i) => i.id === +dossierId) || {};
            const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

            if (action === 'new' || (['edit', 'financialEdit', 'administrativeEdit'].includes(action) && +dossierId)) {
                vm.state.action = action;
            }
            if (['new', 'edit', 'financialEdit', 'administrativeEdit'].includes(action)) {
                vm.state.form = (<DossierForm action={vm.state.action} />);
            } else if (action === 'request') {
                vm.state.form = (<RequestInfoForm />);
            } else if (action === 'finishinfo') {
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'FINISHINFOSUBMISSION' }, { headers }).then((response) => {
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.finishedInfoRequest} tagName="p" />);
                });
            } else if (action === 'confirminfo') {
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'CONFIRMINFOSUBMISSION' }, { headers }).then((response) => {
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.confirmedInfoRequest} tagName="p" />);
                });
            } else if (action === 'confirm') {
                vm.state.form = (<ConfirmCreditRequestForm />);
            } else if (action === 'send') {
                vm.state.form = (<SendToBankForm />);
            } else if (action === 'success' || action === 'failure' || action === 'successconditions') {
                vm.state.form = (<ConfirmBankResponseForm action={action} />);
            } else if (action === 'funder') {
                vm.state.form = (<FunderForm />);
            } else if (action === 'quotation') {
                const data = { action_code: 'CREATEQUOTATIONDOCUMENT', data: {} };
                if (user.vendor) {
                    data.data.vendor = user.vendor.id;
                }
                toast.info('Info', <FormattedMessage {...messages.notifications.startedQuoteDocCreation} tagName="p" />);
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, data, { headers }).then((response) => {
                    dispatch(newDossierDocument(dossierId, response.data.extra.document));
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.finishedQuoteDocCreation} values={{ link: <a href={`${response.data.extra.document.result_file}?token=${vm.props.user.token}`} download>Download</a> }} tagName="p" />);
                });
            } else if (action === 'calcdoc') {
                const data = { action_code: 'CREATECALCDOCUMENT', data: {} };
                if (user.vendor) {
                    data.data.vendor = user.vendor.id;
                }
                toast.info('Info', <FormattedMessage {...messages.notifications.startedCalcDocCreation} tagName="p" />);
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, data, { headers }).then((response) => {
                    dispatch(newDossierDocument(dossierId, response.data.extra.document));
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.finishedCalcDocCreation} values={{ link: <a href={`${response.data.extra.document.result_file}?token=${vm.props.user.token}`} download>Download</a> }} tagName="p" />);
                });
            } else if (action === 'worddoc') {
                vm.setState({ working: true });
                const data = { action_code: 'CREATECONTRACTDOCUMENT', data: {} };
                if (user.vendor) {
                    data.data.vendor = user.vendor.id;
                }
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, data, { headers }).then((response) => {
                    dispatch(newDossierDocument(dossierId, response.data.extra.document));
                    dispatch(editDossier(response.data.result));
                    if (vm.context.router.location.search) {
                      vm.context.router.push(vm.context.router.location.search.replace("?referrer=", ""));
                    } else {
                      vm.context.router.push(`/dossiers/${dossierId}`);
                    }
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.finishedContractDocCreation} values={{ link: <a href={`${response.data.extra.document.result_file}?token=${vm.props.user.token}`} download>Download</a> }} tagName="p" />);
                }).finally(() => {
                  vm.setState({ working: false });
                });
            } else if (action === 'expiry') {
                vm.state.form = (<ExpiryDateForm />);
            } else if (action === 'upload') {
                vm.state.form = (<DocumentUploader show dossier={dossier} />);
            } else if (action === 'submittosni') {
                vm.state.form = (<SubmitToSNIForm dossier={dossier} />);
            } else if (action === 'sign') {
                vm.state.form = (<SignForm />);
            } else if (action === 'docusign') {
                vm.state.form = (<DocuSignForm />);
            } else if (action === 'complete') {
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'COMPLETE' }, { headers }).then((response) => {
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.signedDossier} tagName="p" />);
                });
            } else if (action === 'communication') {
                vm.state.form = (<LogClientCommunicationForm dossier={dossier} dossierId={dossierId} />);
            } else if (action === 'cancel') {
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'CANCEL' }, { headers }).then((response) => {
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push('/dossiers');
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.canceledDossier} tagName="p" />);
                });
            } else if (action === 'archive') {
                axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'ARCHIVEDOSSIER' }, { headers }).then((response) => {
                    dispatch(addToArchive(response.data.result));
                    vm.context.router.push('/dossiers/archive');
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.archivedDossier} tagName="p" />);
                });
            }

            if (vm.state.form) {
                vm.state.showForm = true;
            }
        } else {
            vm.state.showForm = false;
        }
    }
    toggleForm(action) {
        return () => {
            const vm = this;

            if (vm.state.showForm) {
                vm.context.router.push('/dossiers');
            }
            vm.setState({ action, showForm: !vm.state.showForm });
        };
    }
    render() {
        const vm = this;
        const modalOptions = {
            isOpen: vm.state.showForm,
            contentLabel: 'Dossier form',
            className: 'modal-dashboard',
            overlayClassName: 'modal-overlay-dashboard',
            onRequestClose: vm.toggleForm(vm.state.action),
            shouldCloseOnOverlayClick: true,
        };

        return (
            <div id="Dossiers">
                <div className="dossiers-header layout-row">
                    <h1 className="flex">
                        <FormattedMessage {...appMessages.dossiers} />
                    </h1>
                    <div className="dossiers-controls flex-nogrow layout-row layout-align-center-center">
                        <Link to={{ pathname: '/dossiers/new', query: { referrer: vm.context.router.getCurrentLocation().pathname } }} className="dossiers-new button button-default"><FormattedMessage {...appMessages.actions.NEWDOSSIER} /></Link>
                    </div>
                </div>
                <div className="dossiers-sub-header">
                    <Loader className="flex" active={vm.state.loading}>
                        <DossierStatusFilters counters={vm.props.counters} onClick={vm.onCounterClick} />
                    </Loader>
                </div>
                <div className="dossiers">
                    <Loader className="flex" active={vm.state.working}>
                      <DossierList ref={(ref) => { vm.dossierList = ref; }} getDossiers={this.getDossiers} />
                    </Loader>

                </div>
                <ReactModal {...modalOptions}>
                    <Link to={vm.props.location.query.referrer || '/dossiers'} className="modal-close" onClick={vm.toggleForm()}><i className="fa fa-arrow-left"></i></Link>
                    {vm.state.form}
                </ReactModal>
            </div>
        );
    }
}

Dossiers.contextTypes = {
    router: PropTypes.object,
};
Dossiers.propTypes = {
    dossiers: PropTypes.array,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (state) => state.get('dossiers').get('counters').toJS(),
    (state) => state.get('dashboard').get('toast'),
    (user, dossiers, counters, toast) => ({ user, dossiers, counters, toast })
);

export default connect(mapStateToProps, null)(Dossiers);
