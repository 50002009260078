import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { mapFormIdToChildProps } from '../utils';
import { registerForm, clearForm } from '../actions';

class Form extends React.PureComponent {
    constructor(props) {
        super(props);

        const vm = this;
        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.onReset = vm.onReset.bind(vm);
    }
    componentWillMount() {
        const vm = this;
        const { dispatch } = vm.props;
        const form = vm.props.forms[vm.props.id] || {
            id: vm.props.id,
            theme: vm.props.theme,
            states: {
                valid: !!vm.props.noValidate,
                pristine: true,
            },
            fields: {},
        };

        dispatch(registerForm(form));
    }
    componentWillUnmount() {
        const vm = this;
        const event = new Event('reset');
        if (!vm.props.persist) {
            vm.onReset(event);
        }
    }
    onSubmit(event) {
        const vm = this;
        const form = vm.props.forms[vm.props.id];
        const data = {};

        event.preventDefault();

        Object.keys(form.fields).forEach((key) => {
            const field = form.fields[key];

            if (field.fieldGroup) {
                const groupKey = field.fieldGroup.split('[')[0];
                const groupIndex = +field.fieldGroup.split('[')[1].slice(0, -1);
                const fieldKey = key.split('.')[1];

                if (!data[groupKey]) {
                    data[groupKey] = [];
                }
                if (!data[groupKey][groupIndex]) {
                    data[groupKey][groupIndex] = {};
                }
                data[groupKey][groupIndex][fieldKey] = field.value;
            } else {
                data[key] = field.value;
            }

            // dispatch(setValue(vm.props.id, field.name, field.fieldGroup, field.value));

            if (typeof field.validate === 'function') {
                field.validate(field.value);
            }
        });

        if (form.states.valid || vm.props.valid) {
            vm.props.submitHandler(data, event);
        }
    }
    onReset(event) {
        const vm = this;
        const { dispatch } = vm.props;

        dispatch(clearForm(vm.props.id));
        vm.props.resetHandler(event);
    }
    render() {
        const vm = this;
        const children = mapFormIdToChildProps(vm.props.children, vm.props.id);

        return (
            <form
                id={vm.props.id}
                className={`xs2-form ${vm.props.className}`}
                onSubmit={vm.onSubmit}
                onReset={vm.onReset}
                noValidate
            >
                {children}
            </form>
        );
    }
}

Form.defaultProps = {
    theme: 'default',
    className: '',
    resetHandler: () => {},
};

Form.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    forms: PropTypes.object,
    resetHandler: PropTypes.func,
};

// Form.willTransitionFrom = (component, transition) => {
//     const confirmed = !component.state.pristine && confirm(
//         'Are you sure you want to leave this page? All your unsaved information will be lost.'
//     );

//     if (confirmed) {
//         transition.abort();
//     }
// };

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
