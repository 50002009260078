import { API_URL } from 'components/App/constants';

export const DOSSIERS_URL = `${API_URL}dossiers/`;
export const FUNDERS_URL = `${API_URL}funders/`;
export const CREDITREQUEST_OPTION_URL = `${API_URL}dossiers/creditrequests/options/`;
export const DOSSIERS_INIT = 'Dossiers/init';
export const DOSSIERS_NEW = 'Dossiers/new';
export const DOSSIERS_EDIT = 'Dossiers/edit';
export const DOSSIERS_DOCUMENTS_INIT = 'Dossiers/Documents/init';
export const DOSSIERS_DOCUMENTS_NEW = 'Dossiers/Documents/new';
export const DOSSIERS_DOCUMENTS_EDIT = 'Dossiers/Documents/edit';
export const DOSSIERS_ACTIONS_INIT = 'Dossiers/Actions/init';
export const ARCHIVE_INIT = 'Dossiers/Archive/init';
export const ARCHIVE_ADD = 'Dossiers/Archive/add';
export const FUNDERS_INIT = 'Dossiers/Funders/init';

export const DOCUMENT_TYPES = [
    'CALCULATIONEXCEL',
    'CONTRACT',
    'SIGNEDCONTRACT',
    'ID',
    'PV',
    'SEPA',
    'TAXFORM',
    'BALANCESHEET',
    'INVOICE',
    'POWEROFATTORNEY',
    'OTHER',
    'CANCELANDREPLACE',
    'INTERNALCOMMUNICATION',
];

/* eslint-disable quotes */
export const ATRADIUS_RESPONSE_CODES = {
    A0: "Avec versement de l'acompte renseigné dans votre proposition au plus tard le jour de la livraison.",
    A1: "Avec versement d'un acompte en espèces de … euros, au plus tard le jour de la livraison.",
    A4: "Avec un premier loyer augmenté de  … euros. Sauf indication contraire, ce montant s'entend hors taxes.",
    A5: "Avec garantie bancaire irrévocable de … euros, appelable à la première demande depuis la livraison jusqu'a … mois après celle-ci (durée du crédit + 3 mois en principe)",
    A6: "Avec une garantie de … euros constituée de …",
    AA: "Une demande de crédit au nom de l'intéressé€ a déjà été acceptée ailleurs.",
    AR: "Activité récente",
    AT: "En annulation de toutes décisions antérieures.",
    AV: "La reste à vivre est insuffisant.",
    BO: "Avec cession sur salaire dénoncée.", // Body odour
    B1: "Avec précompte sur le salaire de …",
    B2: "Avec prélèvement interbancaire.",
    B3: "Avec prélèvement sur le dépôt à vue.",
    C0: "Avec le (les) co-emprunteur(s) ou le (les) co-acheteur(s) solidaire(s) et indivisible(s) proposé(s).",
    C1: "Avec comme co-emprunteur(s) ou comme co-acheteur(s) solidaire(s) et indivisible(s) …",
    CC: "Il existe un ou plusieurs crédits en cours.",
    CL: "Les charges vont devenir lourdes pour le budget de l'intéressé.",
    CP: "Il existe un ou plusieurs autres crédits en cours.",
    CS: "Le(s) crédit(s) est (sont) trop récent(s) pour être soldé(s).",
    CX: "L'intéressé€ a ou a eu un ou plusieurs contentieux auprès d'une autre société,",
    CZ: "Le(s) crédit(s) en cours constitue(nt) un maximum.",
    DC: "Compte tenu de la décision de la compagnie d'assurance vie.",
    DD: "Selon nos sources, l'intéressé€ serait employé(e) à durée déterminée.",
    DE: "Durée excessive",
    DM: "Décision maintenue malgré vos précisions.",
    E0: "Le montant des inscriptions existantes ne pourra excéder … euros et les inscriptions auront été prises aux dates suivantes: … (si indiquées). Veuillez vérifier sur base d'un état de charges levé auprès du service compétent de l'administration. L'intéressé(e) s'engagera à ne pas aliéner ni hypothéquer les biens immeubles pendant la durée du crédit.",
    E1: "Le montant du crédit hypothécaire ne pourra dépasser … euros. Veuillez vérifier sur base de l'acte de prêt. L'intéressé€ s'engagera à ne pas aliéner ni hypothéquer ni hypothéquer les biens immeubles pendant la durée du crédit.",
    E5: "Attestation du (de la) client€ stipulant qu'il (elle) n'a aucun crédit en cours en dehors du (des) crédit(s) renseigné(s) avec engagement de ne solliciter ni accepter aucun nouveau crédit ou reprise d'encours sans avoir obtenu au préalable l'accord de la société auprès de laquelle le présent crédit est sollicité.",
    EE: "Emploi récent.",
    G0: "Avec aval(s) out caution(s) personnelle(s), solidaire(s), et indivisible(s) tel(s) que proposé(s)",
    G1: "Avec aval ou caution pesonnelle, solidaire et indivisible des époux…",
    G2: "Avec aval ou caution pesonnelle, solidaire et indivisible de …",
    G3: "Avec aval ou caution pesonnelle, solidaire et indivisible des parents du demandeur de crédit.",
    G9: "Avec aval ou caution personnelle, solidaire et indivible proposé et celui de son conjoint.",
    G10: "Avec déclaration de l'époux(se) non signataire par laquelle il (elle) déclare connaître et accepter, sans être personnellement obligé(e), le cautionnement (l'aval) donné par son conjoint",
    GB: "Une garantie autre que celle proposée est nécessaire pour la couverture du rique.",
    GC: "Une garantie complémentaire à celle proposée et nécessaire pour la couverture du risque.",
    GI: "Le résultat de l'étude des éléments communiqués ne permet pas la couverture de l'opération.",
    GN: "La surface présentée par votre client est pratiquement nulle.",
    GV: "Un aval ou une caution personnelle, solidaire et indivisible est nécessaire pour revoir votre dossier.",
    H0: "Pour autant que l'immeuble dont l'intéressé(e) est propriétaire soit libre de toutes charges. Veuillez vérifier sur base d'un état de charges levé auprès du service compétent de l'administration.",
    H5: "Pour autant que la mensualité du crédit hypothécaire ou du loyer n'excède pas … euros",
    H9: "Pour autant que la personne renseignée comme propriétaire soit bien seule propriétaire du (des) bien(s) immeuble(s). Veuillez vérifier sur base de l'acte de propriété.",
    H10: "Pour autant que la personne renseignée comme propriétaire soit bien seule propriétaire du (des) bien(s) immeuble(s). Veuillez vérifier sur base d'une recherche auprès du service compétent de l'administration.",
    HP: "Cette demande sort du cadre de votre police.",
    IC: "Le couverture du risque ne peut être envisagée sur base des seuls éléments de votre propostion: pour une révision éventuelle, merci de nous communiquer…",
    IN: "Selon nos sources, l'intéressé€ serait employé(e) en tant qu'intérimaire.",
    IS: "La fréquence des changements d'adresse et/ou d'emploi ne permet pas d'apprécier la faculté de remboursement dans le futur.",
    M0: "Avec les garanties prévues à votre demande de couverture.",
    M1: "Pour autant que l'intéressé(e) vous apporte la preuve que la (les) traite(s) protestée(s) dont mention ci-après, a (ont) bien été payée(s) au plus tard dans les trois mois qui ont suivi la date du (des) protêt(s)…",
    M2: "Pour autant que le sinistre actuellement en cours soit soldé par vos soins ou tout au moins sous votre contröle (n° de contentieux, caractéristiques du crédit tombé en sinistre et solde).",
    M3: "Pour autant que l'intéressée vous remette une attestation de son employeur certifiant que son salaire n'a fait l'objet d'aucune saisie ou cession depuis 3 ans (sauf indication spéciale reprise à l'option quant à la durée).",
    M4: "Pour autant que vous obteniez la preuve qu'il n'y a aucune dette au plan d'apurement vis-à-vis de la Sécurité Sociale.",
    M5: "Pour autant que l'intéressé(e) vous remette une attestation des administrations compétentes certifiant qu'il (elle) n'a pas de retard ou plan d'apurement pour le paiement des impôts et de la taxe sur la valeur ajoutée.",
    M6: "Pour autant que l'intéressé(e) vous apporte la preuve qu'il (elle) est bien propriétaire.",
    M7: "Pour autant que l'intéressé(e) soit employé€ à durée indéterminée chez l'employeur renseigné.",
    M8: "Avec engagement signé par le vendeur … de reprendre, à notre première demande en cas de sinistre, le matériel financé suivant le plan de rachat détaillé ci-après … La convention devra être signée par le vendeur, l'acquéreur et l'assuré.",
    M9: "Pour autant que le(s) renvu(s) locatif(s) mensuel(s) s'élève(nt) à … euros. Les revenus devront être vérifiés sur base du (des) bail (baux) locatif(s) et du (des) dernier(s) extrait(s) de compte…",
    M10: "Pour autant que le sinistre actuellement en cours soit soldé par vos soins ou tout au moins sous votre contrôle (n° de contentieux, caractéristiques du crédit tombé en sinistre et solde).",
    MG: "Décision maintenue, même avec l'(les) aval(s), la (les) caution(s) personnelle(s) et indivisible(s) ou la (les) garantie(s).",
    MM: "Même pour un montant moindre.",
    MS: "L'intéressé€ sollicite ailleurs un montant supérieur.",
    N1: "Au nom de l'intéressé(e) seul(e), à titre exceptionnel.",
    N2: "Au nom des époux.",
    N3: "Au nom de l'intéressé(e) seul(e), pour autant qu'il (elle) soit marié(e) sous la régime de la séparation des biens.",
    N6: "Avec déclaration de l'époux(se) non signataire par laquelle il (elle) déclare avoir connaissance et accepter, sans être personnellement obligé(e), l'engagement contracté par son conjoint.",
    NA: "La couverture du risque ne peut être envisagée.",
    NE: "Selon nos sources, l'intéressé(e) ne serait pas (plus) employé(e) chez l'employeur renseigné.",
    O1: "Les sigles ci-après concernant uniquement l'emprunteur principal.",
    O2: "Les sigles ci-après concernant uniquement le co-emprunteur ou l'époux(se).",
    O3: "Les sigles ci-après concernant uniquement l' (les) aval(s) ou la (les) caution(s).",
    PC: "La partie cessible et saisissable de la rémunération de l'intéressé(e) est trop réduite pour constituer une garantie du crédit sollicité.",
    PR: "Nous avons relevé un ou plusieurs protêt(s) sur l'intérssé(e).",
    PS: "Retraité(e) sans garantie suffisante et/ou dont les ressources sont insuffisantes.",
    Q0: "Veuillez vérifier sur base de la fiche officielle si la rémunération mensuelle nette de base correspond au montant renseigné sur la demande. Cette fiche devra être celle du mois précédent la demande de crédit et ne pourra faire mention d'aucune saisie ni cession.",
    Q1: "Veuillez vérifier sur base de la fiche officielle si la rémunération mensuelle nette de base atteint au moins … euros. Cette fiche devra être celle du mois précédent la demande de crédit et ne pourra faire mention d'aucune saisie ni cession.",
    R1: "Pour autant que le crédit de … préalablement sollicité et accordé le … chez … ne soit pas réalisé.",
    RA: "Déjà refusé ailleurs.",
    RE: "Renonce à la demande de crédit.",
    RP: "Retards de paiement.",
    RQ: "Certaines informations internes ou externes ne permettent pas l'octroi d'un crédit.",
    RT: "L'intéressé(e) a déjà bénéficié d'une restructuration de crédits.",
    RV: "L'intéressé(e) utilise des crédits de type revolving de manière trop importante.",
    S0: "Pour autant que le(s) crédit(s) ci-arpès soi(en)t régulier(s) à ce jour: organisme … montant … date … Si le(s) crédit(s) au(x) quel(s) il est fait allusion est (sont) déjà soldé(s) au moment ou vous recevez l'accord, la présente condition ne peut être considérée comme remplie. Il y a lieu de nous réinterroger avant réalisation de l'affaire.",
    S1: "Pour autant qu le(s) crédit(s) ci-après soi(en)t soldé(s) au préalable: organisme… montant … date… Si le(s) crédit(s) au(x) quel(s) il est fait allusion est (sont) déjà soldé(s) au moment ou vous recevez l'accord, la présente condition ne peut être considérée comme remplie. Il y a lieu de nous réinterroger avant réalisation de l'affaire.",
    S2: "Pour autant qu le(s) crédit(s) ci-après soi(en)t soldé(s) au préalable par vos soins: organisme … montant … date … Si le(s) crédit(s) au(x) quel(s) il est fait allusion est (sont) déjà soldé(s) au moment ou vous recevez l'accord, la présente condition ne peut être considérée comme remplie. Il y a lieu de nous réinterroger avant réalisation de l'affaire.",
    S3: "Pour autant qu le(s) crédit(s) en cours chez vous se paye(nt) régulièrement.Si le(s) crédit(s) au(x) quel(s) il est fait allusion est (sont) déjà soldé(s) au moment ou vous recevez l'accord, la présente condition ne peut être considérée comme remplie. Il y a lieu de nous réinterroger avant réalisation de l'affaire.",
    S4: "Pour autant qu le(s) crédit(s) en cours chez vous se paye(nt) régulièrement et soi(en)t soldé(s) au préalable.Si le(s) crédit(s) au(x) quel(s) il est fait allusion est (sont) déjà soldé(s) au moment ou vous recevez l'accord, la présente condition ne peut être considérée comme remplie. Il y a lieu de nous réinterroger avant réalisation de l'affaire.",
    S5: "Pour autant que la (les) ligne(s) de crédit ci-après soi(en)t soldée(s) et annulée(s) au préalable par vos soins: organisme(s) …",
    SE: "A défaut de la signature du conjoint.",
    SF: "Sitatuation financière faible.",
    SN: "Nous avons enregistré un ou plusieurs sinistres.",
    SQ: "Sollicite continuellement du crédit.",
    ST: "L'intéressé(e) est actuellement sans emploi ou à charge de la mutuelle.",
    SV: "Succession de crédits de type revolving sur une période courte.",
    TE: "Le taux d'endettement après restructuration reste trop élevé compte tenu des ressources de l' (des) intéressé(s).",
    X0: "Duplicata de l'avis d'acceptation.",
    X3: "Nous notons vos nouvelles précisions et/ou modifications.",
    X6: "En annulation de notre accord du …",
    X7: "En annulation de notre accord du … sous numéro de police…",
    X8: "Le montant accordé de … euros en date du … constitue un maximum.",
    X9: "Ce montant constitue un maximum et ne pourra en aucun cas être dépassé.",
    Z0: "Avec attribution à votre profit du bénéfice d'une assurance dégâts matériels (voiture) ou bris de machine (matériel) d'une durée au moins équivalente à celle du crédit. Sauf indication contraire, une franchise d'un montant égal à 2,5% du prix catalogue pourra être prévue. L'assuré devra contrôler si le paiement de la prime de la première année a bien été effectué au plus tard lors de la livraison et nous avisera de tout retard de paiement des primes ultérieures.",
    BD: "Les derniers comptes annuels font apparaître que cette entreprise n'exerce pas d'acitivité.",
    BI: "Les derniers comptes annuels font apparaître des fonds propres insuffisants, voire négatifs.",
    BK: "Selon les derniers comptes annuels, la situation de cette société serait fragile.",
    CA: "Cessation d'activité commerciale.",
    CM: "Capital souscrit et libéré insuffisant.",
    CR: "Constitution récente.",
    ED: "Exploitation déficitaire.",
    ER: "Entrepreneur radié par la Commission Provinciale d'Enregistrement.",
    ES: "L'évolution de l'entreprise doit être observée.",
    F0: "Pour autant que le fonds de commerce de l'intéressé soit libre de toute charge. Veuillez vérifier sur base d'un état de charges levé auprès du service compétent de l'administration.",
    F1: "Avec nantissement du fonds de commerce à concurrence de … euros. A défaut d'indication spéciale dans la décision, le nantissement sera pris en premier rang et à l'adresse précisée dans la demande de couverture. Si la décision admet un rang autre que le premier, il conviendra d'obtenir du (des) organisme(s) de crédit bénéficiant d'un rang antérieur l'engagement de na pas accorder de reprise d'encours.",
    F4: "Pour autant que le bail commercial ait encore une durée au moins égale a la durée du crédit sollicité.",
    FC: "Faillite, concordat, sursis de paiement, redressement judiciaire, liquidation judiciaire ou toute situation analogue aux cas précédents en droit étranger.",
    FE: "Entreprise de faible envergure.",
    G4: "Au nom du titulaire du register de commerce avec l'aval ou la caution personnelle solidaire et indivisible de son conjoint.",
    G5: "Au nom du demandeur de crédit avec l'aval ou la caution personnelle solidaire et indivisible de son conjoint.",
    G7: "Avec aval ou caution solidaire et indivisible de …",
    GD: "Cette entreprise fait partie d'un groupe en difficulté.",
    GL: "Garanties Limitées.",
    HG: "Les derniers états financiers font apparaitre un endettement élevé eu égard aux fonds propres.",
    HR: "Cette société n'est pas encore enregistrée officiellement et l'information disponible à son sujet ne nous permet pas de vous octroyer le crédit demandé.",
    IP: "Inscriptions de privilèges.",
    L3: "Envoi par vos soins d'une lettre recommandée au(x) bailleur(s) des lieux ou est installé le commerce, de ce que le matériel faisant l'objet de la demande n'est pas payé et ne peut de ce fait faire partie de son (leur) gage. Cette lettre devra parvenir au destinaire au plus tard le jour de la livraison.",
    MI: "Fonds propres insuffisants.",
    MP: "Le crédit sollicité est disproprtionné par rapport aux moyens propres de cette société.",
    MR: "Résultats peu satisfaisants.",
    NR: "Suite à de nouveaux renseignements que nous venons de recevoir.",
    NS: "Retard dans le paiement de cotisations à la Sécurité Sociale ou assignation(s) par cet organisme.",
    OM: "Nous n'estimons pas pouvoir, à l'heure actuelle, prendre de nouveaux engagements sur cette entreprise.",
    PA: "La bénéfice actuel ne permet pas de compenser les pertes antérieures très importantes.",
    PB: "Cette société semble ne pas avoir publié son bilan conformément à la législation en vigueur.",
    PL: "Les derniers comptes de cette entreprise dont nous avons pu disposer font apparaître des pertes.",
    QA: "Le rapport du réviseur/commissaire sur les comptes annuels contient des réserves.",
    RB: "Cette entreprise règle ses fournisseurs par versements échelonnés.",
    RR: "Entreprise de création récente reprenant une affaire en difficulté grave.",
    RU: "Il ne nous a pas été possible d'obtenir sur cette société une information suffisante nous permettant de vous octroyer le crédit demandé.",
    SB: "Nous n'avons pu obtenir le bilan. Ce bilan est indispensable pour une nouvelle étude.",
    SD: "Situation en déclin.",
    SI: "Situation immobilisée et/ou fonds de roulement négatif.",
    T0: "Avec transcription de facture au Greffe du Tribunal de Commerce du domicile ou du siège social de l'emprunteur ou d e l'acheteur dans les 15 jours de la livraison au plus tard.",
    T1: "Avec nantissement de l'outillage concerné.",
    T2: "Pour autant qu'il n'y ait aucune facture transcrite au Greffe du Tribunal de Commerce du domicile ou du siège social de l'emprunteur ou de l'acheteur, et ce, depuis…",
    TR: "Trésorie serrée.",
    V3: "Avec versement des fonds directement au cédant moyennant quittance subrogatoire pour solde de tous compte.",
};
/* eslint-enable quotes */
