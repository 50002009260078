import _ from 'lodash';
import { fromJS } from 'immutable';
import {
    REGISTER_FORM,
    REGISTER_FIELD,
    REMOVE_FIELD,
    SET_VALUE,
    SET_ERRORS,
    CLEAR_FORM,
} from './actions';

const initialState = fromJS({});

function formsReducer(state = initialState, action) {
    let newState = state;

    switch (action.type) {
        case REGISTER_FORM: {
            newState = newState.set(action.form.id, fromJS(action.form));
            break;
        }
        case REGISTER_FIELD: {
            const fieldId = action.field.fieldGroup ? `${action.field.fieldGroup}.${action.field.name}` : action.field.name;
            action.field.previousValue = action.field.value;
            newState = newState.updateIn(
                [action.formId, 'fields', fieldId],
                () => fromJS(action.field)
            );
            break;
        }
        case REMOVE_FIELD: {
            if (action.fieldId) {
                newState = newState.updateIn(
                    [action.formId, 'fields', action.fieldId],
                    (field) => field.delete()
                );
            } else {
                newState = newState.updateIn(
                    [action.formId, 'fields'],
                    (fields) => fields.filter((field, key) => key.indexOf(action.fieldGroupId) < 0)
                );
            }
            break;
        }
        case SET_VALUE: {
            const fieldId = action.fieldGroupId ? `${action.fieldGroupId}.${action.fieldId}` : action.fieldId;
            const form = newState.get(action.formId).toJS();
            const field = form.fields[fieldId] || {};
            newState = newState.setIn([action.formId, 'fields', fieldId, 'previousValue'], field.value);
            newState = newState.setIn([action.formId, 'fields', fieldId, 'value'], action.value);
            newState = newState.setIn([action.formId, 'states', 'pristine'], false);
            break;
        }
        case SET_ERRORS: {
            const fieldId = action.fieldGroupId ? `${action.fieldGroupId}.${action.fieldId}` : action.fieldId;
            newState = newState.setIn([action.formId, 'fields', fieldId, 'errors'], action.errors);
            newState = newState.updateIn(
                [action.formId, 'states', 'valid'],
                () => {
                    let isValid = true;
                    const form = newState.get(action.formId).toJS();

                    _.forEach(_.values(form.fields), (field) => {
                        if (field.errors && field.errors.length > 0) { isValid = false; }
                    });

                    return isValid;
                }
            );
            break;
        }
        case CLEAR_FORM: {
            newState = newState.updateIn(
                [action.formId, 'fields'],
                (fields) => fields.map((field) => field.set('previousValue', field.toJS().value).set('value', ''))
            );
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default formsReducer;
