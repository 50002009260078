import { defineMessages } from 'react-intl';

export default defineMessages({
    addressbook: {
        id: 'app.Contacts.addressbook',
        defaultMessage: 'Address book',
    },
    contact: {
        id: 'app.Contacts.contact',
        defaultMessage: 'Contact',
    },
    name: {
        id: 'app.Contacts.name',
        defaultMessage: 'Name',
    },
    email: {
        id: 'app.Contacts.email',
        defaultMessage: 'Email',
    },
    phoneNumber: {
        id: 'app.Contacts.phoneNumber',
        defaultMessage: 'Phone number',
    },
    mobileNumber: {
        id: 'app.Contacts.mobileNumber',
        defaultMessage: 'Mobile number',
    },
    newContact: {
        id: 'app.Contacts.newContact',
        defaultMessage: 'New contact',
    },
    notifications: {
        createdContact: {
            id: 'app.Contacts.notifications.createdContact',
            defaultMessage: 'New contact "{name} {last_name}" successfully created',
        },
        editedContact: {
            id: 'app.Contacts.notifications.editedContact',
            defaultMessage: 'Contact "{name} {last_name}" was successfully edited',
        },
        deletedContact: {
            id: 'app.Contacts.notifications.deletedContact',
            defaultMessage: '{name} has been deleted',
        },
    },
    contactForVendor: {
        id: 'app.Contacts.contactForVendor',
        defaultMesage: 'Contact for vendor',
    },
    contactForClient: {
        id: 'app.Contacts.contactForClient',
        defaultMesage: 'Contact for client',
    },
});