import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FieldBase } from './FieldBase';

export const FILE_TYPE_MAP = {
    xlsx: 'excel',
    xls: 'excel',
    docx: 'word',
    doc: 'word',
    pdf: 'pdf',
};
export const UNITS = [
    'b',
    'kb',
    'mb',
    'gb',
    'tb',
];

export function parseFileSize(size) {
    const n = parseFloat(size);
    const unit = size.toString().slice(n.toString().length, size.length) || 'b';
    const nextUnit = UNITS[UNITS.indexOf(unit) + 1];

    if (unit !== 'tb' && n > 999) {
        return parseFileSize(`${n / 1000}${nextUnit}`);
    }

    return `${Math.round(n * 10) / 10}${unit}`;
}

export function FileIcon(props) {
    const type = FILE_TYPE_MAP[props.extension];

    return (
        <i className={`fa fa-file-${type ? `${type}-` : ''}o`}></i>
    );
}

FileIcon.propTypes = {
    extension: PropTypes.string,
};

export function FilePreview(props) {
    const { file, emptyLabel } = props;

    if (!file && emptyLabel) {
        return (
            <div className="file-preview">
                <span className="file-empty">{emptyLabel}</span>
            </div>
        );
    }

    file.extension = file.name.split('.').pop();

    return (
        <div className="file-preview">
            <span className="file-icon"><FileIcon extension={file.extension} /></span>
            <span className="file-name"><a href={file.url}> {file.name}</a></span>
            <span className="file-size"> ({parseFileSize(file.size)})</span>
            {props.example ? <span className="file-example"><img src={file.preview} alt="preview" /></span> : null}
        </div>
    );
}

FilePreview.propTypes = {
    file: PropTypes.object,
    emptyLabel: PropTypes.node,
    example: PropTypes.bool,
};

class FileInput extends FieldBase {
    constructor(props) {
        super(props);
        const vm = this;

        if (props.defaultValue) {
            vm.state.file = [props.defaultValue];
        }

        vm.onDrop = vm.onDrop.bind(vm);
    }
    onDrop(files) {
        const vm = this;
        const fakeEvent = { persist: _.noop, currentTarget: { value: files } };

        super.onInput(fakeEvent);
        // vm.props.onDrop(files, ...args);
        vm.state.file = files[0];
        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId];
        // const field = form.fields[vm.props.name] || {};
        const containerClasses = `input-container input-container-file input-container-${vm.props.name} ${vm.props.className} ${vm.state.focus ? ' input-focused' : ''}`;
        const theme = vm.props.theme || form.theme;
        let error = vm.state.errors[0];

        if (error) {
            error = <div className="input-validation-error"><FormattedMessage id={error.id} defaultMessage={error.error} /></div>;
        }

        return (
            <div className={containerClasses}>
                {vm.props.label ? <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} /></label> : null}
                {/* {vm.props.preview ? <FilePreview file={vm.state.file} emptyLabel={vm.props.emptyLabel} example={vm.props.example} /> : null} */}
                <Dropzone
                    id={vm.props.id}
                    name={vm.props.name}
                    onDrop={vm.onDrop}
                    className="file-dropzone layout-row layout-align-center-center"
                    activeClassName="file-dropzone-active"
                    rejectClassName="file-dropzone-rejected"
                    multiple={false}
                >
                    {!vm.state.file ?
                        <div className="file-dropzone-label">
                            <FormattedMessage
                                id="forms.dropzone.instructions"
                                defaultMessage="Drop files here or click to upload"
                            />
                        </div> : null}
                    {vm.props.preview && vm.state.file ? <FilePreview file={vm.state.file} emptyLabel={vm.props.emptyLabel} example={vm.props.example} /> : null}
                </Dropzone>
                <ReactCSSTransitionGroup transitionName={theme} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {error}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null)(FileInput);
