/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    requests: {
        id: 'app.Dashboard.requests',
        defaultMessage: 'Requests in last 7 days',
    },
    pendingDossiers: {
        id: 'app.Dashboard.pendingDossiers',
        defaultMessage: 'Pending dossiers',
    },
    production: {
        id: 'app.Dashboard.production',
        defaultMessage: 'Production',
    },
    creditRequests: {
        id: 'app.Dashboard.creditRequests',
        defaultMessage: 'Credit requests',
    },
});
