export const REGISTER_FORM = 'Forms/registerForm';
export const REGISTER_FIELD = 'Forms/registerField';
export const REMOVE_FIELD = 'Forms/removeField';
export const SET_VALUE = 'Forms/setValue';
export const SET_ERRORS = 'Forms/setErrors';
export const CLEAR_FORM = 'Forms/clearForm';

export function registerForm(form) {
    return {
        type: REGISTER_FORM,
        form,
    };
}

export function registerField(formId, field) {
    return {
        type: REGISTER_FIELD,
        formId,
        field,
    };
}

export function removeField(formId, fieldId, fieldGroupId) {
    return {
        type: REMOVE_FIELD,
        formId,
        fieldId,
        fieldGroupId,
    };
}

export function setValue(formId, fieldId, fieldGroupId, value) {
    return {
        type: SET_VALUE,
        formId,
        fieldId,
        fieldGroupId,
        value,
    };
}

export function setErrors(formId, fieldId, fieldGroupId, errors) {
    return {
        type: SET_ERRORS,
        formId,
        fieldId,
        fieldGroupId,
        errors,
    };
}

export function clearForm(formId) {
    return {
        type: CLEAR_FORM,
        formId,
    };
}
