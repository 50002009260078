import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Form, FormGroup, FieldGroup, Autocomplete, Button } from 'common/Form';
import { VENDORS_URL } from 'components/Vendor/constants';
import { addContractOptions, removeContractOptions } from 'components/Vendor/actions';

import appMessages from 'components/App/messages';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';
import { PAYMENT_TYPES } from '../../../constants';

class ContractOptionsForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static defaultProps = {
        vendor: {},
    }
    static propTypes = {
        intl: PropTypes.object,
        vendor: PropTypes.object,
        vendorId: PropTypes.number,
    }

    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;
        vm.state = {
            vendor: {}
        };
        vm.vendorId = +(props.vendor.id || props.vendorId);
        vm.termsTypeOptions = [
            { value: '', disabled: true, label: intl.formatMessage(appMessages.noop) },
            { value: 'STANDARDINSURANCE', label: intl.formatMessage(messages.termtypes.standardInsurance) },
            { value: 'SPECIALEQUIPMENTWITHINSURANCE', label: intl.formatMessage(messages.termtypes.specialEquipment) },
        ];
        vm.contractTypeOptions = [
            { value: '', disabled: true, label: intl.formatMessage(appMessages.noop) },
            { value: 'LEASE', label: intl.formatMessage(messages.contractTypes.LEASE) },
            { value: 'RENT', label: intl.formatMessage(messages.contractTypes.RENT) },
            { value: 'BOTH', label: intl.formatMessage(messages.contractTypes.BOTH) },
            { value: 'ALLIN', label: intl.formatMessage(messages.contractTypes.ALLIN) },
            { value: 'RENT_PLUS_MAINTENANCE', label: intl.formatMessage(messages.contractTypes.RENT_PLUS_MAINTENANCE) },
        ];
        vm.tenTwelveTypeOptions = [
            { value: '', disabled: true, label: intl.formatMessage(appMessages.noop) },
            { value: 'YES', label: intl.formatMessage(messages.tenTwelveTypes.YES) },
            { value: 'NO', label: intl.formatMessage(messages.tenTwelveTypes.NO) },
            { value: 'BOTH', label: intl.formatMessage(messages.tenTwelveTypes.BOTH) },
        ];
        vm.paymentTypeOptions = [
            { value: '', disabled: true, label: intl.formatMessage(appMessages.noop) },
            ...Object.entries(PAYMENT_TYPES).map(([key, value]) => ({ value, label: intl.formatMessage(messages.paymentTypes[key]) })),
        ];

        vm.add = vm.add.bind(vm);
        vm.remove = vm.remove.bind(vm);
        vm.onSubmit = vm.onSubmit.bind(vm);
    }

    componentWillMount() {
        const vm = this;
        let vendor = vm.props.vendor;
        if (_.isEmpty(vm.props.vendor)) {
            vendor = vm.props.vendors && vm.vendorId
                        ? _.find(vm.props.vendors, (i) => i.id === vm.vendorId) || {}
                        : {};
        }
        vm.setState({ vendor });
        if (!vendor.contract_options || !vendor.contract_options.length) {
            vm.add();
        }
    }

    componentWillReceiveProps(nextProps) {
        const vm = this;
        if (_.isEmpty(vm.props.vendor)) {
            const vendor = _.find(nextProps.vendors, (i) => i.id === nextProps.vendorId);
            if (!_.isEqual(this.state.vendor, vendor)) {
                vm.setState({ vendor });
            }
        }
    }

    onSubmit(data) {
        const vm = this;
        const { router } = vm.context;
        const { toast } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.patch(`${VENDORS_URL}${vm.vendorId}/`, data, { headers }).then((response) => {
            vm.context.router.push(router.getCurrentLocation().query.referrer || `/vendors/${vm.vendorId}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedContractOptions} values={{ ...response.data.result }} tagName="p" />);
        });
    }

    add() {
        const vm = this;
        const { dispatch } = vm.props;
        dispatch(addContractOptions(vm.vendorId, { type: '', ten_twelve_type: '', payment_type: '' }));
    }

    remove(index) {
        const vm = this;
        const { dispatch } = vm.props;
        dispatch(removeContractOptions(vm.vendorId, index));
    }

    render() {
        const vm = this;
        const {
            termsTypeOptions,
            contractTypeOptions,
            tenTwelveTypeOptions,
            paymentTypeOptions,
        } = vm;
        const { vendor = {} } = vm.state;

        return (
            <div id="ContractOptionsForm" className="contract-options-form">
                <h1 className="center-align">{vendor.name}</h1>
                <Form id={'vendor-contract-options'} submitHandler={vm.onSubmit} theme="material">
                    <FormGroup id="contract-options">
                        <h2 className="form-group-label"><FormattedMessage {...messages.contractOptions} /></h2>
                        <Autocomplete
                            name="terms_type"
                            label={appMessages.terms_type}
                            defaultValue={vendor.terms_type}
                            options={termsTypeOptions}
                            required
                        />
                    </FormGroup>
                    <FormGroup id="contract-option-sets">
                        <h2 className="form-group-label"><FormattedMessage {...messages.contractOptionSets} /></h2>
                        <FieldGroup
                            className="input-group layout-row layout-wrap"
                            name="contract_options"
                            add={vm.add}
                            remove={vm.remove}
                            data={vendor.contract_options}
                        >
                            <Autocomplete
                                className="input-normal"
                                name="type"
                                label={appMessages.contractType}
                                options={contractTypeOptions}
                                required
                            />
                            <Autocomplete
                                className="input-normal"
                                name="ten_twelve_type"
                                label={appMessages.tenTwelveType}
                                options={tenTwelveTypeOptions}
                                required
                            />
                            <Autocomplete
                                className="input-normal"
                                name="payment_type"
                                label={appMessages.paymentType}
                                options={paymentTypeOptions}
                                required
                            />
                        </FieldGroup>
                    </FormGroup>
                    <FormGroup id="submit">
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('vendors'),
    (state) => state.get('dashboard').get('toast'),
    (user, vendors, toast) => ({ user, vendors, toast }),
);

export default connect(mapStateToProps, null)(injectIntl(ContractOptionsForm));
