import PropTypes from 'prop-types';
import React from 'react';
import throbber from 'svg-inline-loader!assets/img/throbber.svg';

class Button extends React.Component {
    render() {
        const vm = this;
        const theme = vm.props.theme || vm.props.parent.props.theme;

        return (
            <button
                type={vm.props.type}
                className={`button button-${theme} ${vm.props.className}`}
                onClick={vm.props.onClick}
                disabled={vm.props.loading || vm.props.disabled}
            >
                {vm.props.loading ? <div className="spinner" dangerouslySetInnerHTML={{ __html: throbber }} /> : vm.props.children}
            </button>
        );
    }
}

Button.defaultProps = {
    theme: 'default',
    type: 'submit',
    className: '',
    onClick: () => {},
};

Button.propTypes = {
    theme: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
