import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ListTable from 'components/App/partials/ListTable';
import Loader from 'components/App/partials/Loader';
import ActionsMenu, { getActionsFromDossier } from 'components/ActionsMenu';
import { Form, FormGroup, Autocomplete, Input, Checkbox, Button, Datepicker } from 'common/Form';
import { Menu, MenuTrigger, MenuContent } from 'common/Util';
import appMessages from 'components/App/messages';
import { USERS_URL } from 'components/Users/constants';
import auth from 'components/Users/auth';
import { DOSSIERS_URL } from '../constants';
import { initDossiers } from '../actions';
import { getStatusIcon } from '../utils';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';

const PAGE_SIZE = 10;


class Dossiers extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        dossiers: PropTypes.array,
        user: PropTypes.object,
        intl: PropTypes.object,
        count: PropTypes.number,
        filters: PropTypes.object,
    }
    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;

        let defaultFilterParams = {};
        let lastDossierPage = 0;

        if (localStorage.getItem('restoreFiltersOnDossier')) {
            const lastDossierCall = atob(localStorage.getItem('lastDossierCall'));
            lastDossierPage = parseInt(localStorage.getItem('lastDossierPage'), 0);
            try {
                defaultFilterParams = JSON.parse(lastDossierCall);
            } catch (err) {
                defaultFilterParams = {};
            }
        }

        vm.state = {
            dossiers: props.dossiers,
            count: props.count,
            page: lastDossierPage,
            columns: [
                {
                    Header: intl.formatMessage(appMessages.status),
                    accessor: 'status',
                    sortable: true,
                    Cell: (cellProps) => props.intl.formatMessage({ id: `app.Dossiers.states.${cellProps.value}` }),
                },
                {
                    Header: intl.formatMessage(appMessages.vendor),
                    accessor: 'vendor_name',
                    sortable: true,
                },
                {
                    Header: intl.formatMessage(appMessages.client),
                    accessor: 'client_name',
                    sortable: true,
                },
                {
                    Header: intl.formatMessage(appMessages.createdAt),
                    accessor: 'created_at',
                    sortable: true,
                    Cell: (cellProps) => moment(cellProps.value).format('LL'),
                },
                {
                    Header: intl.formatMessage(appMessages.completedAt),
                    accessor: 'completed_at',
                    sortable: true,
                    Cell: (cellProps) => cellProps.value ? moment(cellProps.value).format('LL') : '-',
                },
                {
                    Header: intl.formatMessage(appMessages.duration),
                    accessor: 'duration',
                    sortable: true,
                },
                {
                    Header: intl.formatMessage(appMessages.total),
                    accessor: 'amount',
                    sortable: true,
                    Cell: (cellProps) => parseInt(cellProps.value, 10),
                },
                {
                    Header: '',
                    accessor: 'review',
                    sortable: false,
                    headerClassName: 'not-sortable',
                    minWidth: 40,
                },
                {
                    Header: '',
                    accessor: 'actions',
                    sortable: false,
                    headerClassName: 'not-sortable',
                    minWidth: 30,
                    Cell: (cellProps) => <div className={'dossier-actions'}><ActionsMenu actions={cellProps.value} /></div>,
                },
            ],
            clientOptions: [],
            atlanceSalesOptions: [],
            funderOptions: [],
            vendorOptions: [],
            vendorOwnerOptions: [],
            statusOptions: [],
            params: defaultFilterParams,
            showFilters: Object.keys(defaultFilterParams).length > 0,
            debounceFilter: _.debounce(vm.filterDossiers.bind(vm), 500),
            loading: !props.dossiers.length,
        };

        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.toggleFilters = vm.toggleFilters.bind(vm);
        vm.filterDossiers = vm.filterDossiers.bind(vm);
        vm.onPageChange = vm.onPageChange.bind(vm);
        vm.onClick = vm.onClick.bind(vm);
        vm.getFilteredDossiers = vm.getFilteredDossiers.bind(vm);
        vm.onExportButtonClick = vm.onExportButtonClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const { headers } = vm;

        axios.get(USERS_URL, {
            headers,
            params: { role: 'SUPERSALES' },
            cancelToken: new axios.CancelToken((cancelFunction) => {
            // An executor function receives a cancel function as a parameter
                vm.cancelRequest = cancelFunction;
            }),
        }).then((response) => {
            vm.state.atlanceSalesOptions = _.map(response.data.result, (user) => ({ label: `${user.name} ${user.last_name}`, value: user.id }));
        });
    }

    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { intl } = vm.props;

        if (_.isEmpty(this.props.filters) && !_.isEmpty(nextProps.filters)) {
            vm.state.vendorOptions = nextProps.filters.vendor_options.map((val) => ({ value: val, label: val }));
            vm.state.statusOptions = nextProps.filters.status_options.map((val) => ({ value: val, label: intl.formatMessage({ id: `app.Dossiers.states.${val}` }) }));
            vm.state.vendorOwnerOptions = nextProps.filters.vendor_owner_options.map((val) => ({ value: val.vendor_owner_id, label: val.vendor_owner__name }));
            vm.state.funderOptions = nextProps.filters.funder_options.map((val) => ({ value: val, label: val }));
            vm.state.clientOptions = nextProps.filters.client_options.map((val) => ({ value: val.client__firmname, label: `${val.client__firmname}${val.client__vat ? ` - ${val.client__vat}` : ''}` }));
        }

        vm.state.dossiers = vm.processDossiers(nextProps.dossiers, nextProps.requirements);
        vm.state.count = nextProps.count;

        if (!_.isEmpty(nextProps.dossiers) || !_.isEqual(nextProps.dossiers, vm.props.dossiers)) {
            vm.state.loading = false;
        }
        vm.setState(vm.state);
    }

    componentWillUnmount() {
        if (this.cancelRequest) {
            this.cancelRequest();
        }
    }


    onClick(event, dossierId) {
        const vm = this;
        const { parentNode } = event.target;

        if (parentNode.tagName !== 'A' &&
            !parentNode.classList.contains('xs2-menu-trigger') &&
            !parentNode.classList.contains('dossiers-review')) {
            vm.context.router.push(`/dossiers/${dossierId}`);
        }
    }
    onExportButtonClick() {
        const vm = this;
        const { headers } = vm;
        axios.get(`${DOSSIERS_URL}download`, {
            headers,
            params: vm.state.params,
            responseType: 'blob',
        }).then((response) => {
            const date = new Date();
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `dossiers_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
    getFilteredDossiers(extraParams = {}) {
        const vm = this;
        const { headers } = vm;
        const { dispatch } = vm.props;

        vm.setState({ loading: true }, () => {
            const params = {
                ...vm.state.params,
                ...extraParams,
            };
            const excludeStatus = _.join(_.map(params.exclude_status, (status) => `&exclude_status=${status}`), '');
            if (!params.hasOwnProperty('ordering')) {
                params.ordering = '-created_at';
            }
            if (!params.hasOwnProperty('offset')) {
                params.offset = 0;
            }

            const stringifyDateFromParams = (key) => {
                const theObj = params[key];
                if (theObj) {
                    if (theObj.hasOwnProperty('_isAMomentObject') && theObj._isAMomentObject) {
                        return theObj.format('YYYY-MM-DD');
                    }
                    return theObj;
                }
                return '';
            };

            const cleanParams = {
                ...params,
                completed_after: stringifyDateFromParams('completed_after'),
                completed_before: stringifyDateFromParams('completed_before'),
                contract_startdate_after: stringifyDateFromParams('contract_startdate_after'),
                contract_startdate_before: stringifyDateFromParams('contract_startdate_before'),
                contract_enddate_after: stringifyDateFromParams('contract_enddate_after'),
                contract_enddate_before: stringifyDateFromParams('contract_enddate_before'),
            };

            const stringifiedParams = JSON.stringify(cleanParams);
            // Store last dossier call
            localStorage.setItem('lastDossierCall', btoa(stringifiedParams));
            delete cleanParams.exclude_status;

            axios.get(`${DOSSIERS_URL}?${excludeStatus}`, {
                headers,
                params: cleanParams,
                cancelToken: new axios.CancelToken((cancelFunction) => {
                // An executor function receives a cancel function as a parameter
                    vm.cancelRequest = cancelFunction;
                }),
            }).then((response) => {
                dispatch(initDossiers(response.data.result, null, null, response.data.count));
            });
        });
    }
    processDossiers(dossiers, requirements) {
        return _.map(dossiers, (dossier) => {
            const mappedDossier = { ...dossier };
            mappedDossier.review = getReviewsFromDossier(dossier, requirements, this.props.intl);
            mappedDossier.actions = getActionsFromDossier(dossier);
            return mappedDossier;
        });
    }
    filterDossiers() {
        const vm = this;
        const { forms: { 'dossier-filters': { fields } } } = vm.props;
        const params = {};

        _.forEach(_.keys(fields), (key) => {
            params[key] = fields[key].value;
        });
        this.setState({ params });
        vm.getFilteredDossiers();
    }
    applyFilter(key, value) {
        const vm = this;
        vm.state.params[key] = value;
        vm.setState(vm.state, () => {
            vm.getFilteredDossiers();
        });
    }
    toggleFilters() {
        const vm = this;
        vm.state.showFilters = !vm.state.showFilters;
        vm.setState(vm.state);
    }
    onPageChange(pageIndex) {
        this.setState({ page: pageIndex });
        localStorage.setItem('lastDossierPage', pageIndex);
    }
    onResetButtonClick = () => {
        // Forget all filters
        const vm = this;
        delete localStorage.lastDossierCall;
        delete localStorage.restoreFiltersOnDossier;
        delete localStorage.lastDossierPage;
        vm.setState({ page: 0 });
    }
    render() {
        const vm = this;
        const { user } = vm.props;
        const {
            dossiers,
            count,
            columns,
            params,
            clientOptions,
            atlanceSalesOptions,
            statusOptions,
            funderOptions,
            vendorOptions,
            vendorOwnerOptions,
            debounceFilter,
            page,
        } = vm.state;

        const userIsAtlance = auth.userIsAtlance(user);
        const userIsSales = auth.userIsSales(user);
        const userIsAtlanceSales = auth.userIsAtlanceSales(user);

        const filters = (
            <Form id="dossier-filters" resetHandler={debounceFilter}>
                <FormGroup className="input-group">
                    {userIsAtlance && !userIsAtlanceSales ?
                        <Autocomplete
                            name="atlance_owner_id"
                            defaultValue={params.atlance_owner_id || null}
                            className="input-small"
                            label={appMessages.atlanceSales}
                            options={atlanceSalesOptions}
                            onInput={debounceFilter}
                        />
                    : null}
                    <Autocomplete
                        name="status"
                        defaultValue={params.status || null}
                        className={userIsAtlance ? 'input-small' : 'input-small'}
                        label={appMessages.status}
                        options={statusOptions}
                        onInput={debounceFilter}
                    />
                    <Checkbox
                        name="has_documents"
                        className={userIsAtlance ? 'input-small' : 'input-large'}
                        defaultValue={!!params.has_documents}
                        label={appMessages.hasDocuments}
                        id="has_documents"
                        onInput={debounceFilter}
                    />
                    <Checkbox
                        name="has_new_documents"
                        className={userIsAtlance ? 'input-small' : 'input-large'}
                        defaultValue={!!params.has_new_documents}
                        label={appMessages.hasNewDocuments}
                        id="has_new_documents"
                        onInput={debounceFilter}
                        style={{ marginLeft: '20px' }}
                    />
                </FormGroup>
                <FormGroup className="input-group">
                    <Input
                        type="text"
                        name="amount_gte"
                        defaultValue={params.amount_gte || null}
                        className="input-small"
                        label={appMessages.amountGreaterThan}
                        onInput={debounceFilter}
                    />
                    <Input
                        type="text"
                        name="amount_lte"
                        defaultValue={params.amount_lte || null}
                        className="input-small"
                        label={appMessages.amountLessThan}
                        onInput={debounceFilter}
                    />
                    <Datepicker
                        label={appMessages.completedAfter}
                        className="input-small"
                        name="completed_after"
                        onInput={debounceFilter}
                        defaultValue={params.completed_after ? moment(params.completed_after) : ''}
                        maxDate={new Date()}
                    />
                    <Datepicker
                        label={appMessages.completedBefore}
                        className="input-small"
                        name="completed_before"
                        onInput={debounceFilter}
                        defaultValue={params.completed_before ? moment(params.completed_before) : ''}
                        maxDate={new Date()}
                    />
                </FormGroup>
                <FormGroup className="input-group">
                    <Datepicker
                        label={appMessages.contractStartdateAfter}
                        className="input-small"
                        name="contract_startdate_after"
                        onInput={debounceFilter}
                        defaultValue={params.contract_startdate_after ? moment(params.contract_startdate_after) : ''}
                    />
                    <Datepicker
                        label={appMessages.contractStartdateBefore}
                        className="input-small"
                        name="contract_startdate_before"
                        onInput={debounceFilter}
                        defaultValue={params.contract_startdate_before ? moment(params.contract_startdate_before) : ''}
                    />
                    <Datepicker
                        label={appMessages.contractEnddateAfter}
                        className="input-small"
                        name="contract_enddate_after"
                        onInput={debounceFilter}
                        defaultValue={params.contract_enddate_after ? moment(params.contract_enddate_after) : ''}
                    />
                    <Datepicker
                        label={appMessages.contractEnddateBefore}
                        className="input-small"
                        name="contract_enddate_before"
                        onInput={debounceFilter}
                        defaultValue={params.contract_enddate_before ? moment(params.contract_enddate_before) : ''}
                    />
                </FormGroup>
                {userIsAtlance || (!userIsAtlance && !userIsSales) ?
                    <FormGroup className="input-group">
                        {userIsAtlance ?
                            <Autocomplete
                                name="vendor_name"
                                className="input-normal"
                                defaultValue={params.vendor_name || null}
                                label={appMessages.vendor}
                                options={vendorOptions}
                                onInput={debounceFilter}
                            />
                        : null}
                        {!userIsSales ?
                            <Autocomplete
                                name="vendor_owner_id"
                                defaultValue={params.vendor_owner_id || null}
                                className={userIsAtlance ? 'input-normal' : 'input-xlarge'}
                                label={appMessages.vendorOwnerName}
                                options={vendorOwnerOptions}
                                onInput={debounceFilter}
                            />
                        : null}
                        {userIsAtlanceSales ?
                            <Autocomplete
                                name="vendor_owner_id"
                                defaultValue={params.vendor_owner_id || null}
                                className={userIsAtlance ? 'input-normal' : 'input-xlarge'}
                                label={appMessages.vendorOwnerName}
                                options={vendorOwnerOptions}
                                onInput={debounceFilter}
                            />
                        : null}
                        {userIsAtlance ?
                            <Autocomplete
                                name="funder_name"
                                defaultValue={params.funder_name || null}
                                className="input-normal"
                                label={appMessages.funder}
                                options={funderOptions}
                                onInput={debounceFilter}
                            />
                        : null}
                    </FormGroup>
                : null}
                <FormGroup className="input-group">
                    <Autocomplete
                        name="client_name"
                        defaultValue={params.client_name || null}
                        className="input-normal"
                        label={appMessages.client}
                        options={clientOptions}
                        onInput={debounceFilter}
                    />
                    <Input
                        type="text"
                        name="client_vat"
                        defaultValue={params.client_vat || null}
                        className="input-normal"
                        label={appMessages.clientVAT}
                        onInput={debounceFilter}
                    />
                    <Input
                        type="text"
                        name="client_address"
                        defaultValue={params.client_address || null}
                        className="input-normal"
                        label={appMessages.clientAddress}
                        onInput={debounceFilter}
                    />
                </FormGroup>
                <FormGroup className="input-group filter-actions">
                    <Button type="export" onClick={this.onExportButtonClick}><FormattedMessage {...appMessages.export} /></Button>
                    <Button type="reset" onClick={this.onResetButtonClick}><FormattedMessage {...appMessages.reset} /></Button>
                </FormGroup>
            </Form>
        );
        return (
            <div className="dossiers-list-container">
                <div className="dossiers-filters-container">
                    <button className="dossiers-filters-button" onClick={vm.toggleFilters}><i className="icon-equalizer"></i></button>
                    <ReactCSSTransitionGroup
                        transitionName="default"
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={0}
                    >
                        {vm.state.showFilters ? filters : null}
                    </ReactCSSTransitionGroup>
                </div>
                <Loader active={vm.state.loading}>
                    {!dossiers.length ? <p>No results found.</p> :
                    <ListTable
                        name="dossiers"
                        onPageChange={this.onPageChange}
                        page={page}
                        columns={columns}
                        count={count}
                        getURL={DOSSIERS_URL}
                        urlParams={params}
                        processor={(data) => vm.processDossiers(data, vm.props.requirements)}
                        data={dossiers.map((dossier) => ({
                            ...dossier,
                            client_name: `${dossier.client_name}${dossier.client_vat ? ` - ${dossier.client_vat}` : ''}`,
                        }))}
                        loadData={vm.getFilteredDossiers}
                        onRowClick={vm.onClick}
                    />}
                </Loader>
            </div>
        );
    }
}

export function getReviewsFromDossier(dossier, fields, intl) {
    const requirements = {};

    _.forEach(fields, (field) => {
        requirements[field] = (!dossier[field] && dossier[field] !== 0) ? 'MISSING' : 'PRESENT';
    });

    _.forEach(dossier.document_summary, (value, key) => {
        const isMissing = value !== 'ACCEPTED' || ~dossier.extra_docs_required.indexOf(key);
        requirements[`document.${key}`] = isMissing ? 'MISSING' : 'PRESENT';
    });

    return (
        <div className="dossiers-review">
            <Menu
                hoverTrigger
                scrollMask={false}
            >
                <MenuTrigger className="actions-menu-trigger">
                    <i className={`fa fa-${getMenuIcon()}`} />
                </MenuTrigger>
                <MenuContent>
                    <ul>
                        {_.map(requirements, (requirement, key) => (
                            <li key={key}>
                                <i className={`fa fa-${getStatusIcon(requirement)}`} />
                                {~key.indexOf('document.')
                                    ? (<span><i className="icon-doc" />{intl.formatMessage(appMessages.docTypes[key.split('.')[1]])}</span>)
                                    : <span>{intl.formatMessage(messages.fields[key])}</span>}
                            </li>
                        ))}
                    </ul>
                </MenuContent>
            </Menu>
        </div>
    );

    function getMenuIcon() {
        let hasMissing = false;

        _.forEach(requirements, (requirement) => {
            if (requirement === 'MISSING') {
                hasMissing = true;
            }
        });

        return hasMissing ? 'times-circle' : 'check-circle';
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('forms').toJS(),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (state) => state.get('dossiers').get('requirements').toJS(),
    (state) => state.get('dossiers').get('count'),
    (state) => state.get('dossiers').get('filters').toJS(),

    (user, forms, dossiers, requirements, count, filters) => (
        { user, forms, dossiers, requirements, count, filters }
    )
);

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(Dossiers, { withRef: true }));
