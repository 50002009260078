import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import { Form, FormGroup, Button, Input, FileField, Autocomplete } from 'common/Form';
import { setErrors } from 'common/Form/actions';
import { Tooltip } from 'common/Util';
import placeholderImg from 'assets/img/placeholder.png';

import { API_URL } from 'components/App/constants';
import appMessages from 'components/App/messages';
import messages from '../messages';
import { editProfile } from '../actions';
import { DEFAULT_LOCALE } from 'components/App/constants';
import '../styles/profile.scss';


class ProfileContainer extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        intl: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.state = {
            statusOptions: [
                { value: 'ABSENT', label: intl.formatMessage(messages.states.ABSENT) },
                { value: 'ONVACATION', label: intl.formatMessage(messages.states.ONVACATION) },
                { value: 'PRESENT', label: intl.formatMessage(messages.states.PRESENT) },
            ],
        };

        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch, form } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const formData = new FormData();

        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('function', data.function);
        formData.append('email', data.email);
        formData.append('notification_email', data.notification_email);
        formData.append('mobile_number', data.mobile_number);
        formData.append('phone_number', data.phone_number);
        formData.append('status', data.status);

        if (data.picture) {
            formData.append('picture', data.picture[0]);
        }

        axios.patch(`${API_URL}user/`, formData, { headers }).then((response) => {
            if (!Object.keys(response.data).includes("result")) {
                throw new Error(JSON.stringify(response.data));
            }
            vm.state.upload = false;
            vm.setState(vm.state);
            dispatch(editProfile(response.data.result));
            return toast.success('Success!', <FormattedMessage {...messages.notifications.editedProfile} tagName="p" />);
        }).catch(({ message = "{}" }) => {
            const errors = JSON.parse(message);
            Object.keys(errors).forEach(key => {
                const field = form.fields[key];
                const fieldErrors = errors[key];
                dispatch(setErrors(form.id, field.name, null, fieldErrors));
            });
            return toast.error('Failed', <FormattedMessage {...messages.notifications.editProfileFailed} tagName="p" />);
        });
    }
    render() {
        const vm = this;
        const { user } = vm.props;
        const { router } = vm.context;
        const { statusOptions } = vm.state;

        return (
            <div id="ProfileContainer">
                <div className="profile-header layout-row">
                    <h1 className="flex"><FormattedMessage {...messages.profilePage} /></h1>
                    <div className="profile-controls flex-nogrow layout-row layout-align-center-center">
                        <Link to={{ pathname: '/logout', query: { referrer: router.getCurrentLocation().pathname } }} className="contacts-new">
                            <i className="icon-logout"></i>&nbsp;<FormattedMessage {...appMessages.logout} />
                        </Link>
                    </div>
                </div>
                <Form id="profile-form" submitHandler={vm.onSubmit}>
                    <FormGroup>
                        {vm.state.upload
                            ? <FileField
                                name="picture"
                                emptyLabel={<div />}
                                preview
                                example
                                required
                            />
                            : <button onClick={() => { vm.setState({ upload: true }); }}>
                                <img src={user.picture.thumb || placeholderImg} alt={user.name} />
                                <Tooltip label="Click to upload" />
                            </button>}
                    </FormGroup>
                    <FormGroup className="input-group">
                        <Input
                            type="text"
                            className="input-medium"
                            name="name"
                            label={messages.firstName}
                            defaultValue={user.name}
                        />
                        <Input
                            type="text"
                            className="input-medium"
                            name="last_name"
                            label={messages.lastName}
                            defaultValue={user.last_name}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input type="text" className="flex" name="function" label={messages.function} defaultValue={user.function} />
                        <Input type="text" className="flex" name="email" label={messages.email} defaultValue={user.email} />
                        <Input
                            type="text"
                            className="flex"
                            name="notification_email"
                            label={messages.notificationEmail}
                            defaultValue={user.notification_email}
                        />
                        <Input type="text" className="flex" name="mobile_number" label={messages.mobileNumber} defaultValue={user.mobile_number} />
                        <Input type="text" className="flex" name="phone_number" label={messages.phoneNumber} defaultValue={user.phone_number} />
                        <Autocomplete name="status" label={messages.status} options={statusOptions} defaultValue={user.status} />
                    </FormGroup>
                    <FormGroup id="save">
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('forms'),
    (user, toast, forms) => ({ user, toast, form: forms.toJS()["profile-form"] })
);

export default connect(mapStateToProps, null)(injectIntl(ProfileContainer));
