import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { ScrollMask } from '../../Util';
import { FieldBase } from './FieldBase';

class Select extends FieldBase {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state.disabled = !props.options || !props.options.length;
        vm.onClick = vm.onClick.bind(vm);
        vm.onScrollMaskClick = vm.onScrollMaskClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.state.disabled = !vm.props.options.length;
        vm.setState(vm.state);
    }
    componentDidUpdate() {
        const vm = this;

        if (vm.state.active) {
            // window.addEventListener('xs2.scroll-mask.click', vm.onScrollMaskClick);
            window.addEventListener('click', vm.onScrollMaskClick);
        } else {
            // window.removeEventListener('xs2.scroll-mask.click', vm.onScrollMaskClick);
            window.removeEventListener('click', vm.onScrollMaskClick);
        }
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        super.componentWillReceiveProps(nextProps);

        if ((nextProps.options.length !== vm.props.options.length)) {
            vm.state.disabled = !nextProps.options.length;
            vm.setState(vm.state);
        }
    }
    onScrollMaskClick(event) {
        const vm = this;
        const targetInContext = this._element.contains(event.target);
        const isOptionButton = event.target.classList && event.target.classList.contains('select-option');

        if (!targetInContext || !isOptionButton) {
            const fieldId = vm.props.fieldGroup ? `${vm.props.fieldGroup}.${vm.props.name}` : vm.props.name;
            const field = vm.props.forms[vm.props.formId].fields[fieldId] || {};
            event.currentTarget.value = vm.props.defaultValue || vm.props.value || field.value;
            vm.onBlur(event);
        }
    }

    onClick(event) {
        const vm = this;
        event.preventDefault();

        if (!event.currentTarget.parentNode.classList.contains('select-value')) {
            vm.onInput(event);
            vm.onBlur(event);
        }
    }

    getMenuStyles() {
        const vm = this;
        const menuStyles = {};

        if (vm._element) {
            const selectStyles = window.getComputedStyle(vm._element);
            const selectPos = vm._element.getBoundingClientRect();

            menuStyles.minWidth = selectStyles.width;
            menuStyles.top = `${selectPos.top}px`;
            menuStyles.left = `${selectPos.left}px`;
        }

        return menuStyles;
    }

    render() {
        const vm = this;
        const { intl } = vm.props;
        const fieldId = vm.props.fieldGroup ? `${vm.props.fieldGroup}.${vm.props.name}` : vm.props.name;
        const field = vm.props.forms[vm.props.formId].fields[fieldId] || {};
        const value = field.value || vm.props.value || vm.props.defaultValue;
        const theme = vm.props.theme || vm.props.forms[vm.props.formId].theme;
        const containerClasses = [
            'input-container',
            'input-container-select',
            `input-container-${vm.props.name}`,
            vm.state.focus ? 'input-focused' : '',
            vm.state.disabled ? 'input-disabled' : '',
            vm.props.className,
        ].join(' ');

        const selectOptions = (
            // <Menu style={vm.getMenuStyles()}>
            <div className="select-options">
                <ScrollMask className="xs2-menu-scroll-mask xs2-select-scroll-mask" />
                {vm.props.options.map((option) => {
                    if (!option.value) { return null; }
                    const label = intl.formatMessage(option.label);
                    return (
                        <button key={option.value} className="select-option" value={option.value} onClick={vm.onClick}>
                            <span>{label}</span>
                        </button>
                    );
                })}
            </div>
            // </Menu>
        );
        let valueLabel;
        try {
            valueLabel = vm.props.options.find((item) => item.value == value).label; // eslint-disable-line eqeqeq

            if (typeof valueLabel !== 'string') {
                valueLabel = intl.formatMessage(valueLabel);
            }
        } catch (e) {
            valueLabel = '';
        }
        let error = vm.state.errors[0];

        if (error) {
            error = <div className="input-validation-error"><FormattedMessage id={error.id} defaultMessage={error.error} /></div>;
        }

        // Safari doesn't call the onFocus so I changed it to onClick. Does not chrash the /vendors/new page, but I don't know about the rest
        return (
            <div className={containerClasses} ref={(ref) => { this._element = ref; }}>
                <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} /></label>
                <div className={`select-container ${vm.props.className}`} onFocus={vm.onFocus} value={value}>
                    <div className="select-value" >
                        <button className="select-option" onClick={vm.onFocus} value={value}>
                            <span>{valueLabel}</span>
                        </button>
                    </div>
                    <ReactCSSTransitionGroup
                        transitionName="toggle-select"
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={0}
                        className="select-options-container"
                        component="div"
                    >
                        {vm.state.active ? selectOptions : null}
                    </ReactCSSTransitionGroup>
                </div>
                <ReactCSSTransitionGroup transitionName={theme} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {error}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(Select, { withRef: true }));
