import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, FormGroup, Textarea, Autocomplete, Datepicker, Button } from 'common/Form';
import appMessages from 'components/App/messages';
import { DOSSIERS_URL, FUNDERS_URL, ATRADIUS_RESPONSE_CODES } from '../constants';
import { editDossier } from '../actions';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class ConfirmCreditRequestForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = { funders: [] };
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const { router } = vm.context;
        const { user } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.all([
            axios.get(`${DOSSIERS_URL}${router.params.id}/creditrequests/`, { headers }),
            axios.get(`${FUNDERS_URL}`, { headers }),
        ]).then((response) => {
            vm.state.creditRequests = response[0].data.result;
            vm.state.funders = response[1].data.result;
            vm.setState(vm.state);
        });
    }
    onSubmit(data) {
        const vm = this;
        const { router } = vm.context;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const referrer = router.getCurrentLocation().query.referrer;
        const creditRequest = vm.state.creditRequests.find((i) => i.funder === +data.funder);

        data.creditrequest_id = creditRequest.id;
        data.expiry_date = moment(data.expiry_date).format('YYYY-MM-DD');

        axios.post(
            `${DOSSIERS_URL}${router.params.id}/actions/`,
            { action_code: 'CONFIRMCREDITREQUESTACTION', data },
            { headers }
        ).then((response) => {
            dispatch(editDossier(response.data.result));
            router.push(referrer || `/dossiers/${router.params.id}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.confirmedCreditRequest} tagName="p" />);
        });
    }
    render() {
        const vm = this;
        const { intl } = vm.props;
        const funderOptions = _.map(vm.state.funders, (funder) => ({
            value: String(funder.id),
            label: funder.name,
        }));
        const resultOptions = [
            { value: 'ACCEPTED', label: intl.formatMessage(messages.states.ACCEPTED) },
            { value: 'ACCEPTEDWITHCONDITIONS', label: intl.formatMessage(messages.states.ACCEPTEDWITHCONDITIONS) },
            { value: 'REJECTED', label: intl.formatMessage(messages.states.REJECTED) },
            { value: 'WAITING_FOR_INFO', label: intl.formatMessage(messages.states.WAITINGFORINFO) },
        ];
        const atradiusResponseCodeOptions = _.map(ATRADIUS_RESPONSE_CODES, (message, code) => ({
            value: code,
            label: `${code} - ${message}`,
        }));

        return (
            <div id="ConfirmCreditRequestForm" className="confirm-credit-request">
                <h2 className="center-align"><FormattedMessage {...appMessages.actions.CONFIRMCREDITREQUEST} /></h2>
                <Form id="confirm-redit-request" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Textarea
                            name="response"
                            label={appMessages.response}
                        />
                        <Autocomplete
                            name="funder"
                            label={appMessages.funder}
                            options={funderOptions}
                            defaultValue={''}
                        />
                        <Autocomplete
                            name="status_code"
                            label={appMessages.result}
                            options={resultOptions}
                            defaultValue={''}
                        />
                        <Autocomplete
                            name="atradius_reason"
                            label={appMessages.atradiusResponseCode}
                            options={atradiusResponseCodeOptions}
                        />
                        <Datepicker
                            name="expiry_date"
                            className="input-large"
                            label={appMessages.expiryDate}
                            defaultValue={moment().add(3, 'months').toISOString()}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('user'),
    (toast, user) => ({ toast, user })
);

export default connect(mapStateToProps, null)(injectIntl(ConfirmCreditRequestForm));
