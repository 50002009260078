import { defineMessages } from 'react-intl';

export default defineMessages({
    states: {
        NEW: {
            id: 'app.Dossiers.states.NEW',
            defaultMessage: 'New',
        },
        WAITINGFORINFO: {
            id: 'app.Dossiers.states.WAITINGFORINFO',
            defaultMessage: 'Waiting for more info',
        },
        WAITING_FOR_INFO: {
            id: 'app.Dossiers.states.WAITING_FOR_INFO',
            defaultMessage: 'Waiting for more info',
        },
        WAITINGCONFIRMATION: {
            id: 'app.Dossiers.states.WAITINGCONFIRMATION',
            defaultMessage: 'Waiting for info confirmation',
        },
        WAITINGFORBANKS: {
            id: 'app.Dossiers.states.WAITINGFORBANKS',
            defaultMessage: 'On going',
        },
        ACCEPTED: {
            id: 'app.Dossiers.states.ACCEPTED',
            defaultMessage: 'Accepted',
        },
        ACCEPTEDWITHCONDITIONS: {
            id: 'app.Dossiers.states.ACCEPTEDWITHCONDITIONS',
            defaultMessage: 'Accepted with conditions',
        },
        REJECTED: {
            id: 'app.Dossiers.states.REJECTED',
            defaultMessage: 'Rejected',
        },
        WAITINGSIGNATURE: {
            id: 'app.Dossiers.states.WAITINGSIGNATURE',
            defaultMessage: 'Waiting for signature',
        },
        PENDING: {
            id: 'app.Dossiers.states.PENDING',
            defaultMessage: 'Pending',
        },
        CANCELED: {
            id: 'app.Dossiers.states.CANCELED',
            defaultMessage: 'Canceled',
        },
        ARCHIVED: {
            id: 'app.Dossiers.states.ARCHIVED',
            defaultMessage: 'Archived',
        },
        ERROR: {
            id: 'app.Dossiers.states.ERROR',
            defaultMessage: 'Error',
        },
        PENDINGREMOTE: {
            id: 'app.Dossiers.states.PENDINGREMOTE',
            defaultMessage: 'Pending remote',
        },
        QUOTATION: {
            id: 'app.Dossiers.states.QUOTATION',
            defaultMessage: 'Quotation',
        },
        OUTDATED: {
            id: 'app.Dossiers.states.OUTDATED',
            defaultMessage: 'Outdated',
        },
        EXPIRED: {
            id: 'app.Dossiers.states.EXPIRED',
            defaultMessage: 'Expired',
        },
        SIGNED: {
            id: 'app.Dossiers.states.SIGNED',
            defaultMessage: 'Signed',
        },
        COMPLETED: {
            id: 'app.Dossiers.states.COMPLETED',
            defaultMessage: 'Completed',
        },
        REQUESTEDINFO: {
            id: 'app.Dossier.states.REQUESTEDINFO',
            defaultMessage: 'Requested information',
        },
    },
    fields: {
        atlance_owner: {
            id: 'app.Dossiers.fields.atlance_owner',
            defaultMessage: 'Atlance owner ID',
        },
        atlance_owner_name: {
            id: 'app.Dossiers.fields.atlance_owner_name',
            defaultMessage: 'Atlance owner',
        },
        atlance_sign_date: {
            id: 'app.Dossiers.fields.atlance_sign_date',
            defaultMessage: 'Atlance sign date',
        },
        atlance_sign_place: {
            id: 'app.Dossiers.fields.atlance_sign_place',
            defaultMessage: 'Atlance sign place',
        },
        client: {
            id: 'app.Dossiers.fields.client',
            defaultMessage: 'Client ID',
        },
        client_name: {
            id: 'app.Dossiers.fields.client_name',
            defaultMessage: 'Client',
        },
        client_sign_date: {
            id: 'app.Dossiers.fields.client_sign_date',
            defaultMessage: 'Client sign date',
        },
        client_sign_expiry_date: {
            id: 'app.Dossiers.fields.client_sign_expiry_date',
            defaultMessage: 'Client sign expiry date',
        },
        client_sign_place: {
            id: 'app.Dossiers.fields.client_sign_place',
            defaultMessage: 'Client sign place',
        },
        client_city: {
            id: 'app.Dossiers.fields.client_city',
            defaultMessage: 'Client city',
        },
        client_country: {
            id: 'app.Dossiers.fields.client_country',
            defaultMessage: 'Client country',
        },
        client_number: {
            id: 'app.Dossiers.fields.client_number',
            defaultMessage: 'Client number',
        },
        client_postalcode: {
            id: 'app.Dossiers.fields.client_postalcode',
            defaultMessage: 'Client postalcode',
        },
        client_street: {
            id: 'app.Dossiers.fields.client_street',
            defaultMessage: 'Client street',
        },
        client_bus: {
            id: 'app.Dossiers.fields.client_bus',
            defaultMessage: 'Client bus',
        },
        client_vat: {
            id: 'app.Dossiers.fields.client_vat',
            defaultMessage: 'Client vat',
        },
        co_client: {
            id: 'app.Dossiers.fields.co_client',
            defaultMessage: 'Co client',
        },
        co_client_name: {
            id: 'app.Dossiers.fields.co_client_name',
            defaultMessage: 'Co client name',
        },
        conditions: {
            id: 'app.Dossiers.fields.conditions',
            defaultMessage: 'Conditions',
        },
        constructor_city: {
            id: 'app.Dossiers.fields.constructor_city',
            defaultMessage: 'Constructor city',
        },
        constructor_name: {
            id: 'app.Dossiers.fields.constructor_name',
            defaultMessage: 'Constructor name',
        },
        constructor_number: {
            id: 'app.Dossiers.fields.constructor_number',
            defaultMessage: 'Constructor number',
        },
        constructor_postalcode: {
            id: 'app.Dossiers.fields.constructor_postalcode',
            defaultMessage: 'Constructor postalcode',
        },
        constructor_street: {
            id: 'app.Dossiers.fields.constructor_street',
            defaultMessage: 'Constructor street',
        },
        created_at: {
            id: 'app.Dossiers.fields.created_at',
            defaultMessage: 'Created at',
        },
        archived_at: {
            id: 'app.Dossiers.fields.archived_at',
            defaultMessage: 'Archived on',
        },
        contract_startdate: {
            id: 'app.Dossiers.fields.contract_startdate',
            defaultMessage: 'Contract start date',
        },
        contract_enddate: {
            id: 'app.Dossiers.fields.contract_enddate',
            defaultMessage: 'Contract end date',
        },
        creditor_city: {
            id: 'app.Dossiers.fields.creditor_city',
            defaultMessage: 'Creditor city',
        },
        creditor_name: {
            id: 'app.Dossiers.fields.creditor_name',
            defaultMessage: 'Creditor name',
        },
        creditor_number: {
            id: 'app.Dossiers.fields.creditor_number',
            defaultMessage: 'Creditor number',
        },
        creditor_postalcode: {
            id: 'app.Dossiers.fields.creditor_postalcode',
            defaultMessage: 'Creditor postalcode',
        },
        creditor_street: {
            id: 'app.Dossiers.fields.creditor_street',
            defaultMessage: 'Creditor street',
        },
        duration: {
            id: 'app.Dossiers.fields.duration',
            defaultMessage: 'Duration',
        },
        duration_type: {
            id: 'app.Dossiers.fields.duration_type',
            defaultMessage: 'Periodicity',
        },
        econocom_contractnumber: {
            id: 'app.Dossiers.fields.econocom_contractnumber',
            defaultMessage: 'SNI Contract number',
        },
        econocom_customeraccount: {
            id: 'app.Dossiers.fields.econocom_customeraccount',
            defaultMessage: 'SNI Customer account',
        },
        equipment_description: {
            id: 'app.Dossiers.fields.equipment_description',
            defaultMessage: 'Equipment description',
        },
        expires_at: {
            id: 'app.Dossiers.fields.expires_at',
            defaultMessage: 'Expires at',
        },
        first_amount: {
            id: 'app.Dossiers.fields.first_amount',
            defaultMessage: 'First amount',
        },
        funder: {
            id: 'app.Dossiers.fields.funder',
            defaultMessage: 'Funder',
        },
        funder_name: {
            id: 'app.Dossiers.fields.funder_name',
            defaultMessage: 'Funder name',
        },
        guarantee_period: {
            id: 'app.Dossiers.fields.guarantee_period',
            defaultMessage: 'Guarantee period',
        },
        hardware_amount: {
            id: 'app.Dossiers.fields.hardware_amount',
            defaultMessage: 'Hardware amount',
        },
        hardware_rate: {
            id: 'app.Dossiers.fields.hardware_rate',
            defaultMessage: 'Hardware rate',
        },
        has_co_debition: {
            id: 'app.Dossiers.fields.has_co_debition',
            defaultMessage: 'Has co debition',
        },
        has_constructor: {
            id: 'app.Dossiers.fields.has_constructor',
            defaultMessage: 'Has constructor',
        },
        has_creditor: {
            id: 'app.Dossiers.fields.has_creditor',
            defaultMessage: 'Has creditor',
        },
        has_guarantee: {
            id: 'app.Dossiers.fields.has_guarantee',
            defaultMessage: 'Has guarantee',
        },
        has_increased_first_rental: {
            id: 'app.Dossiers.fields.has_increased_first_rental',
            defaultMessage: 'Has increased first rental',
        },
        has_start_date: {
            id: 'app.Dossiers.fields.has_start_date',
            defaultMessage: 'Has start date',
        },
        has_zero_initial: {
            id: 'app.Dossiers.fields.has_zero_initial',
            defaultMessage: 'Has zero initial',
        },
        high_limit_amount: {
            id: 'app.Dossiers.fields.high_limit_amount',
            defaultMessage: 'High limit amount',
        },
        is_client_owner_of_building: {
            id: 'app.Dossiers.fields.is_client_owner_of_building',
            defaultMessage: 'Is client owner of building',
        },
        low_limit_amount: {
            id: 'app.Dossiers.fields.low_limit_amount',
            defaultMessage: 'Low limit amount',
        },
        maintenance_contract_number: {
            id: 'app.Dossiers.fields.maintenance_contract_number',
            defaultMessage: 'Maintenance contract number',
        },
        maintenance_contract_owner: {
            id: 'app.Dossiers.fields.maintenance_contract_owner',
            defaultMessage: 'Maintenance contract owner',
        },
        maintenance_cost: {
            id: 'app.Dossiers.fields.maintenance_cost',
            defaultMessage: 'Maintenance cost',
        },
        number_of_pages: {
            id: 'app.Dossiers.fields.number_of_pages',
            defaultMessage: 'Number of pages',
        },
        override_amounts: {
            id: 'app.Dossiers.fields.override_amounts',
            defaultMessage: 'Override amounts',
        },
        owner_city: {
            id: 'app.Dossiers.fields.owner_city',
            defaultMessage: 'Owner city',
        },
        owner_name: {
            id: 'app.Dossiers.fields.owner_name',
            defaultMessage: 'Owner name',
        },
        owner_number: {
            id: 'app.Dossiers.fields.owner_number',
            defaultMessage: 'Owner number',
        },
        owner_postalcode: {
            id: 'app.Dossiers.fields.owner_postalcode',
            defaultMessage: 'Owner postalcode',
        },
        owner_street: {
            id: 'app.Dossiers.fields.owner_street',
            defaultMessage: 'Owner street',
        },
        page_price_black: {
            id: 'app.Dossiers.fields.page_price_black',
            defaultMessage: 'Page price black',
        },
        page_price_color: {
            id: 'app.Dossiers.fields.page_price_color',
            defaultMessage: 'Page price color',
        },
        payment_type: {
            id: 'app.Dossiers.fields.payment_type',
            defaultMessage: 'Payment type',
        },
        price_per_page_details: {
            id: 'app.Dossiers.fields.price_per_page_details',
            defaultMessage: 'Price per page details',
        },
        process_verbal: {
            id: 'app.Dossiers.fields.process_verbal',
            defaultMessage: 'Process verbal',
        },
        rental_amount: {
            id: 'app.Dossiers.fields.rental_amount',
            defaultMessage: 'Rental amount',
        },
        residual_amount: {
            id: 'app.Dossiers.fields.residual_amount',
            defaultMessage: 'Residual amount',
        },
        residual_value: {
            id: 'app.Dossiers.fields.residual_value',
            defaultMessage: 'Residual value',
        },
        software_amount: {
            id: 'app.Dossiers.fields.software_amount',
            defaultMessage: 'Software amount',
        },
        software_rate: {
            id: 'app.Dossiers.fields.software_rate',
            defaultMessage: 'Software rate',
        },
        start_date: {
            id: 'app.Dossiers.fields.start_date',
            defaultMessage: 'Start date',
        },
        status: {
            id: 'app.Dossiers.fields.status',
            defaultMessage: 'Status',
        },
        tco_flat_amount: {
            id: 'app.Dossiers.fields.tco_flat_amount',
            defaultMessage: 'TCO flat amount',
        },
        ten_twelve_type: {
            id: 'app.Dossiers.fields.ten_twelve_type',
            defaultMessage: 'Ten twelve type',
        },
        total_rent_plus_maintenance: {
            id: 'app.Dossiers.fields.total_rent_plus_maintenance',
            defaultMessage: 'Total rent + maintenance',
        },
        type: {
            id: 'app.Dossiers.fields.type',
            defaultMessage: 'Type',
        },
        updated_at: {
            id: 'app.Dossiers.fields.updated_at',
            defaultMessage: 'Updated at',
        },
        vendor: {
            id: 'app.Dossiers.fields.vendor',
            defaultMessage: 'Vendor',
        },
        vendor_name: {
            id: 'app.Dossiers.fields.vendor_name',
            defaultMessage: 'Vendor',
        },
        vendor_owner: {
            id: 'app.Dossiers.fields.vendor_owner',
            defaultMessage: 'Vendor owner',
        },
        vendor_owner_name: {
            id: 'app.Dossiers.fields.vendor_owner_name',
            defaultMessage: 'Vendor owner',
        },
        vendor_city: {
            id: 'app.Dossiers.fields.vendor_city',
            defaultMessage: 'Vendor city',
        },
        vendor_country: {
            id: 'app.Dossiers.fields.vendor_country',
            defaultMessage: 'Vendor country',
        },
        vendor_language: {
            id: 'app.Dossiers.fields.vendor_language',
            defaultMessage: 'Vendor language',
        },
        vendor_number: {
            id: 'app.Dossiers.fields.vendor_number',
            defaultMessage: 'Vendor number',
        },
        vendor_postalcode: {
            id: 'app.Dossiers.fields.vendor_postalcode',
            defaultMessage: 'Vendor postalcode',
        },
        vendor_street: {
            id: 'app.Dossiers.fields.vendor_street',
            defaultMessage: 'Vendor street',
        },
        vendor_bus: {
            id: 'app.Dossiers.fields.vendor_bus',
            defaultMessage: 'Vendor bus',
        },
        vendor_vat: {
            id: 'app.Dossiers.fields.vendor_vat',
            defaultMessage: 'Vendor vat',
        },
        zero_amount_intervals: {
            id: 'app.Dossiers.fields.zero_amount_intervals',
            defaultMessage: 'Zero amount intervals',
        },
        investment_amount: {
            id: 'app.Dossiers.fields.investment_amount',
            defaultMessage: 'Investment amount',
        },
        client_communication_details: {
            id: 'app.Dossiers.fields.client_communication_details',
            defaultMessage: 'Communication details',
        },
        total_coverage: {
            id: 'app.Dossiers.fields.total_coverage',
            defaultMessage: 'Total coverage: | * 1.5',
        },
        legal_form: {
            id: 'app.Dossiers.fields.legal_form',
            defaultMessage: 'Legal form',
        },
        end_date: {
            id: 'app.Dossiers.fields.end_date',
            defaultMessage: 'End date',
        },
        comment_to_funder: {
            id: 'app.Dossiers.fields.comment_to_funder',
            defaultMessage: 'Comment to funder',
        },
        zero_amount_type: {
            id: 'app.Dossiers.fields.zero_amount_type',
            defaultMessage: 'Zero amount type',
        },
        calc_table_name: {
            id: 'app.Dossiers.fields.calc_table_name',
            defaultMessage: 'Calculation table',
        },
    },
    notifications: {
        finishedInfoRequest: {
            id: 'app.Dossiers.notifications.finishedInfoRequest',
            defaultMessage: 'The request for more information is finished, confirm the information to continue',
        },
        confirmedInfoRequest: {
            id: 'app.Dossiers.notifications.confirmedInfoRequest',
            defaultMessage: 'The request for more information has been confirmed',
        },
        startedCalcDocCreation: {
            id: 'app.Dossiers.notifications.startedCalcDocCreation',
            defaultMessage: 'Started creating the calculation document',
        },
        finishedCalcDocCreation: {
            id: 'app.Dossiers.notifications.finishedCalcDocCreation',
            defaultMessage: 'Calculation document was created. {link}',
        },
        startedQuoteDocCreation: {
            id: 'app.Dossiers.notifications.startedQuoteDocCreation',
            defaultMessage: 'Started creating the quotation document',
        },
        finishedQuoteDocCreation: {
            id: 'app.Dossiers.notifications.finishedQuoteDocCreation',
            defaultMessage: 'Quotation document was created. {link}',
        },
        finishedContractDocCreation: {
            id: 'app.Dossiers.notifications.finishedContractDocCreation',
            defaultMessage: 'Contract document was created. {link}',
        },
        submittedSNI: {
            id: 'app.Dossiers.notifications.submittedSNI',
            defaultMessage: 'The dossier has been submitted to SNI',
        },
        renewedDossier: {
            id: 'app.Dossiers.notifications.renewedDossier',
            defaultMessage: 'The dossier has been renewed',
        },
        signedDossier: {
            id: 'app.Dossiers.notifications.signedDossier',
            defaultMessage: 'The dossier has been signed',
        },
        completedDossier: {
            id: 'app.Dossiers.notifications.completeDossier',
            defaultMessage: 'The dossier has been completed',
        },
        canceledDossier: {
            id: 'app.Dossiers.notifications.canceledDossier',
            defaultMessage: 'The dossier has been canceled',
        },
        archivedDossier: {
            id: 'app.Dossiers.notifications.archivedDossier',
            defaultMessage: 'The dossier has been archived',
        },
        sentRequest: {
            id: 'app.Dossiers.notifications.sentRequest',
            defaultMessage: 'Your request has been sent',
        },
        confirmedCreditRequest: {
            id: 'app.Dossiers.notifications.confirmedCreditRequest',
            defaultMessage: 'Credit request confirmed',
        },
        selectFileType: {
            id: 'app.Dossiers.notifications.selectFileType',
            defaultMessage: 'Select a document type',
        },
        reviewedDocument: {
            id: 'app.Dossiers.notifications.reviewedDossier',
            defaultMessage: 'Document has been reviewed and the dossier has been updated.',
        },
        createdDossier: {
            id: 'app.Dossiers.notifications.createdDossier',
            defaultMessage: 'A new dossier was created',
        },
        createdQuotation: {
            id: 'app.Dossiers.notifications.createdQuotation',
            defaultMessage: 'A new quotation was created',
        },
        editedDossier: {
            id: 'app.Dossiers.notifications.editedDossier',
            defaultMessage: 'Updated the dossier',
        },
        financialEditedDossier: {
            id: 'app.Dossiers.notifications.financialEditedDossier',
            defaultMessage: 'Renewed and updated the dossier',
        },
        administrativeEditedDossier: {
            id: 'app.Dossiers.notifications.administrativeEditedDossier',
            defaultMessage: 'Updated administrative parameters for dossier',
        },
        editedQuotation: {
            id: 'app.Dossiers.notifications.editedQuotation',
            defaultMessage: 'Updated the quotation',
        },
        missingClients: {
            id: 'app.Dossiers.notifications.missingClients',
            defaultMessage: '{name} does not have any clients yet',
        },
        missingUsers: {
            id: 'app.Dossiers.notifications.missingUsers',
            defaultMessage: '{name} doesn\'t have any users yet',
        },
        setExpiryDate: {
            id: 'app.Dossiers.notifications.setExpiryDate',
            defaultMessage: 'The signing expiration date is set',
        },
        loggedClientCommunication: {
            id: 'app.Dossiers.notifications.loggedClientCommunication',
            defaultMessage: 'Communication with the client successfully logged',
        },
        submittedRequest: {
            id: 'app.Dossiers.notifications.submittedRequest',
            defaultMessage: 'Your request has been submitted',
        },
        StartedDocusignSession: {
            id: 'app.Dossiers.notifications.StartedDocusignSession',
            defaultMessage: 'DocuSign session has been started',
        },
        funderSubmitted: {
            id: 'app.Dossiers.notifications.funderSubmitted',
            defaultMessage: 'Funder has been submitted',
        },
    },
});
