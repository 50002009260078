import React from 'react';

import '../styles/toolbar.scss';

class Toolbar extends React.Component {

    render() {
        const vm = this;
        const children = React.Children.toArray(vm.props.children);
        const classes = `xs2-toolbar ${vm.props.className}`;

        return (
            <div id={vm.props.id} className={classes}>
                {children}
            </div>
        );
    }
}

Toolbar.defaultProps = {
    className: '',
    id: '',
};

export default Toolbar;
