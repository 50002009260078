/*
 * Users Messages
 *
 * This contains all the text for the Users component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'app.Users.header',
        defaultMessage: 'Header',
    },
    username: {
        id: 'app.Users.username',
        defaultMessage: 'Username',
    },
    email: {
        id: 'app.Users.email',
        defaultMessage: 'Email',
    },
    notificationEmail: {
        id: 'app.Users.notificationEmail',
        defaultMessage: 'Notification email',
    },
    firstName: {
        id: 'app.Users.firstName',
        defaultMessage: 'Firstname',
    },
    lastName: {
        id: 'app.Users.lastName',
        defaultMessage: 'Lastname',
    },
    function: {
        id: 'app.Users.function',
        defaultMessage: 'function',
    },
    phoneNumber: {
        id: 'app.Users.phoneNumber',
        defaultMessage: 'Phone number',
    },
    mobileNumber: {
        id: 'app.Users.mobileNumber',
        defaultMessage: 'Mobile number',
    },
    status: {
        id: 'app.Users.status',
        defaultMessage: 'Status',
    },
    password: {
        id: 'app.Users.password',
        defaultMessage: 'Password',
    },
    repeatPassword: {
        id: 'app.Users.repeatPassword',
        defaultMessage: 'Repeat password',
    },
    forgotPassword: {
        id: 'app.Users.forgotPassword',
        defaultMessage: 'Forgot or want to change your password?',
    },
    resetPassword: {
        id: 'app.Users.resetPassword',
        defaultMessage: 'Reset password',
    },
    changePassword: {
        id: 'app.Users.changePassword',
        defaultMessage: 'Change password',
    },
    login: {
        id: 'app.Users.login',
    },
    profilePage: {
        id: 'app.Users.profilePage',
    },
    activity: {
        id: 'app.Users.activity',
    },
    contracts: {
        id: 'app.Users.contracts',
    },
    settings: {
        id: 'app.Users.settings',
    },
    states: {
        ABSENT: {
            id: 'app.Users.states.ABSENT',
            defaultMessage: 'Absent',
        },
        ONVACATION: {
            id: 'app.Users.states.ONVACATION',
            defaultMessage: 'On vacation',
        },
        PRESENT: {
            id: 'app.Users.states.PRESENT',
            defaultMessage: 'Present',
        },
    },
    notifications: {
        failedLogin: {
            id: 'app.Users.notifications.failedLogin',
            defaultMessage: 'Failed to login, please check your username/password',
        },
        editedProfile: {
            id: 'app.Users.notifications.editedProfile',
            defaultMessage: 'Your profile has successfully been updated',
        },
        editProfileFailed: {
            id: 'app.Users.notifications.editProfileFailed',
            defaultMessage: 'Your profile could not be saved!',
        },
    },
});