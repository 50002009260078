import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, FormGroup, Textarea, Button } from 'common/Form';
import appMessages from 'components/App/messages';
import { DOSSIERS_URL } from '../constants';
import { editDossier } from '../actions';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';
import { VENDORS_URL } from '../../Vendor/constants';
import { CountryFlag } from 'components/LanguageSwitcher/CountryFlag';
import axios from 'axios';


export class LogClientCommunicationForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        this.state = {
            vendorLanguage: 'Loading...',
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
        this.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        this.getVendorForDossier = this.getVendorForDossier.bind(this);
        this.getVendor = this.getVendor.bind(this);
    }

    componentDidMount() {
        this.getVendorForDossier();
    }

    onSubmit(data) {
        const vm = this;
        const { router } = vm.context;

        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const referrer = router.getCurrentLocation().query.referrer;

        axios.post(`${DOSSIERS_URL}${router.params.id}/actions/`, { action_code: 'LOGCLIENTCOMMUNICATION', data }, { headers }).then((response) => {
            router.push(referrer || `/dossiers/${router.params.id}`);
            dispatch(editDossier(response.data.result));
            return toast.success('Success!', <FormattedMessage {...messages.notifications.loggedClientCommunication} tagName="p" />);
        });
    }

    getVendor(vendorId) {
        const { headers, props } = this;
        axios.get(`${VENDORS_URL}${vendorId}/`, { headers }).then((response) => {
            const { data: { result: vendor } = {} } = response;
            const { language = 'en' } = vendor || {};
            this.setState({
                vendorLanguage: props.intl.formatMessage({ id: `app.languages.${language.toLowerCase()}` }),
                vendorLanguageCode: language.toLowerCase(),
            });
        });
    }

    getVendorForDossier() {
        const { dossier, dossierId } = this.props;
        const { headers } = this;
        if (!dossier || Object.keys(dossier).length === 0) {
            axios.get(`${DOSSIERS_URL}${dossierId}/`, { headers }).then((response) => {
                const { data: { result } = {} } = response;
                const { vendor: vendorId } = result || {};
                if (vendorId) {
                    this.getVendor(vendorId);
                } else {
                    this.setState({
                        vendorLanguage: 'Unknown',
                    });
                }
            });
        } else {
            this.getVendor(dossier.vendor);
        }
    }

    render() {
        const vm = this;

        return (
            <div id="LogClientCommunicationForm" className="log-client-communication-form">
                <h1 className="center-align"><FormattedMessage {...appMessages.actions.LOGCLIENTCOMMUNICATION} /></h1>
                <Form id="log-client-communication" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        {vm.state.vendorLanguageCode && <div>
                            <strong><FormattedMessage {...messages.fields.vendor_language} />:</strong>
                            <div className="left-align layout-row">
                                <CountryFlag lang={vm.state.vendorLanguageCode} />
                                <span className="country-label flex">{vm.state.vendorLanguage}</span>
                            </div>
                        </div>}
                        <Textarea
                            name="client_communication_details"
                            label={messages.fields.client_communication_details}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(injectIntl(LogClientCommunicationForm));
