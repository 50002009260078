import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { Menu, MenuTrigger, MenuContent } from 'common/Util';
import Tabs, { Pane } from 'common/Tabs';
import appMessages from 'components/App/messages';
import { CalculationTable } from '.';
import messages from '../messages';
import { VENDORS_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';

class CalculationTables extends Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.state = {
            tables: [],
        };
    }
    componentDidMount() {
        const vm = this;
        vm.fetchData();
    }
    fetchData() {
        const vm = this;
        const { headers } = vm;
        const { vendorId } = vm.props;

        return axios.get(`${VENDORS_URL}${vendorId}/calculationtables/`, { headers }).then((response) => {
            const tables = [...response.data.result];
            vm.state.tables = tables;
            vm.setState(vm.state);
        });
    }
    render() {
        const vm = this;
        const { tables } = vm.state;

        return (
            <div id="CalculationTables">
                <div className="table-container">
                    <Tabs selected={0}>
                        {_.map(tables, (table) =>
                            <Pane
                                key={table.id}
                                label={
                                    <Menu hoverTrigger scrollMask={false}>
                                        <MenuTrigger>
                                            <FormattedMessage {...messages.contractTags[table.tag]} />&nbsp;
                                            <FormattedMessage {...messages.contractTypes[table.type]} />&nbsp;
                                            <FormattedMessage {...appMessages[table.duration_type]} />&nbsp;
                                            <FormattedMessage {...messages.tenTwelveTypes[table.ten_twelve_type]} />&nbsp;
                                            <FormattedMessage {...appMessages[table.zero_amount_type]} />&nbsp;
                                        </MenuTrigger>
                                        <MenuContent>
                                            <ul>
                                                <li><FormattedMessage {...appMessages.tag} tagName="strong" />: <FormattedMessage {...messages.contractTags[table.tag]} /></li>
                                                <li><FormattedMessage {...appMessages.contractType} tagName="strong" />: <FormattedMessage {...messages.contractTypes[table.type]} /></li>
                                                <li><FormattedMessage {...appMessages.periodicity} tagName="strong" />: <FormattedMessage {...appMessages[table.duration_type]} /></li>
                                                <li><FormattedMessage {...appMessages.tenTwelveType} tagName="strong" />: <FormattedMessage {...appMessages[table.ten_twelve_type]} /></li>
                                                <li><FormattedMessage {...appMessages.zeroAmountType} tagName="strong" />: <FormattedMessage {...appMessages[table.zero_amount_type]} /></li>
                                            </ul>
                                        </MenuContent>
                                    </Menu>
                                }
                            >
                                <CalculationTable data={table} />
                            </Pane>
                        )}
                    </Tabs>
                </div>
            </div>
        );
    }
}

CalculationTables.propTypes = {
    user: PropTypes.object,
    // vendorId: PropTypes.number.isRequired,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (user) => ({ user })
);

export default connect(mapStateToProps)(CalculationTables);
