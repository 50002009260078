import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getEntityIdFromUrl } from 'components/App/utils';
import appMessages from 'components/App/messages';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Textarea, Button } from 'common/Form';
import { DOSSIERS_URL } from '../constants';
import { editDossier } from '../actions';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class RequestInfoForm extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.onSubmit = vm.onSubmit.bind(vm);
    }

    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const id = +getEntityIdFromUrl();
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };


        axios.post(`${DOSSIERS_URL}${id}/actions/`, { action_code: 'REQUESTINFOACTION', data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${id}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.submittedRequest} tagName="p" />);
        });
    }

    render() {
        const vm = this;

        return (
            <div id="RequestInfoForm" className="request-info-form">
                <h1 className="center-align">Request info</h1>
                <Form id="request-dossier-info" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Textarea name="info_details" label={appMessages.details} />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

RequestInfoForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(RequestInfoForm);
