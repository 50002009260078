import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getEntityIdFromUrl } from 'components/App/utils';
import appMessages from 'components/App/messages';
import vendorMessages from 'components/Vendor/messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, FormGroup, Textarea, Checkbox, Autocomplete, Button } from 'common/Form';
import { setValue } from 'common/Form/actions';
import { VENDORS_URL } from 'components/Vendor/constants';
import messages from '../messages';
import { DOSSIERS_URL, FUNDERS_URL, DOCUMENT_TYPES } from '../constants';
import { editDossier } from '../actions';
import { DEFAULT_LOCALE } from 'components/App/constants';


class ConfirmBankResponseForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            calcTableOptions: [],
            calcTableIndex: 0,
            isLoaded: false,
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.dossierId = +getEntityIdFromUrl();
        vm.formId = 'confirm-bank-response';
    }
    componentDidMount() {
        const vm = this;
        const { user } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.all([
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/`, { headers }),
            axios.get(`${VENDORS_URL}`, { headers }),
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/creditrequests/`, { headers }),
        ]).then((response) => {
            vm.state.dossier = response[0].data.result;
            vm.state.vendor = response[1].data.result.find((i) => +i.id === +vm.state.dossier.vendor);
            vm.state.creditRequests = response[2].data.result;
            vm.state.isLoaded = true;
            vm.setState(vm.state, () => {
                vm.getCalculationTables();
            });
        });
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const calcTable = vm.state.vendor.available_calculationtable_choices[data.calculation_table];

        delete data.calculation_table;
        data.type = calcTable.type;
        data.duration_type = calcTable.duration_type;
        data.ten_twelve_type = calcTable.ten_twelve_type;
        data.payment_type = calcTable.payment_type;
        data.tag = calcTable.tag;
        data.zero_amount_type = calcTable.zero_amount_type;

        if (vm.props.action === 'success' || vm.props.action === 'failure') {
            const actionCode = vm.props.action === 'success' ? 'ACCEPT' : 'REJECT';
            axios.post(`${DOSSIERS_URL}${vm.dossierId}/actions/`, { action_code: actionCode, data }, { headers }).then((response) => {
                dispatch(editDossier(response.data.result));
                vm.context.router.push(`/dossiers/${vm.dossierId}`);
                return toast.success('Success!', <FormattedMessage {...messages.notifications.sentRequest} tagName="p" />);
            });
        } else if (vm.props.action === 'successconditions') {
            data.extra_docs_required = [];

            _.forEach(_.keys(data), (key) => {
                if (~DOCUMENT_TYPES.indexOf(key)) {
                    data[key] && data.extra_docs_required.push(key); // eslint-disable-line
                    delete data[key];
                }
            });

            window.setTimeout(() => {
                axios.post(`${DOSSIERS_URL}${vm.dossierId}/actions/`, { action_code: 'ACCEPTWITHCONDITIONS', data }, { headers }).then((response) => {
                    dispatch(editDossier(response.data.result));
                    vm.context.router.push(`/dossiers/${vm.dossierId}`);
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.sentRequests} tagName="p" />);
                });
            }, 10);
        }
    }
    getCalculationTables() {
        const vm = this;
        const { dispatch, intl } = vm.props;
        const { dossier, vendor } = vm.state;

        vm.state.calcTableOptions = _.map(
            vendor.available_calculationtable_choices,
            (options, index) => ({
                value: index,
                label: _.map(_.values(options),
                    (option) => {
                        try {
                            return intl.formatMessage(
                                vendorMessages.contractTags[option] ||
                                vendorMessages.contractTypes[option] ||
                                appMessages[option]
                            );
                        } catch (error) {
                            // pass translation errors
                        }
                    }).join(', '),
            })
        );
        vm.state.calcTableIndex = _.findIndex(
            vendor.available_calculationtable_choices,
            (choice) => (
                choice.payment_type === dossier.payment_type &&
                choice.type === dossier.type &&
                choice.ten_twelve_type === dossier.ten_twelve_type &&
                choice.duration_type === dossier.duration_type &&
                ((choice.zero_amount_type === 'YES' && dossier.has_zero_amount) ||
                    (choice.zero_amount_type === 'NO' && !dossier.has_zero_amount))
            )
        );

        if (~vm.state.calcTableIndex) {
            dispatch(setValue(vm.formId, 'calculation_table', undefined, vm.state.calcTableIndex));
        } else {
            vm.state.calcTableIndex = false;
        }
        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const title = ~vm.props.action.indexOf('success')
                      ? 'Confirm successful credit request'
                      : 'Confirm failed credit request';
        return (
            <div id="ConfirmBankResponseForm" className="request-info-form">
                <h1 className="center-align">{title}</h1>
                <Form id={vm.formId} submitHandler={vm.onSubmit} theme="material">
                    {vm.props.action === 'successconditions' ?
                        <FormGroup className="request-conditions layout-row layout-wrap">
                            <Textarea name="conditions" label={appMessages.conditions} labelSuffix={vm.state.isLoaded ? `(${vm.state.vendor.language})` : null} className="flex-100" />
                            {_.map(DOCUMENT_TYPES, (type) =>
                                <Checkbox name={type} key={type} label={appMessages.docTypes[type]} className="flex-50" />
                            )}
                        </FormGroup>
                    : null}
                    <FormGroup>
                        <Autocomplete
                            name="calculation_table"
                            label={appMessages.calculationTable}
                            options={vm.state.calcTableOptions}
                            defaultValue={vm.state.calcTableIndex}
                        />
                        <Checkbox
                            name="fix_rental_amount"
                            label={appMessages.fixRentalAmount}
                            defaultValue={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (state) => state.get('vendors'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(injectIntl(ConfirmBankResponseForm));
