import { fromJS } from 'immutable';
import {
    CONTACTS_INIT,
    CONTACTS_NEW,
    CONTACTS_EDIT,
    CONTACTS_DELETE,
    SUPPORT_INIT,
} from './constants';

const initialState = fromJS({
    contacts: fromJS([]),
    support: fromJS([]),
});

function contactsReducer(state = initialState, action) {
    let newState = state;

    switch (action.type) {
        case CONTACTS_INIT: {
            newState = newState.set('contacts', fromJS(action.contacts));
            break;
        }
        case CONTACTS_NEW: {
            newState = newState.updateIn(['contacts'],
                (contacts) => contacts.push(fromJS(action.contact))
            );
            break;
        }
        case CONTACTS_EDIT: {
            const index = newState.get('contacts').findIndex((i) => +i.get('id') === +action.contact.id);
            newState = newState.updateIn(['contacts', index],
                () => fromJS(action.contact)
            );
            break;
        }
        case CONTACTS_DELETE: {
            const index = newState.get('contacts').findIndex((i) => i.get('id') === action.contact.id);
            newState = newState.updateIn(['contacts'],
                (contacts) => contacts.delete(index)
            );
            break;
        }
        case SUPPORT_INIT: {
            newState = newState.set('support', fromJS(action.contacts));
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default contactsReducer;
