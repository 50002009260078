import PropTypes from 'prop-types';
import React from 'react';
import { mapFieldGroupToChildProps, mapFormIdToChildProps } from '../utils';

class FormGroup extends React.PureComponent {
    render() {
        const vm = this;
        let children = mapFormIdToChildProps(vm.props.children, vm.props.formId);

        if (vm.props.fieldGroup) {
            children = mapFieldGroupToChildProps(children, vm.props.fieldGroup);
        }

        return (
            <div
                id={vm.props.id}
                className={`form-group ${vm.props.className}`}
            >
                {children}
            </div>
        );
    }
}

FormGroup.defaultProps = {
    className: '',
};

FormGroup.propTypes = {
    className: PropTypes.string,
};

export default FormGroup;
