/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import PropTypes from 'prop-types';

import React from 'react';

import 'assets/css/index.scss'; // Import global styles
import 'assets/lib/bootstrap/bootstrap.css';

class App extends React.PureComponent {
    render() {
        const frontendMatchesBackend = () => {
            if (process.env.API_URL === '') {
                return true;
            }
            const { host } = window.location;
            return (process.env.API_URL || '').indexOf(host) >= 0;
        };
        return (
            <div id="App" style={frontendMatchesBackend ? {} : { paddingTop: '50px' }}>
                {!frontendMatchesBackend &&
                    <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 100, background: 'rgba(255, 255, 255, 0.8)', pointerEvents: 'none' }}>
                        <p style={{ textAlign: 'center' }}>⚠️ API does not match frontend URL! <br />Connected with {process.env.API_URL}.</p>
                    </div>
                }
                {React.Children.toArray(this.props.children)}
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.node,
};

export default App;
