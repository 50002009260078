import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';

export class Pages extends Component {
    static defaultProps = {
        className: '',
        active: 0,
        previousLabel: 'Previous',
        nextLabel: 'Next',
        getPageLabel: (index) => index,
    }
    static propTypes = {
        // children: PropTypes.node.isRequired,
        // className: PropTypes.string,
        active: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        // nextLabel: PropTypes.oneOfType([
        //     PropTypes.element,
        //     PropTypes.bool,
        // ]),
        // previousLabel: PropTypes.oneOfType([
        //     PropTypes.element,
        //     PropTypes.bool,
        // ]),
        // getPageLabel: PropTypes.func,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            active: props.active,
        };
    }
    getPageLabel(index) {
        const vm = this;
        return vm.props.getPageLabel(index);
    }
    goToPage(page) {
        const vm = this;
        vm.state.active = page;
        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const children = React.Children.toArray(vm.props.children);
        return (
            <div className={`xs2-pages ${vm.props.className}`}>
                <div className="xs2-pages-content">
                    {React.Children.toArray(children)[vm.state.active]}
                </div>
                <div className="xs2-pages-pagination">
                    {vm.props.previousLabel && (+vm.state.active !== 0) ?
                        <button type="button" className="xs2-pages-button xs2-pages-previous" onClick={vm.goToPage.bind(vm, vm.state.active - 1)}>
                            {vm.props.previousLabel}
                        </button>
                    : null}
                    {_.map(new Array(children.length), (value, index) => (
                        <button
                            key={index + 1}
                            type="button"
                            className={`xs2-pages-button xs2-pages-${index + 1} ${vm.state.active === index ? 'xs2-pages-current' : ''}`}
                            onClick={vm.goToPage.bind(vm, index)}
                        >
                            {vm.getPageLabel(index + 1)}
                        </button>
                    ))}
                    {vm.props.nextLabel && (+vm.state.active !== children.length - 1) ?
                        <button type="button" className="xs2-pages-button xs2-pages-next" onClick={vm.goToPage.bind(vm, vm.state.active + 1)}>
                            {vm.props.previousLabel}
                        </button>
                    : null}
                </div>
            </div>
        );
    }
}
export class Page extends PureComponent { // eslint-disable-line react/no-multi-comp
    static defaultProps = {
        className: '',
    }
    static propTypes = {
        children: PropTypes.element.isRequired,
        className: PropTypes.string,
    }
    render() {
        const vm = this;

        return (
            <div className={`xs2-page xs2-page-${vm.props.index} ${vm.props.className}`}>
                {React.Children.only(vm.props.children)}
            </div>
        );
    }
}

export default Pages;
