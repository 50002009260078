import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactModal from 'react-modal';
import List from 'components/App/partials/List';
import { Tooltip } from 'common/Util';
import appMessages from 'components/App/messages';
import { sortDate } from 'components/App/utils';
import DocumentUploader from '../partials/DocumentUploader';
import DocumentVerdictForm from '../partials/DocumentVerdictForm';

class DossierFilesList extends Component {
    static defaultProps = {
        documents: [],
    }
    static propTypes = {
        intl: PropTypes.object,
        dossier: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.verdict = '';
        vm.document = {};
        vm.dossierId = props.dossier.id;
        vm.columns = [
            {
                label: intl.formatMessage(appMessages.file),
                key: 'result_file',
                formatter: (value, entity) => (<a href={`${process.env.API_URL}${entity.result_file}`} download>{entity.name}</a>),
            }, {
                label: intl.formatMessage(appMessages.status),
                key: 'status',
                formatter: (value) => value && props.intl.formatMessage(appMessages.docStates[value]),
            }, {
                label: intl.formatMessage(appMessages.documentType),
                key: 'document_type',
                formatter: (value) => value && props.intl.formatMessage(appMessages.docTypes[value]),
            }, {
                label: intl.formatMessage(appMessages.createdAt),
                key: 'created_at',
                formatter: (value) => value && moment(value).format('LLL'),
            }, {
                label: intl.formatMessage(appMessages.version),
                key: 'version',
            }, {
                label: intl.formatMessage(appMessages._actions),
                key: 'actions',
            },
        ];

        vm.state = {
            showDialog: false,
        };

        vm.toggleUpload = vm.toggleUpload.bind(vm);
        vm.toggleDialog = vm.toggleDialog.bind(vm);
        vm.cancelDialog = vm.cancelDialog.bind(vm);
    }
    mapDocuments(documents) {
        const vm = this;
        const { dossier } = vm.props;
        const { intl } = vm.props;

        if (!_.isEmpty(dossier) && dossier.available_actions.indexOf('REVIEWDOCUMENT') > -1) {
            return _.map(documents, (document) => {
                document.actions = (
                    <div className="DocumentActions">
                        {document.status !== 'ACCEPTED' &&
                            <button className="document-action" onClick={vm.toggleDialog('ACCEPT', document)}>
                                <i className="icon-like" />
                                <Tooltip label={intl.formatMessage(appMessages.actions.ACCEPT)} />
                            </button>
                        }
                        &nbsp;
                        {document.status !== 'REJECTED' &&
                            <button className="document-action" onClick={vm.toggleDialog('REJECT', document)}>
                                <i className="icon-dislike" />
                                <Tooltip label={intl.formatMessage(appMessages.actions.REJECT)} />
                            </button>
                        }
                    </div>
                );
                return document;
            });
        }
        return documents;
    }
    toggleUpload(event) {
        const vm = this;
        vm.uploader.getWrappedInstance().toggle(event);
    }
    toggleDialog(verdict, document) {
        const vm = this;
        return () => {
            if (verdict) { vm.verdict = verdict; }
            if (document) { vm.document = document; }
            vm.state.showDialog = !vm.state.showDialog;
            vm.setState(vm.state);
        };
    }
    cancelDialog() {
        const vm = this;
        vm.verdict = '';
        vm.document = {};
        vm.toggleDialog()();
    }
    render() {
        const vm = this;
        const { dossier } = vm.props;
        const documents = vm.mapDocuments(vm.props.documents);

        if (_.isEmpty(documents)) {
            documents.push({ result_file: 'No files yet' });
        }

        return (
            <div className="dossier-files">
                <div className="files-header layout-row">
                    <h2 className="flex"><FormattedMessage {...appMessages.files} /></h2>
                    <div className="files-controls flex-nogrow">
                        <button
                            className="file-upload button button-default"
                            onClick={vm.toggleUpload}
                        >
                            <FormattedMessage {...appMessages.uploadDocument} />
                        </button>
                    </div>
                </div>
                <List
                    name="dossier-files"
                    columns={vm.columns}
                    data={documents}
                    sortable={[
                        'result_file',
                        'status',
                        'document_type',
                        { column: 'created_at', sortFunction: sortDate },
                        'version',
                    ]}
                    defaultSort={{ column: 'created_at', direction: 'desc' }}
                />
                <DocumentUploader dossier={dossier} ref={(ref) => { vm.uploader = ref; }} />
                <ReactModal
                    isOpen={vm.state.showDialog}
                    contentLabel="Verdict dialog"
                    className="modal-dialog"
                    overlayClassName="modal-dialog-overlay layout-row layout-align-center-center"
                    onRequestClose={vm.toggleDialog}
                    shouldCloseOnOverlayClick
                    parentSelector={() => document.querySelector('.dashboard-main > .container')}
                >
                    <DocumentVerdictForm
                        onSubmit={vm.toggleDialog()}
                        onCancel={vm.cancelDialog}
                        verdict={vm.verdict}
                        document={vm.document}
                        dossier={dossier}
                    />
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (user) => ({ user })
);

export default connect(mapStateToProps)(injectIntl(DossierFilesList));
