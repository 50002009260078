import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'app.Vendors.name',
        defaultMessage: 'Name',
    },
    phone: {
        id: 'app.Vendors.phone',
        defaultMessage: 'Phone',
    },
    email: {
        id: 'app.Vendors.email',
        defaultMessage: 'Email',
    },
    language: {
        id: 'app.Vendors.language',
        defaultMessage: 'Language',
    },
    firmname: {
        id: 'app.Vendors.firmname',
        defaultMessage: 'Firm name',
    },
    street: {
        id: 'app.Vendors.street',
        defaultMessage: 'number',
    },
    number: {
        id: 'app.Vendors.number',
        defaultMessage: 'Number',
    },
    postalcode: {
        id: 'app.Vendors.postalcode',
        defaultMessage: 'Postalcode',
    },
    bus: {
        id: 'app.Vendors.bus',
        defaultMessage: 'Bus',
    },
    city: {
        id: 'app.Vendors.city',
        defaultMessage: 'City',
    },
    address: {
        id: 'app.Vendors.address',
        defaultMessage: 'Address',
    },
    financial: {
        id: 'app.Vendors.financial',
        defaultMessage: 'Financial',
    },
    vat: {
        id: 'app.Vendors.vat',
        defaultMessage: 'VAT',
    },
    iban: {
        id: 'app.Vendors.iban',
        defaultMessage: 'IBAN',
    },
    swiftBIC: {
        id: 'app.Vendors.swiftBIC',
        defaultMessage: 'Swift BIC',
    },
    contactName: {
        id: 'app.Vendors.contactName',
        defaultMessage: 'Contact name',
    },
    contactFunction: {
        id: 'app.Vendors.contactFunction',
        defaultMessage: 'Contact function',
    },
    contactEmail: {
        id: 'app.Vendors.contactEmail',
        defaultMessage: 'Contact email',
    },
    contractOptions: {
        id: 'app.Vendors.contractOptions',
        defaultMessage: 'Contract options',
    },
    calculationTables: {
        id: 'app.Vendors.calculationTables',
        defaultMessage: 'Calculation tables',
    },
    addClient: {
        id: 'app.Vendors.addClient',
        defaultMessage: 'Add client',
    },
    addVendor: {
        id: 'app.Vendors.addVendor',
        defaultMessage: 'New vendor',
    },
    noEquipment: {
        id: 'app.Vendors.noEquipment',
        defaultMessage: 'No equipment types yet',
    },
    contractOptionSets: {
        id: 'app.Vendors.contractOptionSets',
        defaultMessage: 'Contract option sets',
    },
    limit: {
        id: 'app.Vendors.limit',
        defaultMessage: 'As from',
    },
    type: {
        id: 'app.Vendors.type',
        defaultMessage: 'Type',
    },
    ten_twelve_type: {
        id: 'app.Vendors.ten_twelve_type',
        defaultMessage: 'Ten twelve type',
    },
    payment_type: {
        id: 'app.Vendors.payment_type',
        defaultMessage: 'Payment type',
    },
    _contractTypes: {
        id: 'app.Vendors.contractTypes',
        defaultMessage: 'Contract types',
    },
    filterByCompanyName: {
        id: 'app.Vendors.filterByCompanyName',
        defaultMessage: 'Filter by company name',
    },
    contractTags: {
        STANDARD: {
            id: 'app.Vendors.contractTags.STANDARD',
            defaultMessage: 'Standard',
        },
        ATRADIUS: {
            id: 'app.Vendors.contractTags.ATRADIUS',
            defaultMessage: 'Atradius',
        },
        SCHOOL: {
            id: 'app.Vendors.contractTags.SCHOOL',
            defaultMessage: 'School',
        },
        MAINTENANCE: {
            id: 'app.Vendors.contractTags.MAINTENANCE',
            defaultMessage: 'Maintenance',
        },
        INCREASED_REST_VALUE: {
            id: 'app.Vendors.contractTags.INCREASED_REST_VALUE',
            defaultMessage: 'Increased rest value',
        },
        BRIDGING: {
            id: 'app.Vendors.contractTags.BRIDGING',
            defaultMessage: 'Bridging',
        },
        SOFTWARESERVICES: {
            id: 'app.Vendors.contractTags.SOFTWARESERVICES',
            defaultMessage: 'Software/services',
        },
        ALLINPERPAGE: {
            id: 'app.Vendors.contractTags.ALLINPERPAGE',
            defaultMessage: 'All in per page',
        },
        ALLIN: {
            id: 'app.Vendors.contractTags.ALLIN',
            defaultMessage: 'All in',
        },
        EXCEPTION: {
            id: 'app.Vendors.contractTags.EXCEPTION',
            defaultMessage: 'Exception',
        },
    },
    termtypes: {
        standardInsurance: {
            id: 'app.Vendors.termtypes.standardInsurance',
            defaultMessage: 'Standard with insurance',
        },
        specialEquipment: {
            id: 'app.Vendors.termtypes.specialEquipment',
            defaultMessage: 'Specific equipment with insurance',
        },
    },
    contractTypes: {
        LEASE: {
            id: 'app.Vendors.contractTypes.LEASE',
            defaultMessage: 'Lease',
        },
        RENT: {
            id: 'app.Vendors.contractTypes.RENT',
            defaultMessage: 'Rent',
        },
        ALL: {
            id: 'app.Vendors.contractTypes.BOTH',
            defaultMessage: 'Both',
        },
        '*': {
            id: 'app.Vendors.contractTypes.BOTH',
            defaultMessage: 'Both',
        },
        BOTH: {
            id: 'app.Vendors.contractTypes.BOTH',
            defaultMessage: 'Both',
        },
        ALLIN: {
            id: 'app.Vendors.contractTypes.ALLIN',
            defaultMessage: 'All-in',
        },
        RENT_PLUS_MAINTENANCE: {
            id: 'app.Vendors.contractType.RENT_PLUS_MAINTENANCE',
            defaultMessage: 'Rent + maintenance',
        },
    },
    tenTwelveTypes: {
        YES: {
            id: 'app.Vendors.tenTwelveTypes.yes',
            defaultMessage: 'Yes',
        },
        NO: {
            id: 'app.Vendors.tenTwelveTypes.no',
            defaultMessage: 'No',
        },
        ALL: {
            id: 'app.Vendors.tenTwelveTypes.all',
            defaultMessage: '*',
        },
        '*': {
            id: 'app.Vendors.tenTwelveTypes.all',
            defaultMessage: '*',
        },
        BOTH: {
            id: 'app.Vendors.tenTwelveTypes.both',
            defaultMessage: 'Both',
        },
    },
    paymentTypes: {
        ALLIN: {
            id: 'app.Vendors.paymentTypes.ALLIN',
            defaultMessage: 'All in',
        },
        ALLINPERPAGE: {
            id: 'app.Vendors.paymentTypes.ALLINPERPAGE',
            defaultMessage: 'All in per page',
        },
        perpage: {
            id: 'app.Vendors.paymentTypes.perpage',
            defaultMessage: 'Per page',
        },
        ALLINTCO: {
            id: 'app.Vendors.paymentTypes.ALLINTCO',
            defaultMessage: 'All in TCO',
        },
        RENT_PLUS_MAINTENANCE: {
            id: 'app.Vendors.paymentTypes.RENT_PLUS_MAINTENANCE',
            defaultMessage: 'Rent + Maintenance',
        },
        STANDARD: {
            id: 'app.Vendors.paymentTypes.STANDARD',
            defaultMessage: 'Standard',
        },
    },
    notifications: {
        deletedVendor: {
            id: 'app.Vendors.notifications.deletedVendor',
            defaultMessage: '{name} has been removed',
        },
        confirmVendorDeletion: {
            id: 'app.Vendors.notifications.confirmVendorDeletion',
            defaultMessage: 'Are you sure you want to delete {name}',
        },
        updatedCalcTables: {
            id: 'app.Vendors.notifications.updatedCalcTables',
            defaultMessage: 'Calculation tables for {name} updated',
        },
        updatedContractOptions: {
            id: 'app.Vendors.notifications.updatedContractOptions',
            defaultMessage: 'Contract options updated for {name}',
        },
        updatedEquipment: {
            id: 'app.Vendors.notifications.updatedEquipment',
            defaultMessage: 'Equipment types updated for {name}',
        },
        updatedClient: {
            id: 'app.Vendors.notifications.updatedClient',
            defaultMessage: 'Client {name} has been updated',
        },
        createdClient: {
            id: 'app.Vendors.notifications.createdClient',
            defaultMessage: 'Client {name} has been added to {vendorName}',
        },
        createdVendor: {
            id: 'app.Vendors.notifications.createdVendor',
            defaultMessage: '{name} was added to vendors',
        },
        updatedVendor: {
            id: 'app.Vendors.notifications.updatedVendor',
            defaultMessage: 'Vendor {name} updated',
        },
    },
});
