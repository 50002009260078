import {
    VENDORS_INIT_VENDORS,
    VENDORS_NEW_VENDOR,
    VENDORS_EDIT_VENDOR,
    VENDORS_DELETE_VENDOR,
    VENDORS_ADD_CONTRACT_OPTIONS,
    VENDORS_REMOVE_CONTRACT_OPTIONS,
    VENDORS_ADD_EQUIPMENT_TYPE,
    VENDORS_REMOVE_EQUIPMENT_TYPE,
    VENDORS_ADD_CLIENTS,
    VENDORS_REMOVE_CLIENTS,
} from './constants';

const initialState = [];

function vendorsReducer(state = initialState, action) {
    let newState = [...state];

    switch (action.type) {
        case VENDORS_INIT_VENDORS: {
            newState = action.vendors;
            break;
        }
        case VENDORS_NEW_VENDOR: {
            newState.push(action.vendor);
            break;
        }
        case VENDORS_EDIT_VENDOR: {
            const vendor = newState.find((i) => i.id === action.vendor.id);
            Object.assign(vendor, action.vendor);
            break;
        }
        case VENDORS_DELETE_VENDOR: {
            newState = newState.filter((i) => i.id !== action.vendorId);
            break;
        }
        case VENDORS_ADD_CONTRACT_OPTIONS: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            if (vendor) {
                vendor.contract_options.push({ type: '', ten_twelve_type: '', payment_type: '' });
            }
            break;
        }
        case VENDORS_REMOVE_CONTRACT_OPTIONS: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            if (vendor) {
                vendor.contract_options.splice(action.index, 1);
            }
            break;
        }
        case VENDORS_ADD_EQUIPMENT_TYPE: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            vendor.equipment_types.push(action.equipmentType);
            break;
        }
        case VENDORS_REMOVE_EQUIPMENT_TYPE: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            vendor.equipment_types.splice(action.index, 1);
            break;
        }
        case VENDORS_ADD_CLIENTS: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            vendor.clients = vendor.clients || [];
            vendor.clients.push(action.client);
            break;
        }
        case VENDORS_REMOVE_CLIENTS: {
            const vendor = newState.find((i) => i.id === +action.vendorId);
            vendor.clients.splice(action.index, 1);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default vendorsReducer;
