import Validator from '../Validator';

const options = {
    rules: [],
};

options.rules.push({
    name: 'numeric',
    pattern: new RegExp(/^\d*$/),
    error: 'numeric',
});

export default new Validator(options);

