import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Table, Tr, Td, Sort } from 'reactable';
import ActionsMenu from 'components/ActionsMenu';
import messages from '../messages';

function List(props) {
    const { intl } = props;
    const rows = [];
    const tableProps = {
        className: `${props.name}-list`,
        columns: props.columns.map(({ key, label }) => ({ key, label })),
        noDataText: props.noDataText,
        previousPageLabel: intl.formatMessage(messages.previous),
        nextPageLabel: intl.formatMessage(messages.next),
    };
    const className = `${props.name}-list-container ${props.responsive ? 'responsive-list-container' : ''} ${props.className}`;

    function getSortFn(option) {
        if (_.has(option, 'sortFunction') && _.isString(option.sortFunction)) {
            const sortFn = option.sortFunction;
            if (_.has(Sort, sortFn)) {
                option.sortFunction = (a, b) => {
                    if (_.has(a, 'props.children')) {
                        a = a.props.children; // eslint-disable-line no-param-reassign
                    }
                    if (_.has(b, 'props.children')) {
                        b = b.props.children; // eslint-disable-line no-param-reassign
                    }
                    return Sort[sortFn](a, b);
                };
            }
        }
        return option;
    }

    if (props.rowHighlight) {
        tableProps.className += ' table-highlight';
    }
    if (props.itemsPerPage) {
        tableProps.itemsPerPage = props.itemsPerPage;
    }
    if (props.pageButtonLimit) {
        tableProps.pageButtonLimit = props.pageButtonLimit;
    }
    if (props.sortable === true) {
        tableProps.sortable = _.map(props.columns, (column) => column.key !== 'actions' && column.key);
    } else if (props.sortable) {
        tableProps.sortable = _.map(props.sortable, getSortFn);
    }
    if (props.defaultSort) {
        tableProps.defaultSort = props.defaultSort;
    }

    _.forEach(props.data, (entity, index) => {
        const actions = entity.actions && entity.actions.length ? (
            <Td key="actions" label="Actions" column="actions">
                <div className={`${props.name}-actions`}>
                    <ActionsMenu actions={entity.actions} />
                </div>
            </Td>
        ) : null;

        rows.push(
            <Tr key={entity.id || index} data-id={entity.id} onClick={props.onRowClick}>
                {_.map(props.columns, (column) => (<Td key={column.key} label={column.label} column={column.key}>
                    {
                        (column.formatter
                        ? column.formatter(entity[column.key], entity, column.key)
                        : entity[column.key]) || ' '
                    }
                </Td>)
                )}
                {props.showActions ? actions : null}
            </Tr>
        );
    });

    return (
        <div id={props.id} className={className}>
            <Table {...tableProps}>{rows}</Table>
        </div>
    );
}

List.defaultProps = {
    id: '',
    className: '',
    itemsPerPage: 10,
    pageButtonLimit: 5,
    showActions: true,
    onRowClick: () => {},
    noDataText: 'No data found',
    rowHighlight: true,
    sortable: true,
    data: [],
};
List.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number,
    pageButtonLimit: PropTypes.number,
    showActions: PropTypes.bool,
    sortable: PropTypes.any,
    defaultSort: PropTypes.any,
    noDataText: PropTypes.string,
    rowHighlight: PropTypes.bool,
    responsive: PropTypes.bool,
    intl: PropTypes.object,
};

export default injectIntl(List);
