/*
 * SideNav Messages
 *
 * This contains all the text for the SideNav component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'app.SideNav.header',
        defaultMessage: 'This is the SideNav component !',
    },
    navItems: {
        admin: {
            id: 'app.SideNav.navItems.admin',
            defaultMessage: 'Admin',
        },
        myvendor: {
            id: 'app.SideNav.navItems.myvendor',
            defaultMessage: 'My Vendor',
        },
        vendors: {
            id: 'app.SideNav.navItems.vendors',
            defaultMessage: 'Vendors',
        },
        dossiers: {
            id: 'app.SideNav.navItems.dossiers',
            defaultMessage: 'Dossiers',
        },
        users: {
            id: 'app.SideNav.navItems.users',
            defaultMessage: 'Users',
        },
        user: {
            id: 'app.SideNav.navItems.profile',
            defaultMessage: 'Profiel',
        },
        dashboard: {
            id: 'app.SideNav.navItems.dashboard',
            defaultMessage: 'Dashboard',
        },
        contracts: {
            id: 'app.SideNav.navItems.contracts',
            defaultMessage: 'Contracten',
        },
        contacts: {
            id: 'app.SideNav.navItems.contacts',
            defaultMessage: 'Adresboek',
        },
        downloads: {
            id: 'app.SideNav.navItems.downloads',
            defaultMessage: 'Downloads',
        },
        support: {
            id: 'app.SideNav.navItems.support',
            defaultMessage: 'Support',
        },
        archive: {
            id: 'app.SideNav.navItems.archive',
            defaultMessage: 'Archive',
        },
        news: {
            id: 'app.SideNav.navItems.news',
            defaultMessage: 'News',
        },
    },
    linkMessage: {
        id: 'app.SideNav.linkMessage',
        defaultMessages: 'Mijn profiel',
    },
});
