import Validator from '../Validator';

const options = {
    rules: [],
    required: true,
};

// Rule to require @
options.rules.push({
    name: 'require@',
    pattern: new RegExp('@'),
    error: 'Email needs to include an \'@\' character.',
});

export default new Validator(options);
