import { API_URL } from 'components/App/constants';

export const ATLANCE_ADMIN_ROLE = 'SUPERADMIN';
export const ATLANCE_MANAGER_ROLE = 'SUPERMANAGER';
export const ATLANCE_SALES_ROLE = 'SUPERSALES';
export const VENDOR_ADMIN_ROLE = 'ADMIN';
export const VENDOR_MANAGER_ROLE = 'MANAGER';
export const VENDOR_SALES_ROLE = 'SALES';

export const ATLANCE_ADMIN_ROLES = [
    ATLANCE_ADMIN_ROLE,
];
export const ATLANCE_CLIENT_ROLES = [
    ATLANCE_MANAGER_ROLE,
    ATLANCE_SALES_ROLE,
];
export const VENDOR_ADMIN_ROLES = [
    VENDOR_ADMIN_ROLE,
];
export const VENDOR_CLIENT_ROLES = [
    VENDOR_MANAGER_ROLE,
    VENDOR_SALES_ROLE,
];
export const ADMIN_ROLES = [
    ...ATLANCE_ADMIN_ROLES,
    ...VENDOR_ADMIN_ROLES,
];
export const USER_ROLES = [
    ...ATLANCE_CLIENT_ROLES,
    ...VENDOR_CLIENT_ROLES,
];
export const ATLANCE_ROLES = [
    ...ATLANCE_ADMIN_ROLES,
    ...ATLANCE_CLIENT_ROLES,
];
export const VENDOR_ROLES = [
    ...VENDOR_ADMIN_ROLES,
    ...VENDOR_CLIENT_ROLES,
];
export const ROLES = [
    ...ATLANCE_ROLES,
    ...VENDOR_ROLES,
];

export const USERS_URL = `${API_URL}users/`;

// User action hooks
export const LOGOUT = 'User/logout';
export const LOGIN = 'User/login';
export const EDIT_PROFILE = 'User/editProfile';

// Users action hooks
export const INIT_USERS = 'User/initUsers';
export const NEW_USER = 'User/newUser';
export const EDIT_USER = 'User/editUser';
export const REMOVE_USER = 'User/removeUser';
