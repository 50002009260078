import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Chart as GoogleChart } from 'react-google-charts';
import appMessages from 'components/App/messages';

import '../styles/Chart.scss';

export class Chart extends Component {
    static defaultProps = {
        className: '',
    }
    static propTypes = {
        type: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.node,
        className: PropTypes.string,
        data: PropTypes.array,
        children: PropTypes.node,
        options: PropTypes.object,
        overlay: PropTypes.node,
        chartEvents: PropTypes.array,
        height: PropTypes.string,
        width: PropTypes.string,
        graphId: PropTypes.string,
        legendToggle: PropTypes.bool,
    }
    hasData() {
        const { data, children } = this.props;
        return (data && data.length > 1) || children;
    }
    render() {
        return (
            <div id={this.props.id} className={`xs2-chart ${this.props.className}`}>
                <h2 className="xs2-chart-title">
                    {this.props.title}
                </h2>
                {this.hasData()
                    ? <div className="xs2-chart-content">
                        {this.props.data
                            ? <GoogleChart
                                chartType={this.props.type}
                                data={this.props.data}
                                options={this.props.options}
                                graph_id={this.props.graphId}
                                height={this.props.height}
                                width={this.props.width}
                                legend_toggle={this.props.legendToggle}
                                chartEvents={this.props.chartEvents}
                            />
                            : this.props.children}
                        {this.props.overlay &&
                            <div className="xs2-chart-overlay">
                                {this.props.overlay}
                            </div>}
                    </div>
                    : <div className="xs2-chart-content xs2-chart-no-data">
                        <FormattedMessage {...appMessages.noData} />
                    </div>}
            </div>
        );
    }
}

export default Chart;
