import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import FormGroup from './FormGroup';
import { removeField } from '../actions';
import { mapFormIdToChildProps, mapFieldGroupToChildProps } from '../utils';
import { FormattedMessage } from 'react-intl';

class FieldGroup extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            data: [],
        };
        vm.add = vm.add.bind(vm);
        vm.remove = vm.remove.bind(vm);
    }
    add(event) {
        const vm = this;
        const target = event.currentTarget.previousSibling;
        const index = +target.getAttribute('data-index');

        event.preventDefault();

        vm.props.add(index, target);
    }
    remove(event) {
        const vm = this;
        const { dispatch } = vm.props;
        const target = event.currentTarget.parentNode.parentNode;
        const index = +target.getAttribute('data-index');

        event.preventDefault();

        dispatch(removeField(vm.props.formId, null, `${vm.props.name}[${index}]`));
        vm.props.remove(index, target);
    }
    mapFieldGroups(children, data) {
        const vm = this;
        const fieldGroupId = vm.props.id;
        const fieldGroupClasses = `field-group ${vm.props.className}`;
        const form = vm.props.forms[vm.props.formId];
        const fieldGroups = [];
        let mappedChildren;

        if (vm.props.data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                mappedChildren = mapFormIdToChildProps(children, vm.props.formId);
                mappedChildren = mapFieldGroupToChildProps(mappedChildren, vm.props.name, i);
                mappedChildren = React.Children.map(mappedChildren, (child) => {
                    let mappedChild;
                    if (child.type === FormGroup) {
                        mappedChild = React.cloneElement(child, {
                            children: React.Children.map(child.props.children, (formGroupChild) => {
                                const field = form.fields[`${vm.props.name}[${i}].${formGroupChild.props.name}`];
                                const defaultValue = field && field.value !== '' ? field.value : item[formGroupChild.props.name];
                                return React.cloneElement(formGroupChild, { defaultValue });
                            }),
                        });
                    } else {
                        const field = form.fields[`${vm.props.name}[${i}].${child.props.name}`];
                        const defaultValue = field && field.value !== '' ? field.value : item[child.props.name];
                        mappedChild = React.cloneElement(child, { defaultValue });
                    }

                    return mappedChild;
                });

                fieldGroups.push(
                    <div key={i} id={fieldGroupId} className={fieldGroupClasses} data-index={i}>
                        {mappedChildren}
                        <div className="field-group-actions">
                            <button type="button" className="field-group-remove" onClick={vm.remove}><i className="fa fa-remove"></i></button>
                        </div>
                    </div>
                );
            }
        } else {
            mappedChildren = mapFormIdToChildProps(children, vm.props.formId);
            mappedChildren = mapFieldGroupToChildProps(mappedChildren, vm.props.name, 0);
            fieldGroups.push(
                <div key={0} id={vm.props.id} className={fieldGroupClasses} data-index={0}>
                    {mappedChildren}
                    <div className="field-group-actions">
                        <button type="button" className="field-group-remove" onClick={vm.remove}><i className="fa fa-remove"></i></button>
                    </div>
                </div>
            );
        }

        return fieldGroups;
    }
    render() {
        const vm = this;
        const { children, data } = vm.props;

        return (
            <div className={`field-group-container ${vm.props.className}`}>
                {vm.mapFieldGroups(children, data)}
                <button type="button" className="field-group-add" onClick={vm.add}><i className="fa fa-plus"></i> <FormattedMessage id="app.addAnother" defaultMessage="Add another" /></button>
            </div>
        );
    }
}

FieldGroup.defaultProps = {
    id: '',
    className: '',
    data: [],
};

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldGroup);
