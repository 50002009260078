import PropTypes from 'prop-types';
import React from 'react';
import DataGrid from 'react-data-grid';
import { Menu } from 'react-data-grid-addons';

function TableContextMenu(props) {
    const { ContextMenu, MenuItem, SubMenu } = Menu;

    return (
        <ContextMenu>
            <SubMenu title="Column">
                <MenuItem data={{ rowIndex: props.rowIdx, index: props.idx }} onClick={props.onColumnDelete}>Delete</MenuItem>
                <MenuItem data={{ rowIndex: props.rowIdx, index: props.idx }} onClick={props.onColumnAdd}>Add</MenuItem>
            </SubMenu>
            <SubMenu title="Row">
                <MenuItem data={{ rowIndex: props.rowIdx }} onClick={props.onRowDelete}>Delete</MenuItem>
                <MenuItem data={{ rowIndex: props.rowIdx }} onClick={props.onRowAdd}>Add</MenuItem>
            </SubMenu>
        </ContextMenu>
    );
}
TableContextMenu.propTypes = {
    rowIdx: PropTypes.number,
    idx: PropTypes.number,
    onColumnDelete: PropTypes.func,
    onColumnAdd: PropTypes.func,
    onRowDelete: PropTypes.func,
    onRowAdd: PropTypes.func,
};

class EditableHeaderRenderer extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.onChange = vm.onChange.bind(vm);
    }
    onChange(event) {
        const vm = this;
        vm.props.handleUpdate(vm.props.index, event.target.value);
    }
    render() {
        const vm = this;
        const { column } = vm.props;

        return (
            <input
                type="text"
                className="widget-HeaderCell__value"
                value={column.name}
                onChange={vm.onChange}
            />
        );
    }
}
EditableHeaderRenderer.propTypes = {
    column: PropTypes.object,
    handleUpdate: PropTypes.func,
};

class FloatFormatter extends React.Component { // eslint-disable-line
    shouldComponentUpdate(nextProps) {
        const vm = this;
        return nextProps.value !== vm.props.value;
    }
    render() {
        const vm = this;
        const value = vm.props.value ? parseFloat(vm.props.value).toFixed(3) : '';
        const title = isNaN(value) ? 'Please enter a number' : value;
        return <div title={title}>{value}</div>;
    }
}
FloatFormatter.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

class CalculationTableForm extends React.Component { // eslint-disable-line
    constructor(props) {
        super(props);
        const vm = this;
        vm.rowGetter = vm.rowGetter.bind(vm);
    }
    rowGetter(i) {
        const vm = this;
        return vm.props.rows[i];
    }
    render() {
        const vm = this;
        const { props } = vm;
        const columns = props.columns.map((column, index) => {
            if (!column.lock) {
                column.formatter = FloatFormatter;
                column.headerRenderer = <EditableHeaderRenderer handleUpdate={props.onHeaderUpdate} index={index} />;
            }
            return column;
        });

        return (
            <DataGrid
                enableCellSelect
                columns={columns}
                rowGetter={vm.rowGetter}
                rowsCount={props.rows.length}
                onGridRowsUpdated={props.onGridRowsUpdated}
                minHeight={250}
                contextMenu={
                    <TableContextMenu
                        onRowDelete={props.onRowDelete}
                        onRowAdd={props.onRowAdd}
                        onColumnDelete={props.onColumnDelete}
                        onColumnAdd={props.onColumnAdd}
                    />
                }
            />
        );
    }
}

CalculationTableForm.defaultProps = {
    rows: [],
    columns: [],
};

CalculationTableForm.propTypes = {
    // columns: React.PropTypes.array,
    // rows: React.PropTypes.array,
    // onHeaderUpdate: React.PropTypes.func,
    // onGridRowsUpdate: React.PropTypes.func,
    // onRowDelete: React.PropTypes.func,
    // onRowAdd: React.PropTypes.func,
    // onColumnDelete: React.PropTypes.func,
    // onColumnAdd: React.PropTypes.func,
};

export default CalculationTableForm;
