import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tabs, { Pane } from 'common/Tabs';
import appMessages from 'components/App/messages';
import { Form, FormGroup, Autocomplete, Input } from 'common/Form';
import { Menu, MenuTrigger, MenuContent } from 'common/Util';
import CalculationTableForm from './CalculationTableForm';
import { VENDORS_URL } from '../constants';
import messages from '../messages';
import {
    API_URL,
    DEFAULT_LOCALE,
    EQUIPMENT_TYPE_BOTH,
    EQUIPMENT_TYPE_HARDWARE,
    EQUIPMENT_TYPE_SOFTWARE,
} from 'components/App/constants';

import '../styles/CalculationTableForm.scss';

const DEFAULT_TABLE = {
    durations: [36, 48, 60, 72],
    limits: [5000, 10001, 15001, 25001, 50001, 125001],
    values: [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    residuals: [],
};

class CalculationTablesForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        intl: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.tagOptions = [
            { value: 'STANDARD', label: intl.formatMessage(messages.contractTags.STANDARD) },
            { value: 'ATRADIUS', label: intl.formatMessage(messages.contractTags.ATRADIUS) },
            { value: 'SCHOOL', label: intl.formatMessage(messages.contractTags.SCHOOL) },
            { value: 'MAINTENANCE', label: intl.formatMessage(messages.contractTags.MAINTENANCE) },
            { value: 'INCREASED_REST_VALUE', label: intl.formatMessage(messages.contractTags.INCREASED_REST_VALUE) },
            { value: 'BRIDGING', label: intl.formatMessage(messages.contractTags.BRIDGING) },
            { value: 'SOFTWARESERVICES', label: intl.formatMessage(messages.contractTags.SOFTWARESERVICES) },
            { value: 'EXCEPTION', label: intl.formatMessage(messages.contractTags.EXCEPTION) },
        ];
        vm.typeOptions = [
            { value: 'LEASE', label: intl.formatMessage(messages.contractTypes.LEASE) },
            { value: 'RENT', label: intl.formatMessage(messages.contractTypes.RENT) },
            { value: 'ALLIN', label: intl.formatMessage(messages.contractTypes.ALLIN) },
            { value: 'RENT_PLUS_MAINTENANCE', label: intl.formatMessage(messages.contractTypes.RENT_PLUS_MAINTENANCE) },
        ];
        vm.durationTypeOptions = [
            { value: 'MONTH', label: intl.formatMessage(appMessages.month) },
            { value: 'QUARTER', label: intl.formatMessage(appMessages.quarter) },
            { value: 'SEMESTER', label: intl.formatMessage(appMessages.semester) },
            { value: 'YEAR', label: intl.formatMessage(appMessages.year) },
        ];
        vm.tenTwelveOptions = [
            { value: 'YES', label: intl.formatMessage(appMessages.yes) },
            { value: 'NO', label: intl.formatMessage(appMessages.no) },
        ];
        vm.zeroAmountOptions = [
            { value: 'YES', label: intl.formatMessage(appMessages.yes) },
            { value: 'NO', label: intl.formatMessage(appMessages.no) },
        ];
        vm.equipmentTypeOptions = [
            { value: EQUIPMENT_TYPE_HARDWARE, label: intl.formatMessage(appMessages.hardware) },
            { value: EQUIPMENT_TYPE_SOFTWARE, label: intl.formatMessage(appMessages.software) },
            { value: EQUIPMENT_TYPE_BOTH, label: intl.formatMessage(appMessages.both) },
        ];
        vm.paneHeader = intl.formatMessage(appMessages.paneHeader);
        vm.state = {};
        vm.state.data = [{
            tmp_id: 'new',
            tag: 'STANDARD',
            type: 'RENT',
            duration_type: 'MONTH',
            ten_twelve_type: 'NO',
            zero_amount_type: 'NO',
            hardware_data: _.cloneDeep(DEFAULT_TABLE),
            software_data: _.cloneDeep(DEFAULT_TABLE),
        }];
        vm.state.count = 0;
        vm.state.tables = mapTablesData(vm.state.data);
        vm.state.deleted = {};

        vm.addTable = vm.addTable.bind(vm);
        vm.deleteTable = vm.deleteTable.bind(vm);
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.get(`${VENDORS_URL}${vm.props.vendorId}/calculationtables/`, { headers }).then((response) => {
            if (response.data.result.length > 0) {
                vm.state.data = response.data.result.map((table) => {
                    if (!table.software_data) { table.software_data = _.cloneDeep(DEFAULT_TABLE); }
                    if (!table.hardware_data) { table.hardware_data = _.cloneDeep(DEFAULT_TABLE); }
                    if (table.software_data.residuals == null) { table.software_data.residuals = []; }
                    if (table.hardware_data.residuals == null) { table.hardware_data.residuals = []; }
                    return table;
                });
            }
            vm.state.count = response.data.count;
            vm.state.tables = mapTablesData(vm.state.data);
            vm.setState(vm.state);
        });
    }
    onSubmit() {
        const vm = this;
        const { user, toast, vendors, forms, vendorId } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        const vendor = vendors.find((i) => i.id === +vendorId) || {};
        const requests = [];

        event.preventDefault();

        _.forEach(vm.state.data, (table) => {
            const form = forms[`calculation_table_${table.id || table.tmp_id}`];

            if (form) {
                const { tag, type, duration_type, ten_twelve_type, zero_amount_type, equipment_type, name } = form.fields;
                table.tag = tag.value;
                table.type = type.value;
                table.ten_twelve_type = ten_twelve_type.value;
                table.duration_type = duration_type.value;
                table.zero_amount_type = zero_amount_type.value;
                table.equipment_type = equipment_type.value;
                table.name = name.value;

                if (table.id) {
                    requests.push(axios.patch(`${API_URL}calculationtables/${table.id}/`, table, { headers }));
                } else {
                    requests.push(axios.post(`${VENDORS_URL}${vendorId}/calculationtables/`, table, { headers }));
                }
            }
        });

        _.forEach(_.keys(vm.state.deleted), (key) => {
            requests.push(axios.delete(`${API_URL}calculationtables/${key}/`, { headers }));
        });

        axios.all(requests).then(() => {
            vm.context.router.push(`/vendors/${vendorId}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedCalcTables} values={{ ...vendor }} tagName="p" />);
        });
    }
    onRowDelete(tableId, event, { rowIndex }) {
        const vm = this;
        const newState = update(vm.state, {
            data: {
                [tableId]: {
                    hardware_data: {
                        limits: { $splice: [[rowIndex, 1]] },
                        values: { $splice: [[rowIndex, 1]] },
                    },
                    software_data: {
                        limits: { $splice: [[rowIndex, 1]] },
                        values: { $splice: [[rowIndex, 1]] },
                    },
                },
            },
        });
        newState.tables = mapTablesData(newState.data);
        vm.setState(newState);
    }
    onColumnDelete(tableId, event, { index }) {
        const vm = this;
        const newState = update(vm.state, {
            data: {
                [tableId]: {
                    hardware_data: {
                        durations: { $splice: [[index - 1, 1]] },
                    },
                    software_data: {
                        durations: { $splice: [[index - 1, 1]] },
                    },
                },
            },
        });
        newState.data[tableId].hardware_data.values.map((value) => {
            value.splice(index - 1, 1);
            return value;
        });
        newState.data[tableId].software_data.values.map((value) => {
            value.splice(index - 1, 1);
            return value;
        });
        newState.data[tableId].hardware_data.residuals.splice(index - 1, 1);
        newState.data[tableId].software_data.residuals.splice(index - 1, 1);
        newState.tables = mapTablesData(newState.data);
        vm.setState(newState);
    }
    addTable() {
        const vm = this;
        const newState = update(vm.state, {
            data: {
                $push: [{
                    tmp_id: `new-${vm.state.count}`,
                    type: 'RENT',
                    duration_type: 'MONTH',
                    ten_twelve_type: 'NO',
                    zero_amount_type: 'NO',
                    hardware_data: _.cloneDeep(DEFAULT_TABLE),
                    software_data: _.cloneDeep(DEFAULT_TABLE),
                }],
            },
        });
        newState.tables = mapTablesData(newState.data);
        newState.count++;
        vm.setState(newState);
    }
    deleteTable(index) {
        const vm = this;

        return () => {
            const newState = update(vm.state, {
                data: { $splice: [[index, 1]] },
            });
            newState.deleted[vm.state.data[index].id] = true;
            newState.tables = mapTablesData(newState.data);
            vm.setState(newState);
        };
    }
    insertRow(tableId) {
        const vm = this;
        const limit = +window.prompt('What limit?');
        const limits = vm.state.data[tableId].hardware_data.limits;
        const hwDurations = vm.state.data[tableId].hardware_data.durations;
        const swDurations = vm.state.data[tableId].software_data.durations;
        if (limit) {
            const index = _.sortedIndex(limits, limit);
            const newState = update(vm.state, {
                data: {
                    [tableId]: {
                        hardware_data: {
                            limits: { $splice: [[index, 0, limit]] },
                            values: { $splice: [[index, 0, Array(hwDurations.length).fill(0)]] },
                        },
                        software_data: {
                            limits: { $splice: [[index, 0, limit]] },
                            values: { $splice: [[index, 0, Array(swDurations.length).fill(0)]] },
                        },
                    },
                },
            });
            newState.tables = mapTablesData(newState.data);
            vm.setState(newState);
        }
    }
    insertColumn(tableId) {
        const vm = this;
        const duration = +window.prompt('What duration?');
        const durations = vm.state.data[tableId].hardware_data.durations;
        if (duration) {
            const index = _.sortedIndex(durations, duration);
            const newState = update(vm.state, {
                data: {
                    [tableId]: {
                        hardware_data: {
                            durations: { $splice: [[index, 0, +duration]] },
                        },
                        software_data: {
                            durations: { $splice: [[index, 0, +duration]] },
                        },
                    },
                },
            });
            newState.data[tableId].hardware_data.values.map((value) => {
                value.splice(index, 0, 0);
                return value;
            });
            newState.data[tableId].software_data.values.map((value) => {
                value.splice(index, 0, 0);
                return value;
            });
            newState.tables = mapTablesData(newState.data);
            vm.setState(newState);
        }
    }
    handleHeaderUpdate(tableId, tableType, columnIndex, value) {
        const vm = this;
        const newState = update(vm.state, {
            data: {
                [tableId]: {
                    hardware_data: {
                        durations: { $splice: [[columnIndex - 1, 1, +value]] },
                    },
                    software_data: {
                        durations: { $splice: [[columnIndex - 1, 1, +value]] },
                    },
                },
            },
        });
        vm.state.tables = mapTablesData(newState.data);
        vm.setState(newState);
    }
    handleGridRowsUpdated(tableId, tableType, { fromRow, toRow, updated }) {
        const vm = this;
        const table = vm.state.tables[tableId][tableType];

        for (let rowIndex = fromRow; rowIndex <= toRow; rowIndex++) {
            Object.keys(updated).forEach((column) => {
                const columnIndex = table.columns.findIndex((i) => i.name === +column);
                let newState = { ...vm.state };

                if (column !== 'limits') {
                    if (rowIndex === (table.rows.length - 1)) {
                        newState = update(newState, {
                            data: {
                                [tableId]: {
                                    [`${tableType}_data`]: {
                                        residuals: {
                                            [columnIndex - 1]: {
                                                $set: updated[column],
                                            },
                                        },
                                    },
                                },
                            },
                        });
                    } else if (newState.data[tableId][`${tableType}_data`].values[rowIndex]) {
                        newState = update(newState, {
                            data: {
                                [tableId]: {
                                    [`${tableType}_data`]: {
                                        values: {
                                            [rowIndex]: {
                                                [columnIndex - 1]: {
                                                    $set: updated[column],
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        });
                    }
                }
                if (column === 'limits') {
                    newState = update(newState, {
                        data: {
                            [tableId]: {
                                [`${tableType}_data`]: {
                                    limits: {
                                        [rowIndex]: {
                                            $set: updated[column],
                                        },
                                    },
                                },
                            },
                        },
                    });
                }

                newState.tables = mapTablesData(newState.data);
                vm.setState(newState);
            });
        }
    }
    render() {
        const vm = this;
        const {
            tagOptions,
            typeOptions,
            durationTypeOptions,
            tenTwelveOptions,
            zeroAmountOptions,
            equipmentTypeOptions,
        } = vm;
        const tables = vm.state.tables.map((table, index) => {
            const form = vm.props.forms[`calculation_table_${table.id || table.tmp_id}`];
            const tag = form && form.fields.tag.value ? form.fields.tag.value : table.tag;
            const type = form && form.fields.type.value ? form.fields.type.value : table.type;
            const durationType = form && form.fields.duration_type.value ? form.fields.duration_type.value : table.duration_type;
            const tenTwelveType = form && form.fields.ten_twelve_type.value ? form.fields.ten_twelve_type.value : table.ten_twelve_type;
            const zeroAmountType = form && form.fields.zero_amount_type.value ? form.fields.zero_amount_type.value : table.zero_amount_type;
            const equipmentType = form && form.fields.equipment_type.value ? form.fields.equipment_type.value : table.equipment_type;
            const showBothTabs = equipmentType === EQUIPMENT_TYPE_BOTH;
            const showHardwareTab = equipmentType === EQUIPMENT_TYPE_HARDWARE;
            const showSoftwareTab = equipmentType === EQUIPMENT_TYPE_SOFTWARE;
            const name = form && form.fields.name.value ? form.fields.name.value : table.name;
            const paneHeader = table.name ? table.name : vm.paneHeader;

            return (
                <Pane
                    key={table.id || table.tmp_id}
                    label={
                        <Menu hoverTrigger scrollMask={false}>
                            <MenuTrigger>
                                {paneHeader}
                            </MenuTrigger>
                            <MenuContent>
                                <ul>
                                    <li><FormattedMessage {...appMessages.tag} tagName="strong" />: <FormattedMessage {...messages.contractTags[tag]} /></li>
                                    <li><FormattedMessage {...appMessages.contractType} tagName="strong" />: <FormattedMessage {...messages.contractTypes[type]} /></li>
                                    <li><FormattedMessage {...appMessages.periodicity} tagName="strong" />: <FormattedMessage {...appMessages[durationType]} /></li>
                                    <li><FormattedMessage {...appMessages.tenTwelveType} tagName="strong" />: <FormattedMessage {...appMessages[tenTwelveType]} /></li>
                                    <li><FormattedMessage {...appMessages.zeroAmountType} tagName="strong" />: <FormattedMessage {...appMessages[zeroAmountType]} /></li>
                                </ul>
                            </MenuContent>
                        </Menu>
                    }
                >
                    {vm.state.tables.length > 1 ? <button onClick={vm.deleteTable(index)} className="remove-table"><i className="icon-trash"></i></button> : null}
                    {table.id || table.tmp_id ?
                        <Form
                            id={`calculation_table_${table.id || table.tmp_id}`}
                            theme="material"
                            persist
                        >
                            <FormGroup className="input-group contract-options-group">
                                <Input type="text" name="name" className="input-medium" label={appMessages.name} defaultValue={name} required />
                                <Autocomplete name="tag" className="input-medium" label={appMessages.tag} options={tagOptions} defaultValue={tag} required />
                                <Autocomplete name="type" className="input-medium" label={appMessages.contractType} options={typeOptions} defaultValue={type} required />
                                <Autocomplete name="duration_type" className="input-medium" label={appMessages.periodicity} options={durationTypeOptions} defaultValue={durationType} required />
                                <Autocomplete name="ten_twelve_type" className="input-medium" label={appMessages.tenTwelveType} options={tenTwelveOptions} defaultValue={tenTwelveType} required />
                                <Autocomplete name="zero_amount_type" className="input-medium" label={appMessages.zeroAmountType} options={zeroAmountOptions} defaultValue={zeroAmountType} required />
                                <Autocomplete name="equipment_type" className="input-medium" label={appMessages.equipmentType} options={equipmentTypeOptions} defaultValue={equipmentType} required />
                            </FormGroup>
                        </Form>
                    : null}

                    {equipmentType && (
                        <Tabs selected={showSoftwareTab ? 1 : 0}>
                            {(showBothTabs || showHardwareTab) && (
                                <Pane key={0} label={<FormattedMessage {...appMessages.hardware} />}>
                                    <CalculationTableForm
                                        id={`${index}-hardware`}
                                        columns={table.hardware.columns}
                                        rows={table.hardware.rows}
                                        onHeaderUpdate={vm.handleHeaderUpdate.bind(vm, index)}
                                        onGridRowsUpdated={vm.handleGridRowsUpdated.bind(vm, index, 'hardware')}
                                        onRowDelete={vm.onRowDelete.bind(vm, index)}
                                        onRowAdd={vm.insertRow.bind(vm, index)}
                                        onColumnDelete={vm.onColumnDelete.bind(vm, index)}
                                        onColumnAdd={vm.insertColumn.bind(vm, index)}
                                    />
                                </Pane>
                            )}
                            {(showBothTabs || showSoftwareTab) && (
                                <Pane key={1} label={<FormattedMessage {...appMessages.software} />}>
                                    <CalculationTableForm
                                        id={`${index}-software`}
                                        columns={table.software.columns}
                                        rows={table.software.rows}
                                        onHeaderUpdate={vm.handleHeaderUpdate.bind(vm, index)}
                                        onGridRowsUpdated={vm.handleGridRowsUpdated.bind(vm, index, 'software')}
                                        onRowDelete={vm.onRowDelete.bind(vm, index)}
                                        onRowAdd={vm.insertRow.bind(vm, index)}
                                        onColumnDelete={vm.onColumnDelete.bind(vm, index)}
                                        onColumnAdd={vm.insertColumn.bind(vm, index)}
                                    />
                                </Pane>
                            )}
                        </Tabs>
                    )}
                </Pane>
            );
        });

        return (
            <div id="CalculationTableForm" className="calculation-tables-form">
                <h1><FormattedMessage {...messages.calculationTables} /></h1>
                <ReactCSSTransitionGroup
                    transitionName="vertical-slide"
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                >
                    <div className="table-container">
                        <Tabs selected={0}>
                            {tables}
                        </Tabs>
                    </div>
                </ReactCSSTransitionGroup>
                <button onClick={vm.addTable} className="add-table"><i className="fa fa-plus"></i></button>
                <button onClick={vm.onSubmit} className="button button-default"><FormattedMessage {...appMessages.save} /></button>
            </div>
        );
    }
}

function mapTablesData(data) {
    return data.map((table, index) => {
        const mappedTable = {
            tag: table.tag || 'STANDARD',
            type: table.type,
            duration_type: table.duration_type,
            ten_twelve_type: table.ten_twelve_type,
            zero_amount_type: table.zero_amount_type,
            equipment_type: table.equipment_type,
            software: mapTable(table.software_data),
            hardware: mapTable(table.hardware_data),
            name: table.name,
            index,
        };

        if (table.id) {
            mappedTable.id = table.id;
        }
        if (table.tmp_id) {
            mappedTable.tmp_id = table.tmp_id;
        }

        return mappedTable;
    });

    function mapTable(table) {
        return {
            columns: mapColumns(table),
            rows: mapRows(table),
        };
    }
    function mapColumns(table) {
        const columns = table.durations.map((duration) => ({
            key: String(duration),
            name: duration,
            editable: true,
        }));

        columns.unshift({
            key: 'limits',
            name: 'Limits',
            lock: true,
            editable: true,
        });

        return columns;
    }
    function mapRows(table) {
        const rows = table.limits.map((limit, rowIndex) => {
            const row = { id: rowIndex };
            const values = table.values[rowIndex] || [];

            table.durations.forEach((duration, index) => {
                const defaultValue = table.limits.length === (rowIndex + 1) ? '' : '0';
                row[table.durations[index]] = values[index] == null ? defaultValue : String(values[index]);
            });
            row.limits = table.limits[rowIndex];

            return row;
        });
        const residuals = {
            limits: 'Residuals',
        };

        if (table.residuals) {
            table.durations.forEach((duration, index) => {
                residuals[duration] = table.residuals[index] == null ? '' : String(table.residuals[index]);
            });
        }

        rows.push(residuals);

        return rows;
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('forms').toJS(),
    (state) => state.get('vendors'),
    (user, toast, forms, vendors) => ({ user, toast, forms, vendors })
);

export default connect(mapStateToProps, null)(injectIntl(CalculationTablesForm));
