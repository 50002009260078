/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

import languageProviderReducer from 'components/LanguageProvider/reducer';
import { userReducer, usersReducer } from 'components/Users/reducers';
import formsReducer from 'common/Form/reducers';
import dashboardReducer from 'components/Dashboard/reducers';
import vendorsReducer from 'components/Vendor/reducers';
import dossiersReducer from 'components/Dossiers/reducers';
import downloadsReducer from 'components/Downloads/reducers';
import contactsReducer from 'components/Contacts/reducers';
import newsReducer from 'components/News/reducers';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
    locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
    switch (action.type) {
        /* istanbul ignore next */
        case LOCATION_CHANGE:
            return state.merge({
                locationBeforeTransitions: action.payload,
            });
        default:
            return state;
    }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
    return combineReducers({
        route: routeReducer,
        language: languageProviderReducer,
        user: userReducer,
        users: usersReducer,
        forms: formsReducer,
        dashboard: dashboardReducer,
        vendors: vendorsReducer,
        dossiers: dossiersReducer,
        downloads: downloadsReducer,
        contacts: contactsReducer,
        news: newsReducer,
        ...asyncReducers,
    });
}
