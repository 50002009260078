import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { Link } from 'react-router';

import messages from '../messages';
import NewsList from '../partials/News';
import NewsForm from '../partials/NewsForm';
import { initNews, deleteNews } from '../actions';
import { NEWS_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';


const FORM_ACTIONS = ['new', 'edit', 'delete'];

class News extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;

        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.state = { showModal: false };
        vm.toggleModal = vm.toggleModal.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getNews();
        vm.handlePath();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.handlePath();
        }

        if (nextProps.dossiers || !_.isEqual(nextProps.dossiers, vm.props.dossiers)) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    getNews() {
        const vm = this;
        const { headers } = vm;
        const { dispatch } = vm.props;

        axios.get(NEWS_URL, { headers }).then((response) => {
            dispatch(initNews(response.data.result));
        });
    }
    handlePath() {
        const vm = this;
        const { headers } = vm;
        const { dispatch, toast } = vm.props;
        const { router } = vm.context;
        const pathArray = router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const action = pathArray[pathArray.length - 1];

        if (~FORM_ACTIONS.indexOf(action)) {
            const id = +router.params.id;

            if (action === 'new' || action === 'edit') {
                vm.toggleModal();
            } else if (action === 'delete' && id) {
                const title = vm.props.news.find((i) => +i.id === +id).title;
                const confirmed = confirm(`Are you sure you want to delete ${title}`);

                if (confirmed) {
                    axios.delete(`${NEWS_URL}${id}/`, { headers }).then(() => {
                        dispatch(deleteNews(id));
                        toast.success('Success!', <FormattedMessage {...messages.notifications.deletedNew} values={{ title }} tagName="p" />);
                    });
                }
                router.push('/news');
            }

            vm.state.showModal = true;
        } else {
            vm.state.showModal = false;
        }
    }
    toggleModal() {
        const vm = this;
        vm.state.showModal = !vm.state.showModal;
        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const { router } = vm.context;
        const referrer = router.getCurrentLocation().pathname;
        const pathArray = router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const action = pathArray[pathArray.length - 1];
        const modalOptions = {
            isOpen: vm.state.showModal,
            contentLabel: 'News form',
            className: 'modal-dashboard',
            overlayClassName: 'modal-overlay-dashboard',
            onRequestClose: vm.toggleModal,
            shouldCloseOnOverlayClick: true,
        };

        return (
            <div id="News">
                <div className="news-header layout-row">
                    <h1 className="flex"><FormattedMessage {...messages.news} /></h1>
                    <div className="news-controls flex-nogrow">
                        <Link
                            to={{ pathname: '/news/new', query: { referrer } }}
                            className="news-new button button-default"
                        >
                            <FormattedMessage {...messages.addNews} />
                        </Link>
                    </div>
                </div>
                <div className="news">
                    <NewsList />
                </div>
                <ReactModal {...modalOptions}>
                    <Link to="/news" className="modal-close" onClick={vm.toggleModal}><i className="fa fa-arrow-left"></i></Link>
                    <NewsForm action={action} />
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('news'),
    (state) => state.get('dashboard').get('toast'),
    (user, news, toast) => ({ user, news, toast })
);

export default connect(mapStateToProps)(News);
