import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import MenuTrigger from './MenuTrigger';
import MenuContent from './MenuContent';
import ScrollMask from '../ScrollMask';

import '../../styles/menu.scss';

class Menu extends React.Component {
    static childContextTypes = {
        menu: PropTypes.object,
    }
    static defaultProps = {
        className: '',
        closeOnSelect: true,
        scrollMask: true,
        onMouseLeave: () => {},
    }
    static propTypes = {
        // className: PropTypes.string,
        // closeOnSelect: PropTypes.bool,
        // scrollMask: PropTypes.bool,
        // onMouseLeave: PropTypes.func,
    }
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {
            open: false,
        };

        vm.toggle = vm.toggle.bind(vm);
        vm.open = vm.open.bind(vm);
        vm.close = vm.close.bind(vm);
        vm.onMouseLeave = vm.onMouseLeave.bind(vm);
    }

    componentDidMount() {
      window.addEventListener('xs2.scroll-mask.click', this.close);
    }

    componentWillUnmount() {
      window.removeEventListener('xs2.scroll-mask.click', this.close);
    }

    getChildContext() {
        const vm = this;

        return {
            menu: vm,
        };
    }
    onMouseLeave(event) {
        const vm = this;
        vm.close();
        vm.props.onMouseLeave(event, vm);
    }
    toggle() {
        const vm = this;

        if (vm.state.open) {
            vm.close();
        } else {
            vm.open();
        }
    }
    open() {
        const vm = this;
        vm.state.open = true;
        vm.setState(vm.state);
    }
    close() {
        const vm = this;
        vm.state.open = false;
        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const { children } = vm.props;
        const Trigger = children.find((child) => child.type === MenuTrigger);
        const Content = children.find((child) => child.type === MenuContent);
        const props = {
            className: `xs2-menu ${vm.props.className}`,
        };

        if (vm.props.hoverTrigger) {
            props.onMouseLeave = vm.onMouseLeave;
        }

        return (
            <div {...props}>
                {Trigger}
                <ReactCSSTransitionGroup
                    transitionName="xs2-menu"
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                    component="div"
                >
                    {vm.state.open ? Content : null}
                </ReactCSSTransitionGroup>
                {vm.props.scrollMask && vm.state.open ? <ScrollMask className="xs2-menu-scroll-mask" /> : null}
            </div>
        );
    }
}

export default Menu;
