import { defineMessages } from 'react-intl';

export default defineMessages({
    notifications: {
        uploadedFile: {
            id: 'app.Downloads.notifications.uploadedFile',
            defaultMessage: 'The file has been uploaded',
        },
        deletedFile: {
            id: 'app.Downloads.notifications.deletedFile',
            defaultMessage: '{fileName} has been deleted',
        },
    },
});
