import PropTypes from 'prop-types';
import React from 'react';

export function CountryFlag(props) {
    const src = require(`assets/img/flags/${props.lang}.png`); // eslint-disable-line global-require

    return (
        <div className="country-flag-container">
            <img className="country-flag" src={src} title={props.lang} alt={props.lang} />
        </div>
    );
}

CountryFlag.propTypes = {
    lang: PropTypes.string.isRequired,
};
