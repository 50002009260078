import PropTypes from 'prop-types';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import { Link } from 'react-router';

import Loader from 'components/App/partials/Loader';
import { ADMIN_ROLES } from 'components/Users/constants';
import { getActionFromLocation } from 'components/App/utils';
import appMessages from 'components/App/messages';
import List from 'components/App/partials/List';
import auth from 'components/Users/auth';
import FileUpload from '../components/FileUpload';
import { initDownloads, deleteDownload } from '../actions';
import { DOWNLOADS_URL } from '../constants';
import messages from '../messages';

const FORM_ACTIONS = ['new', 'delete'];

class Downloads extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.state = {
            columns: [
                { label: intl.formatMessage(appMessages.name), key: 'name' },
                { label: intl.formatMessage(appMessages.file), key: 'download_filename' },
                { label: intl.formatMessage(appMessages.description), key: 'description' },
                { label: '', key: 'actions' },
            ],
            downloads: props.downloads,
            form: null,
            showForm: false,
            headers: { Authorization: `Token ${props.user.token}` },
            loading: !props.downloads.length,
        };

        vm.onRowClick = vm.onRowClick.bind(vm);
        vm.toggleForm = vm.toggleForm.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getDownloads();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { user } = vm.props;

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.handleForm();
        }

        vm.state.downloads = _.map(nextProps.downloads, (download) => {
            const mappedDownload = { ...download };
            mappedDownload.actions = [];

            if (~ADMIN_ROLES.indexOf(user.role)) {
                mappedDownload.actions.push({
                    label: appMessages.delete,
                    url: `/downloads/${download.id}/delete`,
                    icon: 'trash',
                });
            }

            return mappedDownload;
        });

        if (nextProps.downloads.length || !_.isEqual(nextProps.downloads, vm.props.downloads)) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    onRowClick(event) {
        const vm = this;
        const download = vm.state.downloads.find((i) => +i.id === +event.currentTarget.dataset.id);
        window.open(download.download_file, '_blank');
    }
    getDownloads() {
        const vm = this;
        const { dispatch } = vm.props;
        const { headers } = vm.state;

        axios.get(`${DOWNLOADS_URL}`, { headers }).then((response) => {
            vm.setState({ loading: false }, () => {
                dispatch(initDownloads(response.data.result));
            });
        });
    }
    handleForm() {
        const vm = this;
        const { router } = vm.context;
        const { headers } = vm.state;
        const { dispatch, toast } = vm.props;
        const action = getActionFromLocation(router.getCurrentLocation());

        if (~FORM_ACTIONS.indexOf(action)) {
            const downloadId = router.params.id;

            if (action === 'new') {
                vm.state.action = action;
                vm.state.form = (<FileUpload />);
            } else if (action === 'delete') {
                const fileName = vm.props.downloads.find((i) => +i.id === +downloadId).name;
                vm.state.form = null;
                axios.delete(`${DOWNLOADS_URL}${router.params.id}/`, { headers }).then(() => {
                    dispatch(deleteDownload(router.params.id));
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.deletedFile} values={{ fileName }} tagName="p" />);
                });
            }

            if (vm.state.form) {
                vm.state.showForm = true;
            }
        } else {
            vm.state.showForm = false;
        }
    }
    toggleForm(action) {
        return () => {
            const vm = this;
            const { router } = vm.context;

            if (vm.state.showForm) {
                const referrer = router.getCurrentLocation().query.referrer;
                router.push(referrer || '/downloads');
            }

            vm.setState({ action, showForm: !vm.state.showForm });
        };
    }
    render() {
        const vm = this;
        const { router } = vm.context;
        const { user } = vm.props;
        const { columns, downloads } = vm.state;
        const referrer = router.getCurrentLocation().pathname;

        // if (!downloads.length) {
        //     downloads.push({ result_file: 'No files uploaded yet' });
        // }

        return (
            <div id="Downloads">
                <div className="downloads-header layout-row">
                    <h1 className="flex">
                        <FormattedMessage {...appMessages.downloads} />
                    </h1>
                    <div className="downloads-controls flex-nogrow layout-row layout-align-center-center">
                        {auth.userIsAtlance(user) ?
                            <Link to={{ pathname: '/downloads/new', query: { referrer } }} className="downloads-new button button-default">
                                <FormattedMessage {...appMessages.uploadFile} />
                            </Link>
                        : null}
                    </div>
                </div>
                <div className="downloads">
                    <Loader active={vm.state.loading}>
                        <List
                            name="downloads"
                            columns={columns}
                            data={downloads}
                            onRowClick={vm.onRowClick}
                        />
                    </Loader>
                </div>
                <ReactModal
                    isOpen={vm.state.showForm}
                    contentLabel="Upload file"
                    className="modal-dashboard file-upload-modal"
                    overlayClassName="modal-overlay-dashboard"
                    onRequestClose={vm.toggleForm(vm.state.action)}
                    shouldCloseOnOverlayClick
                >
                    <FileUpload />
                </ReactModal>
            </div>
        );
    }
}

Downloads.contextTypes = {
    router: PropTypes.object,
};
Downloads.propTypes = {
    downloads: PropTypes.array,
    user: PropTypes.object,
    intl: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('downloads').toJS(),
    (user, toast, downloads) => ({ user, toast, downloads })
);

export default connect(mapStateToProps, null)(injectIntl(Downloads));
