import { API_URL } from 'components/App/constants';

export const VENDORS_URL = `${API_URL}vendors/`;
export const MY_VENDOR_URL = `${API_URL}myvendor/`;

export const VENDORS_INIT_VENDORS = 'Vendors/initVendors';
export const VENDORS_NEW_VENDOR = 'Vendors/addVendor';
export const VENDORS_EDIT_VENDOR = 'Vendors/editVendor';
export const VENDORS_DELETE_VENDOR = 'Vendors/deleteVendor';
export const VENDORS_ADD_CONTRACT_OPTIONS = 'Vendors/addContractOptions';
export const VENDORS_REMOVE_CONTRACT_OPTIONS = 'Vendors/removeContractOptions';
export const VENDORS_ADD_EQUIPMENT_TYPE = 'Vendors/addEquipmentType';
export const VENDORS_REMOVE_EQUIPMENT_TYPE = 'Vendors/removeEquipmentType';
export const VENDORS_ADD_CLIENTS = 'Vendors/addClients';
export const VENDORS_REMOVE_CLIENTS = 'Vendors/removeClients';
