import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Callout } from 'common/Util';
import appMessages from 'components/App/messages';
import messages from '../messages';

class DossierStatusFilters extends PureComponent {
    static defaultProps = {
        onClick: _.noop,
    }
    static propTypes = {
        className: PropTypes.string,
        counters: PropTypes.object,
        onClick: PropTypes.func,
        intl: PropTypes.object,
    }
    getContainerClasses() {
        const classList = [
            'dossier-status-filters',
            'layout-row',
        ];

        if (this.props.className) {
            classList.push(this.props.className);
        }

        return classList.join(' ');
    }
    getLabelDescriptor(key) {
        return key === 'TOTAL' ? appMessages.total : messages.states[key];
    }
    render() {
        const { intl } = this.props;
        return (
            <div className={this.getContainerClasses()}>
                {_.map(this.props.counters, (count, key) =>
                    <Callout
                        key={key}
                        label={intl.formatMessage(this.getLabelDescriptor(key))}
                        count={count}
                        onClick={(event) => this.props.onClick(event, key)}
                        className="dossier-status-filter flex"
                    />
                )}
            </div>
        );
    }
}

export default injectIntl(DossierStatusFilters);
