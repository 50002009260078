import PropTypes from 'prop-types';
import React from 'react';


class Pane extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    }
    render() {
        const vm = this;
        return (
            <div className="pane">{vm.props.children}</div>
        );
    }
}


export default Pane;
