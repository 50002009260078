import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import ReactModal from 'react-modal';

import ActionsMenu from 'components/ActionsMenu';
import List from 'components/App/partials/List';
import { API_URL } from 'components/App/constants';
import appMessages from 'components/App/messages';
import auth from 'components/Users/auth';
import { VendorForm, CalculationTables, ContractOptionsForm } from '../partials';
import messages from '../messages';
import VendorClientsForm from '../partials/VendorClientsForm';
import { getVendorActions } from '../utils';
import { newVendor, editVendor } from '../actions';
import { VENDORS_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';


const FORM_ACTIONS = ['new', 'edit', 'options', 'calctables'];

class MyVendorContainer extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);

        const vm = this;
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.state = {
            vendor: { clients: [], equipment_types: [], contract_options: [] },
            actions: [],
            showModal: false,
            modalContent: null,
        };
        vm.contractColumns = [
            { label: 'Type', key: 'type' },
            { label: 'Ten twelve type', key: 'ten_twelve_type' },
            { label: 'Payment type', key: 'payment_type' },
        ];
        vm.clientColumns = [
            { label: 'Firmname', key: 'firmname' },
            { label: 'Street', key: 'street' },
            { label: 'Number', key: 'number' },
            { label: 'Postalcode', key: 'postalcode' },
            { label: 'Bus', key: 'bus' },
            { label: 'City', key: 'city' },
            { label: 'VAT', key: 'vat' },
            { label: 'IBAN', key: 'iban' },
            { label: 'SWIFT BIC', key: 'swift_bic' },
            { label: 'Contact name', key: 'name' },
            { label: 'Contact function', key: 'function' },
            { label: 'Contact email', key: 'mail_accounting' },
            { label: '', key: 'actions' },
        ];

        vm.toggleModal = vm.toggleModal.bind(vm);
        vm.onClientClick = vm.onClientClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.fetchData();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const action = vm.getCurrentAction(nextProps);
        if (!_.isEqual(nextProps.location, vm.props.location)) {
            if (~FORM_ACTIONS.indexOf(action)) {
                vm.toggleModal(action);
            } else {
                vm.state.showModal = false;
            }
        }
    }
    onClientClick(event) {
        const vm = this;
        const { router } = vm.context;
        const clientId = event.currentTarget.getAttribute('data-id');

        if (event.target.parentNode.tagName !== 'A') {
            router.push(`/vendors/my/clients/${clientId}/edit?referrer=/vendors/my`);
        }
    }
    getCurrentAction(props = this.props) {
        let action = props.params.action;

        if (!action) {
            const pathArray = props.location.pathname.split('/').filter((value) => !!value);
            action = pathArray[pathArray.length - 1];
        }

        return action === 'my' ? null : action;
    }
    fetchData() {
        const vm = this;
        const { headers } = vm;
        const { intl, dispatch, user } = vm.props;
        const action = vm.getCurrentAction();

        return axios.get(`${API_URL}myvendor/`, { headers }).then((response) => {
            const vendor = response.data.result;
            return axios.get(`${VENDORS_URL}${vendor.id}/clients/`, { headers }).then((clientResponse) => {
                const clients = clientResponse.data.result;
                vendor.actions = getVendorActions('my', user);
                vendor.clients = _.map(clients, (client) => {
                    const mappedClient = { ...client };
                    mappedClient.name = `${mappedClient.name} ${mappedClient.last_name || ''}`;
                    mappedClient.actions = [{
                        label: appMessages.edit,
                        url: `/vendors/my/clients/${client.id}/edit`,
                        icon: 'icon-note',
                    }];
                    return mappedClient;
                });
                vendor.contractOptions = _.map(vendor.contract_options, (contractOption) => {
                    const mappedContractOption = { ...contractOption };
                    mappedContractOption.type = intl.formatMessage(messages.contractTypes[mappedContractOption.type]);
                    mappedContractOption.ten_twelve_type = intl.formatMessage(messages.tenTwelveTypes[mappedContractOption.ten_twelve_type]);
                    mappedContractOption.payment_type = intl.formatMessage(messages.paymentTypes[mappedContractOption.payment_type]);
                    return mappedContractOption;
                });

                if (_.isEmpty(vm.props.vendors)) {
                    dispatch(newVendor(vendor));
                } else {
                    dispatch(editVendor(vendor));
                }
                vm.state.vendor = vendor;

                vm.setState(vm.state, () => {
                    if (action) {
                        vm.toggleModal(action);
                    }
                });

                return Promise.resolve([response, clientResponse]);
            });
        });
    }
    toggleModal(action) {
        const vm = this;
        const { router } = vm.context;
        const { vendor } = vm.state;
        const location = router.getCurrentLocation();


        if (vm.state.showModal) {
            router.push(location.query.referrer || '/vendors/my');
        }

        vm.state.showModal = !vm.state.showModal;

        switch (action) {
            case 'calctables': {
                vm.state.modalContent = <CalculationTables vendorId={vendor.id} />;
                break;
            }
            case 'options': {
                vm.state.modalContent = <ContractOptionsForm vendor={vendor} />;
                break;
            }
            case 'edit': {
                if (~location.pathname.indexOf('clients/')) {
                    vm.state.modalContent = <VendorClientsForm vendorId={vendor.id} />;
                } else {
                    vm.state.modalContent = <VendorForm vendor={vendor} vendorId={vendor.id} action="edit" />;
                }
                break;
            }
            case 'new': {
                vm.state.modalContent = <VendorClientsForm vendorId={vendor.id} />;
                break;
            }
            default: {
                vm.state.modalContent = null;
                break;
            }
        }

        vm.setState(vm.state);
    }
    render() {
        const vm = this;
        const { location, user } = vm.props;
        const { vendor } = vm.state;
        const modalOptions = {
            isOpen: vm.state.showModal,
            contentLabel: '',
            className: 'modal-dashboard',
            overlayClassName: 'modal-overlay-dashboard',
            onRequestClose: vm.toggleModal,
            shouldCloseOnOverlayClick: true,
        };
        const equipmentTypes = vendor.equipment_types.length > 0
            ? _.map(vendor.equipment_types, (type, index) =>
                <li key={index}>{type.description}</li>)
            : <p><FormattedMessage {...messages.noEquipment} /></p>;

        vendor.logo = vendor.logo ? <img src={vendor.logo} alt="logo" title="logo" /> : null;

        return (
            <div id="MyVendor" className={`vendor vendor-${vendor.id} vendor-${vendor.name}`}>
                <div className="vendor-header layout-row">
                    <h1 className="flex">{vendor.name}</h1>
                    <div className="vendor-details-controls detail-controls layout-row layout-align-center-end">
                        <ActionsMenu actions={vendor.actions} />
                    </div>
                </div>
                <div className="vendor-content layout-column layout-gt-sm-row">
                    <div className="vendor-contact-info flex">
                        <div className="layout-row">
                            <h2 className="flex">
                                <FormattedMessage {...appMessages.contactInfo} />
                            </h2>
                        </div>
                        {vendor.logo}
                        <p><FormattedMessage {...appMessages.name} />: {vendor.name}</p>
                        <p><FormattedMessage {...appMessages.address} />: {vendor.street} {vendor.number}</p>
                        <p><FormattedMessage {...appMessages.postalcode} />: {vendor.postalcode}</p>
                        <p><FormattedMessage {...appMessages.country} />: {vendor.country}</p>
                        <p><FormattedMessage {...appMessages.phone} />: {vendor.phone}</p>
                        <p><FormattedMessage {...appMessages.vat} />: {vendor.vat}</p>
                        <p><FormattedMessage {...appMessages.email} />: {vendor.email}</p>
                        <p><FormattedMessage {...appMessages.language} />: {vendor.language}</p>
                    </div>
                    <div className="vendor-options flex">
                        <div className="layout-row">
                            <h2 className="flex">
                                <FormattedMessage {...appMessages.contractOptions} />
                            </h2>
                        </div>
                        <List
                            name="contract-options"
                            columns={vm.contractColumns}
                            data={vendor.contractOptions}
                        />
                    </div>
                </div>
                <div className="vendor-content">
                    <div className="vendor-clients">
                        <div className="layout-row">
                            <h2 className="flex">
                                <FormattedMessage {...appMessages.clients} />
                            </h2>
                            <div className="vendor-clients-controls flex-nogrow">
                                <Link to={{ pathname: '/vendors/my/clients/new', query: { referrer: vm.context.router.getCurrentLocation().pathname } }} className="vendor-add-client button button-default">Add client</Link>
                            </div>
                        </div>
                        <div className="flex">
                            <List
                                name="vendors-client"
                                columns={vm.clientColumns}
                                data={vendor.clients}
                                defaultSort={{ column: 'name', direction: 'asc' }}
                                onRowClick={vm.onClientClick}
                                responsive
                            />
                        </div>
                    </div>
                </div>
                {auth.userIsAtlance(user) ?
                    <div className="vendor-content">
                        <div className="vendor-equipment-types">
                            <div className="layout-row">
                                <h2 className="flex">
                                    <FormattedMessage {...appMessages.equipmentTypes} />
                                </h2>
                            </div>
                            <div className="flex">
                                <ul>{equipmentTypes}</ul>
                            </div>
                        </div>
                    </div>
                : null}
                <ReactModal {...modalOptions}>
                    <Link
                        to={location.query.referrer || '/vendors/my'}
                        className="modal-close"
                        onClick={vm.toggleModal}
                    >
                        <i className="fa fa-arrow-left" />
                    </Link>
                    {vm.state.modalContent}
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('vendors'),
    (user, vendors) => ({ user, vendors })
);

export default connect(mapStateToProps)(injectIntl(MyVendorContainer));
