/*
 * App Messages
 *
 * This contains all the text for the App component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    noop: {
        id: 'app.noop',
        defaultMessage: ' ',
    },
    both: {
        id: 'app.both',
        defaultMessage: 'Both',
    },
    BOTH: {
        id: 'app.both',
        defaultMessage: 'Both',
    },
    na: {
        id: 'app.na',
        defaultMessage: 'N/A',
    },
    yes: {
        id: 'app.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'app.no',
        defaultMessage: 'No',
    },
    YES: {
        id: 'app.yes',
        defaultMessage: 'Yes',
    },
    NO: {
        id: 'app.no',
        defaultMessage: 'No',
    },
    true: {
        id: 'app.true',
        defaultMessage: 'True',
    },
    false: {
        id: 'app.false',
        defaultMessage: 'False',
    },
    edit: {
        id: 'app.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'app.delete',
        defaultMessage: 'Delete',
    },
    month: {
        id: 'app.month',
        defaultMessage: 'Month',
    },
    months: {
        id: 'app.months',
        defaultMessage: '{months} months',
    },
    quarter: {
        id: 'app.quarter',
        defaultMessage: 'Quarter',
    },
    semester: {
        id: 'app.semester',
        defaultMessage: 'Semester',
    },
    year: {
        id: 'app.year',
        defaultMessage: 'Year',
    },
    MONTH: {
        id: 'app.month',
        defaultMessage: 'Month',
    },
    QUARTER: {
        id: 'app.quarter',
        defaultMessage: 'Quarter',
    },
    SEMESTER: {
        id: 'app.semester',
        defaultMessage: 'Semester',
    },
    YEAR: {
        id: 'app.year',
        defaultMessage: 'Year',
    },
    details: {
        id: 'app.details',
        defaultMessage: 'Details',
    },
    login: {
        id: 'app.login',
        defaultMessage: 'Login',
    },
    username: {
        id: 'app.username',
        defaultMessage: 'Username',
    },
    password: {
        id: 'app.password',
        defaultMessage: 'Password',
    },
    active: {
        id: 'app.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'app.inactive',
        defaultMessage: 'Inactive',
    },
    confirm: {
        id: 'app.confirm',
        defaultMessage: 'Confirm',
    },
    cancel: {
        id: 'app.cancel',
        defaultMessage: 'Cancel',
    },
    save: {
        id: 'app.save',
        defaultMessage: 'Save',
    },
    send: {
        id: 'app.send',
        defaultMessage: 'Send',
    },
    notifications: {
        id: 'app.notifications',
        defaultMessage: 'Notifications',
    },
    noNotifications: {
        id: 'app.noNotifications',
        defaultMessage: 'No notifications found',
    },
    newsTicker: {
        id: 'app.newsTicker',
        defaultMessage: 'News',
    },
    noTickers: {
        id: 'app.noNews',
        defaultMessage: 'No news found',
    },
    upload: {
        id: 'app.upload',
        defaultMessage: 'Upload',
    },
    uploadFile: {
        id: 'app.uploadFile',
        defaultMessage: 'Upload document',
    },
    nofile: {
        id: 'app.nofile',
        defaultMessage: 'No document chosen',
    },
    file: {
        id: 'app.file',
        defaultMessage: 'Document',
    },
    files: {
        id: 'app.files',
        defaultMessage: 'Documents',
    },
    vendor: {
        id: 'app.vendor',
        defaultMessage: 'Vendor',
    },
    vendors: {
        id: 'app.vendor',
        defaultMessage: 'Vendors',
    },
    client: {
        id: 'app.client',
        defaultMessage: 'Client',
    },
    addClient: {
        id: 'app.addClient',
        defaultMessage: 'Add client',
    },
    coClient: {
        id: 'app.coClient',
        defaultMessage: 'Co client',
    },
    startDate: {
        id: 'app.startDate',
        defaultMessage: 'Start date',
    },
    status: {
        id: 'app.status',
        defaultMessage: 'Status',
    },
    type: {
        id: 'app.type',
        defaultMessage: 'Type',
    },
    contactInfo: {
        id: 'app.contactInfo',
        defaultMessage: 'Contact info',
    },
    contractOptions: {
        id: 'app.options',
        defaultMessage: 'Contract options',
    },
    dossierInfo: {
        id: 'app.dossierInfo',
        defaultMessage: 'Dossier info',
    },
    requestLog: {
        id: 'app.requestLog',
        defaultMessage: 'Request log',
    },
    actionLog: {
        id: 'app.actionLog',
        defaultMessage: 'Action log',
    },
    name: {
        id: 'app.name',
        defaultMessage: 'Name',
    },
    street: {
        id: 'app.street',
        defaultMessage: 'Street',
    },
    number: {
        id: 'app.number',
        defaultMessage: 'Number',
    },
    postalcode: {
        id: 'app.postalcode',
        defaultMessage: 'Postalcode',
    },
    country: {
        id: 'app.country',
        defaultMessage: 'Country',
    },
    commercant: {
        id: 'app.commercant',
        defaultMessage: 'Commercant',
    },
    address: {
        id: 'app.address',
        defaultMessage: 'Address',
    },
    phone: {
        id: 'app.phone',
        defaultMessage: 'Phone',
    },
    vat: {
        id: 'app.vat',
        defaultMessage: 'VAT',
    },
    logo: {
        id: 'app.logo',
        defaultMessage: 'Logo',
    },
    email: {
        id: 'app.email',
        defaultMessage: 'Email',
    },
    language: {
        id: 'app.language',
        defaultMessage: 'Language',
    },
    rate: {
        id: 'app.rate',
        defaultMessage: 'Rate',
    },
    terms_type: {
        id: 'app.termsType',
        defaultMessage: 'Terms type',
    },
    contractType: {
        id: 'app.contractType',
        defaultMessage: 'Contract type',
    },
    tenTwelveType: {
        id: 'app.tenTwelveTypes',
        defaultMessage: 'Ten twelve',
    },
    zeroAmountType: {
        id: 'app.zeroAmountType',
        defaultMessage: 'Zero amount',
    },
    paymentType: {
        id: 'app.paymentType',
        defaultMessage: 'Payment type',
    },
    equipmentType: {
        id: 'app.equipmentType',
        defaultMessage: 'Equipment type',
    },
    paneHeader: {
        id: 'app.paneHeader',
        defaultMessage: 'New table',
    },
    clients: {
        id: 'app.clients',
        defaultMessage: 'Clients',
    },
    equipmentDescription: {
        id: 'app.equipmentDescription',
        defaultMessage: 'Equipment description',
    },
    amount: {
        id: 'app.amount',
        defaultMessage: 'Amount',
    },
    hardware: {
        id: 'app.hardware',
        defaultMessage: 'Hardware',
    },
    HARDWARE: {
        id: 'app.hardware',
        defaultMessage: 'Hardware',
    },
    software: {
        id: 'app.software',
        defaultMessage: 'Software',
    },
    SOFTWARE: {
        id: 'app.software',
        defaultMessage: 'Software',
    },
    hardwareRate: {
        id: 'app.hardwareRate',
        defaultMessage: 'Hardware rate',
    },
    softwareRate: {
        id: 'app.softwareRate',
        defaultMessage: 'Software rate',
    },
    hardwareAmount: {
        id: 'app.hardwareAmount',
        defaultMessage: 'Hardware amount',
    },
    softwareAmount: {
        id: 'app.softwareAmount',
        defaultMessage: 'Software amount',
    },
    duration: {
        id: 'app.duration',
        defaultMessage: 'Duration',
    },
    lowLimit: {
        id: 'app.lowLimit',
        defaultMessage: 'Low limit',
    },
    highLimit: {
        id: 'app.highLimit',
        defaultMessage: 'High limit',
    },
    lowLimitAmount: {
        id: 'app.lowLimitAmount',
        defaultMessage: 'Low limit amount',
    },
    highLimitAmount: {
        id: 'app.highLimitAmount',
        defaultMessage: 'High limit amount',
    },
    paymentInterval: {
        id: 'app.paymentInterval',
        defaultMessage: 'Periodicity',
    },
    periodicity: {
        id: 'app.periodicity',
        defaultMessage: 'Periodicity',
    },
    hasCoDebition: {
        id: 'app.hasCoDebition',
        defaultMessage: 'Has co debition',
    },
    hasZeroInitial: {
        id: 'app.hasZeroInitial',
        defaultMessage: 'Has zero intial',
    },
    isTenTwelve: {
        id: 'app.isTenTwelve',
        defaultMessage: 'Is ten twelve',
    },
    zeroAmountIntervals: {
        id: 'app.zeroAmountIntervals',
        defaultMessage: 'Zero amount intervals',
    },
    hasIncreasedFirstRental: {
        id: 'app.hasIncreasedFirstRental',
        defaultMessage: 'Has increased first amount',
    },
    firstAmount: {
        id: 'app.firstAmount',
        defaultMessage: 'First amount',
    },
    hasStartDate: {
        id: 'app.hasStartDate',
        defaultMessage: 'Has start date',
    },
    exceptionDuration: {
        id: 'app.exceptionDuration',
        defaultMessage: 'Exception duration',
    },
    rentalAmount: {
        id: 'app.rentalAmount',
        defaultMessage: 'Rental amount',
    },
    maintenanceAmount: {
        id: 'app.maintenanceAmount',
        defaultMessage: 'Maintenance costs',
    },
    totalRentPlusMaintenance: {
        id: 'app.totalRentPlusMaintenance',
        defaultMessage: 'Total Rent + Maintenance',
    },
    maintenance: {
        id: 'app.maintenance',
        defaultMessage: 'Maintenance',
    },
    maintenanceCost: {
        id: 'app.maintenanceCost',
        defaultMessage: 'Maintenance cost',
    },
    maintenanceContractNumber: {
        id: 'app.maintenanceContractNumber',
        defaultMessage: 'Maintenance contract number',
    },
    maintenanceContractOwner: {
        id: 'app.maintenanceContractOwner',
        defaultMessage: 'Maintenance contract owner',
    },
    portalUndergoingMaintenance: {
        id: 'app.portalUndergoingMaintenance',
        defaultMessage: 'The portal is undergoing maintenance right now. You\'ll be able to access it again at 10AM',
    },
    numberOfPages: {
        id: 'app.numberOfPages',
        defaultMessage: 'Number of pages',
    },
    pagePriceBlack: {
        id: 'app.pagePriceBlack',
        defaultMessage: 'Page price black',
    },
    pagePriceColor: {
        id: 'app.pagePriceColor',
        defaultMessage: 'Page price color',
    },
    residualAmount: {
        id: 'app.residualAmount',
        defaultMessage: 'Residual amount',
    },
    residual: {
        id: 'app.residual',
        defaultMessage: 'Residual',
    },
    building: {
        id: 'app.building',
        defaultMessage: 'Place of delivery',
    },
    isClientOwnerOfBuilding: {
        id: 'app.isClientOwnerOfBuilding',
        defaultMessage: 'Is client owner of building?',
    },
    ownerDetails: {
        id: 'app.ownerDetails',
        defaultMessage: 'Owner details',
    },
    creditor: {
        id: 'app.creditor',
        defaultMessage: 'Creditor',
    },
    hasCreditor: {
        id: 'app.hasCreditor',
        defaultMessage: 'Is there a pledge on the goodwill?',
    },
    creditorDetails: {
        id: 'app.creditorDetails',
        defaultMessage: 'Creditor details',
    },
    guarantee: {
        id: 'app.guarantee',
        defaultMessage: 'Guarantee',
    },
    hasGuarantee: {
        id: 'app.hasGuarantee',
        defaultMessage: 'Is there a guarantee?',
    },
    guaranteePeriod: {
        id: 'app.guaranteePeriod',
        defaultMessage: 'Guarantee period',
    },
    hasConstructor: {
        id: 'app.hasConstructor',
        defaultMessage: 'By constructor',
    },
    constructorIsOtherCompany: {
        id: 'app.constructorIsOtherCompany',
        defaultMessage: 'Maintenance by other company',
    },
    constructorDetails: {
        id: 'app.constructorDetails',
        defaultMessage: 'Constructor details',
    },
    constructor: {
        id: 'app.constructors',
        defaultMessage: 'Constructor',
    },
    city: {
        id: 'app.city',
        defaultMessage: 'City',
    },
    firmname: {
        id: 'app.firmname',
        defaultMessage: 'Firmname',
    },
    function: {
        id: 'app.function',
        defaultMessage: 'Function',
    },
    iban: {
        id: 'app.iban',
        defaultMessage: 'Iban',
    },
    swiftBic: {
        id: 'app.swiftBic',
        defaultMessage: 'Swift BIC',
    },
    mailAccounting: {
        id: 'app.mailAccounting',
        defaultMessage: 'Accounting mail',
    },
    isSchool: {
        id: 'app.isSchool',
        defaultMessage: 'Is school',
    },
    isPhysicalPerson: {
        id: 'app.isPhysicalPerson',
        defaultMessage: 'Is physical person',
    },
    nationality: {
        id: 'app.nationality',
        defaultMessage: 'Nationality',
    },
    nace: {
        id: 'app.nace',
        defaultMessage: 'NACE-code',
    },
    activityStart: {
        id: 'app.activityStart',
        defaultMessage: 'Activity start',
    },
    activityDescription: {
        id: 'app.activityDescription',
        defaultMessage: 'Activity description',
    },
    nationalRegistryNo: {
        id: 'app.nationalRegistryNo',
        defaultMessage: 'National registry number',
    },
    funder: {
        id: 'app.funder',
        defaultMessage: 'Funder',
    },
    user: {
        id: 'app.user',
        defaultMessage: 'User',
    },
    users: {
        id: 'app.users',
        defaultMessage: 'Users',
    },
    add: {
        id: 'app.add',
        defaultMessage: 'add',
    },
    new: {
        id: 'app.new',
        defaultMessage: 'New',
    },
    contact: {
        id: 'app.contact',
        defaultMessage: 'Contact',
    },
    contacts: {
        id: 'app.contacts',
        defaultMessage: 'Contacts',
    },
    equipmentTypes: {
        id: 'app.equipmentTypes',
        defaultMessage: 'Equipment types',
    },
    allowedFunders: {
        id: 'app.allowedFunders',
        defaultMessage: 'Allowed funders',
    },
    calculationTable: {
        id: 'app.calculationTable',
        defaultMessage: 'Calculation table',
    },
    calculationTables: {
        id: 'app.calculationTables',
        defaultMessage: 'Calculation tables',
    },
    softwareTable: {
        id: 'app.softwareTable',
        defaultMessage: 'Software table',
    },
    hardwareTable: {
        id: 'app.hardwareTable',
        defaultMessage: 'Hardware table',
    },
    residuals: {
        id: 'app.residuals',
        defaultMessage: 'Residuals',
    },
    dropzoneInstructions: {
        id: 'app.dropzoneInstructions',
        defaultMessage: 'Drop a document in, or click on, this area to upload a document',
    },
    incasso: {
        id: 'app.incasso',
        defaultMessage: 'Incasso',
    },
    id: {
        id: 'app.id',
        defaultMessage: 'ID',
    },
    calculationSheet: {
        id: 'app.calculationSheet',
        defaultMessage: 'Calculation sheet',
    },
    contract: {
        id: 'app.contract',
        defaultMessage: 'Contract',
    },
    selectDocType: {
        id: 'app.selectDocType',
        defaultMessage: 'Select the document type',
    },
    expiryDate: {
        id: 'app.expiryDate',
        defaultMessage: 'Expiry date (yyyy-mm-dd)',
    },
    owner: {
        id: 'app.owner',
        defaultMessage: 'Owner',
    },
    processVerbal: {
        id: 'app.processVerbal',
        defaultMessage: 'Approval of delivery',
    },
    rental: {
        id: 'app.rental',
        defaultMessage: 'Rental',
    },
    first: {
        id: 'app.first',
        defaultMessage: 'First',
    },
    conditions: {
        id: 'app.conditions',
        defaultMessage: 'Conditions',
    },
    clientSignDate: {
        id: 'app.clientSignDate',
        defaultMessage: 'Client sign date',
    },
    clientSignPlace: {
        id: 'app.clientSignPlace',
        defaultMessage: 'Client sign place',
    },
    atlanceSignDate: {
        id: 'app.atlanceSignDate',
        defaultMessage: 'Atlance sign date',
    },
    atlanceSignPlace: {
        id: 'app.atlanceSignPlace',
        defaultMessage: 'Atlance sign place',
    },
    clientSignExpiryDate: {
        id: 'app.clientSignExpiryDate',
        defaultMessage: 'Client sign expiry date',
    },
    dossier: {
        id: 'app.dossier',
        defaultMessage: 'Dossier',
    },
    dossiers: {
        id: 'app.dossiers',
        defaultMessage: 'Dossiers',
    },
    newDossier: {
        id: 'app.newDossier',
        defaultMessage: 'New dossier',
    },
    financialEditDossier: {
        id: 'app.financialEditDossier',
        defaultMessage: 'Edit Financial parameters',
    },
    administrativeEditDossier: {
        id: 'app.administrativeEditDossier',
        defaultMessage: 'Edit Administrative parameters',
    },
    archive: {
        id: 'app.archive',
        defaultMessage: 'Archive',
    },
    markAs: {
        id: 'app.markAs',
        defaultMessage: 'Mark as {value}',
    },
    _actions: {
        id: 'app.actions',
        defaultMessage: 'Actions',
    },
    actions: {
        NEWDOSSIER: {
            id: 'app.actions.NEWDOSSIER',
            defaultMessage: 'New dossier',
        },
        EDITDOSSIER: {
            id: 'app.actions.EDITDOSSIER',
            defaultMessage: 'Edit dossier',
        },
        FINANCIALEDITDOSSIER: {
            id: 'app.actions.FINANCIALEDITDOSSIER',
            defaultMessage: 'Edit Financial parameters',
        },
        ADMINISTRATIVEEDITDOSSIER: {
            id: 'app.actions.ADMINISTRATIVEEDITDOSSIER',
            defaultMessage: 'Edit Administrative parameters',
        },
        CREATEQUOTATIONDOCUMENT: {
            id: 'app.actions.CREATEQUOTATIONDOCUMENT',
            defaultMessage: 'Create printable offer',
        },
        CREATECALCDOCUMENT: {
            id: 'app.actions.CREATECALCDOCUMENT',
            defaultMessage: 'Create calculation',
        },
        CREATECONTRACTDOCUMENT: {
            id: 'app.actions.CREATECONTRACTDOCUMENT',
            defaultMessage: 'Create printable document',
        },
        ADDSIGNINGEXPIRYDATE: {
            id: 'app.actions.ADDSIGNINGEXPIRYDATE',
            defaultMessage: 'Add sign expiry date',
        },
        ACCEPT: {
            id: 'app.actions.ACCEPT',
            defaultMessage: 'Accept',
        },
        ACCEPTWITHCONDITIONS: {
            id: 'app.actions.ACCEPTWITHCONDITIONS',
            defaultMessage: 'Accept with conditions',
        },
        REJECT: {
            id: 'app.actions.REJECT',
            defaultMessage: 'Reject',
        },
        REQUESTINFOACTION: {
            id: 'app.actions.REQUESTINFOACTION',
            defaultMessage: 'Request info',
        },
        CONFIRMINFOSUBMISSION: {
            id: 'app.actions.CONFIRMINFOSUBMISSION',
            defaultMessage: 'Approve extra info',
        },
        FINISHINFOSUBMISSION: {
            id: 'app.actions.FINISHINFOSUBMISSION',
            defaultMessage: 'Send extra info',
        },
        CONFIRMCREDITREQUEST: {
            id: 'app.actions.CONFIRMCREDITREQUEST',
            defaultMessage: 'Response bank',
        },
        CONFIRMCREDITREQUESTACTION: {
            id: 'app.actions.CONFIRMCREDITREQUESTACTION',
            defaultMessage: 'Response bank',
        },
        BANKSUCCESS: {
            id: 'app.actions.BANKSUCCESS',
            defaultMessage: 'Bank success',
        },
        BANKFAILURE: {
            id: 'app.actions.BANKFAILURE',
            defaultMessage: 'Bank failure',
        },
        SENDTOBANKS: {
            id: 'app.actions.SENDTOBANKS',
            defaultMessage: 'Send to banks',
        },
        UPLOADDOCUMENT: {
            id: 'app.actions.UPLOADDOCUMENT',
            defaultMessage: 'Upload document',
        },
        LOGCLIENTCOMMUNICATION: {
            id: 'app.actions.LOGCLIENTCOMMUNICATION',
            defaultMessage: 'Log client communication',
        },
        SUBMITTOSNI: {
            id: 'app.actions.SUBMITTOSNI',
            defaultMessage: 'Submit to SNI',
        },
        REVIEWDOCUMENT: {
            id: 'app.actions.REVIEWDOCUMENT',
            defaultMessage: 'Review document',
        },
        CANCEL: {
            id: 'app.actions.CANCEL',
            defaultMessage: 'Cancel dossier',
        },
        ARCHIVEDOSSIER: {
            id: 'app.actions.ARCHIVEDOSSIER',
            defaultMessage: 'Archive dossier',
        },
        SIGN: {
            id: 'app.actions.SIGN',
            defaultMessage: 'Sign dossier',
        },
        SIGNWITHDOCUSIGN: {
            id: 'app.actions.SIGNWITHDOCUSIGN',
            defaultMessage: 'Sign dossier with docusign',
        },
        COMPLETE: {
            id: 'app.actions.COMPLETE',
            defaultMessage: 'Complete dossier',
        },
        NEWVENDOR: {
            id: 'app.actions.NEWVENDOR',
            defaultMessage: 'New vendor',
        },
        EDITVENDOR: {
            id: 'app.actions.EDITVENDOR',
            defaultMessage: 'Edit vendor',
        },
        EDITCONTRACTOPTIONS: {
            id: 'app.actions.EDITCONTRACTOPTIONS',
            defaultMessage: 'Edit contract options',
        },
        ADDCLIENT: {
            id: 'app.actions.ADDCLIENT',
            defaultMessage: 'Add client',
        },
        EDITEQUIPMENTTYPES: {
            id: 'app.actions.EDITEQUIPMENTTYPES',
            defaultMessage: 'Edit equipment types',
        },
        CHOOSEFUNDER: {
            id: 'app.actions.CHOOSEFUNDER',
            defaultMessage: 'Choose funder',
        },
    },
    docTypes: {
        CONTRACT: {
            id: 'app.doctypes.contract',
            defaultMessage: 'Contract',
        },
        ID: {
            id: 'app.doctypes.id',
            defaultMessage: 'ID',
        },
        QUOTATIONEXCEL: {
            id: 'app.doctypes.quotationexcel',
            defaultMessage: 'Quotation sheet',
        },
        CALCULATIONEXCEL: {
            id: 'app.doctypes.calcexcel',
            defaultMessage: 'Calculation sheet',
        },
        SEPA: {
            id: 'app.doctypes.sepa',
            defaultMessage: 'SEPA',
        },
        INCASSO: {
            id: 'app.doctypes.sepa',
            defaultMessage: 'SEPA',
        },
        TAXFORM: {
            id: 'app.doctypes.taxform',
            defaultMessage: 'Tax form',
        },
        BALANCESHEET: {
            id: 'app.doctypes.balancesheet',
            defaultMessage: 'Balance sheet',
        },
        SIGNEDCONTRACT: {
            id: 'app.doctypes.signedcontract',
            defaultMessage: 'Signed contract',
        },
        PV: {
            id: 'app.doctypes.pv',
            defaultMessage: 'PV',
        },
        INVOICE: {
            id: 'app.doctypes.invoice',
            defaultMessage: 'Invoice',
        },
        POWEROFATTORNEY: {
            id: 'app.doctypes.powerofattorney',
            defaultMessage: 'Power of attorney',
        },
        OTHER: {
            id: 'app.doctypes.other',
            defaultMessage: 'Other',
        },
        CANCELANDREPLACE: {
            id: 'app.doctypes.cancelandreplace',
            defaultMessage: 'Cancel and replace',
        },
        INTERNALCOMMUNICATION: {
            id: 'app.doctypes.internalcommunication',
            defaultMessage: 'Internal Communcation',
        },
        selectDocType: {
            id: 'app.selectDocType',
            defaultMessage: 'Select the document type',
        },
    },
    docStates: {
        NEW: {
            id: 'app.docstates.new',
            defaultMessage: 'New',
        },
        AUTOCREATED: {
            id: 'app.docstates.autocreated',
            defaultMessage: 'Auto created',
        },
        ACCEPTED: {
            id: 'app.docstates.accepted',
            defaultMessage: 'Accepted',
        },
        REJECTED: {
            id: 'app.docstates.rejected',
            defaultMessage: 'Rejected',
        },
        OUTDATED: {
            id: 'app.docstates.outdated',
            defaultMessage: 'Outdated',
        },
    },
    downloads: {
        id: 'app.downloads',
        defaultMessage: 'Downloads',
    },
    support: {
        id: 'app.support',
        defaultMessage: 'Support',
    },
    accountManager: {
        id: 'app.accountManager',
        defaultMessage: 'Account manager',
    },
    contactsForVendor: {
        id: 'app.contactsForVendor',
        defaultMessage: 'Contacts for vendor',
    },
    contactsForClient: {
        id: 'app.contactsForClient',
        defaultMessage: 'Contacts for client',
    },
    addressbook: {
        id: 'app.addressbook',
        defaultMessage: 'Addressbook',
    },
    bus: {
        id: 'app.bus',
        defaultMessage: 'Bus',
    },
    amountGreaterThan: {
        id: 'app.amountGreaterThan',
        defaultMessage: 'Amount greater then',
    },
    amountLessThan: {
        id: 'app.amountLessThan',
        defaultMessage: 'Amount less then',
    },
    completedBefore: {
        id: 'app.completedBefore',
        defaultMessage: 'Completed before',
    },
    completedAfter: {
        id: 'app.completedAfter',
        defaultMessage: 'Completed after',
    },
    contractEnddateAfter: {
        id: 'app.contractEnddateAfter',
        defaultMessage: 'Contract enddate after',
    },
    contractEnddateBefore: {
        id: 'app.contractEnddateBefore',
        defaultMessage: 'Contract enddate before',
    },
    contractStartdateAfter: {
        id: 'app.contractStartdateAfter',
        defaultMessage: 'Contract startdate after',
    },
    contractStartdateBefore: {
        id: 'app.contractStartdateBefore',
        defaultMessage: 'Contract startdate before',
    },
    atlanceSales: {
        id: 'app.atlanceSales',
        defaultMessage: 'Atlance sales',
    },
    hasDocuments: {
        id: 'app.hasDocuments',
        defaultMessage: 'Dossier with documents',
    },
    hasNewDocuments: {
        id: 'app.hasNewDocuments',
        defaultMessage: 'Dossier with new documents',
    },
    vendorOwner: {
        id: 'app.vendorOwner',
        defaultMessage: 'Vendor owner',
    },
    vendorSales: {
        id: 'app.vendorSales',
        defaultMessage: 'Vendor sales',
    },
    vendorOwnerName: {
        id: 'app.vendorOwnerName',
        defaultMessage: 'Vendor owner',
    },
    vendorVAT: {
        id: 'app.vendorVAT',
        defaultMessage: 'Vendor VAT',
    },
    clientVAT: {
        id: 'app.clientVAT',
        defaultMessage: 'Client VAT',
    },
    clientAddress: {
        id: 'app.clientAddress',
        defaultMessage: 'Client address',
    },
    logout: {
        id: 'app.logout',
        defaultMessage: 'Logout',
    },
    firstName: {
        id: 'app.firstName',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'app.lastName',
        defaultMessage: 'Last name',
    },
    mobileNumber: {
        id: 'app.mobileNumber',
        defaultMessage: 'Mobile number',
    },
    phoneNumber: {
        id: 'app.phoneNumber',
        defaultMessage: 'Phone number',
    },
    lostConnection: {
        id: 'app.lostConnection',
        defaultMessage: 'Lost connection',
    },
    reloadNow: {
        id: 'app.reloadNow',
        defaultMessage: 'Reload now',
    },
    reconnectingIn: {
        id: 'app.reconnectingIn',
        defaultMessage: 'Reconnecting in {duration}',
    },
    export: {
        id: 'app.export',
        defaultMessage: 'Export',
    },
    reset: {
        id: 'app.reset',
        defaultMessage: 'Reset',
    },
    dateOfBirth: {
        id: 'app.dateOfBirth',
        defaultMessage: 'Date of birth',
    },
    isbn: {
        id: 'app.isbn',
        defaultMessage: 'ISBN',
    },
    equipment: {
        id: 'app.equipment',
        defaultMessage: 'Equipment',
    },
    priceBlack: {
        id: 'app.priceBlack',
        defaultMessage: 'Price black',
    },
    priceColor1: {
        id: 'app.priceColor1',
        defaultMessage: 'Price color 1',
    },
    priceColor2: {
        id: 'app.priceColor2',
        defaultMessage: 'Price color 2',
    },
    priceColor3: {
        id: 'app.priceColor3',
        defaultMessage: 'Price color 3',
    },
    extraPriceBlack: {
        id: 'app.extraPriceBlack',
        defaultMessage: 'Extra price black',
    },
    extraPriceColor1: {
        id: 'app.extraPriceColor1',
        defaultMessage: 'Extra price color 1',
    },
    extraPriceColor2: {
        id: 'app.extraPriceColor2',
        defaultMessage: 'Extra price color 2',
    },
    extraPriceColor3: {
        id: 'app.extraPriceColor3',
        defaultMessage: 'Extra price color 3',
    },
    minimumPriceBlack: {
        id: 'app.minimumPriceBlack',
        defaultMessage: 'Minimum price black',
    },
    minimumPriceColor1: {
        id: 'app.minimumPriceColor1',
        defaultMessage: 'Minimum price color 1',
    },
    minimumPriceColor2: {
        id: 'app.minimumPriceColor2',
        defaultMessage: 'Minimum price color 2',
    },
    minimumPriceColor3: {
        id: 'app.minimumPriceColor3',
        defaultMessage: 'Minimum price color 3',
    },
    tcoOptions: {
        id: 'app.tcoOptions',
        defaultMessage: 'TCO options',
    },
    tcoFlatAmount: {
        id: 'app.tcoFlatAmount',
        defaultMessage: 'TCO flat amount',
    },
    commissionPercentage: {
        id: 'app.commissionPercentage',
        defaultMessage: 'Commission %',
    },
    commercialActionPercentage: {
        id: 'app.commercialActionPercentage',
        defaultMessage: 'Commercial action %',
    },
    requests: {
        id: 'app.requests',
        defaultMessage: 'Requests',
    },
    pendingDossiers: {
        id: 'app.pendingDossiers',
        defaultMessage: 'Pending dossiers',
    },
    production: {
        id: 'app.production',
        defaultMessage: 'Production',
    },
    creditRequests: {
        id: 'app.creditrequests',
        defaultMessage: 'Credit requests',
    },
    result: {
        id: 'app.result',
        defaultMessage: 'Result',
    },
    tag: {
        id: 'app.tag',
        defaultMessage: 'Tag',
    },
    overrideRates: {
        id: 'app.overrideRates',
        defaultMessage: 'Override rates',
    },
    atradiusResponseCode: {
        id: 'app.atradiusResponseCode',
        defaultMessage: 'Atradius response code',
    },
    total: {
        id: 'app.total',
        defaultMessage: 'Total',
    },
    fixRentalAmount: {
        id: 'app.fixRentalAmount',
        defaultMessage: 'Fix rental amount',
    },
    uploadDocument: {
        id: 'app.uploadDocument',
        defaultMessage: 'Upload document',
    },
    sentAt: {
        id: 'app.sendAt',
        defaultMessage: 'Sent at',
    },
    expiresAt: {
        id: 'app.expiresAt',
        defaultMessage: 'Expires at',
    },
    value: {
        id: 'app.value',
        defaultMessage: 'Value',
    },
    values: {
        id: 'app.values',
        defaultMessage: 'Values',
    },
    response: {
        id: 'app.response',
        defaultMessage: 'Response',
    },
    date: {
        id: 'app.date',
        defaultMessage: 'Date',
    },
    action: {
        id: 'app.action',
        defaultMessage: 'Action',
    },
    summary: {
        id: 'app.summary',
        defaultMessage: 'Summary',
    },
    item: {
        id: 'app.item',
        defaultMessage: 'Item',
    },
    docType: {
        id: 'app.docType',
        defaultMessage: 'Document type',
    },
    createdAt: {
        id: 'app.createdAt',
        defaultMessage: 'Created at',
    },
    completedAt: {
        id: 'app.completedAt',
        defaultMessage: 'Completed at',
    },
    version: {
        id: 'app.version',
        defaultMessage: 'Version',
    },
    documentType: {
        id: 'app.documentType',
        defaultMessage: 'Document type',
    },
    next: {
        id: 'app.next',
        defaultMesage: 'Next',
    },
    previous: {
        id: 'app.previous',
        defaultMesage: 'Previous',
    },
    description: {
        id: 'app.description',
        defaultMessage: 'Description',
    },
    saveRequest: {
        id: 'app.saveRequest',
        defaultMessage: 'Send request',
    },
    saveRequestWithDoc: {
        id: 'app.saveRequestWithDoc',
        defaultMessage: 'Send request with doc',
    },
    saveAdministrative: {
        id: 'app.saveAdministrative',
        defaultMessage: 'Save',
    },
    saveQuotation: {
        id: 'app.saveQuotation',
        defaultMessage: 'Save quotation',
    },
    saveAtlance: {
        id: 'app.saveAtlance',
        defaultMessage: 'Send to Atlance',
    },
    ggirs: {
        id: 'app.ggirs',
        defaultMessage: 'GG IRS',
    },
    news: {
        id: 'app.News.news',
        defaultMessage: 'News',
    },
    addNews: {
        id: 'app.News.addNews',
        defaultMessage: 'Add news',
    },
    noData: {
        id: 'app.noData',
        defaultMessage: 'No data available',
    },
    rrecode: {
        id: 'app.rreCode',
        defaultMessage: 'RRE‑code',
    },
    searchRRECode: {
        id: 'app.searchRRECode',
        defaultMessage: 'Search RRE‑code',
    },
    searchResults: {
        id: 'app.searchResults',
        defaultMessage: 'Search results',
    },
    noResultsFound: {
        id: 'app.noResultsFound',
        defaultMessage: 'No results found',
    },
    addThisRRECode: {
        id: 'app.addThisRRECode',
        defaultMessage: 'Add this RRE‑code',
    },
    createRRECode: {
        id: 'app.createRRECode',
        defaultMessage: 'Create RRE‑code',
    },
    structureType: {
        id: 'app.structureType',
        defaultMessage: 'Structure type',
    },
    submitToSNI: {
        id: 'app.submitToSNI',
        defaultMessage: 'Submit to SNI',
    },
    signWithDocuSign: {
        id: 'app.signWithDocuSign',
        defaultMessage: 'Sign with DocuSign',
    },
    phoneNumberFormatting: {
        id: 'app.phoneNumberFormatting',
        defaultMessage: 'Cell phone number should mention country code. Example : +32472954198',
    },
    chooseFunder: {
        id: 'app.chooseFunder',
        defaultMessage: 'Choose funder',
    },
});
