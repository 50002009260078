import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { Menu, MenuTrigger, MenuContent, MenuItem, Tooltip } from 'common/Util';
import appMessages from 'components/App/messages';
import { withRouter } from "react-router";

class ActionsMenu extends React.Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const scrollMaskEvent = new MouseEvent('xs2.scroll-mask.click', event);
            window.dispatchEvent(scrollMaskEvent);
        }
    }
    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    moveArrayElement(array, elementIndex, newIndex) {
        if (newIndex >= array.length) {
            let k = newIndex - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        array.splice(newIndex, 0, array.splice(elementIndex, 1)[0]);
        return array;
    }

    renderActions() {
        const vm = this;
        const tpl = [];
        const currentLocation = vm.context.router.getCurrentLocation();
        const referrer = currentLocation.query.referrer;
        let { actions } = vm.props;

        const sendToBanksAction = (actions || []).find((action) => action.action === 'SENDTOBANKS');
        if (sendToBanksAction) {
            const sendToBanksIndex = actions.indexOf(sendToBanksAction);
            actions = this.moveArrayElement(actions, sendToBanksIndex, 0);
        }

        _.forEach(actions, (action) => {
            const link = { pathname: action.url };
            link.search = `?referrer=${referrer || currentLocation.pathname}`;

            if (action.action !== 'REVIEWDOCUMENT') {
                tpl.push(
                    <span onClick={this.onClick} key={action.action || action.label.id}>
                    <Link to={link} className="actions-menu-item left-align">
                        <i className={action.icon}></i>
                        <FormattedMessage {...action.label} />
                    </Link>
                    </span>
                );
            }
        });
        return tpl;
    }
    render() {
        const vm = this;
        const actions = vm.renderActions();

        return (
            <Menu ref={(ref) => { vm.menu = ref; }} className="xs2-icon-menu actions-menu">
                <MenuTrigger className="actions-menu-trigger" onClick={vm.onClick}>
                    <i className="icon-options-vertical"></i>
                    <Tooltip label="Actions" />
                </MenuTrigger>
                <MenuContent className="actions-menu-content">
                    {actions}
                </MenuContent>
            </Menu>
        );
    }
}

ActionsMenu.contextTypes = {
    router: PropTypes.object,
};

ActionsMenu.propTypes = {
    actions: PropTypes.array,
};

export default withRouter(ActionsMenu);

export function getActionsFromDossier(dossier) {
    const actions = _.map(dossier.available_actions, (action) => {
        const mappedAction = {
            action,
        };

        mappedAction.label = appMessages.actions[action];

        switch (action) {
            case 'FINANCIALEDITDOSSIER': {
                mappedAction.url = `/dossiers/${dossier.id}/financialEdit`;
                mappedAction.icon = 'icon-diamond';
                break;
            }
            case 'ADMINISTRATIVEEDITDOSSIER': {
                mappedAction.url = `/dossiers/${dossier.id}/administrativeEdit`;
                mappedAction.icon = 'icon-calculator';
                break;
            }
            case 'REQUESTINFOACTION': {
                mappedAction.url = `/dossiers/${dossier.id}/request`;
                mappedAction.icon = 'icon-info';
                break;
            }
            case 'FINISHINFOSUBMISSION': {
                mappedAction.url = `/dossiers/${dossier.id}/finishinfo`;
                mappedAction.icon = 'icon-info';
                break;
            }
            case 'CONFIRMINFOSUBMISSION': {
                mappedAction.url = `/dossiers/${dossier.id}/confirminfo`;
                mappedAction.icon = 'icon-check';
                break;
            }
            case 'SENDTOBANKS': {
                mappedAction.url = `/dossiers/${dossier.id}/send`;
                mappedAction.icon = 'icon-paper-plane';
                break;
            }
            case 'CONFIRMCREDITREQUESTACTION': {
                mappedAction.url = `/dossiers/${dossier.id}/confirm`;
                mappedAction.icon = 'icon-check';
                break;
            }
            case 'ACCEPT': {
                mappedAction.url = `/dossiers/${dossier.id}/success`;
                mappedAction.icon = 'icon-like';
                break;
            }
            case 'ACCEPTWITHCONDITIONS': {
                mappedAction.url = `/dossiers/${dossier.id}/successconditions`;
                mappedAction.icon = 'icon-like';
                break;
            }
            case 'REJECT': {
                mappedAction.url = `/dossiers/${dossier.id}/failure`;
                mappedAction.icon = 'icon-dislike';
                break;
            }
            case 'CREATEQUOTATIONDOCUMENT': {
                mappedAction.url = `/dossiers/${dossier.id}/quotation`;
                mappedAction.icon = 'icon-calculator';
                break;
            }
            case 'CREATECALCDOCUMENT': {
                mappedAction.url = `/dossiers/${dossier.id}/calcdoc`;
                mappedAction.icon = 'fa fa-file-excel-o';
                break;
            }
            case 'CREATECONTRACTDOCUMENT': {
                mappedAction.url = `/dossiers/${dossier.id}/worddoc`;
                mappedAction.icon = 'fa fa-file-pdf-o';
                break;
            }
            case 'ADDSIGNINGEXPIRYDATE': {
                mappedAction.url = `/dossiers/${dossier.id}/expiry`;
                mappedAction.icon = 'icon-hourglass';
                break;
            }
            case 'UPLOADDOCUMENT': {
                mappedAction.url = `/dossiers/${dossier.id}/upload`;
                mappedAction.icon = 'icon-cloud-upload';
                break;
            }
            case 'REVIEWDOCUMENT': {
                mappedAction.url = `/dossiers/${dossier.id}/review`;
                mappedAction.icon = 'icon-eye';
                break;
            }
            case 'LOGCLIENTCOMMUNICATION': {
                mappedAction.url = `/dossiers/${dossier.id}/communication`;
                mappedAction.icon = 'icon-bubbles';
                break;
            }
            case 'CHOOSEFUNDER': {
                mappedAction.url = `/dossiers/${dossier.id}/funder`;
                mappedAction.icon = 'icon-wallet';
                break;
            }
            case 'SUBMITTOSNI': {
                mappedAction.url = `/dossiers/${dossier.id}/submittosni`;  // NOTE: I have no idea what this URL does
                mappedAction.icon = 'fa fa-external-link';
                break;
            }
            case 'CANCEL': {
                mappedAction.url = `/dossiers/${dossier.id}/cancel`;
                mappedAction.icon = 'icon-ban';
                break;
            }
            case 'ARCHIVEDOSSIER': {
                mappedAction.url = `/dossiers/${dossier.id}/archive`;
                mappedAction.icon = 'icon-folder-alt';
                break;
            }
            case 'SIGN': {
                mappedAction.url = `/dossiers/${dossier.id}/sign`;
                mappedAction.icon = 'icon-pencil';
                break;
            }
            case 'SIGNWITHDOCUSIGN': {
                mappedAction.url = `/dossiers/${dossier.id}/docusign`;
                mappedAction.icon = 'icon-note';
                break;
            }
            case 'COMPLETE': {
                mappedAction.url = `/dossiers/${dossier.id}/complete`;
                mappedAction.icon = 'icon-check';
                break;
            }
            default: {
                mappedAction.label = appMessages.noop;
                mappedAction.url = '';
                mappedAction.icon = '';
                break;
            }
        }

        return mappedAction;
    });

    return actions;
}
