import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Datepicker, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { editDossier } from '../actions';
import { DOSSIERS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class ExpiryDateForm extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const id = vm.context.router.params.id;

        data.client_sign_expiry_date = moment(data.client_sign_expiry_date).format('Y-M-D');

        axios.post(`${DOSSIERS_URL}${id}/actions`, { action_code: 'ADDSIGNINGEXPIRYDATE', data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${id}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.setExpiryDate} tagName="p" />);
        });
    }
    render() {
        const vm = this;

        return (
            <div id="ExpiryDateForm" className="expiry-date-form">
                <h1 className="center-align">Set signing expiry date</h1>
                <Form id="expiry-date" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Datepicker
                            name="client_sign_expiry_date"
                            label={appMessages.expiryDate}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

ExpiryDateForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(ExpiryDateForm);
