import React from 'react';
import ReactDOM from 'react-dom';

class InjectBody extends React.Component {
    constructor(props) {
        super(props);

        const vm = this;
        vm._container = document.createElement('div');
    }

    componentWillMount() {
        const vm = this;
        if (!vm.props.children) { return; }
        vm._child = ReactDOM.render(vm.props.children, vm._container);
    }

    componentDidMount() {
        const vm = this;
        if (document.body.contains(vm._container)) { return; }
        document.body.appendChild(vm._container);
    }

    componentDidUpdate() {
        const vm = this;
        if (!vm._child) { return; }

        if (vm._child.setState) {
            vm._child.setState({});
        }
    }

    componentWillUnmount() {
        const vm = this;
        ReactDOM.unmountComponentAtNode(vm._container);
        document.body.removeChild(vm._container);
    }

    render() {
        return null;
    }
}

InjectBody.defaultProps = {
    style: {},
};

export default InjectBody;
