// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import { getAsyncInjectors } from 'utils/asyncInjectors';

import auth, { requireAuth } from 'components/Users/auth';
import vendorRoutes from 'components/Vendor/routes';
import dossierRoutes from 'components/Dossiers/routes';
import dashboardRoutes from 'components/Dashboard/routes';
import downloadRoutes from 'components/Downloads/routes';
import userRoutes from 'components/Users/routes';
import { supportRoutes, contactRoutes } from 'components/Contacts/routes';
import newsRoutes from 'components/News/routes';

const errorLoading = (err) => {
    console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};
const loadModule = (cb) => (componentModule) => {
    cb(null, componentModule.default);
};
export default function createRoutes(store) {
    // Create reusable async injectors using getAsyncInjectors factory
    const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars
    const appRoutes = [
        {
            path: '/',
            name: 'index',
            onEnter: (nextState, replace) => {
                if (auth.loggedIn()) {
                    replace('/dossiers');
                } else {
                    replace('/login');
                }
            },
        },
        {
            path: '/login',
            name: 'login',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Users/containers/Login'),
                ]);

                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });

                importModules.catch(errorLoading);
            },
            onEnter: (nextState, replace) => {
                if (auth.loggedIn() && !auth.tokenExpired()) {
                    replace({
                        pathname: '/dashboard',
                        state: { nextPathname: nextState.location.pathname },
                    });
                } else {
                    auth.logout();
                }
            },
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Users/containers/ForgotPassword'),
                ]);

                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });

                importModules.catch(errorLoading);
            },
        },
        {
            path: '/reset-password(/:token)',
            name: 'reset-password',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Users/containers/ResetPassword'),
                ]);

                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });

                importModules.catch(errorLoading);
            },
        },
        {
            path: '/logout',
            name: 'logout',
            onEnter: (nextState, replace) => {
                auth.logout();
                replace({
                    pathname: '/login',
                    state: { nextPathname: nextState.location.pathname },
                });
            },
        },
        // {
        //     path: '/dashboard',
        //     name: 'dashboard',
        //     getComponent(nextState, cb) {
        //         const importModules = Promise.all([
        //             import('components/Admin/Layout'),
        //         ]);
        //         const renderRoute = loadModule(cb);
        //         importModules.then(([component]) => {
        //             renderRoute(component);
        //         });
        //         importModules.catch(errorLoading);
        //     },
        //     onEnter: requireAuth(store),
        // },
        {
            path: '/dashboard',
            name: 'dashboard',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: dashboardRoutes[0],
            childRoutes: dashboardRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/users',
            name: 'users',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: userRoutes[0],
            childRoutes: userRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/vendors',
            name: 'vendors',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: vendorRoutes[0],
            childRoutes: vendorRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/dossiers',
            name: 'dossiers',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: dossierRoutes[0],
            childRoutes: dossierRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/downloads',
            name: 'downloads',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: downloadRoutes[0],
            childRoutes: downloadRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/support',
            name: 'support',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: supportRoutes[0],
            onEnter: requireAuth(store),
        },
        {
            path: '/contacts',
            name: 'contacts',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: contactRoutes[0],
            childRoutes: contactRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '/news',
            name: 'news',
            getComponent(nextState, cb) {
                const importModules = Promise.all([
                    import('components/Admin/Layout'),
                ]);
                const renderRoute = loadModule(cb);
                importModules.then(([component]) => {
                    renderRoute(component);
                });
                importModules.catch(errorLoading);
            },
            indexRoute: newsRoutes[0],
            childRoutes: newsRoutes,
            onEnter: requireAuth(store),
        },
        {
            path: '*',
            name: 'notfound',
            getComponent(nextState, cb) {
                import('components/NotFoundPage')
                    .then(loadModule(cb))
                    .catch(errorLoading);
            },
            onEnter: requireAuth(store),
        },
    ];
    return appRoutes;
}
