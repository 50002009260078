import { browserHistory } from 'react-router';
import Validator from 'common/validator';

export function getEntityIdFromUrl() {
    const pathArray = browserHistory.getCurrentLocation().pathname.split('/').filter((value) => !!value);
    const entityId = pathArray[pathArray.length - 2];

    return entityId;
}

export function getActionFromLocation(location) {
    const pathArray = location.pathname.split('/').filter((value) => !!value);
    return pathArray[pathArray.length - 1];
}

export function ibanFormatter(value) {
    return value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
}

export const vatRegex = new RegExp(/^BE[0-9]{10}$/, "i");

export const vatValidator = (function () { // eslint-disable-line
    const options = {
        rules: [],
        required: false,
    };
    options.rules.push({
        name: 'vat',
        pattern: vatRegex,
        error: 'Requires valid VAT no.',
    });
    return new Validator(options);
})();

export function sortDate(current, next) {
    const iso = moment(current, 'LLL').toDate();
    const nextIso = moment(next, 'LLL').toDate();

    if (iso < nextIso) {
        return -1;
    } else if (iso > nextIso) {
        return 1;
    }
    return 0;
}
