import { defineMessages } from 'react-intl';

export default defineMessages({
    languages: {
        nl: {
            id: 'app.languages.nl',
            defaultMessage: 'Dutch',
        },
        en: {
            id: 'app.languages.en',
            defaultMessage: 'English',
        },
        fr: {
            id: 'app.languages.fr',
            defaultMessage: 'French',
        },
    },
    countries: {
        be: {
            id: 'app.countries.be',
            defaultMessage: 'Belgium',
        },
        nl: {
            id: 'app.countries.nl',
            defaultMessage: 'Netherlands',
        },
        fr: {
            id: 'app.countries.fr',
            defaultMessage: 'France',
        },
        lu: {
            id: 'app.countries.lu',
            defaultMessage: 'Luxembourg',
        },
    },
});
