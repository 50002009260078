import { NEWS_INIT, NEWS_ADD, NEWS_EDIT, NEWS_DELETE } from './constants';

const initialState = [];

function newsReducer(state = initialState, action) {
    let newState = [...state];

    switch (action.type) {
        case NEWS_INIT: {
            newState = action.news;
            break;
        }
        case NEWS_ADD: {
            newState.push(action.news);
            break;
        }
        case NEWS_EDIT: {
            const news = newState.find((i) => i.id === action.news.id);
            Object.assign(news, action.news);
            break;
        }
        case NEWS_DELETE: {
            newState = newState.filter((i) => i.id !== action.id);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default newsReducer;
