import {
    DOSSIERS_INIT,
    DOSSIERS_NEW,
    DOSSIERS_EDIT,
    DOSSIERS_DOCUMENTS_INIT,
    DOSSIERS_DOCUMENTS_NEW,
    DOSSIERS_DOCUMENTS_EDIT,
    DOSSIERS_ACTIONS_INIT,
    ARCHIVE_INIT,
    ARCHIVE_ADD,
    FUNDERS_INIT,
} from './constants';

export function initDossiers(dossiers, counters, requirements, count = 0, filters = {}) {
    return {
        type: DOSSIERS_INIT,
        dossiers,
        counters,
        requirements,
        count,
        filters,
    };
}

export function newDossier(dossier) {
    return {
        type: DOSSIERS_NEW,
        dossier,
    };
}

export function editDossier(dossier) {
    return {
        type: DOSSIERS_EDIT,
        dossier,
    };
}

export function initDossierDocuments(dossierId, documents, token) {
    function addToken(document) {
        const documentObj = document;
        documentObj.result_file = `${documentObj.result_file}?token=${token}`;
        return documentObj;
    }
    _.map(documents, addToken);

    return {
        type: DOSSIERS_DOCUMENTS_INIT,
        dossierId,
        documents,
    };
}

export function newDossierDocument(dossierId, document) {
    return {
        type: DOSSIERS_DOCUMENTS_NEW,
        dossierId,
        document,
    };
}

export function editDossierDocument(dossierId, document) {
    return {
        type: DOSSIERS_DOCUMENTS_EDIT,
        dossierId,
        document,
    };
}

export function initDossierActions(dossierId, actions) {
    return {
        type: DOSSIERS_ACTIONS_INIT,
        dossierId,
        actions,
    };
}

export function initArchive(dossiers) {
    return {
        type: ARCHIVE_INIT,
        dossiers,
    };
}

export function addToArchive(dossier) {
    return {
        type: ARCHIVE_ADD,
        dossier,
    };
}

export function initFunders(funders) {
    return {
        type: FUNDERS_INIT,
        funders,
    };
}
