export function getStatusIcon(status) {
    switch (status) {
        case 'PRESENT':
            return 'check-circle';
        case 'MISSING':
            return 'times-circle';
        default:
            return 'info-circle';
    }
}
