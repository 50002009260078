import { API_URL } from 'components/App/constants';

export const SUPPORT_URL = `${API_URL}support/`;
export const CONTACTS_URL = `${API_URL}contacts/`;

export const CONTACTS_INIT = 'Contacts/init';
export const CONTACTS_NEW = 'Contacts/new';
export const CONTACTS_EDIT = 'Contacts/edit';
export const CONTACTS_DELETE = 'Contacts/delete';
export const SUPPORT_INIT = 'Support/init';
