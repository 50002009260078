import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FieldBase } from './FieldBase';

class Checkbox extends FieldBase {
    getContainerClasses() {
        const vm = this;
        const classList = [
            'input-container',
            'input-container-checkbox',
            'checkbox-container',
            `checkbox-container-${vm.props.name}`,
        ];
        if (vm.props.className) {
            classList.push(vm.props.className);
        }
        if (vm.state.focus) {
            classList.push('input-focused');
        }
        if (vm.state.disabled) {
            classList.push('input-disabled');
        }
        return classList.join(' ');
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId];
        const field = form.fields[vm.props.name] || {};
        const value = typeof field.value === 'undefined'
            ? typeof vm.props.value === 'undefined'
                ? !!vm.props.defaultValue
                : vm.props.value
            : field.value;
        const theme = vm.props.theme || form.theme;
        let error = vm.state.errors[0];
        const inputId = vm.props.id || `${vm.props.formId}_${vm.props.name}`;

        if (error) {
            error = <div className="input-validation-error"><FormattedMessage id={error.id} defaultMessage={error.error} /></div>;
        }

        return (
            <div className={vm.getContainerClasses()}>
                {vm.props.label ? <label htmlFor={inputId}><FormattedMessage {...vm.props.label} /></label> : null}
                {vm.props.customLabel ? <label htmlFor={inputId}>{vm.props.customLabel}</label> : null}
                <input
                    type="checkbox"
                    id={inputId}
                    name={vm.props.name}
                    checked={value}
                    onFocus={vm.onFocus}
                    onBlur={vm.onBlur}
                    onChange={vm.onInput}
                />
                <ReactCSSTransitionGroup transitionName={theme} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {error}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null)(Checkbox);
