import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import appMessages from 'components/App/messages';
import Loader from 'components/App/partials/Loader';
import UserCard from '../components/UserCard';
import { SUPPORT_URL } from '../constants';
import { initSupport } from '../actions';
import { DEFAULT_LOCALE } from 'components/App/constants';
import '../styles/Support.scss';

class Support extends React.Component {
    static propTypes = {
        contacts: PropTypes.array,
    };
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            contacts: props.contacts,
            loading: !props.contacts.length,
            cards: [],
        };
    }
    componentDidMount() {
        const vm = this;
        const { dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.get(SUPPORT_URL, { headers }).then((response) => {
            vm.setState({ loading: false }, () => {
                dispatch(initSupport(response.data.result));
            });
        });
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (nextProps.contacts.length || !_.isEqual(nextProps.contacts, vm.props.contacts)) {
            vm.state.cards = _.map(nextProps.contacts, (contact, index) => <UserCard key={index} user={contact} className="flex-50" />);
        }
    }
    renderSupportSection(translationHeading, cards) {
        if (!translationHeading || !cards.length) return null;
        return (
            <div className="support-section">
                <h2><FormattedMessage {...translationHeading} /></h2>
                <div className="support-list">
                    {cards.map((card) => card)}
                </div>
            </div>
        );
    }
    render() {
        const vm = this;
        const { cards } = vm.state;
        const cardsLinkedSalesUser = cards.filter((card) => !card.props.user.contact_for_client && !card.props.user.contact_for_vendor);
        const cardsContactVendor = cards.filter((card) => card.props.user.contact_for_vendor);
        const cardsContactClient = cards.filter((card) => card.props.user.contact_for_client);

        return (
            <div id="Support">
                <div className="support-header layout-row">
                    <h1 className="flex-nogrow">
                        <FormattedMessage {...appMessages.support} />
                    </h1>
                </div>
                <Loader active={vm.state.loading}>
                    {this.renderSupportSection(appMessages.accountManager, cardsLinkedSalesUser)}
                    {this.renderSupportSection(appMessages.contactsForVendor, cardsContactVendor)}
                    {this.renderSupportSection(appMessages.contactsForClient, cardsContactClient)}
                </Loader>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('contacts').get('support').toJS(),
    (user, contacts) => ({ user, contacts })
);

export default connect(mapStateToProps, null)(Support);
