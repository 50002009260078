import PropTypes from 'prop-types';
import React from 'react';
import ReactDatepicker from 'react-datepicker';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { FieldBase } from './FieldBase';
import 'react-datepicker/dist/react-datepicker.css';

class Datepicker extends FieldBase {
    constructor(props) {
        super(props);

        const vm = this;
        vm.onChange = vm.onChange.bind(vm);
    }
    onChange(date) {
        const vm = this;
        const fakeEvent = { persist: _.noop, currentTarget: { value: date } };
        vm.onInput(fakeEvent);
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId];
        const field = form.fields[vm.props.name] || {};
        const containerClasses = `input-container input-container-date input-container-${vm.props.name} ${vm.props.className} ${vm.state.focus ? 'input-focused' : ''}`;
        let selected = field.value || vm.props.value || vm.props.defaultValue;
        selected = selected ? moment(selected) : '';

        return (
            <div className={containerClasses}>
                <ReactDatepicker
                    dateFormat={vm.props.dateFormat || 'YYYY-MM-DD'}
                    selected={selected}
                    onChange={vm.onChange}
                    onFocus={vm.onFocus}
                    onBlur={vm.onBlur}
                />
                {vm.props.label ? <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} /></label> : null}
            </div>
        );
    }
}

Datepicker.propTypes = {
  ...FieldBase.propTypes,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
};

const formsSelector = createSelector(
  (state) => state.get('forms').toJS(),
  (forms) => ({ forms }),
);
const localeSelector = createSelector(
  (state) => state.get('language').toJS(),
  (locale) => locale
);

const mapStateToProps = (state) => {
  return {
    ...formsSelector(state),
    ...localeSelector(state),
  };
}

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(Datepicker, { withRef: true }));