import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Loader from 'components/App/partials/Loader';
import List from 'components/App/partials/List';
import appMessages from 'components/App/messages';
import { sortDate } from 'components/App/utils';
import messages from '../messages';

export class ArchiveList extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        dossiers: PropTypes.array,
        intl: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        const { intl, dossiers } = props;
        vm.state = {
            loading: !dossiers.length,
        };
        vm.columns = [
            { label: intl.formatMessage(appMessages.vendor), key: 'vendor_name' },
            { label: intl.formatMessage(appMessages.client), key: 'client_name' },
            { label: intl.formatMessage(messages.fields.created_at), key: 'created_at', formatter: (value) => moment(value).format('LLL') },
            { label: intl.formatMessage(messages.fields.archived_at), key: 'archived_at', formatter: (value) => value ? moment(value).format('LLL') : intl.formatMessage(appMessages.na) },
        ];
        vm.onRowClick = vm.onRowClick.bind(vm);
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;

        if (nextProps.dossiers || !_.isEqual(nextProps.dossiers, vm.props.dossiers)) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    onRowClick(event) {
        const vm = this;
        const dossierId = event.currentTarget.getAttribute('data-id');
        const { parentNode } = event.target;

        if (parentNode.tagName !== 'A' && !parentNode.classList.contains('xs2-menu-trigger')) {
            vm.context.router.push(`/dossiers/${dossierId}`);
        }
    }
    render() {
        const vm = this;
        const { dossiers } = vm.props;

        return (
            <div className="archive-list-container">
                <Loader active={vm.state.loading}>
                    {!dossiers.length ? <p>No archived dossiers found</p> :
                    <List
                        name="archive"
                        columns={vm.columns}
                        data={dossiers}
                        sortable={[
                            { column: 'vendor_name', sortFunction: 'CaseInsensitive' },
                            { column: 'client_name', sortFunction: 'CaseInsensitive' },
                            { column: 'created_at', sortFunction: sortDate },
                            { column: 'archived_at', sortFunction: sortDate },
                        ]}
                        defaultSort={{ column: 'created_at', sortFunction: sortDate }}
                        onRowClick={vm.onRowClick}
                    />}
                </Loader>
            </div>
        );
    }
}

export default injectIntl(ArchiveList);
