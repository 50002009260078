import { NEWS_INIT, NEWS_ADD, NEWS_EDIT, NEWS_DELETE } from './constants';

export function initNews(news) {
    return {
        type: NEWS_INIT,
        news,
    };
}

export function addNews(news) {
    return {
        type: NEWS_ADD,
        news,
    };
}

export function editNews(news) {
    return {
        type: NEWS_EDIT,
        news,
    };
}

export function deleteNews(id) {
    return {
        type: NEWS_DELETE,
        id,
    };
}
