import { TOKEN_DURATION } from 'components/App/constants';
import {
    LOGIN,
    ATLANCE_ROLES,
    ATLANCE_SALES_ROLE,
    ATLANCE_MANAGER_ROLE,
    VENDOR_ROLES,
    VENDOR_SALES_ROLE,
    VENDOR_MANAGER_ROLE,
    ADMIN_ROLES,
} from './constants';

const auth = {
    logout,
    getToken,
    setToken,
    getCurrentUser,
    setCurrentUser,
    loggedIn,
    tokenExpired,
    userHasRole,
    userIsSales,
    userIsManager,
    userIsAdmin,
    userIsAtlance,
    userIsVendor,
    userIsAtlanceSales
};

export { requireAuth };
export default auth;

function logout(cb) {
    // Remove tokens and users
    delete localStorage.token;
    delete localStorage.tokenExpirationDate;
    delete localStorage.currentUser;
    delete localStorage.lastDossierCall;
    delete localStorage.restoreFiltersOnDossier;
    delete localStorage.lastDossierPage;

    if (cb) { cb(); }
}
function getToken() {
    return localStorage.token;
}
function setToken(token) {
    localStorage.token = token;
    localStorage.tokenExpirationDate = moment().format();
}
function getCurrentUser() {
    return JSON.parse(localStorage.currentUser);
}
function setCurrentUser(user) {
    localStorage.currentUser = JSON.stringify(user);
}
function loggedIn() {
    return !!localStorage.token;
}
function tokenExpired() {
    return moment().diff(localStorage.tokenExpirationDate, 'hours') >= TOKEN_DURATION;
}
function userHasRole(user, role) {
    return !!~user.role.indexOf(role); // eslint-disable-line no-bitwise
}
function userIsSales(user) {
    return (
        user.role === VENDOR_SALES_ROLE ||
        user.role === ATLANCE_SALES_ROLE
    );
}
function userIsManager(user) {
    return (
        user.role === VENDOR_MANAGER_ROLE ||
        user.role === ATLANCE_MANAGER_ROLE
    );
}
function userIsAdmin(user) {
    return !!~ADMIN_ROLES.indexOf(user.role) || user.is_superuser; // eslint-disable-line no-bitwise
}
function userIsAtlance(user) {
    return !!~ATLANCE_ROLES.indexOf(user.role); // eslint-disable-line no-bitwise
}
function userIsAtlanceSales(user) {
    return (
        user.role === ATLANCE_SALES_ROLE
    );
}

function userIsVendor(user) {
    return !!~VENDOR_ROLES.indexOf(user.role); // eslint-disable-line no-bitwise
}

function requireAuth(store) {
    return (nextState, replace) => {
        if (!auth.loggedIn() || auth.tokenExpired()) {
            auth.logout();
            replace({
                pathname: '/login',
                state: { nextPathname: nextState.location.pathname },
            });
        } else {
            store.dispatch({
                type: LOGIN,
                user: auth.getCurrentUser(),
            });
        }
    };
}
