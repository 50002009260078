import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'common/Util';
import appMessages from 'components/App/messages';
import messages from 'components/Dossiers/messages';

class DossierCounters extends React.Component {
    static defaultProps = {
        className: '',
        onClick: () => {},
    }
    static propTypes = {
        className: PropTypes.string,
        counters: PropTypes.object,
        onClick: PropTypes.func,
    }
    onClick(key) {
        const vm = this;

        return (event) => {
            vm.props.onClick(event, key);
        };
    }
    getLabelDescriptor(key) {
        return key === 'TOTAL' ? appMessages.total : messages.states[key];
    }
    render() {
        const vm = this;
        const { formatMessage } = vm.props.intl;

        return (
            <div className={`dossier-counters ${vm.props.className}`}>
                {_.map(vm.props.counters, (count, key) =>
                    // eslint-disable-next-line
                    <div key={key} className="dossier-counter" onClick={vm.onClick(key)}>
                        <span>{count}</span>
                        <Tooltip label={formatMessage(this.getLabelDescriptor(key))} show />
                    </div>
                )}
            </div>
        );
    }
}

export default injectIntl(DossierCounters);
