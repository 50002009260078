import React from 'react';
import _ from 'lodash';
import * as formComponents from '.';

export function mapFormIdToChildProps(children, formId) {
    return React.Children.map(children, (child) => {
        if (child && ~_.values(formComponents).indexOf(child.type)) {
            return React.cloneElement(child, { formId });
        }
        return child;
    });
}

export function mapFieldGroupToChildProps(children, name, index) {
    const fieldGroup = index != null ? `${name}[${index}]` : name;
    return React.Children.map(children, (child) => {
        if (child && ~_.values(formComponents).indexOf(child.type)) {
            return React.cloneElement(child, { fieldGroup });
        }
        return child;
    });
}
