/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import nlLocaleData from 'react-intl/locale-data/nl';
import deLocaleData from 'react-intl/locale-data/fr';

import { DEFAULT_LOCALE } from './components/App/constants'; // eslint-disable-line
import enTranslationMessages from './translations/en.json';
import nlTranslationMessages from './translations/nl.json';
import deTranslationMessages from './translations/fr.json';

export const appLocales = [
    'en',
    'nl',
    'fr',
];

addLocaleData(enLocaleData);
addLocaleData(nlLocaleData);
addLocaleData(deLocaleData);

export const formatTranslationMessages = (locale, messages) => {
    const defaultFormattedMessages = locale !== DEFAULT_LOCALE
        ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
        : {};
    return Object.keys(messages).reduce((formattedMessages, key) => {
        let message = messages[key];
        if (!message && locale !== DEFAULT_LOCALE) {
            message = defaultFormattedMessages[key];
        }
        return Object.assign(formattedMessages, { [key]: message });
    }, {});
};

export const translationMessages = {
    en: formatTranslationMessages('en', enTranslationMessages),
    nl: formatTranslationMessages('nl', nlTranslationMessages),
    fr: formatTranslationMessages('fr', deTranslationMessages),
};
