import appMessages from 'components/App/messages';
import auth from 'components/Users/auth';
import messages from './messages';

export function getVendorActions(id, user = {}) {
    const actions = [
        { label: messages.calculationTables, url: `/vendors/${id}/calctables`, icon: 'icon-calculator' },
    ];

    if (auth.userIsAtlance(user) || auth.userIsAdmin(user)) {
        actions.push({ label: appMessages.edit, url: `/vendors/${id}/edit`, icon: 'icon-note' });
    }
    if (auth.userIsAtlance(user)) {
        actions.push({ label: messages.contractOptions, url: `/vendors/${id}/options`, icon: 'icon-doc' });
    }
    if (auth.userIsAdmin(user) || auth.userIsVendor(user)) {
        actions.push({ label: messages.addClient, url: `/vendors/${id}/clients/new`, icon: 'icon-user-follow' });
    }

    return actions;
}
