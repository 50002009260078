import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Autocomplete, Button, Form, FormGroup } from 'common/Form';

import appMessages from 'components/App/messages';
import { editDossier } from '../actions';
import { DOSSIERS_URL, FUNDERS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';

class FunderForm extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            funders: [],
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const { user } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        axios.get(FUNDERS_URL, { headers }).then((response) => {
            vm.setState({
                funders: response.data.result
            });
        });
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const dossierId = vm.context.router.params.id;

        axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'CHOOSEFUNDER', data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${dossierId}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.funderSubmitted} tagName="p" />);
        });
    }
    render() {
        const vm = this;
        const funderOptions = _.map(vm.state.funders, (funder) => ({
            value: String(funder.id),
            label: funder.name,
        }));
        return (
            <div>
                <h1 className="center-align">
                  <FormattedMessage {...appMessages.chooseFunder} />
                </h1>
                <Form id="funder" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Autocomplete
                            name="funder_id"
                            label={appMessages.funder}
                            options={funderOptions}
                            defaultValue={''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

FunderForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(FunderForm);
