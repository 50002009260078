import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Filter } from 'common/Util';
import Chart from 'components/Dashboard/partials/Chart';
import Loader from 'components/App/partials/Loader';
import DossierCounters from 'components/Dossiers/partials/DossierCounters';
import auth from 'components/Users/auth';
import sideNavMessages from '../partials/SideNav/messages';
import messages from '../messages';
import { DASHBOARD_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';

export class Dashboard extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            latestRequests: [],
            pendingDossiers: [],
            productionValue: [],
            creditRequestsStatus: {},
        };

        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.onCounterClick = vm.onCounterClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        const { headers } = vm;

        axios.all([
            axios.get(`${DASHBOARD_URL}latest_requests/?period=120&period_type=days`, { headers }),
            axios.get(`${DASHBOARD_URL}pending_requests/`, { headers }),
            axios.get(`${DASHBOARD_URL}production_value/`, { headers }),
            axios.get(`${DASHBOARD_URL}credit_requests_status/`, { headers }),
        ]).then((response) => {
            vm.state.latestRequests = vm.parseLatestRequests(response[0].data.result);
            vm.state.pendingDossiers = vm.parsePendingDossiers(response[1].data);
            vm.state.productionValue = vm.parseProductionValue(response[2].data.result);
            vm.state.creditRequestsStatus = vm.parseCreditRequestsStatus(response[3].data);
            vm.setState(vm.state);
        });
    }
    onCounterClick(event, key) {
        const vm = this;

        if (key === 'ARCHIVED') {
            vm.context.router.push('/dossiers/archive');
        } else {
            const status = key === 'TOTAL' ? '' : key;
            vm.context.router.push({ pathname: '/dossiers', query: { status } });
        }
    }
    parseLatestRequests(data) {
        const columns = [
            'Day', 'Dossiers',
            { role: 'style' },
            { role: 'annotation' },
            { role: 'tooltip', p: { html: true } },
        ];
        const rows = _.map(data, (item) => [
            moment(item.period).format('DD/M'),
            item.count,
            '#DEDAD9',
            item.count,
            `<div class="xs2-chart-tooltip">
                <b>${moment(item.period).format('DD/M')}</b><br />
                Dossiers: <b>${item.count}</b><br />
                Amount: <b>${Filter.filters.currency(item.software + item.hardware, ',', '&euro;')}<b>
            </div>`,
        ]);
        const dates = _.map(data, (item) => item.period);
        return {
            dates,
            data: [columns, ...rows],
            options: {
                legend: 'none',
                tooltip: { isHtml: true },
                bar: {
                    groupWidth: '65%',
                },
                chartArea: {
                    height: '87%',
                    width: '60%',
                    left: '10%',
                    top: '5%',
                },
                annotations: {
                    alwaysOutside: true,
                    textStyle: {
                        fontName: 'Open Sans',
                        fontSize: 24,
                    },
                    boxStyle: {
                        stroke: '#A0569D',
                        strokeWidth: 2,
                        rx: 5,
                        ry: 5,
                    },
                    stem: {
                        length: 7,
                    },
                },
            },
        };
    }
    parsePendingDossiers(data) {
        const columns = ['Vendor', 'Amount', { role: 'tooltip', p: { html: true } }];
        const rows = _.map(data.result, (item) => [
            item.vendor__name,
            item.amount,
            `<div class="xs2-chart-tooltip">
                <b>${item.vendor__name}</b><br />
                Dossiers: <b>${item.count}</b><br />
                Amount: <b>${Filter.filters.currency(item.amount, ',', '&euro;')}</b><br />
            </div>`,
        ]);
        return {
            data: [columns, ...rows],
            options: {
                legend: 'none',
                pieHole: 0.35,
                colors: ['#a0569d', '#dedad9'],
                tooltip: { isHtml: true },
                chartArea: {
                    height: '90%',
                    width: '100%',
                    // left: '10%',
                    top: '5%',
                },
                pieSliceText: 'label',
                slices: _.map(Array(20), (u, i) => !(i % 2)
                    ? { textStyle: { color: '#fff' } }
                    : { textStyle: { color: '#000' } }
                ),
            },
            extra: data.extra,
        };
    }
    parseProductionValue(data) {
        const columns = ['Month', 'IM', { role: 'annotation' }, 'PV', { role: 'annotation' }];
        let rows = {};

        _.forEach(data.im, (item) => {
            rows[item.period] = [item.count, item.count, 0, null];
        });

        _.forEach(data.pv, (item) => {
            if (rows[item.period]) {
                rows[item.period][2] = item.count;
                rows[item.period][3] = item.count;
            } else {
                rows[item.period] = [0, null, item.count, item.count];
            }
        });

        rows = _.map(rows, (item, key) => [moment(key).format('DD/M'), ...item]);

        return {
            data: [columns, ...rows],
            options: {
                chartArea: {
                    height: '70%',
                    width: '82%',
                    left: '7%',
                },
                legend: {
                    position: 'bottom',
                    alignment: 'start',
                },
                colors: ['#a0569d', '#dedad9'],
                isStacked: true,
            },
        };
    }
    parseCreditRequestsStatus(data) {
        const parsedData = {
            TOTAL: data.extra.total,
        };

        _.forEach(data.result, (item) => {
            parsedData[item.status] = item.count;
        });

        return parsedData;
    }
    render() {
        const vm = this;
        const { intl, user } = vm.props;
        const {
            latestRequests,
            pendingDossiers,
            productionValue,
            creditRequestsStatus,
        } = vm.state;
        return (
            <div id="Dashboard">
                <h1><FormattedMessage {...sideNavMessages.navItems.dashboard} /></h1>
                <Loader active={vm.state.loading} className="charts" style={{ margin: '-10px' }}>
                    <div className="layout-row layout-xs-column layout-wrap layout-align-space-between-center">
                        <Chart
                            title={intl.formatMessage(messages.requests)}
                            type="BarChart"
                            className="well well-default flex-xs-100 flex-45"
                            height="300px"
                            width="100%"
                            chartEvents={[{
                                eventName: 'select',
                                callback($chart) {
                                    const selection = $chart.chart.getSelection()[0];

                                    if (selection) {
                                        const createdAt = moment(latestRequests.dates[selection.row]).format('YYYY-MM-DD');
                                        vm.context.router.push({ pathname: '/dossiers', query: { created_at: createdAt, exclude_status: ['NEW', 'REJECTED'] } });
                                    }
                                },
                            }]}
                            {...latestRequests}
                        />
                        <Chart
                            title={intl.formatMessage(messages.creditRequests)}
                            type="BarChart"
                            className="well well-default flex-xs-100 flex-45"
                            height="300px"
                            width="100%"
                        >
                            <DossierCounters
                                className="layout-row layout-wrap layout-align-center-start"
                                counters={creditRequestsStatus}
                                onClick={vm.onCounterClick}
                            />
                        </Chart>
                    </div>
                    {auth.userIsAtlance(user) ?
                        <div className="layout-row layout-xs-column layout-wrap layout-align-space-between-center">
                            <Chart
                                title={intl.formatMessage(messages.production)}
                                type="ColumnChart"
                                className="well well-default flex-xs-100 flex-45"
                                height="300px"
                                width="100%"
                                chartEvents={[{
                                    eventName: 'select',
                                    callback($chart) {
                                        const selection = $chart.chart.getSelection()[0];

                                        if (selection) {
                                            const vendorName = pendingDossiers.data[selection.row + 1][0];
                                            vm.context.router.push({ pathname: '/dossiers', query: { vendor_name: vendorName } });
                                        }
                                    },
                                }]}
                                {...productionValue}
                            />
                            <Chart
                                title={intl.formatMessage(messages.pendingDossiers)}
                                type="PieChart"
                                className="well well-default flex-xs-100 flex-45"
                                height="300px"
                                width="100%"
                                overlay={pendingDossiers.extra && (
                                    <Filter
                                        filter="currency"
                                        prefix="&euro;"
                                        separator=","
                                        component="h3"
                                    >
                                        {pendingDossiers.extra.total_amount}
                                    </Filter>
                                )}
                                chartEvents={[{
                                    eventName: 'select',
                                    callback($chart) {
                                        const selection = $chart.chart.getSelection()[0];

                                        if (selection) {
                                            const vendorName = pendingDossiers.data[selection.row + 1][0];
                                            vm.context.router.push({ pathname: '/dossiers', query: { vendor_name: vendorName } });
                                        }
                                    },
                                }]}
                                {...pendingDossiers}
                            />
                        </div>
                    : null}
                </Loader>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (user) => ({ user })
);

export default connect(mapStateToProps, null)(injectIntl(Dashboard));
