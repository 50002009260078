import { API_URL } from 'components/App/constants';

export const TOAST_INIT = 'Dashboard/toast/init';
export const TOAST_ADD = 'Dashboard/toast/add';
export const TOAST_REMOVE = 'Dashboard/toast/remove';

export const DATA_TIMEOUT_DURATION = 10000; // miliseconds
export const RECONNECT_TIMEOUT_DURATION = 30; // seconds

export const NOTIFY_INIT = 'Dashboard/notifications/init';

export const SIDENAV_TOGGLE = 'Dashboard/sideNav/toggle';
export const SIDENAV_TOGGLE_SMALL = 'Dashboard/sideNav/toggle/small';

export const DASHBOARD_URL = `${API_URL}statistics/`;
export const SITESETTINGS_URL = `${API_URL}site-settings/`;
