import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Datepicker, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { editDossier } from '../actions';
import { DOSSIERS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';

class SubmitToSNIForm extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.state = {
            endDate: '',
        };
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    onSubmit(data) {
        const vm = this;
        const { user, toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const dossierId = vm.context.router.params.id;

        data.contract_startdate = moment(data.contract_startdate).format('Y-M-D');
        data.contract_enddate = moment(data.contract_enddate).format('Y-M-D');

        axios.post(`${DOSSIERS_URL}${dossierId}/actions/`, { action_code: 'SUBMITTOSNI', data }, { headers }).then((response) => {
            dispatch(editDossier(response.data.result));
            vm.context.router.push(`/dossiers/${dossierId}`);
            return toast.success('Success!', <FormattedMessage {...messages.notifications.submittedSNI} tagName="p" />);
        });
    }
    onStartDateInput(event) {
        const vm = this;
        const { duration } = vm.props.dossier;
        if (duration) {
            const startDate = event.currentTarget.value;
            const calculatedEndDate = moment(startDate).add(duration, 'months');
            vm.setState({ endDate: calculatedEndDate });
        }
    }
    render() {
        const vm = this;
        return (
            <div id="SubmitSNIForm" className="submit-sni-form">
                <h1 className="center-align"><FormattedMessage {...appMessages.submitToSNI} /></h1>
                <Form id="submit-sni" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup>
                        <Datepicker
                            name="contract_startdate"
                            label={messages.fields.contract_startdate}
                            onInput={vm.onStartDateInput.bind(vm)}
                        />
                        <Datepicker
                            name="contract_enddate"
                            label={messages.fields.contract_enddate}
                            defaultValue={vm.state.endDate}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.send} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

SubmitToSNIForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (user, toast) => ({ user, toast })
);

export default connect(mapStateToProps, null)(SubmitToSNIForm);
