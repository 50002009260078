import { fromJS } from 'immutable';
import {
    DOWNLOADS_INIT,
    DOWNLOADS_NEW,
    DOWNLOADS_DELETE,
} from './constants';

const initialState = fromJS([]);

function downloadsReducer(state = initialState, action) {
    let newState = fromJS([...state]);

    switch (action.type) {
        case DOWNLOADS_INIT: {
            newState = fromJS(action.downloads);
            break;
        }
        case DOWNLOADS_NEW: {
            newState = newState.push(fromJS(action.download));
            break;
        }
        case DOWNLOADS_DELETE: {
            const index = newState.findIndex((download) => +download.get('id') === +action.id);
            newState = newState.splice(index, 1);
            break;
        }
        default: {
            return state;
        }
    }

    return newState;
}

export default downloadsReducer;
