import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {Link} from 'react-router';
import ReactModal from 'react-modal';
import {FormattedMessage, injectIntl} from 'react-intl';
import auth from 'components/Users/auth';
import messages from '../messages';
import {VENDORS_URL} from '../constants';

import VendorList from '../partials/Vendors';
import VendorForm from '../partials/VendorForm';
import ContractOptionsForm from '../partials/ContractOptionsForm';
import VendorClientsForm from '../partials/VendorClientsForm';
import CalcutlationTableForm from '../partials/CalculationTablesForm';
import EquipmentTypeForm from '../partials/EquipmentTypeForm';
import {initVendors, deleteVendor} from '../actions';
import {DEFAULT_LOCALE} from 'components/App/constants';
import '../styles/vendors.scss';

const FORM_ACTIONS = ['new', 'edit', 'options', 'calctables', 'clients', 'delete', 'equipment'];

class Vendors extends React.PureComponent {
    constructor(props) {
        super(props);

        const vm = this;
        vm.state = {};
        vm.toggleForm = vm.toggleForm.bind(this);
    }

    componentDidMount() {
        const vm = this;
        vm.getVendors();
        vm.handleForm();
    }

    componentWillReceiveProps() {
        const vm = this;
        vm.handleForm();
    }

    getVendors() {
        const vm = this;
        const {dispatch} = vm.props;
        const headers = {
            Authorization: `Token ${vm.props.user.token}`,
            'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl'
        };

        axios.get(`${VENDORS_URL}`, {headers}).then((response) => {
            dispatch(initVendors(response.data.result));
        });
    }

    handleForm() {
        const vm = this;
        const {dispatch, toast, intl, user} = vm.props;
        const pathArray = vm.context.router.getCurrentLocation().pathname.split('/').filter((value) => !!value);
        const action = pathArray[pathArray.length - 1];
        const headers = {
            Authorization: `Token ${vm.props.user.token}`,
            'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl'
        };

        if (~FORM_ACTIONS.indexOf(action)) {
            const vendorId = +vm.context.router.params.id;
            const vendor = _.find(vm.props.vendors, (i) => i.id === +vendorId);

            if (action === 'new' || (vendorId && (action === 'edit' || action === 'options' || action === 'clients'))) {
                vm.state.action = action;
            } else if (action === 'delete' && vendorId) {
                const confirmed = confirm(intl.formatMessage({
                    ...messages.notifications.confirmVendorDeletion,
                    values: {...vendor}
                }));

                if (confirmed) {
                    axios.delete(`${VENDORS_URL}${vendorId}/`, {headers}).then(() => {
                        dispatch(deleteVendor(vendorId));
                        toast.success('Success!', <FormattedMessage {...messages.notifications.deletedVendor}
                                                                    values={{...vendor}} tagName="p"/>);
                    });
                }
                vm.context.router.push('/vendors');
            }

            if (action === 'new' || action === 'edit') {
                if (auth.userIsAdmin(user) || auth.userIsVendor(user)) {
                    vm.state.form = ~pathArray.indexOf('clients')
                        ? (<VendorClientsForm vendorId={vendorId}/>)
                        : (<VendorForm action={vm.state.action}/>);
                    vm.state.showForm = true;
                }
            } else if (action === 'options') {
                vm.state.form = (<ContractOptionsForm vendorId={vendorId}/>);
                vm.state.showForm = true;
            } else if (action === 'calctables') {
                vm.state.form = (<CalcutlationTableForm vendorId={vendorId}/>);
                vm.state.showForm = true;
            } else if (action === 'clients') {
                vm.state.form = (<VendorClientsForm vendorId={vendorId}/>);
                vm.state.showForm = true;
            } else if (action === 'equipment') {
                vm.state.form = (<EquipmentTypeForm vendorId={vendorId}/>);
                vm.state.showForm = true;
            }
        } else {
            vm.state.showForm = false;
        }
    }

    toggleForm(action) {
        return () => {
            const vm = this;

            if (vm.state.showForm) {
                const referrer = vm.context.router.getCurrentLocation().query.referrer;
                vm.context.router.push(referrer || '/vendors');
            }

            vm.setState({action, showForm: !vm.state.showForm});
        };
    }

    render() {
        const vm = this;
        const modalOptions = {
            isOpen: vm.state.showForm,
            contentLabel: 'Vendor form',
            className: 'modal-dashboard',
            overlayClassName: 'modal-overlay-dashboard',
            onRequestClose: vm.toggleForm(vm.state.action),
            shouldCloseOnOverlayClick: true,
        };

        return (
            <div id="Vendors">
                <div className="vendors-header layout-row">
                    <h1 className="flex">Vendors</h1>
                    <div className="vendors-controls flex-nogrow">
                        <Link to={{
                            pathname: '/vendors/new',
                            query: {referrer: vm.context.router.getCurrentLocation().pathname}
                        }}
                              className="vendor-new button button-default"><FormattedMessage {...messages.addVendor} /></Link>
                    </div>
                </div>
                <div className="vendors">
                    <VendorList/>
                </div>
                <ReactModal {...modalOptions}>
                    <Link to={vm.props.location.query.referrer || '/vendors'} className="modal-close"
                          onClick={vm.toggleForm()}><i className="fa fa-arrow-left"></i></Link>
                    {vm.state.form}
                </ReactModal>
            </div>
        );
    }
}

Vendors.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('vendors'),
    (state) => state.get('dashboard').get('toast'),
    (user, vendors, toast) => ({user, vendors, toast}),
);

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Vendors));
