import InjectBody from './InjectBody';

import '../styles/base.scss';

class ScrollMask extends InjectBody {
    componentDidMount() {
        const vm = this;
        super.componentDidMount();

        vm._container.classList.add('xs2-scroll-mask');

        if (vm.props.className) {
            vm._container.classList.add(...vm.props.className.split(' '));
        }

        vm._container.addEventListener('click', vm.onClick);
    }

    onClick(event) {
        const scrollMaskEvent = new MouseEvent('xs2.scroll-mask.click', event);
        window.dispatchEvent(scrollMaskEvent);
    }
}

export default ScrollMask;
