import validators from './validators';

class Validator {
    constructor(options) {
        const self = this;

        self.options = options;
        self.rules = self.options.rules;
        self.validate = validatorFn;

        function validatorFn(value, cb) {
            let isValid = true;
            const errors = [];

            Object.keys(self.options).forEach((optionName) => {
                if (Object.prototype.hasOwnProperty.call(validators, optionName)) {
                    validators[optionName].rules.forEach((rule) => {
                        rule.params = self.options[optionName];
                        if (self.rules.indexOf(rule) < 0) { self.rules.push(rule); }
                    });
                }
            });

            self.rules.forEach((rule) => {
                const patternFailed = !!(rule.pattern && !rule.pattern.test(value));
                const testFailed = !!(rule.test && !rule.test(value, ...rule.params));

                if (!options.required && value === '') { return; }
                if (patternFailed || testFailed) { errors.push({ id: `Form.errors.${rule.name}`, error: rule.error }); }
            });

            if (errors.length > 0) { isValid = false; }
            if (cb && typeof cb === 'function') { return cb(isValid, errors, value); }

            return isValid;
        }
    }

    static validate(validator, value, cb) {
        let validatorFn;

        if (validator) {
            if (Object.prototype.hasOwnProperty.call(validators, validator)) {
                validatorFn = validators[validator].validate;
            } else if (typeof validator === 'object') {
                if (!(validator instanceof Validator)) {
                    return console.error('The validator function should be an instance of "Validator"'); // eslint-disable-line no-console
                }
                validatorFn = validator.validate;
            } else if (validator.constructor === Array) {
                const name = validator[0];
                const params = validator.slice();
                params.shift();

                if (Object.prototype.hasOwnProperty.call(validators, name)) {
                    validators[name].rules.forEach((rule, index) => {
                        if (rule.name === name) { validators[name].rules[index].params = params; }
                    });
                }

                validatorFn = validators[name].validate;
            }
        }

        if (!validatorFn) { return true; }
        return validatorFn(value, cb);
    }
}

export default Validator;
