import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FieldBase } from './FieldBase';

class Input extends FieldBase {
    getContainerClasses() {
        const vm = this;
        const classList = [
            'input-container',
            `input-container-${vm.props.type}`,
            `input-container-${vm.props.name}`,
        ];
        if (vm.props.className) {
            classList.push(vm.props.className);
        }
        if (vm.state.focus) {
            classList.push('input-focused');
        }
        if (vm.state.disabled) {
            classList.push('input-disabled');
        }
        if (vm.props.readOnly) {
            classList.push('input-readonly');
        }

        return classList.join(' ');
    }
    render() {
        const vm = this;
        const form = vm.props.forms[vm.props.formId];
        const theme = vm.props.theme || form.theme;
        let error = vm.state.errors[0];

        return (
            <div className={vm.getContainerClasses()}>
                {vm.props.label ? <label htmlFor={vm.props.name}><FormattedMessage {...vm.props.label} /></label> : null}
                <input
                    type={vm.props.type}
                    id={vm.props.id}
                    name={vm.props.name}
                    value={vm.state.value || vm.state.value === 0 ? vm.state.value : ''}
                    defaultChecked={!!vm.props.defaultChecked}
                    onFocus={vm.onFocus}
                    onBlur={vm.onBlur}
                    onChange={vm.onInput}
                    autoFocus={!!vm.props.autoFocus}
                    placeholder={vm.props.placeholder ? vm.props.intl.formatMessage({ ...vm.props.placeholder }) : ''}
                    disabled={vm.props.disabled}
                    readOnly={vm.props.readOnly}
                />
                {vm.props.loading ? <em className="input-loading">Loading...</em> : null}
                <ReactCSSTransitionGroup transitionName={theme} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {error && (
                        <div className="input-validation-error">
                            {typeof error === "object"
                                ? <FormattedMessage id={error.id} defaultMessage={error.error} />
                                : error
                            }
                        </div>
                    )}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('forms').toJS(),
    (forms) => ({ forms })
);

export default connect(mapStateToProps, null, null, { withRef: true })(injectIntl(Input, { withRef: true }));
