import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import ReactModal from 'react-modal';

import List from 'components/App/partials/List';
import Loader from 'components/App/partials/Loader';
import appMessages from 'components/App/messages';
import { getActionFromLocation } from 'components/App/utils';
import { ADMIN_ROLES } from 'components/Users/constants';
import auth from 'components/Users/auth';
import messages from '../messages';
import { CONTACTS_URL } from '../constants';
import ContactForm from '../components/ContactForm';
import { initContacts, deleteContact } from '../actions';

const FORM_ACTIONS = ['new', 'edit', 'delete'];

class Addressbook extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.state = {
            columns: [
                { label: intl.formatMessage(appMessages.name), key: 'name' },
                { label: intl.formatMessage(messages.email), key: 'email' },
                { label: intl.formatMessage(messages.phoneNumber), key: 'phone_number' },
                { label: intl.formatMessage(messages.mobileNumber), key: 'mobile_number' },
                { label: intl.formatMessage(appMessages.vendor), key: 'vendor_name' },
                { label: '', key: 'actions' },
            ],
            contacts: props.contacts,
            form: null,
            showForm: false,
            headers: { Authorization: `Token ${props.user.token}` },
            loading: !props.contacts.length,
        };

        vm.onRowClick = vm.onRowClick.bind(vm);
    }
    componentDidMount() {
        const vm = this;
        vm.getContacts();
        vm.handleForm();
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { user } = nextProps;

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.handleForm();
        }

        if (!_.isEqual(nextProps.contacts, vm.props.contacts)) {
            vm.state.contacts = _.map(nextProps.contacts, (contact) => {
                const mappedContact = { ...contact };
                mappedContact.actions = [];

                if (~ADMIN_ROLES.indexOf(user.role)) {
                    mappedContact.actions.push({
                        label: appMessages.edit,
                        url: `/contacts/${mappedContact.id}/edit`,
                        icon: 'icon-note',
                    });
                    mappedContact.actions.push({
                        label: appMessages.delete,
                        url: `/contacts/${mappedContact.id}/delete`,
                        icon: 'icon-trash',
                    });
                }

                return mappedContact;
            });
        }


        if (nextProps.contacts || !_.isEqual(nextProps.contacts, vm.props.contacts)) {
            vm.state.loading = false;
        }

        vm.setState(vm.state);
    }
    onRowClick(event) {
        const vm = this;
        const { router } = vm.context;
        const contactId = event.currentTarget.getAttribute('data-id');

        if (event.target.parentNode.tagName !== 'A') {
            router.push(`/contacts/${contactId}/edit`);
        }
    }
    getContacts() {
        const vm = this;
        const { headers } = vm.state;
        const { dispatch } = vm.props;

        axios.get(CONTACTS_URL, { headers }).then((response) => {
            dispatch(initContacts(response.data.result));
        });
    }
    handleForm() {
        const vm = this;
        const { headers } = vm.state;
        const { dispatch, toast } = vm.props;
        const { router } = vm.context;
        const action = getActionFromLocation(router.getCurrentLocation());


        if (~FORM_ACTIONS.indexOf(action)) {
            const contactId = router.params.id;

            if (action === 'new' || action === 'edit') {
                vm.state.action = action;
                vm.state.form = (<ContactForm action={action} />);
            } else if (action === 'delete') {
                const contact = vm.props.contacts.find((i) => +i.id === +contactId);
                const referrer = router.getCurrentLocation().query.referrer;
                vm.state.form = null;

                axios.delete(`${CONTACTS_URL}${contact.id}/`, { headers }).then(() => {
                    router.push(referrer || '/contacts');
                    dispatch(deleteContact(contact));
                    return toast.success('Success!', <FormattedMessage {...messages.notifications.deletedContact} values={{ ...contact }} tagName="p" />);
                });
            }

            if (vm.state.form) {
                vm.state.showForm = true;
            }
        } else {
            vm.state.showForm = false;
        }

        vm.setState(vm.state);
    }
    toggleForm(action) {
        return () => {
            const vm = this;
            const { router } = vm.context;

            if (vm.state.showForm) {
                const referrer = router.getCurrentLocation().query.referrer;
                router.push(referrer || '/contacts');
            }

            vm.setState({ action, showForm: !vm.state.showForm });
        };
    }
    render() {
        const vm = this;
        const { router } = vm.context;
        const { user } = vm.props;
        const { columns, contacts } = vm.state;
        const modalOptions = {
            isOpen: vm.state.showForm,
            contentLabel: 'Vendor form',
            className: 'modal-dashboard',
            overlayClassName: 'modal-overlay-dashboard',
            onRequestClose: vm.toggleForm(vm.state.action),
            shouldCloseOnOverlayClick: true,
        };

        return (
            <div className="Addressbook">
                <div className="addressbook-header layout-row">
                    <h1 className="flex-grow">
                        <FormattedMessage {...messages.addressbook} />
                    </h1>
                    {auth.userIsManager(user) || auth.userIsAdmin(user) ?
                        <div className="contacts-controls flex-nogrow layout-row layout-align-center-center">
                            <Link to={{ pathname: '/contacts/new', query: { referrer: router.getCurrentLocation().pathname } }} className="contacts-new button button-default">
                                <FormattedMessage {...appMessages.new} /> <FormattedMessage {...appMessages.contact} />
                            </Link>
                        </div>
                    : null}
                </div>
                <Loader active={vm.state.loading}>
                    <List
                        name="addressbook"
                        columns={columns}
                        data={contacts}
                        onRowClick={vm.onRowClick}
                    />
                </Loader>
                <ReactModal {...modalOptions}>
                    <Link to={router.getCurrentLocation().query.referrer || '/contacts'} className="modal-close" onClick={vm.toggleForm()}><i className="fa fa-arrow-left"></i></Link>
                    {vm.state.form}
                </ReactModal>

            </div>
        );
    }
}

Addressbook.contextTypes = {
    router: PropTypes.object,
};
Addressbook.propTypes = {
    contacts: PropTypes.array,
    user: PropTypes.object,
    intl: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('contacts').get('contacts').toJS(),
    (user, toast, contacts) => ({ user, toast, contacts })
);

export default connect(mapStateToProps, null)(injectIntl(Addressbook));
