/**
* app.js
*
* This is the entry file for the application, only setup and boilerplate
* code.
*/

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll } from 'react-router-scroll';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'components/App';

// Import selector for `syncHistoryWithStore`
import { makeSelectLocationState } from 'components/App/selectors';

// Import Language Provider
import LanguageProvider from 'components/LanguageProvider';


import configureStore from './store';

// Import i18n messages
import { translationMessages } from './i18n';

// Import root routes
import createRoutes from './routes';

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: makeSelectLocationState(),
});

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
    component: App,
    childRoutes: createRoutes(store),
};

const render = (messages) => {
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <Router
                    history={history}
                    routes={rootRoute}

                    render={
                        // Scroll to top when going to a new page, imitating default browser
                        // behaviour
                        applyRouterMiddleware(useScroll())
                    }
                />
            </LanguageProvider>
        </Provider>,
        document.getElementById('root')
    );
};

// Hot reloadable translation json files
if (module.hot) {
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept('./i18n', () => {
        render(translationMessages);
    });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    (new Promise((resolve) => {
        resolve(import('intl'));
    }))
    .then(() => Promise.all([
        import('intl/locale-data/jsonp/en.js'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
        throw err;
    });
} else {
    render(translationMessages);
}
