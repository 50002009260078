import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Form, FormGroup, Autocomplete, Checkbox, Input, Textarea, Button } from 'common/Form';

import appMessages from 'components/App/messages';
import { VENDORS_URL } from 'components/Vendor/constants';
import { addNews, editNews } from '../actions';
import { NEWS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';


class NewsForm extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        action: PropTypes.string,
        user: PropTypes.object,
    }
    constructor(props) {
        super(props);
        const vm = this;
        const { intl } = props;
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        vm.formId = `${props.action}-news`;
        vm.state = {
            news: {},
        };

        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentWillMount() {
        const vm = this;
        const { router } = vm.context;
        const { news } = vm.props;

        vm.state.news = _.find(
            news,
            (item) => +item.id === +router.params.id
        ) || {};
        vm.setState(vm.state);
    }
    componentDidMount() {
        const vm = this;
        vm.getVendors();
    }
    onSubmit(data) {
        const vm = this;
        const { headers } = vm;
        const { router } = vm.context;
        const { toast, dispatch } = vm.props;

        data.created_at = moment().toISOString();

        if (vm.props.action === 'new') {
            axios.post(NEWS_URL, data, { headers }).then((response) => {
                dispatch(addNews(response.data.result));
                router.push('/news');
                return toast.success('Success!', <FormattedMessage {...messages.notifications.createdNews} tagName="p" />);
            });
        } else if (vm.props.action === 'edit') {
            axios.patch(`${NEWS_URL}${router.params.id}/`, data, { headers }).then((response) => {
                dispatch(editNews(response.data.result));
                router.push('/news');
                return toast.success('Success!', <FormattedMessage {...messages.notifications.editedNews} tagName="p" />);
            });
        }
    }
    getVendors() {
        const vm = this;
        const { headers } = vm;
        axios.get(`${VENDORS_URL}`, { headers }).then((response) => {
            vm.state.vendors = response.data.result;
            vm.vendorOptions = _.map(
                vm.state.vendors,
                (vendor) => ({
                    value: vendor.id,
                    label: vendor.name,
                })
            );
            vm.setState(vm.state);
        });
    }
    render() {
        const vm = this;
        const { intl } = vm.props;
        const { news } = vm.state;
        const { vendorOptions } = vm;

        return (
            <div id="NewsForm">
                <h1 className="center-align"><FormattedMessage {...messages.actions[vm.props.action]} /></h1>
                <Form id={vm.formId} submitHandler={vm.onSubmit} theme="material">
                    <FormGroup id="news-info">
                        <Input
                            type="text"
                            name="title"
                            label={messages.title}
                            defaultValue={news.title}
                        />
                        <Textarea
                            name="content"
                            label={messages.content}
                            defaultValue={news.content}
                        />
                        <Checkbox
                            name="active"
                            label={messages.active}
                            defaultValue={news.active}
                        />
                        {vm.props.action === 'new' ?
                            <Autocomplete
                                name="vendors"
                                label={appMessages.vendors}
                                options={vendorOptions}
                                defaultValue={news.vendors}
                                autoBlur
                                multi
                            />
                        : null}
                    </FormGroup>
                    <FormGroup className="news-submit">
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

NewsForm.propTypes = {
    intl: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('news'),
    (state) => state.get('dashboard').get('toast'),
    (user, news, toast) => ({ user, news, toast })
);

export default connect(mapStateToProps, null)(injectIntl(NewsForm));
