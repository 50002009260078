import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import Pages, { Page } from 'components/App/partials/Pages';
import List from 'components/App/partials/List';
import { Menu, MenuContent, MenuTrigger } from 'common/Util';
import ActionsMenu, { getActionsFromDossier } from 'components/ActionsMenu';
import auth from 'components/Users/auth';
import appMessages from 'components/App/messages';
import vendorMessages from 'components/Vendor/messages';
import Loader from 'components/App/partials/Loader';
import { sortDate } from 'components/App/utils';
import DossierFilesList from '../partials/DossierFilesList';
import messages from '../messages';
import { editDossier, initDossierDocuments, initDossierActions } from '../actions';
import { DOSSIERS_URL } from '../constants';
import { DEFAULT_LOCALE } from 'components/App/constants';

class Dossier extends Component {
    static propTypes = {
        user: PropTypes.object,
        intl: PropTypes.object,
    }
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props, context) {
        super(props);

        const vm = this;
        const { intl } = props;
        vm.state = {
            dossier: {},
            actions: [],
            requests: [],
            loading: true,
        };

        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.dossierId = +context.router.params.id;

        vm.requestLogColumns = [
            {
                label: intl.formatMessage(appMessages.id),
                key: 'id',
            },
            {
                label: intl.formatMessage(appMessages.funder),
                key: 'funder_name',
            }, {
                label: intl.formatMessage(appMessages.status),
                key: 'status',
                formatter: (value) => (<FormattedMessage {...messages.states[value]} />),
            }, {
                label: intl.formatMessage(appMessages.sentAt),
                key: 'created_at',
                formatter: (value) => moment(value).format('LLL'),
            }, {
                label: intl.formatMessage(appMessages.expiresAt),
                key: 'expiry_date',
                formatter: (value) => !value || value === 'N/A' ? value : moment(value).format('LLL'),
            }, {
                label: intl.formatMessage(appMessages.values),
                key: 'values',
                formatter: (value, entity) => (<RequestValuesMenu values={entity} />),
            }, {
                label: intl.formatMessage(appMessages.response),
                key: 'response',
                formatter: (value) => value !== 'N/A' ? (<RequestResponseMenu response={value} />) : value,
            },
        ];
        vm.actionLogColumns = [
            {
                label: intl.formatMessage(appMessages.user),
                key: 'user_first_name',
                formatter: (value, entity) => `${entity.user_first_name} ${entity.user_last_name}`,
            }, {
                label: intl.formatMessage(appMessages.date),
                key: 'created_at',
                formatter: (value) => moment(value).format('LLL'),
            }, {
                label: intl.formatMessage(appMessages.action),
                key: 'action_type',
                formatter: (value) => props.intl.formatMessage(appMessages.actions[value]),
            }, {
                label: intl.formatMessage(appMessages.expiresAt),
                key: 'expiry_date',
                formatter: (value, entity) => {
                    if (entity.action_type === 'ACCEPT') {
                        return moment(value).format('LL');
                    }
                    return '';
                },
            }, {
                label: intl.formatMessage(appMessages.summary),
                key: 'summary',
                formatter: (value) => {
                    if (typeof value === 'object' && value !== null) {
                        return <RequestValuesMenu values={value} />;
                    }
                    return value
                        ? (<ActionSummaryMenu summary={value} />)
                        : props.intl.formatMessage(appMessages.na);
                },
            },
        ];
        vm.detailColumns = [
            {
                label: intl.formatMessage(appMessages.item),
                key: 'label',
            },
            {
                label: intl.formatMessage(appMessages.value),
                key: 'value',
                formatter: (value, entity) => {
                    const descriptor = (
                        appMessages[value] ||
                        vendorMessages.contractTypes[value] ||
                        vendorMessages.paymentTypes[value] ||
                        messages[value] ||
                        messages.states[value]
                    );

                    if (entity.key === 'client_name') {
                        const url = `/vendors/${vm.state.dossier.vendor}/clients/${vm.state.dossier.client}/edit?referrer=/dossiers/${vm.state.dossier.id}`;
                        const rre = vm.state.dossier.client_has_rrecode_assigned;

                        return (<a href={url} style={{ textDecoration: 'underline' }}>
                            {value}
                            {rre && ' - RRE'}
                            {rre && <i className="fa fa-check-circle green"></i>}
                        </a>);
                    }

                    const parsedValue = descriptor ? intl.formatMessage(descriptor) : value;

                    if (entity.key.endsWith('_amount') || entity.key.endsWith('_cost') || entity.key === 'total_rent_plus_maintenance') {
                        // format all amounts in the Dutch way.
                        return new Intl.NumberFormat('nl', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(parsedValue);
                    }
                    if (entity.key === 'duration') {
                        return intl.formatMessage(
                            appMessages.months,
                            {
                                months: parsedValue,
                            }
                        );
                    }
                    return moment(parsedValue, moment.ISO_8601).isValid() ? moment(parsedValue).format('LLL') : parsedValue;
                },
            },
        ];
    }
    componentDidMount() {
        const vm = this;
        vm.getData();

        // We go back to dossiers, apply filters.
        this.props.router.setRouteLeaveHook(this.props.route, () => {
            localStorage.setItem('restoreFiltersOnDossier', true);
        });
    }

    componentWillReceiveProps(nextProps) {
        const vm = this;
        const nextDossier = _.find(nextProps.dossiers, { id: vm.dossierId });

        if (!_.isEqual(nextProps.location, vm.props.location)) {
            vm.dossierId = +vm.context.router.params.id;
            vm.getData();
        }

        if (!_.isEmpty(nextDossier) && !_.isEqual(nextDossier, vm.state.dossier)) {
            vm.state.dossier = nextDossier;
            vm.state.dossier.hardware_amount = Math.round(vm.state.dossier.hardware_amount * 100) / 100;
            vm.state.dossier.software_amount = Math.round(vm.state.dossier.software_amount * 100) / 100;
            vm.state.dossier.rental_amount = Math.round(vm.state.dossier.rental_amount * 100) / 100;
            vm.state.actions = getActionsFromDossier(nextDossier);
            if (auth.userIsAtlance(vm.props.user)) {
                vm.getRequests(nextDossier);
            }
        }

        vm.setState(vm.state);
    }
    getData() {
        const vm = this;
        const { headers } = vm;
        const { dispatch } = vm.props;
        const requests = [
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/`, { headers }),
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/actions/`, { headers }),
            axios.get(`${DOSSIERS_URL}${vm.dossierId}/documents/`, { headers }),
        ];

        vm.state.loading = true;
        vm.setState(vm.state);

        axios.all(requests).then((response) => {
            const dossier = response[0].data.result;
            dispatch(editDossier(dossier));
            dispatch(initDossierDocuments(vm.dossierId, response[2].data.result, vm.props.user.token));
            dispatch(initDossierActions(vm.dossierId, response[1].data.result));
            vm.state.loading = false;
            vm.setState(vm.state);
        });
    }
    getRequests(dossier) {
        const vm = this;
        const { headers } = vm;

        axios.get(`${DOSSIERS_URL}${vm.dossierId}/creditrequests/`, { headers }).then((response) => {
            vm.state.requests = _.map(response.data.result, (request) =>
                _.forEach(request, (value, key) => {
                    if (!value) {
                        request[key] = dossier[key] || dossier[key] === 0 ? dossier[key] : vm.props.intl.formatMessage(appMessages.na);
                    }
                })
            );
            vm.setState(vm.state);
        });
    }
    getVendorProps() {
        return [
            'client_name',
            'equipment_description',
            'software_amount',
            'hardware_amount',
            'software_rate',
            'hardware_rate',
            'duration_type',
            'duration',
            'rental_amount',
            'is_client_owner_of_building',
            'owner_name',
            'owner_street',
            'owner_number',
            'owner_postalcode',
            'owner_city',
            'has_creditor',
            'creditor_name',
            'creditor_street',
            'creditor_number',
            'creditor_postalcode',
            'creditor_city',
            'has_guarantee',
            'guarantee_period',
            'has_construcor',
            'constructor_is_other_company',
            'constructor_name',
            'constructor_street',
            'constructor_number',
            'constructor_postalcode',
            'constructor_city',
        ];
    }
    getSignificantProps() {
        return [
            'vendor_name',
            'vendor_owner_name',
            'atlance_owner_name',
            'status',
            'client_name',
            'type',
            'payment_type',

            'equipment_description',
            'hardware_rate',
            'software_rate',
            'hardware_amount',
            'software_amount',
            'duration',
            'duration_type',
            'rental_amount',
            'residual_amount',
            'total_rent_plus_maintenance',

            'has_zero_initial',
            'has_co_debition',
            'has_increased_first_rental',

            'funder_name',
            'econocom_contractnumber',
            'econocom_customeraccount',

            'calc_table_name',
        ];
    }
    getSignificantData() {
        const vm = this;
        const { dossier } = vm.state;
        const keys = vm.getSignificantProps();
        const fields = vm.filterDossierProps(dossier, keys);
        return fields.sort((a, b) => keys.indexOf(a.key) - keys.indexOf(b.key));
    }
    getExceptionProps() {
        return [
            'has_start_date',
            'start_date',
            'co_client',
            'co_client_name',
            'is_ten_twelve',
            'first_rental',
            'process_verbal',
            'low_limit_amount',
            'high_limit_amount',
            'zero_amount_intervals',
        ];
    }
    getExceptionData() {
        const vm = this;
        const { dossier } = vm.state;

        return vm.filterDossierProps(dossier, vm.getExceptionProps());
    }
    getRestProps() {
        const vm = this;
        const props = _.concat(vm.getSignificantProps(), vm.getExceptionProps());
        return _.without(_.keys(vm.state.dossier), ...props);
    }
    getRestData() {
        const vm = this;
        const { dossier } = vm.state;

        return vm.filterDossierProps(dossier, vm.getRestProps());
    }
    contentClasslist() {
        const vm = this;
        const classList = [
            'dossier-content',
            `dossier-${vm.dossierId}`,
            'layout-column',
        ];
        classList.push('layout-gt-sm-row');
        return classList.join(' ');
    }
    mapDossierProps(dossier) {
        const vm = this;
        const { intl } = vm.props;
        return _.map(dossier,
            (value, key) => {
                if (_.has(messages.fields, key)) {
                    let displayValue = value;
                    if (key === 'client_name') {
                        displayValue = `${value}${vm.state.dossier.client_vat ? ` - ${vm.state.dossier.client_vat}` : ''}`;
                    }
                    return {
                        label: intl.formatMessage(messages.fields[key]),
                        value: displayValue === null ? intl.formatMessage(appMessages.na) : displayValue,
                        key,
                    };
                }
                return false;
            }
        );
    }
    filterDossierProps(dossier, targetProps) {
        const vm = this;
        const { user } = vm.props;

        if (!auth.userIsAtlance(user)) {
            dossier = _.pick(dossier, vm.getVendorProps()); // eslint-disable-line
        }

        return _.filter(
            vm.mapDossierProps(dossier),
            (value) => ~targetProps.indexOf(value.key)
        );
    }
    render() {
        const vm = this;
        const { dossier, requests, actions } = vm.state;
        const { user, documents } = vm.props;
        let actionLog = vm.props.actions[vm.dossierId];
        const significantData = vm.getSignificantData();
        const exceptionData = vm.getExceptionData();
        const restData = vm.getRestData();

        if (actionLog && 'available_read_actions' in dossier) {
            actionLog = actionLog.filter(
                (log) => (dossier.available_read_actions || []).includes(log.action_type)
            );
        }

        return (
            <div id="Dossier" className="dossier">
                <div className="dossier-header layout-row">
                    <h1 className="flex"><FormattedMessage {...appMessages.dossier} /></h1>
                    <div className="dossier-info-controls detail-controls flex-nogrow layout-row layout-align-center-end">
                        <ActionsMenu actions={actions} />
                    </div>
                </div>
                <Loader active={vm.state.loading}>
                    <div className={vm.contentClasslist()}>
                        <div className="dossier-info flex">
                            <Pages
                                className="dossier-info-pages"
                                previousLabel={false}
                                nextLabel={false}
                            >
                                {!_.isEmpty(significantData) ?
                                    <Page>
                                        <List
                                            name="dossier-info-significant"
                                            columns={vm.detailColumns}
                                            data={significantData}
                                            itemsPerPage={0}
                                            pageButtonLimit={0}
                                            showActions={false}
                                        />
                                    </Page>
                                    : null}
                                {!_.isEmpty(exceptionData) ?
                                    <Page>
                                        <List
                                            name="dossier-info-exception"
                                            columns={vm.detailColumns}
                                            data={exceptionData}
                                            itemsPerPage={0}
                                            pageButtonLimit={0}
                                            showActions={false}
                                        />
                                    </Page>
                                    : null}
                                {!_.isEmpty(restData) ?
                                    <Page>
                                        <List
                                            name="dossier-info-rest"
                                            columns={vm.detailColumns}
                                            data={restData}
                                            itemsPerPage={0}
                                            pageButtonLimit={0}
                                            showActions={false}
                                        />
                                    </Page>
                                    : null}
                            </Pages>
                        </div>
                        <div className="layout-column flex">
                            {auth.userIsAtlance(user) ?
                                <div className="dossier-request-log">
                                    <h2 className="flex"><FormattedMessage {...appMessages.requestLog} /></h2>
                                    <List
                                        name="request-log"
                                        columns={vm.requestLogColumns}
                                        data={requests}
                                    />
                                </div>
                                : null}
                            <div className="dossier-action-log">
                                <h2 className="flex"><FormattedMessage {...appMessages.actionLog} /></h2>
                                <List
                                    name="action-log"
                                    columns={vm.actionLogColumns}
                                    data={actionLog}
                                    sortable={[
                                        'user',
                                        { column: 'created_at', sortFunction: sortDate },
                                        'action_type',
                                        'summary',
                                    ]}
                                    defaultSort={{
                                        column: 'created_at',
                                        sortFunction: sortDate,
                                        direction: 'desc',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <DossierFilesList documents={documents[vm.dossierId]} dossier={dossier} />
                </Loader>
            </div>
        );
    }
}

function RequestValuesMenu(props) {
    // List used to only set certain keys in the request log values popup
    const requiredKeys = [
        'funder_name',
        'created_at',
        'comment_to_funder',
        'investment_amount',
        'rental_amount',
        'duration',
        'residual_amount',
        'duration_type',
        'residual_value',
    ];
    return (
        <div className="dossier-request-values">
            <Menu
                hoverTrigger
                scrollMask={false}
            >
                <MenuTrigger className="request-menu-trigger">
                    <i className="icon-magnifier" />
                </MenuTrigger>
                <MenuContent>
                    <ul>
                        {_.map(props.values, (value, key) =>
                            _.has(messages.fields, key) && requiredKeys.includes(key) && (
                                <li key={key}>
                                    <span className="bold"><FormattedMessage {...messages.fields[key]} /></span>: {value}
                                </li>
                            ))}
                    </ul>
                </MenuContent>
            </Menu>
        </div>
    );
}
RequestValuesMenu.propTypes = {
    values: PropTypes.object,
};

function RequestResponseMenu(props) {
    return (
        <div className="dossier-request-response">
            <Menu
                hoverTrigger
                scrollMask={false}
            >
                <MenuTrigger className="request-response-menu-trigger">
                    <i className="icon-magnifier" />
                </MenuTrigger>
                <MenuContent>
                    {props.response}
                </MenuContent>
            </Menu>
        </div>
    );
}
RequestResponseMenu.propTypes = {
    response: PropTypes.string,
};

function ActionSummaryMenu(props) {
    return (
        <div className="dossier-action-summary">
            <Menu
                hoverTrigger
                scrollMask={false}
            >
                <MenuTrigger className="action-summary-menu-trigger">
                    <i className="icon-magnifier" />
                </MenuTrigger>
                <MenuContent>
                    <span className="bold"><FormattedMessage {...appMessages.details} /></span>: {props.summary}
                </MenuContent>
            </Menu>
        </div>
    );
}
ActionSummaryMenu.propTypes = {
    summary: PropTypes.string,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dossiers').get('dossiers').toJS(),
    (state) => state.get('dossiers').get('documents').toJS(),
    (state) => state.get('dossiers').get('actions').toJS(),
    (user, dossiers, documents, actions) => ({ user, dossiers, documents, actions })
);

export default connect(mapStateToProps)(injectIntl(Dossier));
