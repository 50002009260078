import PropTypes from 'prop-types';
import React from 'react';

class MenuTrigger extends React.Component {
    static contextTypes = {
        menu: PropTypes.object,
    }
    static defaultProps = {
        className: '',
        onClick: () => {},
        onMouseEnter: () => {},
    }
    static propTypes = {
        // className: PropTypes.string,
        // onClick: PropTypes.func,
        // onMouseEnter: PropTypes.func,
    }
    constructor(props) {
        super(props);

        const vm = this;
        vm.onClick = vm.onClick.bind(vm);
        vm.onMouseEnter = vm.onMouseEnter.bind(vm);
    }
    onClick(event) {
        const vm = this;
        const { menu } = vm.context;
        menu.toggle(event);
        vm.props.onClick(event, menu);
    }
    onMouseEnter(event) {
        const vm = this;
        const { menu } = vm.context;
        menu.open();
        vm.props.onMouseEnter(event, menu);
    }
    render() {
        const vm = this;
        const { menu } = vm.context;
        const props = {
            className: `xs2-menu-trigger ${vm.props.className}`,
            onClick: vm.onClick,
        };

        if (menu.props.hoverTrigger) {
            props.onMouseEnter = vm.onMouseEnter;
        }

        return (
            <button {...props}>
                {vm.props.children}
            </button>
        );
    }
}

export default MenuTrigger;
