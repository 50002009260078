import Validator from '../Validator';

const options = {
    rules: [],
};

options.rules.push({
    name: 'required',
    pattern: new RegExp(/(?!^$)|^\S+/),
    error: 'required',
});

export default new Validator(options);
