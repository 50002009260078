import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Form, FormGroup, FieldGroup, Input, Autocomplete, Button, Checkbox } from 'common/Form';
import { API_URL, DEFAULT_LOCALE } from 'components/App/constants';
import { VENDORS_URL } from 'components/Vendor/constants';
import appMessages from 'components/App/messages';
import { vatValidator, vatRegex, getEntityIdFromUrl } from 'components/App/utils';
import auth from 'components/Users/auth';

import { newVendor, editVendor, addEquipmentType, removeEquipmentType } from 'components/Vendor/actions';
import messages from '../messages';
import LogoUploader from './LogoUploader';
import { FUNDERS_URL } from "components/Dossiers/constants";

class VendorForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    static propTypes = {
        // action: React.PropTypes.string,
        vendorId: PropTypes.number,
        user: PropTypes.object,
        intl: PropTypes.object,
    }
    constructor(props) {
        super(props);

        const vm = this;
        const { intl } = props;

        vm.state = {
            retrievingCompanyInfo: false,
        };
        vm.fields = {};
        vm.headers = { Authorization: `Token ${props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };
        vm.vendorId = props.vendorId || +getEntityIdFromUrl();

        vm.countryOptions = ['BE', 'FR', 'LU', 'NL'].map((code) => ({
            value: code,
            label: new Intl.DisplayNames([intl.locale], { type: 'region' }).of(code),
        }));

        vm.languageOptions = ['fr', 'en', 'nl'].map((code) => ({
            value: code.toUpperCase(),
            label: new Intl.DisplayNames([intl.locale], { type: 'language' }).of(code),
        }));

        vm.termsTypeOptions = [
            { value: '', disabled: true, label: intl.formatMessage(appMessages.noop) },
            { value: 'STANDARDINSURANCE', label: intl.formatMessage(messages.termtypes.standardInsurance) },
            { value: 'SPECIALEQUIPMENTWITHINSURANCE', label: intl.formatMessage(messages.termtypes.specialEquipment) },
        ];

        vm.onSubmit = vm.onSubmit.bind(vm);
        vm.addEquipmentType = vm.addEquipmentType.bind(vm);
        vm.removeEquipmentType = vm.removeEquipmentType.bind(vm);
        vm.getVendorBI = _.debounce(vm.getVendorBI.bind(vm), 500);
    }
    componentWillReceiveProps(nextProps) {
        const vm = this;
        const { dispatch } = vm.props;
        const vendor = vm.props.vendor || nextProps.vendors.find((i) => i.id === vm.vendorId);

        if (nextProps.vendors.length && !vendor && !vm.vendorId) {
            vm.vendorId = Infinity;
            dispatch(newVendor({
                id: vm.vendorId,
                name: '',
                street: '',
                number: '',
                postalcode: '',
                country: '',
                phone: '',
                vat: '',
                accounting_email: '',
                language: '',
                terms_type: '',
                commission_percentage: '',
                commercial_action_percentage: '',
                equipment_types: [],
            }));
        }
        if (vendor && !vendor.equipment_types.length) {
            vm.addEquipmentType();
        }
    }
    componentDidMount() {
        const vm = this;
        vm.getData();
    }
    onSubmit(data) {
        const vm = this;
        const { toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        if (!data.commission_percentage) {
            delete data.commission_percentage;
        }
        if (!data.commercial_action_percentage) {
            delete data.commercial_action_percentage;
        }
        if (!data.terms_type) {
            delete data.terms_type;
        }
        if (data.equipment_types.length === 1 && !data.equipment_types[0].description) {
            delete data.equipment_types;
        }
        // Map allowed funders to a more logical format for a DRF interface
        const allowedFunders = [];
        for (const [key, value] of Object.entries(data)) {
            if (key.startsWith('allowed_funder_')) {
                if (value === true) {
                    const funderId = key.split('_')[2];
                    allowedFunders.push({ id: Number(funderId) });
                }
                delete data[key];
            }
        }
        data.allowed_funders = allowedFunders;

        if (vm.props.action === 'new') {
            axios.post(`${VENDORS_URL}`, data, { headers }).then((response) => {
                dispatch(newVendor(response.data.result));
                vm.context.router.push(`/vendors/${response.data.result.id}`);
                return toast.success('Success!', <FormattedMessage {...messages.notifications.createdVendor} values={{ ...response.data.result }} tagName="p" />);
            });
        } else if (vm.props.action === 'edit') {
            axios.patch(`${VENDORS_URL}${vm.vendorId}/`, data, { headers }).then((response) => {
                dispatch(editVendor(response.data.result));
                vm.context.router.push(`/vendors/${vm.vendorId}`);
                return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedVendor} values={{ ...response.data.result }} tagName="p" />);
            });
        }
    }
    getData() {
        const vm = this;
        const { headers } = vm;

        axios.get(`${FUNDERS_URL}`, { headers }).then((response) => {
            const funders = response.data.result;
            vm.state.funders = funders;
            vm.setState(vm.state);
        });
    }
    getVendorBI(event, value) {
        const vm = this;
        const { headers } = vm;
        const vat = value;
        const isValidVAT = vatRegex.test(vat);

        if (isValidVAT) {
            vm.setState({ retrievingCompanyInfo: true });
            axios.get(`${API_URL}vat_check/`, { params: { vat }, headers }).then((response) => {
                const company = response.data.result;
                _.forEach(vm.fields, (field, key) => {
                    if (typeof field.getWrappedInstance === 'function') {
                        const input = field.getWrappedInstance();
                        if (input) {
                            input.setValue(company[key] || '');
                        }
                    }
                });
            }).finally(() => {
                vm.setState({ retrievingCompanyInfo: false });
            });
        }
    }
    addEquipmentType() {
        const vm = this;
        const { dispatch } = vm.props;

        dispatch(addEquipmentType(vm.vendorId, { details: '' }));
    }
    removeEquipmentType(index) {
        const vm = this;
        const { dispatch } = vm.props;

        dispatch(removeEquipmentType(vm.vendorId, index));
    }
    render() {
        const vm = this;
        const { intl, user } = vm.props;
        const formName = `${intl.formatMessage(appMessages[vm.props.action])} vendor`;
        const vendor = vm.props.vendor || vm.props.vendors.find((i) => i.id === vm.vendorId) || { funder_choices: [] };

        const funders = vm.state.funders || [];
        const funderChoices = funders.length > 0
            ? funders.map((funder) =>
                (<Checkbox
                    name={`allowed_funder_${funder.id}`}
                    key={funder.id}
                    id={funder.id}
                    customLabel={funder.name}
                    defaultValue={vendor.allowed_funders ? vendor.allowed_funders.some((allowedFunder) => allowedFunder.id === funder.id) : false}
                />)
            )
            : <p>No funders yet</p>;
        return (
            <div id="VendorForm" className="vendor-form">
                <h1 className="center-align">{ formName }</h1>
                <h2 className="center-align">{ vendor.name }</h2>
                <Form id={`${vm.props.action}-vendor`} submitHandler={vm.onSubmit} theme="material">
                    <FormGroup id="vendor-contact-info">
                        <h2 className="form-group-label">Contact info</h2>
                        <Input
                            type="text"
                            name="name"
                            label={appMessages.name}
                            defaultValue={vendor.name}
                            ref={(ref) => { vm.fields.name = ref && ref.getWrappedInstance(); }}
                            // required
                        />
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                className="input-large"
                                name="street"
                                label={appMessages.street}
                                defaultValue={vendor.street}
                                ref={(ref) => { vm.fields.street = ref && ref.getWrappedInstance(); }}
                                // required
                            />
                            <Input
                                type="text"
                                className="input-small"
                                name="number"
                                label={appMessages.number}
                                defaultValue={vendor.number}
                                ref={(ref) => { vm.fields.number = ref && ref.getWrappedInstance(); }}
                                // required
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                className="input-small"
                                name="postalcode"
                                label={appMessages.postalcode}
                                defaultValue={vendor.postalcode}
                                ref={(ref) => { vm.fields.postalcode = ref && ref.getWrappedInstance(); }}
                                // required
                            />
                            <Autocomplete
                                name="country"
                                className="input-small"
                                label={appMessages.country}
                                defaultValue={vendor.country || 'BE'}
                                options={vm.countryOptions}
                                ref={(ref) => { vm.fields.country_code = ref && ref.getWrappedInstance(); }}
                                // required
                            />
                            <Input
                                type="text"
                                name="city"
                                className="input-medium"
                                label={appMessages.city}
                                defaultValue={vendor.city}
                                ref={(ref) => { vm.fields.city = ref && ref.getWrappedInstance(); }}
                                // required
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                type="text"
                                name="phone"
                                className="input-normal"
                                label={appMessages.phone}
                                defaultValue={vendor.phone}
                                // required
                            />
                            <Input
                                type="text"
                                name="vat"
                                className="input-normal"
                                label={appMessages.vat}
                                defaultValue={vendor.vat}
                                onInput={vm.getVendorBI}
                                validator={vatValidator}
                                loading={this.state.retrievingCompanyInfo}
                                // required
                            />
                            <Input
                                type="email"
                                name="accounting_email"
                                className="input-normal"
                                label={appMessages.email}
                                defaultValue={vendor.accounting_email}
                                noValidate
                                // required
                            />
                        </FormGroup>
                        <Autocomplete
                            name="language"
                            className="input-small"
                            label={appMessages.language}
                            defaultValue={vendor.language || 'NL'}
                            options={vm.languageOptions}
                            // required
                        />
                        {this.props.action === 'edit' &&
                            <LogoUploader vendor={vendor} />
                        }
                    </FormGroup>
                    <FormGroup id="vendor-contract-options">
                        <h2 className="form-group-label"><FormattedMessage {...messages._contractTypes} /></h2>
                        <FormGroup className="input-group">
                            <Autocomplete
                                name="terms_type"
                                className="input-normal"
                                label={appMessages.terms_type}
                                defaultValue={vendor.terms_type}
                                options={vm.termsTypeOptions}
                            />
                            <Input
                                type="text"
                                className="input-normal"
                                name="commission_percentage"
                                label={appMessages.commissionPercentage}
                                defaultValue={vendor.commission_percentage}
                            />
                            <Input
                                type="text"
                                className="input-normal"
                                name="commercial_action_percentage"
                                label={appMessages.commercialActionPercentage}
                                defaultValue={vendor.commercial_action_percentage}
                            />
                            {/* <Input type="number" name="rate" label={appMessages.rate} defaultValue={vendor.rate} /> */}
                        </FormGroup>
                    </FormGroup>
                    {auth.userIsAtlance(user) ?
                        <FormGroup id="vendor-equipment-types">
                            <h2 className="form-group-label"><FormattedMessage {...appMessages.equipmentTypes} /></h2>
                            <FieldGroup
                                name="equipment_types"
                                add={vm.addEquipmentType}
                                remove={vm.removeEquipmentType}
                                data={vendor.equipment_types}
                            >
                                <Input
                                    type="text"
                                    name="description"
                                />
                            </FieldGroup>
                        </FormGroup>
                        : null}
                    {auth.userIsAtlance(user) ?
                        <FormGroup id="vendor-allowed-funders">
                            <h2 className="form-group-label"><FormattedMessage {...appMessages.allowedFunders} /></h2>
                            {funderChoices}
                        </FormGroup>
                        : null}
                    <FormGroup id="vendor-contract-submit">
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('vendors'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('forms').toJS(),
    (user, vendors, toast, forms) => ({ user, vendors, toast, forms })
);

export default connect(mapStateToProps, null)(injectIntl(VendorForm));
