import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, FieldGroup, Input, Button } from 'common/Form';
import appMessages from 'components/App/messages';
import { editVendor, addEquipmentType, removeEquipmentType } from '../actions';
import { VENDORS_URL } from '../constants';
import messages from '../messages';
import { DEFAULT_LOCALE } from 'components/App/constants';

class EquipmentTypeForm extends React.Component {
    constructor(props) {
        super(props);
        const vm = this;
        vm.add = vm.add.bind(vm);
        vm.remove = vm.remove.bind(vm);
        vm.onSubmit = vm.onSubmit.bind(vm);
    }
    componentWillMount() {
        const vm = this;
        const vendor = vm.props.vendors.length && +vm.props.vendorId
                       ? vm.props.vendors.find((i) => i.id === +vm.props.vendorId)
                       : {};

        if (!vendor.equipment_types.length) {
            vm.add();
        }
    }
    onSubmit(data) {
        const vm = this;
        const { toast, dispatch } = vm.props;
        const headers = { Authorization: `Token ${vm.props.user.token}`, 'Accept-Language': window.localStorage.getItem('locale') || DEFAULT_LOCALE || 'nl' };

        const referrer = vm.context.router.getCurrentLocation().query.referrer;

        axios.patch(`${VENDORS_URL}${vm.props.vendorId}/`, data, { headers }).then((response) => {
            dispatch(editVendor(response.data.result));
            vm.context.router.push(referrer || vm.context.router.push(`/vendors/${vm.props.vendorId}`));
            return toast.success('Success!', <FormattedMessage {...messages.notifications.updatedEquipment} values={{ ...response.data.result }} tagName="p" />);
        });
    }
    add() {
        const vm = this;
        const { dispatch } = vm.props;
        dispatch(addEquipmentType(vm.props.vendorId, { details: '' }));
    }
    remove(index) {
        const vm = this;
        const { dispatch } = vm.props;
        dispatch(removeEquipmentType(vm.props.vendorId, index));
    }
    render() {
        const vm = this;
        const vendor = vm.props.vendors.length && +vm.props.vendorId
                       ? vm.props.vendors.find((i) => i.id === +vm.props.vendorId)
                       : {};

        return (
            <div id="EquipmentTypeForm" className="vendor-equipment-type-form">
                <h1 className="center-align">{vendor.name}</h1>
                <h2 className="center-align"><FormattedMessage {...appMessages.equipmentTypes} /></h2>
                <Form id="vendor-equipment-types" submitHandler={vm.onSubmit} theme="material">
                    <FormGroup id="equipment-type-details">
                        <FieldGroup
                            name="equipment_types"
                            add={vm.add}
                            remove={vm.remove}
                            data={vendor.equipment_types}
                        >
                            <Input type="text" name="description" label={appMessages.details} required />
                        </FieldGroup>
                    </FormGroup>
                    <FormGroup>
                        <Button><FormattedMessage {...appMessages.save} /></Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

EquipmentTypeForm.contextTypes = {
    router: PropTypes.object,
};

const mapStateToProps = createSelector(
    (state) => state.get('user'),
    (state) => state.get('dashboard').get('toast'),
    (state) => state.get('vendors'),
    (user, toast, vendors) => ({ user, toast, vendors })
);

export default connect(mapStateToProps, null)(EquipmentTypeForm);
